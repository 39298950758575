import withStyles from "@material-ui/core/styles/withStyles";
import {map} from 'lodash';
import React from "react";
import GoldBadge from 'common/assets/gold.png';
import SilverBadge from 'common/assets/silver.png';
import BronzeBadge from 'common/assets/bronze.png';
import { useSetTranslation } from "common/useSetTranslation";


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  badgeContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 8px 30px 23px'
  },
  badgeTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  sessionList: {
    fontWeight: 500,
    fontSize: 10
  }
});


const badges = {
  GOLD: GoldBadge,
  SILVER: SilverBadge,
  BRONZE: BronzeBadge,
};


const getBadgeImage = (type) => {
  return badges[type];
};

const Badge = ({classes, type, title, list}) => {
  const {st} = useSetTranslation();

  return (
    <div className={classes.wrapper}>
      <div>
        <img src={getBadgeImage(type)} width='24'/>
      </div>
      <div className={classes.badgeContent}>
        <div className={classes.badgeTitle} style={{color: '#E0B334'}}>{list.length} {title}</div>
        <div className={classes.sessionList}>{map(list, listItem => <p
          style={{lineHeight: '12px'}}>- {st(listItem.sessionTitle, listItem.localeSessionTitle)}</p>)}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Badge);


