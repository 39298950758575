import React from "react";
import {Redirect, Route} from "react-router-dom";


const ProtectedRoute = ({
                          component: Component,
                          isAuthenticated,
                          isVerifying,
                          ...rest
                        }) => (
  <Route
    {...rest}
    render={props =>
      isVerifying ? (
        <div/>
      ) : isAuthenticated ? (
        <Component {...props} />
      ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: {from: props.location}
              }}
            />
          )
    }
  />
);
export default ProtectedRoute;
