import _, {find, get, has, isEmpty, values,toPairs} from 'lodash';

export const isQuizAnswersForMentorship = (quizAnswers) => has(quizAnswers, 'menteeAnswers');

export const getSelfAnswers = (isLearningHub, quizAnswers) => {
  if (isLearningHub) return quizAnswers;
  return quizAnswers.userAnswer;
};

export const getPartnerAnswers = (quizAnswers) => {
  return quizAnswers.partnerDetails;
};

export const selfQuizAnswered = (role, quizAnswers, isLearningHub) => {
  return !isEmpty(!isLearningHub ? get(toPairs(getSelfAnswers(isLearningHub, quizAnswers)), '[0][1]', []) : quizAnswers);
};

export const getPartnerName = (partnerDetails, id) => {
  const partner = find(partnerDetails, partner => partner.id === id);
  return get(partner, 'firstName') + " " + get(partner, 'lastName')
};
