import Video from "common/assets/videoBlack.svg";
import React from "react";
import {startVideo} from "actions/videoAction";
import {connect} from "react-redux";
import CircularIcon from "components/CircularIcon";
import {noop} from 'lodash';

export const VideoButtonComponent = ({
                                       videoEnabled = true,
                                       channel,
                                       startNewVideo,
                                       isBlackedOut,
                                       onClick = noop
                                     }) => {
  const onButtonClick = () => {
    if (isBlackedOut) return;
    startNewVideo(channel);
    onClick();
  };
  return videoEnabled ? (
    <CircularIcon onClick={onButtonClick} style={{backgroundColor: isBlackedOut ? 'rgba(0, 0, 0, 0.4)' : '#ffffff'}}>
      <img src={Video} alt={""}/>
    </CircularIcon>
  ) : <></>;
};

const mapStateToProps = ({auth: {user: {status: {experiences: {video}}}}}) => ({videoEnabled: video});

export const VideoButton = connect(mapStateToProps, {startNewVideo: startVideo})(VideoButtonComponent);
