import _ from 'lodash';
import moment from 'moment/moment';

const questionType = (key, questions) => _.get(_.find(questions, {key}), 'questionType');

export const convertToSubmitFormat = (questions, answers) => {

  const answersAfterValueConversion = _.mapValues(answers, (value, key) => {
    switch (questionType(key, questions)) {
      case 'Text':
      case 'TextBox':
      case 'AgreeabilityTenScale':
      case 'AgreeabilitySevenScale':
      case 'AgreeabilityThreeOptions':
      case 'AgreeabilityFiveOptions':
      case 'Scale':
        return value;
      case 'Number':
        return !_.isEmpty(value) && (isNaN(parseFloat(value)) || !isFinite(value)) ?
               null : _.toNumber(value);
      case 'Date':
        return moment(value).format('YYYY-MM-DD');
      case 'SingleChoiceRadio':
      case 'SingleChoiceDropDown':
      case 'SingleChoiceButton':
        return value && value.key;
      case 'SingleChoiceWithImage':
        return value && (value.length === 1) ? value[0].key : null;
      case 'MultiChoiceWithImage':
      case 'MultiChoiceDropDown':
      case 'ArrangeInOrder':
        return _.map(value, (answer) => answer.key);
      default:
        // console.log('convertToSubmitFormat: cannot find case for ', questionType(key, questions));
        return null;
    }
  });
  const answersAfterRemovingNulls = _.pickBy(answersAfterValueConversion, (answer) => answer);

  return _.reduce(_.toPairs(answersAfterRemovingNulls),
    (result, [questionKey, answer]) => _.concat(result, {
      questionKey,
      answer,
    }), []);
};
