import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getColor,
  getSubTitle,
  getTimeRemaining,
  groupCompatibilityStatus,
  timeRemainingMessage,
  trackerStatus
} from "business/matching";
import classNames from 'classnames';
import MatchingCriteriaGroup from "components/Matching/MatchingCriteriaGroup";
import MatchingInfoModal from "components/MatchingInfoModal";
import {get, noop} from 'lodash';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  matchCardWrapper: {
    display: 'flex',
    padding: 23,
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    "&:last-child": {border: 0,borderBottomRightRadius: 5, borderBottomLeftRadius: 5}
  },
  expiredCard: {
    backgroundColor: '#DFDFDF',
  },
  incompatible: {
    backgroundColor: '#DFDFDF',
  },
  name: {
    fontSize: 18,
    color: '#000000'
  },
  affiliation: {
    fontSize: 12,
    color: '#EB8E6F',
    marginTop: 9,
  },
  icon: {
    backgroundColor: '#ffffff',
    color: '#808080',
    borderRadius: 2,
  },
  iconContainer: {
    backgroundColor: '#ffffff',
    width: 37,
    height: 37,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    marginRight:16,
    borderRadius:2,
  },
  infoButton: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#808080',
    backgroundColor: '#ffffff',
  },
  expiredMessage: {
    fontSize: 12,
    color: '#E14747',
    marginTop:9,
  },
  selectedGreen: {
    backgroundColor: '#41A156',
    color: '#ffffff'
  },
  incompatibleGray: {
    backgroundColor: '#E3E3E3',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  selectedBlack:{
    backgroundColor: '#000000',
    color: '#ffffff'
  },
  expiryMessage: {
    fontSize: 12,
    marginBottom: 12,
  },
  expiredName: {
    color: '#7B7B7B'
  },
  incompatibleText: {
    color: '#E14747',
    fontWeight: 'bold',
    fontSize: 12,
  }

});


const MatchingCard = ({classes, match, onUpdateMatch = noop, infoOnly, role, expiryHours, showCriteria = false, showExpiry = false}) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!Boolean(open));
  const isExpired = get(match, "status") === 'EXPIRED';
  const isActive = get(match, "status") === 'ACTIVE';
  const interestStatus = get(match, "interestStatus");
  const groupCompatibility = get(match, "groupCompatibility");
  const isSelected = interestStatus === trackerStatus.INTERESTED;
  const isRejected = interestStatus === trackerStatus.REJECTED;
  const isIncompatible = groupCompatibility === groupCompatibilityStatus.INCOMPATIBLE;
  const exclusiveCriteria = get(match,'exclusiveCriteria');
  const clashingCriterias = get(match, 'clashingCriterias', []);
  const groupFull = groupCompatibility === groupCompatibilityStatus.GROUP_FULL;
  const isPairComplete = groupCompatibility === groupCompatibilityStatus.PAIR_COMPLETE;

  return (
    <div
      className={classNames(classes.matchCardWrapper, (isIncompatible || groupFull || isPairComplete) && classes.incompatible, isExpired && classes.expiredCard)}>
      <div
        style={{paddingTop: (showCriteria || showExpiry) ? isExpired ? 5 : 36 : 5}}>
        {
          <div className={classes.iconContainer} style={{borderRadius: 43}}
               onClick={handleClose}>
            <span className={classes.infoButton}>i</span>
            <MatchingInfoModal handleClose={handleClose} open={open} fullName={match.fullName} matchId={match.id}/>
          </div>
        }
      </div>
      <div style={{display:'flex',flex:1,flexDirection:'column'}}>
        <div style={{borderBottom: showCriteria && '1px solid rgba(0, 0, 0, 0.13)', paddingBottom: showCriteria ? 20 : 0,flex:1}}>
          {!isExpired && showExpiry &&
          <div className={classes.expiryMessage}
               style={{color: getColor(getTimeRemaining(match.updatedTimestamp, expiryHours).color)}}>
            {t('autoExpiresIn')} <span style={{fontWeight: 'bold'}}>
              {timeRemainingMessage(getTimeRemaining(match.updatedTimestamp, expiryHours))}</span></div>
          }
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{flex: 1}}>
              <div className={classNames(classes.name, isExpired && classes.expiredName)}>{match.fullName}</div>

              {isIncompatible && <div className={classes.incompatibleText}>{t('incompatible')}</div>}
              {groupFull && <div className={classes.incompatibleText}>{t('groupFull')}</div>}
              {isPairComplete && <div className={classes.incompatibleText}>{t('pairComplete')}</div>}

              <div className={classes.affiliation}>{getSubTitle(match)}</div>
            </div>
            {!infoOnly && !isExpired && isActive &&
            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start',paddingTop:5}}>
                <div
                  className={classNames(classes.iconContainer, isRejected && classes.selectedBlack, isIncompatible && classes.incompatibleGray, (groupFull || isPairComplete) && classes.incompatibleGray)}
                  onClick={() => !isIncompatible && !groupFull && !isPairComplete && onUpdateMatch(trackerStatus.REJECTED)}>
                  <ClearIcon
                    className={classNames(classes.icon, isRejected && classes.selectedBlack, isIncompatible && classes.incompatibleGray, (groupFull || isPairComplete) && classes.incompatibleGray)}
                  />
                </div>
                <div
                  className={classNames(classes.iconContainer, isSelected && classes.selectedGreen, (isIncompatible || groupFull || isPairComplete) && classes.incompatibleGray)}
                  onClick={() => !isIncompatible && !groupFull && !isPairComplete && onUpdateMatch(trackerStatus.INTERESTED)}
                ><CheckIcon
                  className={classNames(classes.icon, isSelected && classes.selectedGreen, (isIncompatible || groupFull || isPairComplete) && classes.incompatibleGray)}
                />
                </div>
            </div>}
          </div>
          {isExpired && <div className={classes.expiredMessage}>{match.displayName}</div>}
        </div>
        {showCriteria &&
        <MatchingCriteriaGroup onClick={handleClose} criterias={exclusiveCriteria} clashingCriterias={clashingCriterias}/>
        }
      </div>
    </div>
  )
};

export default withStyles(styles)(MatchingCard)


