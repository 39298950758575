import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid/Grid";
import {noop} from "lodash";
import moment from "moment";
import React, {useEffect, useState} from "react";
import OtpInput from 'react-otp-input';
import {useTimer} from "react-timer-hook";
import '../common/styles/common.css';
import {useTranslation} from "react-i18next";

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex'
  },
  image: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  button: {
    marginTop: 31,
    backgroundColor: '#E0B334',
    boxShadow: '0px 2px 4px rgba(224, 179, 52, 0.25)',
    color: 'white',
    fontSize: 12
  },
  buttonDisabled: {
    backgroundColor: '#797979',
    marginTop: 31,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontSize: 12
  },
  formWrapper: {
    paddingLeft: 70,
    paddingRight: 70,
    marginTop: 40,
  },
  input: {
    marginTop: 16
  },
  error: {
    color: 'red',
    textAlign: 'center'
  },
  message: {
    textAlign: 'center'
  }
};

export default function OTP({error, onAnswered = noop, message = "", currentStep, resendOtp}) {
  const {t} = useTranslation();
  const numInputs = 4;
  const isDisabled = false;

  const [otp, setOtp] = useState("");
  const [titleState, setTitleState] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);

  const expiryTimestamp = moment(new Date).add(2, 'm').toDate();

  const {
    seconds,
    minutes,
    restart
  } = useTimer({expiryTimestamp, onExpire: () => setTitleState(0)});

  useEffect(() => {
    let newTime = moment(new Date).add(2, 'm').toDate();
    return () => {
      restart(newTime)
    };
  }, [currentStep]);

  const validateOTP = async () => {
    await onAnswered(otp);
    setInitialLoad(false);
  }

  const handleResend = () => {
    if (!titleState) {
      resendOtp();
      let newTime = moment(new Date).add(2, 'm').toDate();
      restart(newTime);
      setTitleState(1);
    }
  };

  const title = titleState ? `${t('resendOTP')} (${minutes} : ${seconds})` : t('resendOTP');

  const handleOtpChange = otp => {
    setOtp(otp);
  };

  return (
    <Grid item sm={12} md={12} style={{marginTop: 70}}>

      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
        <h2 style={{flex: 1}}>{t('enterOTP')}</h2>
        <p style={{color: '#728D9A', fontSize: 12, cursor: 'pointer'}} onClick={handleResend}>{title}</p>
      </div>
      <div style={styles.input}>
        <OtpInput
          inputStyle={{
            width: '3rem',
            height: '3rem',
            margin: '0 1rem',
            fontSize: '2rem',
            borderRadius: 4,
            border: '1px solid rgba(0,0,0,0.3)',
          }}
          numInputs={numInputs}
          isDisabled={isDisabled}
          hasErrored={!initialLoad && error}
          isInputNum={true}
          errorStyle={{borderColor: 'red'}}
          onChange={handleOtpChange}
          shouldAutoFocus
          value={otp}
        />
      </div>

      <Button variant="contained" style={otp.toString().length < numInputs ? styles.buttonDisabled : styles.button}
              fullWidth onClick={() => validateOTP()}>
        {t('continueCaps')}
      </Button>
      {(!initialLoad && error) && <h5 style={styles.error}>{t('otpVerificationFailed')}</h5>}
      {message && <h6 style={styles.message}>{message}</h6>}
    </Grid>

  );
};
