import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import SessionCard from "components/SessionCard";
import {map} from "lodash";
import React, {useEffect} from 'react';


const MaterialStyles = theme => ({
  allSessionsWrapper: {
    maxHeight: '85vh',
    display: 'flex',
    flexDirection: 'column',
  },
  allSessionsListWrapper: {
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginLeft: -12,
    marginRight: -12
  },
  header: {
    display: 'flex',
  },
  headerText: {
    color: '#766430',
    fontSize: '18px',
  },
});

const AllSessions = ({classes, allSessions, mySessions, toggleBookmark, initSessions}) => {

  useEffect(() => {
    initSessions();
  }, []);


  return (
    <div className={classNames(classes.allSessionsWrapper, 'animated faster fadeIn')}>
      <div className={classes.header}>
        <p className={classes.headerText}>All Sessions</p>
      </div>
      <div className={classes.allSessionsListWrapper}>
        {map(allSessions, session => <SessionCard style={{margin: 12}} session={session} key={session.id}
                                                  toggleBookmark={toggleBookmark}/>)}
        {map(mySessions, session => <SessionCard style={{margin: 12}} session={session} key={session.id}
                                                 toggleBookmark={toggleBookmark}/>)}
        <div style={{display: 'flex', maxWidth: 50}}/>
      </div>
    </div>
  )
};

export default withStyles(MaterialStyles)(AllSessions);
