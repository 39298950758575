import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from "components/Badge";
import {filter, isUndefined} from 'lodash';
import React from "react";
import { useTranslation } from "react-i18next";

const styles = theme => ({
  wrapper: {
    borderRadius: 5,
    backgroundColor: '#ffffff',
    width: '100%',
  },
  panelTitle: {
    fontWeight: 'bold',
    fontSize: 14
  },
  panelCount: {
    backgroundColor: '#DE6E49',
    color: 'white',
    borderRadius: '50%',
    height: 26,
    width: 26,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 4,
  },
  expansionTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  }
});


const getList = (badges, type) => filter(badges, badge => badge.badgeType === type);

const MentorshipRewards = ({classes, badges}) => {
  const {t} = useTranslation();

  return (
    <div className={classes.wrapper}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          aria-controls="panel1c-content"
          id="panel1c-header"
          classes={{content: classes.expansionTitleWrapper}}
        >
          <div className={classes.panelTitle}>{t('rewards')}</div>
          <div className={classes.panelCount}>{isUndefined(badges) ? 0 : badges.length}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <Badge type={'GOLD'} list={getList(badges, 'Gold')} title={t('goldBadges')}/>
          <Badge type={'SILVER'} list={getList(badges, 'Silver')} title={t('silverBadges')}/>
          <Badge type={'BRONZE'} list={getList(badges, 'Bronze')} title={t('bronzeBadges')}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

export default withStyles(styles)(MentorshipRewards);


