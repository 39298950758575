import {apiAction, dataAction, staticAction} from "actions/actionWrappers";
import {emailSignIn, initApp} from "actions/authAction";
import {getDeclaration, signup} from "api";
import {get, join, map, merge, toPairs} from 'lodash';
import {actions} from "reducers/profileReducer";
import {getProjects} from "../api";
import {loginType} from "../common/loginType";
import {trackEvent, events} from "../analytics/facebook";

export const addFormDetailsToProfile = (formData) => {
  return (dispatch) => {
    dispatch(dataAction(actions.ADD_FORM_DETAILS_TO_PROFILE, formData));
  }
};


export const submitProfileData = () => async (dispatch, getState) => {
  const state = getState();

  const formData = merge({
      role: state.profile.userType,
      first_name: state.profile.firstName,
      last_name: state.profile.lastName,
      institution_code: state.profile.institutionCode,
      phone_number: state.profile.phoneNumber,
      provider: state.auth.provider,
      project: get(state.profile, 'program.key'),
      new_primary_language: get(state.profile, 'primaryLanguage.key'),
    },
    state.auth.provider === loginType.EMAIL ?
      {
        email: state.auth.email,
        password: state.auth.password,
        otp: state.auth.otp
      } :
      {}
  );

  try {
    await dispatch(apiAction(signup, formData));
    dispatch(staticAction(actions.SIGNUP_SUCCESS));
    trackEvent(events.completeRegistration, state.auth.email);
    if (state.auth.provider === loginType.EMAIL) {
      dispatch(emailSignIn(state.auth.email, state.auth.password));
    } else {
      dispatch(initApp());
    }
  } catch (e) {
    console.log('Error', e);
    const error = join(get(e, 'response.data.non_field_errors'), "<br>");
    dispatch(dataAction(actions.SIGNUP_ERROR, error));
    console.log("Error: Could not submit", get(e, 'response.data.non_field_errors'))
  }

};


export const getProjectList = () => async (dispatch) => {
  const mapProjects = (response) => map(
    toPairs(get(response, 'data.projects', {})),
    (entry) => ({key: entry[0], ...entry[1]}));

  try {
    const projects = await dispatch(apiAction(getProjects));
    dispatch(dataAction(actions.SAVE_PROJECT_LIST, mapProjects(projects)))
  } catch (e) {
    console.log("Error: Error in fetching project list");
  }
};

export const gettingDeclaration = () => async (dispatch, getState) => {
  const {profile: {userType}} = getState();

  try {
    const declaration = await dispatch(apiAction(getDeclaration, userType));
    dispatch(dataAction(actions.SET_DECLARATION, declaration.declaration));
  } catch (e) {
    console.log("Error: Fetching Declaration from Server");
  }
};
