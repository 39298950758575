import withStyles from "@material-ui/core/styles/withStyles"
import classNames from "classnames"
import MatchingCard from "components/MatchingCard"
import ScreenDescription from "components/ScreenDescription"
import {get, isEmpty, map} from "lodash"
import React from "react"
import {convertUrl} from "../../common/helpers";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 12px',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  alternateTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  message: {
    fontSize: 12,
    lineHeight: '21px',
    color: '#747474',
    textAlign: 'center',
    marginTop: 14,
  },
  alternateMessage: {
    fontSize: 10,
  },
  imageWrapper: {
    marginTop: 24,
  },
  contentWrapper: {
    marginTop: 24,
    marginBottom: 40,
    backgroundColor: '#FFEFE9',
    borderRadius: 5,
    width: '390px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      objectFit: 'contain'
    },
  }
});


const AwaitingResponse = ({classes, listDetail, matches}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();
  const title = st(get(listDetail, "title"), get(listDetail, "localeTitle")) || t("awaitingResponse");
  const description = st(get(listDetail, "description"), get(listDetail, "localeDescription"))
  const image = get(listDetail, "image", "");

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <ScreenDescription description={description}
                         title={title}
      />

      {!isEmpty(image) &&
      <div className={classes.imageWrapper}>
        <img src={convertUrl(image)} className={classes.image}/>
      </div>}
      <div className={classes.contentWrapper}>
        {map(matches, match => <MatchingCard
          infoOnly={true}
          match={match}/>)}
      </div>


    </div>
  )
}

export default withStyles(styles)(AwaitingResponse)


