import { sha256 } from 'js-sha256';
const fbBizSdk = require('facebook-nodejs-business-sdk');
const EventRequest = fbBizSdk.EventRequest;
const UserData = fbBizSdk.UserData;
const ServerEvent = fbBizSdk.ServerEvent;

const ACCESS_TOKEN = 'EAAGqbHdIOjMBAIy4BNTzZAk75zRpkXaJvZAutdJbfae7y5rx5pO9YB0nf41VIZAiNEz98IiDCWZB48VXgQGUu6XG80m74UkkBbaT3w0uFOppUsa5IyDNYqNZCZCZCgZBsCvcykZCsZAnuLVNOULIZCc8LBro16AknrQvuOSRcFgFuh76ZBkc0GFWy9dOCOnxMymT2W4ZD';
const PIXEL_ID = '3039345676391152';

export const events = {completeRegistration: 'CompleteRegistration', lead: 'Lead'};


export const trackEvent = (eventName, email) => {
  const api = fbBizSdk.FacebookAdsApi.init(ACCESS_TOKEN);
  const userData = (new UserData())
    .setEmail(sha256(email.toLowerCase()))
    .setClientUserAgent(window.navigator.userAgent);
  const serverEvent = (new ServerEvent())
    .setEventName(eventName)
    .setEventTime(Math.floor(new Date() / 1000))
    .setUserData(userData)
    .setActionSource('website')
    .setEventSourceUrl(process.env.REACT_APP_HOST_URL.replace(/(m2g|console)/, 'app'));

  const eventsData = [serverEvent];
  const eventRequest = (new EventRequest(ACCESS_TOKEN, PIXEL_ID))
    .setEvents(eventsData)
    // .setTestEventCode('TEST55716')
  ;

  eventRequest.execute();
}