import {sessionStatus} from "actions/mentorshipAction";
import { initQuiz, initSession, startSession, stepCompleted } from "actions/sessionAction";
import {connect} from 'react-redux';
import MentorshipSessionSummary from "views/Mentorship/MentorshipSessionSummary";
import {filter, get} from "lodash";
import {isMentee, isMentor} from "../../business/role";


const mapStateToProps = ({
                           auth: {user: {role, status}},
                           session: {currentSession},
                           mentorship: {currentSessionCompletedSteps, currentSessionPartnerCompletedSteps, currentMentorshipSessionProgress, partnerDetails},
                           sessionStatus:{sessions}
                         }) => ({
  session: currentSession,
  completedSteps: currentSessionCompletedSteps,
  partnerCompletedSteps: currentSessionPartnerCompletedSteps,
  currentMentorshipSessionProgress,
  sessionProgress: sessions,
  role,
  userDetails: get(partnerDetails, 'userDetails.[0]'),
  mentorDetails: isMentee(role) && get(filter(partnerDetails.partnerDetails, partner => isMentor(partner.role)), '[0]'),
  partnerMenteesDetails: filter(partnerDetails.partnerDetails, partner => isMentee(partner.role)),
  mentorshipType: get(status, 'mentorships[0].mentorshipType')
  });


export default connect(mapStateToProps, {initQuiz, stepCompleted,sessionStatus, startSession, initSession})(MentorshipSessionSummary);
