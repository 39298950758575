import Button from "@material-ui/core/Button/Button"
import Popover from "@material-ui/core/Popover/Popover"
import withStyles from "@material-ui/core/styles/withStyles"
import CloseIcon from '@material-ui/icons/Close'
import TimeLineFilter from "components/Filter/TimeLineFilter"
import TypeOfPostFilter from "components/Filter/TypeOfPostFilter"
import {without} from "lodash"
import React from "react"
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {
    width: 288,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px'
  }
})


const FeedFilterPopover = ({classes, open, handleClose, id, anchorEl, setFeedFilter, filterValue, applyFeedFilter}) => {
  const {t} = useTranslation();

  return (
    <div>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={classes.wrapper}>
          <div className={classes.title}>
            <span style={{fontSize: 12, fontWeight: 'bold'}}>{t('filters')}</span>
            <span style={{cursor: 'pointer', marginLeft: 'auto'}}>
            <CloseIcon
              style={{color: '#52B2E2', position: 'absolute', right: 2, top: 2}}
              onClick={handleClose}
            /></span>
          </div>
          <TypeOfPostFilter setFeedFilter={setFeedFilter} filterValue={filterValue.event}/>
          <TimeLineFilter setFeedFilter={setFeedFilter} filterValue={filterValue}/>
          {/*<TagFilter/>*/}
          <Button variant="contained"
                  style={{
                    backgroundColor: '#52B2E2',
                    fontSize: 12,
                    color: 'white',
                    borderRadius: '0px 0px 5px 5px',
                    height: 48
                  }}
                  fullWidth
                  size="large"
                  onClick={applyFeedFilter}
          >{t('apply')}
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export default withStyles(styles)(FeedFilterPopover)


