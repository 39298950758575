import withStyles from "@material-ui/core/styles/withStyles";
import {convertUrl} from "common/helpers";
import {history} from 'common/history';
import {ellipsize, isEmpty} from 'lodash';
import React from "react";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {
    backgroundColor: '#ffffff',
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 80,
    cursor: 'pointer'
  },
  contentWrapper: {
    padding: '20px 12px'
  },
  image: {
    width: 110,
    height:'100%',
    objectFit: 'cover',
    borderRadius: '5px 0px 0px 5px'
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: 10,
    color: '#DE6E49'
  },
  sessionName: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#000000',
  },
  date: {
    marginTop: 6,
    fontFamily: 'Roboto',
    fontSize: 8,
    color: 'rgba(0, 0, 0, 0.6)'
  }

});


const PickupWhereYouLeft = ({classes, setCurrentStepId, lastMentorshipSessionStatus, completeByMessage = "", initSession}) => {
  const {t} = useTranslation();

  const {sessionId, stepId, sessionTitle, banner} = lastMentorshipSessionStatus;

  const sessionEmpty = isEmpty(sessionTitle);
  const title = sessionEmpty? t('pickUpANewSession'): sessionTitle;

  return (
    <div className={classes.wrapper}
         onClick={() => initSession(sessionId, false) && history.push(`/mentorship/${sessionId}/step/${stepId}`)}>
      <div className={classes.imageWrapper}>
        <img src={convertUrl(banner)} className={classes.image}/>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.title}>{t('pickUpWhereYouLeft')}</div>
        <div className={classes.sessionName}>{title}</div>
        <div className={classes.date}>{completeByMessage}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PickupWhereYouLeft);


