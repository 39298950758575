import {getSelfAnswers} from "business/quiz";
import AgreeabilityScaleAnswer from "components/QuizAnswers/AgreeabilityScaleAnswer";
import DateSelectionAnswer from "components/QuizAnswers/DateSelectionAnswer";
import ImageResponseAnswer from "components/QuizAnswers/ImageResponseAnswer";
import MultiChoiceAnswer from "components/QuizAnswers/MultiChoiceAnswer";
import NumberInputAnswer from "components/QuizAnswers/NumberInputAnswer";
import QuizQuestion from "components/QuizAnswers/QuizQuestion";
import SingleChoiceAnswer from "components/QuizAnswers/SingleChoiceAnswer";
import TextBoxAnswer from "components/QuizAnswers/TexBoxAnswer";
import TextInputAnswer from "components/QuizAnswers/TexInputAnswer";
import {find, get, isEmpty} from "lodash";
import React from "react";
import ScaleAnswer from "components/QuizAnswers/ScaleAnswer";
import {useTranslation} from "react-i18next";
import QuizAnswerNote from "./QuizAnswerNote";
import DocumentAnswer from "./DocumentAnswer";


const GetAnswerView = (props) => {
  switch (props.question.questionType) {
    case 'Text':
      return (<TextInputAnswer {...props} />);
    case 'Number':
      return (<NumberInputAnswer {...props} />);
    case 'TextBox':
      return <TextBoxAnswer  {...props}/>;
    case 'SingleChoiceRadio':
      return <SingleChoiceAnswer {...props}/>;
    case 'SingleChoiceDropDown':
      return <SingleChoiceAnswer {...props}/>;
    case 'MultiChoiceDropDown':
      return <MultiChoiceAnswer {...props}/>;
    case 'SingleChoiceButton':
      return <SingleChoiceAnswer {...props}/>;
    case 'Date':
      return <DateSelectionAnswer {...props}/>;
    case 'AgreeabilityThreeOptions':
      return <AgreeabilityScaleAnswer {...props} maximumValue={3}/>;
    case 'AgreeabilityFiveOptions':
      return <AgreeabilityScaleAnswer {...props} maximumValue={5}/>;
    case 'AgreeabilitySevenScale':
      return <AgreeabilityScaleAnswer {...props} maximumValue={7}/>;
    case 'AgreeabilityTenScale':
      return <AgreeabilityScaleAnswer {...props} maximumValue={10}/>;
    case 'Scale':
      return <ScaleAnswer {...props} />
    case 'ImageResponse':
      return <ImageResponseAnswer {...props}/>;
    case 'Document':
      return <DocumentAnswer {...props} />;
    // case 'YoutubeVideo':
    //   return (<Video
    //     videoId={get(props.question, 'answerOptions[0].key')}
    //   />);

    default:
      return (<p>Can not render! {props.question.questionType}</p>);
  }
};

const QuizAnswerWrapper = ({quizQuestions, quizAnswers, helpText, isLearningHub}) => {
  const {t} = useTranslation();

  const selfAnswers = getSelfAnswers(isLearningHub, quizAnswers);
  // const partnerAnswers = getPartnerAnswers(role, quizAnswers);
  // let partnerTitle;
  // const isMentorship = isQuizAnswersForMentorship(quizAnswers);

  // if (isMentorship) {
  //   partnerTitle = `${(isMentee(role) ? partnerDetails.mentor.firstName : partnerDetails.mentee.firstName) || 'Your partner'}'s answers`;
  // }


  const getAnswerFor = (answers, key) => {
    return find(answers, (answer) => answer.questionKey === key);
  };

  return (
    <div>
      {!isEmpty(helpText) && (
        <QuizAnswerNote
          helpText={helpText}
        />
      )}
      {quizQuestions.map((question, key) => {
        return (
          <div className="quiz-answer">
            <QuizQuestion questionPrefix={t('questionPrefixQ') + (key + 1)} question={question.title || question.label}
                          localeQuestion={get(question, 'localeTitle') || get(question, 'localeLabel')}/>

            <GetAnswerView quizAnswer={getAnswerFor(selfAnswers, question.key)} question={question}/>
          </div>)
      })}
    </div>
  );
};


export default QuizAnswerWrapper;
