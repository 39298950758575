import withStyles from "@material-ui/core/styles/withStyles";
import SessionCard from "components/SessionCard";
import {isEmpty, map} from 'lodash';
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

const styles = theme => ({
  sessionContainer: {
    maxWidth: '600px',
    maxHeight: '85vh',
    paddingLeft: '2px',
    display: 'flex',
    flexDirection: 'column',
  },
  feedWrapper: {
    marginTop: '20px',
    padding: '0px 15px',
  },
  header: {
    display: 'flex',
  },
  headerText: {
    color: '#766430',
    fontSize: '18px',
  },
  continueSessionsListWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginLeft: -12,
    marginRight: -12,
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      height: 200,
      flexWrap: 'nowrap',
    },
  },
  curatedSessionListWrapper: {
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    marginLeft: -12,
    marginRight: -12,
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
      height: 200,
      flexWrap: 'nowrap',
    },
  }
});



const Sessions = ({match, classes, allSessions, mySessions, initSessions, toggleBookmark}) => {
  const {t} = useTranslation();

  useEffect(() => {
    initSessions();
  }, []);


  return (
    <div className={classes.sessionContainer}>
      {!isEmpty(mySessions) &&
      <div className={classes.header}>
        <p className={classes.headerText}>{t('continueLearning')}</p>
      </div>}
      {!isEmpty(mySessions) &&
      <div className={classes.continueSessionsListWrapper}>
        {map(mySessions, session => <SessionCard style={{margin: 12}} session={session} key={session.id}
                                                 toggleBookmark={toggleBookmark}/>)}
      </div>
      }

      {!isEmpty(allSessions) &&
      <div className={classes.header}>
        <p className={classes.headerText}>{t('curatedSessions')}</p>
      </div>
      }
      {!isEmpty(allSessions) &&
      <div className={classes.curatedSessionListWrapper}>
        {map(allSessions, session => <SessionCard style={{margin: 12}} session={session} key={session.id}
                                                  toggleBookmark={toggleBookmark}/>)}
      </div>
      }
    </div>
  );
};

export default withStyles(styles)(Sessions);
