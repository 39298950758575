import request, { toSnakeCase } from "api/request";
import { isArray, forEach, map } from 'lodash';
import { ApiResponseError } from "api/errors";
import Bugsnag from "@bugsnag/js";

const parseStage = (stage) => {
  return {
    ...stage,
    pages: map(stage.pages, (page) => (
      {
        ...page,
        questions: map(page.questions, (question) => ({
          displayOrder: question.displayOrder,
          mandatory: question.mandatory,
          pageNumber: question.pageNumber,
          ...question.question
        })),
      })
    )
  };
};

export const getStage = async (id, auth) => request({
  url: `api/v2/screening/stages/${id}/`,
  method: 'GET',
  auth,
  parse: parseStage
});


export const saveStage = (surveyId,stageId, data, auth) => request({
  url: 'api/v2/screening/answers/',
  method: 'POST',
  data: {
    answers: data,
    survey: surveyId,
    stage: stageId,
  },
  auth,
}).catch((e) => {
  if (e instanceof ApiResponseError && e.response.status === 410) {
    return Promise.resolve();
  } else if (e instanceof ApiResponseError && e.response.status === 422) {
    Bugsnag.notify(e.originalError, (event) => {
      event.addMetadata('requestBody', {answers: data, survey: surveyId, stage: stageId})
    });
    return Promise.resolve();
  } else {
    throw e;
  }
});

export const saveStageAsForm = async ( surveyId, stageId, data, files, auth ) => {
  const formData = new FormData();

  formData.append("data", JSON.stringify({
    answers: toSnakeCase(data),
    survey: surveyId,
    stage: stageId,
  }));

  forEach(files, file => isArray(file.answer) ? file.answer.forEach(( item ) => formData.append(file.questionKey, item))
    : formData.append(file.questionKey, file.answer));

  await request({
    url: 'api/v3/screening/answers/',
    method: 'POST',
    data: formData,
    auth,
  }).catch(( e ) => {
    if (e instanceof ApiResponseError && e.response.status === 410) {
      return Promise.resolve();
    } else if (e instanceof ApiResponseError && e.response.status === 422) {
      Bugsnag.notify(e.originalError, ( event ) => {
        event.addMetadata('requestBody', { answers: data, survey: surveyId, stage: stageId })
      });
      return Promise.resolve();
    } else {
      throw e;
    }
  });
}

export const getUserStageExistingAnswers = (params, auth) => {
  const url = `api/v3/screening/answers/?${params}`;
  return request({
    url: url,
    method: 'GET',
    auth
  });
};
