import request from "api/request";

export const getSurvey = async (id, auth) => request({
  url: `api/v2/screening/surveys/${id}/?image_type=web`,
  method: 'GET',
  auth,
});


export const fetchSurveys = (auth) => request({
  url: `api/v2/screening/survey-list/`,
  method: 'GET',
  auth
});
