import {apiAction, dataAction, staticAction} from "actions/actionWrappers"
import {fetchWorkReadinessScores} from "actions/supplementaryAction"
import {
  emailExistsInSystem,
  fetchSystemStatus,
  getCurrentProfileByGoogle,
  resetPassword,
  sendOtp,
  sendPasswordResetOtp,
  updateProfile,
  verifyOtp,
  verifyOtpForResetOtp
} from "api";
import {accountType, omitNulls, firebaseSignInErrors, isTemporarilyBlockedDueToManyFailedLogins} from "common/helpers";
import {history} from 'common/history';
import i18n from "i18n";


import * as firebase from 'firebase/app'
import 'firebase/auth'
import {filter, get, isEmpty, join} from 'lodash'
import {actions} from "reducers/authReducer"
import {actions as profileActions} from "reducers/profileReducer"
import {myFirebase} from "../firebase/firebase"
import {isMatchingOpen} from "business/matching";
import {loginType} from "common/loginType";
import {UserAlreadyExistsError} from "api/errors";
import { changeLanguage, initLanguages } from "actions/languageAction";
import Bugsnag from '@bugsnag/js';
import { cleanupChat } from "actions/chatAction";


const initApp = (noRedirect = false) => async dispatch => {
  const status = await dispatch(apiAction(fetchSystemStatus));
  const isMaintenance = get(status, 'underMaintenance');
  if (isMaintenance) {
    dispatch(dataAction(actions.SET_SYSTEM_STATUS, isMaintenance));
    history.push("/signin")
  } else {
    await dispatch(initLanguages());
    dispatch(verifyAuth(noRedirect));
  }
};

var provider = new firebase.auth.GoogleAuthProvider();

const isSurveyPending = (profile) => isOnlyOneSurveyAvailableForUseAndNotCompleted(profile).length > 1;

const isOnlyOneSurveyAvailableForUseAndNotCompleted = (profile) => {
  const surveys = get(profile, 'status.surveys');
  const usableSurveys = filter(surveys, survey => survey.expPermission === 'use');
  return filter(usableSurveys, survey => survey.surveyStatus.startsWith('NOT_STARTED') || survey.surveyStatus.startsWith('ONGOING'));
};

const isLearningHubUse = (profile) => get(profile, 'status.experiences.session') === 'use';
const isMentorshipUse = (profile) => get(profile, 'status.experiences.curriculum') === 'use';
const isMentorshipShow = (profile) => get(profile, 'status.experiences.curriculum') === 'show';
const hasNewSession = (profile) => get(profile, 'status.newSessionAvailable') === true;


const emailSignIn = (email, password) => async dispatch => {
  Bugsnag.setUser(email);

  try {
    const {data} = await dispatch(apiAction(emailExistsInSystem, email))
    const isAccountTypeGoogle = get(data, "user.provider") === accountType.GOOGLE
    if (isAccountTypeGoogle) {
      await dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, "Our records say that you have signed up for our app via ‘Google login’ authentication. Please use the ‘Sign-in via Google’ option"));
    } else {
      await myFirebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const user = await myFirebase.auth().signInWithEmailAndPassword(email, password);
      const token = await user.user.getIdToken();
      await dispatch(dataAction(actions.ADD_ID_TOKEN, token));
      dispatch(getUserProfileWithGoogleLogin(false));
    }
  } catch (e) {
    if (
      e.code === firebaseSignInErrors.USER_ID_DISABLED ||
      e.code === firebaseSignInErrors.INVALID_EMAIL_ADDRESS
    ) {
      dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, e.message))
    } else if (e.code === firebaseSignInErrors.WRONG_PASSWORD) {
      dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, i18n.t('firebaseWrongPassword')))
    } else if (e.code === firebaseSignInErrors.USER_DOES_NOT_EXIST) {
      dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, i18n.t('firebaseUserDoesNotExist')))
    } else if (isTemporarilyBlockedDueToManyFailedLogins(e)) {
      Bugsnag.notify(e);
      dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, `${i18n.t('firebaseTemporarilyDisabledPart1')} ${i18n.t('firebaseTemporarilyDisabledPart2')}`));
    }  else {
      Bugsnag.notify(e);
      dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, i18n.t('firebaseDefaultError')))
    }
    // dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, "Error with EmailId or Password"));
    console.error('Error', e);
  }
};

const getUserProfileWithGoogleLogin = (noRedirect) => async dispatch => {
  const profile = await dispatch(apiAction(getCurrentProfileByGoogle));
  dispatch(dataAction(profileActions.ADD_DISPLAY_NAME, `${profile.firstName} ${profile.lastName}`));
  await dispatch(dataAction(actions.GOOGLE_LOGIN_SUCCESS, profile));
  dispatch(afterLogin(profile, noRedirect))
};

const afterLogin = (profile, noRedirect) => async dispatch => {
  dispatch(changeLanguage(get(profile, "newPrimaryLanguage")));
  dispatch(fetchWorkReadinessScores());

  const queryParams = new URLSearchParams(history.location.search);
  if (queryParams.has('redirectUri')) {
    history.push(queryParams.get('redirectUri'));
    return;
  }

  if (noRedirect) {
    return;
  }

  if (isOnlyOneSurveyAvailableForUseAndNotCompleted(profile).length === 1) {
    history.push("/survey", {surveyId: isOnlyOneSurveyAvailableForUseAndNotCompleted(profile)[0].surveyId})
  } else if (isSurveyPending(profile)) {
    history.push("/surveylisting")
  } else if (isMentorshipUse(profile) || isMatchingOpen(profile.status)) {
    history.push("/mentorship")
  } else if (isLearningHubUse(profile) && hasNewSession(profile)) {
    history.push("/learninghub")
  } else if (isMentorshipShow(profile)) {
    history.push("/mentorship")
  } else if (isMentorshipShow(profile)) {
    history.push("/mentorship")
  } else {
    history.push("/learninghub")
  }
};

const googleSignIn = () => async dispatch => {
  dispatch(staticAction(actions.GOOGLE_LOGIN_REQUEST));
  try {
    const user = await myFirebase.auth().signInWithPopup(provider);
    Bugsnag.setUser(user.user.email);
    const token = get(user, 'credential.idToken');
    dispatch(dataAction(actions.ADD_ID_TOKEN, token));
    const result = await dispatch(apiAction(emailExistsInSystem, ''));
    if (result.userExist) {
      dispatch(getUserProfileWithGoogleLogin(false));
    } else {
      await dispatch(dataAction(actions.GOOGLE_LOGIN_SIGNUP_SUCCESS));
      dispatch(dataAction(profileActions.ADD_BASIC_DETAILS_TO_PROFILE, user))
      history.push("/signup");
    }

  } catch (e) {
    Bugsnag.notify(e);
    dispatch(staticAction(actions.GOOGLE_LOGIN_FAILURE))
  }
};

const logoutUser = () => async (dispatch, getState) => {
  await dispatch(cleanupChat());
  const {language} = await getState();
  dispatch(staticAction(actions.LOGOUT_REQUEST));
    myFirebase
      .auth()
      .signOut()
      .then(() => {
        dispatch(changeLanguage(get(language, 'defaultLanguageCode'), false));
        dispatch(staticAction(profileActions.RESET_USER_PROFILE));
        dispatch(staticAction('USER_LOGOUT'));
        dispatch(staticAction(actions.LOGOUT_SUCCESS));
      })
      .catch(error => {
        dispatch(staticAction(actions.LOGOUT_FAILURE));
        console.error('Error', error);
      });
};

const verifyAuth = (noRedirect) => dispatch => {
  dispatch(staticAction(actions.VERIFY_REQUEST));
  const unsubscribeHandler = myFirebase
    .auth()
    .onAuthStateChanged(async user => {
      unsubscribeHandler();
      if (user) {
        if (!user.emailVerified) {
          dispatch(dataAction(actions.EMAIL_LOGIN_FAILURE, "Email Not Verified"));
        }
        dispatch(dataAction(profileActions.ADD_DISPLAY_NAME, user.displayName));
        const {userExist} = await dispatch(apiAction(emailExistsInSystem, ''));
        if (userExist) {
          dispatch(getUserProfileWithGoogleLogin(noRedirect));
        } else {
          await dispatch(dataAction(actions.GOOGLE_LOGIN_SIGNUP_SUCCESS));
          history.push("/signup");
        }
      } else {
        const currentPath = history.location.pathname;
        if (currentPath !== "/resetpassword") {
          history.push("/signin");
        }
      }
    });
  dispatch(staticAction(actions.VERIFY_SUCCESS));
};

const verifyEmail = (email) => async dispatch => {
  Bugsnag.setUser(email);
  try {
    await dispatch(apiAction(sendOtp, email));
    dispatch(dataAction(actions.SET_EMAIL_OTP_ERROR, false))
    dispatch(dataAction(actions.UPDATE_STEP, 1))
  } catch (e) {
    if (e instanceof UserAlreadyExistsError) {
      dispatch(dataAction(actions.SET_EMAIL_OTP_ERROR, true))
    } else {
      alert('Error while communicating with server. Please try again later or check your network configuration.');
    }
  }
};

const validateOtp = (email, otp) => async dispatch => {
  try {
    await dispatch(apiAction(verifyOtp, {email, otp}));
    dispatch(dataAction(actions.OTP_VERIFIED, {email, otp}));
    dispatch(dataAction(actions.UPDATE_STEP, 2))
  } catch (e) {
    dispatch(staticAction(actions.OTP_VERIFY_FAILED));
  }
};

const setProfilePassword = (password) => dispatch => {
  dispatch(dataAction(actions.SET_PASSWORD, password));
  history.push("/signup");
};


const setNewPassword = (password) => async (dispatch, getState) => {
  const {auth: {email, otp, provider}} = getState();
  try {
    await dispatch(apiAction(resetPassword, {email, otp, password, confirmPassword: password}));
    if (provider === loginType.EMAIL) {
      dispatch(emailSignIn(email, password));
    } else {
      dispatch(initApp());
    }
  } catch (e) {
    console.log("New Password set failed", e)
  }
}

const resetPasswordOtpVerify = (email, otp) => async dispatch => {
  try {
    await dispatch(apiAction(verifyOtpForResetOtp, {email, otp}))
    dispatch(dataAction(actions.OTP_VERIFIED, {email, otp}));
    dispatch(dataAction(actions.RESET_PASSWORD_SCREEN, 2));
  } catch (e) {
    dispatch(staticAction(actions.OTP_VERIFY_FAILED));
  }
}

const sendOTPEmailForPasswordReset = (email) => async dispatch => {
  try {
    const {message, errors} = await dispatch(apiAction(sendPasswordResetOtp, email));

    if (!isEmpty(errors)) {
      const error = join(errors, " AND ");
      dispatch(dataAction(actions.RESET_PASSWORD_ERROR, error));
      return
    }

    dispatch(dataAction(actions.RESET_PASSWORD_ERROR, ""))
    dispatch(dataAction(actions.RESET_OTP_SCREEN_MESSAGE, message))
    dispatch(dataAction(actions.RESET_PASSWORD_SCREEN, 1));
  } catch (e) {
    const error = join(get(e, 'response.data.errors')," AND");
    dispatch(dataAction(actions.RESET_PASSWORD_ERROR, error))
  }
}

const validateEmailAddress = (emailId) => async (dispatch) => {
  const email = emailId.toLowerCase();
  Bugsnag.setUser(email);
  try {
    const {userExist} = await dispatch(apiAction(emailExistsInSystem, email));
    if (userExist) {
      dispatch(sendOTPEmailForPasswordReset(email));
    } else {
      dispatch(dataAction(actions.RESET_PASSWORD_ERROR, "Account with this email does not exist"))
    }
  }
  catch (e) {
    dispatch(dataAction(actions.RESET_PASSWORD_ERROR, "Account with this email does not exist"))
  }
};


const resetErrorMessage = () => dispatch =>{
  dispatch(staticAction(actions.RESET_ERROR_MESSAGE))
}

const saveProfile = (profileToBeSaved) => async(dispatch) => {
  await dispatch(apiAction(updateProfile, omitNulls(profileToBeSaved)));
  await dispatch(dataAction(actions.UPDATE_USER_PROFILE_LOCALLY, profileToBeSaved));
};

export {
  emailSignIn,
  googleSignIn,
  logoutUser,
  verifyAuth,
  verifyEmail,
  validateOtp,
  setProfilePassword,
  initApp,
  validateEmailAddress,
  resetPasswordOtpVerify,
  setNewPassword,
  resetErrorMessage,
  saveProfile
};
