import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {AllSessionIcon, BookMarkIcon, CuratedSessionIcon, OngoingIcon} from "common/customIcon";
import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  navigationWrapper: {
    marginTop: 18,
    height: '202px',
  },
  menuItem: {
    height: '32px',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    paddingLeft: 16,
    alignItems: 'center',
    fontSize: '10px',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: 'black'

  },
  menuIcon: {
    float: 'right',
  },
  greetingMessage: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black'
  }

});

const LearningHubNavigation = ({classes, displayName, mentorshipSessionStatus}) => {
  const {t} = useTranslation();

  return (
    <div className={classes.navigationWrapper}>
      <Grid container justify="center" style={{height: '100%'}}>
        <div className={classes.greetingMessage}>{`${t('helloComma')} ${displayName}`}</div>
        <Link to={'/learninghub/allsession'} className={classes.menuItem}>
          <span><p style={{display: 'inline-block'}}>{t('allSessions')}</p>
            <span className={classes.menuIcon}>
            <AllSessionIcon
              viewBox="1 0 17 9"
              style={{color: '#E0B334'}}/>
          </span></span>
        </Link>

        <Link to={'/learninghub/curatedsession'} className={classes.menuItem}>
          <span><p style={{display: 'inline-block'}}>{t('curatedSessions')}</p>
            <span className={classes.menuIcon}>
            <CuratedSessionIcon
              viewBox="0 0 16 9"
              style={{color: '#E0B334'}}/>
          </span></span>
        </Link>

        <Link to={'/learninghub/ongoingsessions'} className={classes.menuItem}>
          <span><p style={{display: 'inline-block'}}>{t('ongoingSessions')}</p>
            <span className={classes.menuIcon}>
            <OngoingIcon
              viewBox="0 0 16 9"
              style={{color: '#E0B334'}}/>
          </span></span>
        </Link>

        <Link to={'/learninghub/bookmarkedsessions'} className={classes.menuItem}>
          <span><p style={{display: 'inline-block'}}>{t('bookmarkedSessions')}</p>
            <span className={classes.menuIcon}>
            <BookMarkIcon
              viewBox="0 0 16 9"
              style={{color: '#E0B334'}}/>
          </span></span>
        </Link>
        {/*Todo : Hiding untill logic is decided*/}
        {/*<MentorshipStatusHomePageCard mentorshipSessionStatus={mentorshipSessionStatus}/>*/}
      </Grid>
    </div>
  )
};

export default withStyles(MaterialStyles)(LearningHubNavigation);
