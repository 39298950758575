import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import chatWindowImage from 'common/assets/chatWindowImage.png';

const styles = theme => ({
  wrapper:{
    backgroundColor:'#F0EFEB',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    height:'100%'
  },
  text:{
    marginTop:30,
    fontSize:24,
    fontWeight:'bold',
  }
});


const BlankChatWindow = ({classes,}) => {

  return (
    <div className={classes.wrapper}>
      <img src={chatWindowImage} style={{width: 248}} alt={'chat'}/>
      <div className={classes.text}>Say Hi, to your fellows!</div>
    </div>
  );
};

export default withStyles(styles)(BlankChatWindow);


