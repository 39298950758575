import config from "common/config";
import firebase from "firebase/app";
import { myFirebase } from "../firebase/firebase";
import "firebase/messaging";
import Bugsnag from "@bugsnag/js";

const messaging = firebase.messaging.isSupported() ? myFirebase.messaging() : null;

export const getToken = (storeToken) => {
  return messaging && messaging.getToken({vapidKey: config.VAPID_KEY}).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      storeToken(currentToken);
    } else {
      // console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    // catch error while creating client token
    if (err.code !== 'messaging/permission-blocked') Bugsnag.notify(err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
