import Button from "@material-ui/core/Button/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {history} from 'common/history';
import TextBox from "components/TextBox";
import React, {useState} from "react";
import StarRatingComponent from 'react-star-rating-component';
import {useTranslation} from "react-i18next";


const styles = theme => ({
  feedbackWrapper: {
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  starWrapper: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#DD6BAC',
    fontSize: 26,
    width: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      borderTopRightRadius: 10,
      borderTopLeftRadius: 10,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      minHeight: 150
    },
  },
  feedbackTextWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
  },
  buttonWrapper: {},
  textBoxWrapper: {
    flex: 1,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px'
  },
  title: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 18
  },
  star: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    borderRadius: '0 0 10px 0',
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0 0 10px 10px',
      height: 50
    },
  }
});

const Feedback = ({isLearningHub, sessionId, classes, submitSessionFeedback}) => {
  const {t} = useTranslation();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const closeFeedBack = () => history.push(isLearningHub ? '/learninghub' : '/mentorship');

  const submitFeedBack = async () => {
    await submitSessionFeedback(sessionId, {
      rating,
      review,
    }, closeFeedBack)
  }

  return (
    <div className={classes.feedbackWrapper}>
      <div className={classes.starWrapper}>
        <p className={classes.title}>{t('rateSession')}</p>
        <div className={classes.star}>
          <StarRatingComponent
            name="rate"
            starCount={5}
            value={rating}
            onStarClick={(val) => setRating(val)}
            starColor={'white'}
          />
        </div>
      </div>

      <div className={classes.feedbackTextWrapper}>
        <div className={classes.textBoxWrapper}>
          <TextBox style={{borderTopRightRadius: 10}} label={`${t('thoughtsOnSession')} ${t('typeHere')}`}
                   onAnswered={(text) => setReview(text)} value={review}/>
        </div>
        <div className={classes.buttonWrapper}>
          <Button style={{
            backgroundColor: '#52B2E2', color: 'white',
          }}
                  className={classes.button}
                  onClick={submitFeedBack}
                  fullWidth>{t('submitFeedback')}</Button>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Feedback);
