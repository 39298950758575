import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import devTranslation from 'common/translation.json';

const resources = {
  dev: {
    translation: devTranslation,
  },
};

i18n.use(initReactI18next)
  .init({
    resources,
    debug: false,
    returnObjectTrees: true,
    fallbackLng: ['en', 'dev'],
    react: {
      wait: true,
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  }).catch(() => console.log("Error setting language"));

export default i18n;
