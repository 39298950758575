import IconButton from "@material-ui/core/IconButton";
import React from "react";

export default ({onClick,style, children}) => (
  <IconButton
    edge={'end'}
    onClick={onClick}>
    <div style={{
      display: 'flex',
      borderRadius: 14,
      width: 28,
      height: 28,
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      ...style
    }}>
      {children}
    </div>
  </IconButton>
)
