import React, {useState, useRef, useEffect} from "react";
import ListMember from "components/Chat/ListMember";
import {get, map} from 'lodash';
import withStyles from "@material-ui/core/styles/withStyles";
import InfiniteScroll from "react-infinite-scroll-component";
import SearchedChatMessage from "./SearchedChatMessage";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const styles = theme => ({
  chatMemberListWrapper:{
    display:'flex',
    flexDirection:'column',
    flex: 1,
    backgroundColor: 'white',
  },
  channelHeading: {
    fontSize: 10,
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.51)',
    marginTop: 12,
    marginBottom: 4,
    padding: '0px 16px',
  },
  channelBlock: {
    paddingBottom: 8,
  },
  searchBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '93%',
    padding: 42,
  },
  searchBlockHelperText: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.51)',
    fontWeight: 400,
    textAlign: 'center',
  },
  customScrollbar: {
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
  },
  searchMessage: {
    marginTop: 6,
    marginBottom: 6,
    fontSize: 10,
    color: '#808080',
  },
  searchedChannelsBlock: {
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.51)',
    textAlign: 'center',
  },
});

const ChatSearchResult = ({ classes,
  searchedChannels,
  onItemClick,
  myId,
  isSearched,
  searchedChannelsFound,
  selectedChannelId,
  searchMessageResult,
  searchedMessagesFound,
  loadMoreSearchMessages,
  hasMoreSearchMessageResult,
  searchedMessageText,
  myEmail,
  setSelectedMessageId,
  setSelectedTab,
  selectedTab,
  selectedMessageId,
  isMobile,
  chatChannels,
  nextPageNumber,
}) => {
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const messagesContainerRef = useRef(null);
  const theme = useTheme();
  const isTabletView = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = prevScrollPosition;
    }
  }, [searchMessageResult, prevScrollPosition]);

  const handleScroll = () => {
    setPrevScrollPosition(messagesContainerRef.current.scrollTop);

    loadMoreSearchMessages();
  };

  return (
    isSearched ?
      searchedChannelsFound ||  searchedMessagesFound ?
        (
          <div className={classes.chatMemberListWrapper}>
            <div className={classes.searchedChannelsBlock} style={{height: searchedChannelsFound? 200 : 80}}>
              <h1 className={classes.channelHeading}>People & Groups</h1>
              {searchedChannelsFound ?
                <div className={classes.channelBlock} style={{ maxHeight: 170, overflowY: 'auto' }}>
                {Array.isArray(searchedChannels) && searchedChannels
                  .filter(channel => {
                    const channelMembers = channel.channelMembers || [];
                    const myMember = channelMembers.find(member => member.userId === myId);
                    if (myMember && myMember.isPairManager) {
                      const otherMembersInactive = channelMembers
                        .filter(member => member.userId !== myId)
                        .every(member => !member.isActive);
                      return !otherMembersInactive;
                    }
                    return true;
                  })
                  .map(channel => (
                    <ListMember
                      key={channel.channelId}
                      isGroup={get(channel, 'isGroup')}
                      members={get(channel, 'channelMembers')}
                      channelName={get(channel, 'channelName')}
                      onItemClick={onItemClick}
                      channelId={channel.channelId}
                      isSearch={true}
                      myId={myId}
                      isSelected={selectedTab === 'channels' && selectedChannelId === get(channel, 'channelId')}
                      setSelectedTab={setSelectedTab}
                    />
                  ))}
              </div>
                :
                <h1 className={classes.helperText} style={{height: searchedChannelsFound? 155 : 30}}>
                  No results for People & Groups
                </h1>
              }
            </div>
            <div>
              <h1 className={classes.channelHeading}>Messages</h1>
              { searchedMessagesFound ?
                <>
                  <div
                    id="scrollableSearchResultDiv"
                    style={{ height : searchedChannelsFound ? (isTabletView ? 540 : 250) : (isTabletView ? 650 : 350), overflowY: 'auto' }}
                  >
                    <InfiniteScroll
                      dataLength={searchMessageResult.length}
                      next={handleScroll}
                      hasMore={hasMoreSearchMessageResult}
                      ref={messagesContainerRef}
                      className={classes.customScrollbar}
                      scrollableTarget="scrollableSearchResultDiv"
                      loader={
                        <p style={{textAlign: 'center', color: 'rgba(0, 0, 0, 0.4)'}}>
                          {("Loading...")}
                        </p>
                      }
                    >
                      {map(searchMessageResult, (message, index) => {
                        return (
                          <SearchedChatMessage
                            message={message}
                            chatChannels={chatChannels}
                            key={index}
                            searchedMessageText={searchedMessageText}
                            myEmail={myEmail}
                            onItemClick={onItemClick}
                            setSelectedMessageId={setSelectedMessageId}
                            setSelectedTab={setSelectedTab}
                            selectedTab={selectedTab}
                            selectedMessageId={isMobile ? null : selectedMessageId}
                            nextPageNumber={nextPageNumber}
                          />
                        )
                      })}
                    </InfiniteScroll>
                  </div>
                </>
                :
                <h1 className={classes.helperText}>No results for messages</h1>
              }
            </div>
          </div>
        )
      : (
        <div className={classes.searchBlock}>
          <h1 className={classes.searchBlockHelperText}>No Search Results</h1>
        </div>
      )
    : (
      <div className={classes.searchBlock}>
        <h1 className={classes.searchBlockHelperText}>Search for People, Groups, and Messages</h1>
      </div>
    )
  );
};



export default withStyles(styles)(ChatSearchResult);
