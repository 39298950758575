
import Button from "@material-ui/core/Button/Button";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import Question from "components/Question";
import {get, isEmpty, map} from 'lodash';
import React from "react";

import {useTranslation} from "react-i18next";


const answerFor = (question, answers) => get(answers, question.key);
const errorFor = (question, pageErrors) => get(pageErrors, question.key);

const LoadingSkeleton = () => {
  return (
    <div style={{marginTop: 30}}>
      <Skeleton animation="wave" height={80} width="100%"/>
      <Skeleton variant="rect" animation="wave" width={"300px"} height={200}/>
      <Skeleton animation="wave" height={80} width="50%"/>
    </div>
  );
};

const NoQuestions = () => {
  return <div style={{paddingTop: '50%', fontSize: '20px'}}>
    No questions to answer in this step.<br />Click Continue or Submit.
  </div>
}

export default function PageContent({isLastPage, questions, nextPage, setAnswer, answers, pageErrors, submitSurveyStage, fetchingStage, existingAnswers, isSurveySubmitting }) {
  const {t} = useTranslation();

  // filter out questions that are present in existing answers
  let questionsToShow = []
  let existingAnswersQuestionKeys = []

  if (existingAnswers.length && existingAnswers[0].answers.length) {
    existingAnswersQuestionKeys = existingAnswers[0].answers.map(answer => answer.questionKey)
  }
  questionsToShow = (questions || []).filter(question => !existingAnswersQuestionKeys.includes(question.key))

  let questionView = <></>;
  if (questionsToShow.length) {
    questionView = map(questionsToShow, question =>
      <Question
        key={question.key}
        question={question}
        onAnswered={(answer) => setAnswer(answer, question)}
        value={answerFor(question, answers)}
        error={!isEmpty(errorFor(question, pageErrors)) && errorFor(question, pageErrors)}
        />
    );
  } else if (existingAnswers.length && !isSurveySubmitting) {
    questionView = <NoQuestions />;
  }

  return (
    <div style={{flex: 1, height: '100%'}}>
      <div className={"page-content-wrapper"}>
        {fetchingStage ? <LoadingSkeleton/> : questionView}
      </div>
      <div className={"pageButton"}>
        <div className={"btn"}>
          {isLastPage ? <Button variant="contained"
                                style={{backgroundColor: '#E0B334', fontSize: 12, color: 'white'}}
                                fullWidth
                                size="large"
                                onClick={submitSurveyStage}>{t('submitCaps')}
                      </Button>
                      :
           <Button variant="contained"
                   style={{backgroundColor: '#52B2E2', fontSize: 12, color: 'white'}}
                   fullWidth
                   size="large"
                   onClick={nextPage}>{t('continueCaps')}
           </Button>}

        </div>
      </div>
    </div>
  );
};
