import MomentUtils from '@date-io/moment';
import {makeStyles} from "@material-ui/core";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {map, noop} from 'lodash';
import React from "react";


const useOutlinedInputStyles = makeStyles(theme => {
  return (
    {
      root: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {}
      },
      notchedOutline: {
        border: '0px !important',
      },
    })
});

const DateTimeSelection = ({onAnswered = noop(), value = null}) => {
  const classes = useOutlinedInputStyles();
  const handleDateChange = date => {
    onAnswered(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDateTimePicker
        fullWidth
        id="date-picker-dialog"
        format="DD/MM/YYYY HH:mm"
        value={value}
        onChange={handleDateChange}
        emptyLabel={"DD/MM/YYYY HH:mm"}
        KeyboardButtonProps={{
          'aria-label': 'Select Date Time',
        }}
        inputVariant="outlined"
        inputStyle={{backgroundColor: 'red'}}
        InputProps={{
          classes: {
            root: classes.root, notchedOutline: classes.notchedOutline
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
};


export default (DateTimeSelection);
