import _ from 'lodash';

const questionType = (key, questions) => _.get(_.find(questions, {key}), 'questionType');

export const convertFilesToMultipartSubmitFormat = (questions, answers) => {
  const answersAfterValueConversion = _.mapValues(answers, (value, key) => {
    switch (questionType(key, questions)) {
      case 'ImageResponse':
      case 'Document':
        return value;
      default:
        // console.log('convertFilesToMultipartSubmitFormat: cannot find case for ', questionType(key, questions));
        return null;
    }
  });
  const answersAfterRemovingNulls = _.pickBy(answersAfterValueConversion, (answer) => answer);

  return _.reduce(_.toPairs(answersAfterRemovingNulls),
    (result, [questionKey, answer]) => _.concat(result, {
      questionKey,
      answer,
    }), []);
};
