import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import TrackerAvatar from "components/TrackerAvatar";
import { get, isEmpty } from "lodash";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";


const styles = theme => ({
  partnerInfoWrapper: {
    display: 'flex',
    padding: '24px 52px',
    [theme.breakpoints.down('xs')]: {
      padding: '18px 22px',
    }
    },
  name: {
    fontSize: 24,
  },
  role: {
    fontSize: 14,
    color: '#EB8E6F',
    textTransform: 'UPPERCASE',
    marginTop: 2
  }
});


const PartnerInfo = ({classes, partner, hideBorder = false}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const partnerStatus = get(partner, 'applicationStatus');

  return (
    <div className={classes.partnerInfoWrapper}
         style={{borderBottom: hideBorder ? 'none' : '1px solid rgba(0, 0, 0, 0.13)',}}>
      <div style={{flex: 1}}>
        <TrackerAvatar
          displayName={get(partner, 'firstName') + " " + get(partner, 'lastName')}
          displayPicture={get(partner, 'picture')}
          styles={{width: isXs ? 64 : 83, height: isXs ? 64 : 83, fontSize: 18}}
          size={isXs ? 64 : 83}/>
      </div>
      <div style={{flex: 2, paddingLeft: 28}}>
        <div className={classes.name}>{get(partner, 'firstName')}</div>
        <div className={classes.name}>{get(partner, 'lastName')}</div>
        <div className={classes.role}>{get(partner, 'role')}{!isEmpty(partnerStatus) ? ` (${partnerStatus}) `: ''}</div>
      </div>
      <div style={{flex: 2}}>
        <div>{get(partner, 'bio')}</div>
        <div>{get(partner, 'phoneNumber')}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PartnerInfo);


