import {filter, find, get, indexOf, map, values} from "lodash";
import moment from "moment";

const availableScreens = ['default', 'potential_match', 'awaiting_mentor_response','group_hold'];

export const getScreenIndex = (screen) => indexOf(availableScreens, screen) + 1;

export const getScreenDetail = (list, listName) => filter(list, listItem => listItem.key === listName)[0];


export const statuses = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  SUCCESS: 'SUCCESS',
}


export const getTimeRemaining = (timeStamp, durationToExpire) => {
  const timeOfExpiary = moment(timeStamp).add(durationToExpire, 'hour')
  const timetoExpiary = moment.duration(timeOfExpiary.diff(moment(), 'minutes'), 'minutes')


  let hours = timetoExpiary.get('hours')
  let days = timetoExpiary.get('days')
  let minutes = timetoExpiary.get('minutes')
  let color = "RED"

  if (timetoExpiary.days() >= 3) {
    color = "GREEN"
  } else if (timetoExpiary.days() <=2) {
    color = "YELLOW"
  } else {
    color = "RED"
  }
  return {hours, days, minutes, color}
}


export const timeRemainingMessage = (time) => {
  return `${time.days} days ${time.hours} hours and ${time.minutes} mins`
}

export const getColor = (color) => {
  switch (color) {
    case "RED":
      return '#E14747'
    case "YELLOW":
      return '#EFB214'
    case "GREEN":
      return '#41A156'
  }
}

// 5 days 0 hrs 0 minutes - 3 day 0hrs 0 min = green
// 2 day 24 hrs 59 mins - 12 hrs 1 min = yellow
// 12 hrs 0 min - 0 min = red
export const isMatchActive = (match) => match.status === 'ACTIVE';

export const getSubTitle = (match) => {
  const userInfo = []
  map(values(match.userInfo), info => map(info, val => userInfo.push(val)))

  return userInfo.join(', ')
}

export const trackerStatus = {
  REJECTED: 'REJECTED',
  INTERESTED: 'INTERESTED',
};

export const groupCompatibilityStatus = {
  INCOMPATIBLE: 'INCOMPATIBLE',
  COMPATIBLE: 'COMPATIBLE',
  GROUP_FULL: 'GROUP_FULL',
  PAIR_COMPLETE: 'PAIR_COMPLETE',
};

export const matchType = {
  many: "1toM",
  one: "1to1",
};

export const isOneToOne = (match) => match === matchType.one;
export const isOneToMany = (match) => match === matchType.many;

export const isMatchingOpen = (status) => {
  const ACTIVE_STATUSES = ['READY', 'ON_HOLD','GROUP_HOLD'];
  return !!find(ACTIVE_STATUSES, activeStatus => activeStatus === get(status, 'matching.matchingStatus'))
}
