import {isEmpty} from 'lodash';
import React, {Fragment} from 'react';

const styles = {
  questionTitleText: {
    marginTop: 0,
    fontSize: 24,
    fontWeight: 'bold',
  },
  questionTitleLocal: {
    fontSize: 20,
    marginTop: 10,
  }
};


const QuestionDetails = (props) => {
  const {title, mandatory, isLabelEmpty} = props;
  return (
    <Fragment>
      <div style={styles.questionTitleContainer}>
        {!isEmpty(title) && <p style={styles.questionTitleText}>{title}{(isLabelEmpty && mandatory) && "*"} </p>}
      </div>
    </Fragment>
  );
};

export default QuestionDetails;
