export const actions = {
  STORE_SURVEY: '[Survey] STORE_SURVEY',
  GET_SURVEY: '[Survey] GET_SURVEY',
  STORE_SURVEYS: '[Survey] STORE_SURVEYS',

};


const initSurvey = {
  survey: {},
  gettingSurvey: false
};

export default (state = initSurvey, action) => {
  switch (action.type) {
    case actions.STORE_SURVEY :
      return {
        ...state,
        survey: action.payload,
        gettingSurvey: false
      };
    case actions.STORE_SURVEYS: {
      return {
        ...state,
        surveys: action.payload
      };
    }
    case actions.GET_SURVEY :
      return {
        ...state,
        gettingSurvey: true,
      };
    default:
      return state

  }
}


