import {apiAction, dataAction, silentApiAction, staticAction} from "actions/actionWrappers";
import {
  fetchAllMentorshipSessions,
  fetchMentorship,
  fetchMentorshipCards,
  fetchMentorshipProgress,
  fetchOngoingMentorshipSessions,
  getSessionStatus
} from "api/mentorship";
import {bookmarkSession} from "api/session";
import {actions} from "reducers/mentorshipReducer";

const getMentorshipCards = () => async (dispatch) => {
  dispatch(staticAction(actions.GET_MENTORSHIP_CARDS));
  try {
    const cards = await dispatch(apiAction(fetchMentorshipCards));
    dispatch(dataAction(actions.STORE_CARDS, cards));
  } catch (e) {
    dispatch(staticAction(actions.ERROR_FETCHING_CARDS));
  }
};


const initMentorship = (id) => async dispatch => {
  if (!id) return;
  const mentorship = await dispatch(apiAction(fetchMentorship, id));
  dispatch(dataAction(actions.STORE_MENTORSHIP, mentorship));

  const progress = await dispatch(apiAction(fetchMentorshipProgress, id));
  dispatch(dataAction(actions.STORE_MENTORSHIP_PROGRESS, progress));
};

const initMentorshipSessions = () => async dispatch => {
  const allSessionsPromise = dispatch(silentApiAction(fetchAllMentorshipSessions));
  const ongoingSessionsPromise = dispatch(silentApiAction(fetchOngoingMentorshipSessions));
  const [allSessions, ongoingSessions] = await Promise.all([allSessionsPromise, ongoingSessionsPromise]);
  dispatch(dataAction(actions.STORE_ALL_MENTORSHIP_SESSIONS, allSessions));
  dispatch(dataAction(actions.STORE_ONGOING_MENTORSHIP_SESSIONS, ongoingSessions));
};

const toggleMentorshipBookmark = (sessionId) => async (dispatch) => {
  dispatch(silentApiAction(bookmarkSession, sessionId));
  dispatch(dataAction(actions.BOOKMARK_SESSION, sessionId));
};

const sessionStatus = (sessionId) => async (dispatch) => {
  const status = await dispatch(apiAction(getSessionStatus, sessionId));
  dispatch(dataAction(actions.STORE_CURRENT_SESSION_PROGRESS, status));
};

const setCurrentSessionStepIndex = (index) => async (dispatch) => {
  dispatch(dataAction(actions.SET_CURRENT_STEP_INDEX, index));
};
const setCurrentStepId = (id) => async (dispatch) => {
  dispatch(dataAction(actions.SET_CURRENT_STEP_ID, id));
};


export {
  getMentorshipCards,
  initMentorshipSessions,
  toggleMentorshipBookmark,
  initMentorship,
  sessionStatus,
  setCurrentSessionStepIndex,
  setCurrentStepId
};
