import withStyles from "@material-ui/core/styles/withStyles";
import MatchCriteria from "components/Matching/MatchCriteria";
import {capitalize, filter, get, isEmpty, keys, map, noop} from 'lodash';
import React from "react";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  criteriaWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap:'wrap',
    paddingTop:2,
    marginLeft:-8
  },
  moreInfo: {
    color: 'rgba(0, 0, 0, 0.29)',
    fontSize: 10,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '8px 12px',
    marginRight: 8,
    marginTop: 8,
    cursor: 'pointer'
  },
  noNeeds: {
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 8,
  }
});


const MatchingCriteriaGroup = ({classes, criterias, clashingCriterias, onClick = noop}) => {
  const {t} = useTranslation();

  const getIconForCriteria = (criteria) => get(criterias,`${criteria}.icon`);
  const getNameForCriteria = (criteria) => get(criterias,`${criteria}.name`);
  const anyMandatoryCritera = (criterias) => filter(criterias, criteria => criteria.mandatory === true);

  return (
    <div className={classes.wrapper}>
      <div
        style={{
          color: 'rgba(0, 0, 0, 0.64)',
          fontSize: 10,
          fontWeight: 'bold',
          marginTop: 20,
        }}
      >{t('mentorshipGroupNeeds')}
      </div>
      {isEmpty(anyMandatoryCritera(criterias)) && <div className={classes.noNeeds}>{t('noGroupNeeds')}</div>}
      <div className={classes.criteriaWrapper}>
        {
          map(criterias, (criteria) => criteria.mandatory === true &&
            <MatchCriteria criteria={criteria.name}
                           icon={criteria.icon}
                           status={isEmpty(clashingCriterias) ? MatchCriteria.criteriaStatus.ACTIVE : MatchCriteria.criteriaStatus.INACTIVE}/>
          )
        }
        <div className={classes.moreInfo} onClick={onClick}>{t('moreInfo')}</div>
      </div>
      {!isEmpty(clashingCriterias) &&
      <>
        <div
          style={{
            color: 'rgba(0, 0, 0, 0.64)',
            fontSize: 10,
            fontWeight: 'bold',
            marginTop: 20,
          }}
        >{t('clashingCriteria')}
        </div>
        <div className={classes.criteriaWrapper}>
          {
            map(clashingCriterias, criteria =>
              <MatchCriteria criteria={getNameForCriteria(criteria)} icon={getIconForCriteria(criteria)} status={MatchCriteria.criteriaStatus.CLASHING}/>
            )
          }
        </div>
      </>}
    </div>
  );
};


export default withStyles(styles)(MatchingCriteriaGroup);


