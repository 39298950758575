import React, { useEffect } from 'react';
import {
  initInbox,
  removeChatwootWidget,
  setUserProperties
} from "common/ChatwootHelper";
import { connect } from 'react-redux';
import { get } from "lodash";
import config from "common/config";

const ChatwootWidget = ({
                          isAuthenticated,
                          id,
                          email,
                          role,
                          newPrimaryLanguage,
                          status,
                          displayName
                        }) => {
  const [showHelpCenter, setShowHelpCenter] = React.useState(true);
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = `${config.CHATWOOT_BASEURL}/packs/js/sdk.js`;
    document.head.appendChild(script);
    script.onload = () => {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const isChatInUse = get(status, 'experiences.chattype') === 'use';
      const isMentorshipChatActive = get(status, 'experiences.chatStatus.mentorshipChat') === 'use' || get(status, 'experiences.chatStatus.aiChat') === 'use';
      const isHelpCenterActive = get(status, 'experiences.chatStatus.helpCenter') === 'use';
      console.log('Chat experiences:', isChatInUse, isHelpCenterActive, isMentorshipChatActive);
      // console.log('showHelpCenter =', isChatInUse && isHelpCenterActive && !isMentorshipChatActive)
      setShowHelpCenter(isChatInUse && isHelpCenterActive && !isMentorshipChatActive);
    } else {
      setShowHelpCenter(true);
    }
  }, [isAuthenticated]);

  React.useEffect(() => {
    // console.log( 'scriptLoaded', scriptLoaded, 'showHelpCenter', showHelpCenter, 'isAuthenticated', isAuthenticated);
    if (scriptLoaded && showHelpCenter) {
      if (isAuthenticated) {
        setUserProperties({id: id.toString(), email, role, newPrimaryLanguage, displayName})
      } else {
        initInbox();
      }
    } else {
      removeChatwootWidget();
    }
  }, [scriptLoaded, showHelpCenter, isAuthenticated]);

  return null;
}

const mapStateToProps = ({
                           auth: {isAuthenticated, user: {id, email, role, newPrimaryLanguage, status}},
                           profile: {displayName}
                         }) => {
  return {
    isAuthenticated,
    id,
    email,
    role,
    newPrimaryLanguage,
    status,
    displayName
  }
};

export default connect(mapStateToProps)(ChatwootWidget);
