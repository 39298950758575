import withStyles from "@material-ui/core/styles/withStyles";
import React from 'react';
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  wrapper: {
    padding: '28px 17px 40px 20px',
    maxWidth: '270px',
    [theme.breakpoints.down('xs')]: {
      padding: '28px 17px 40px 20px',
      maxWidth: '100%',
    },
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  title: {
    color: '#DE6E49',
    fontWeight: 'bold',
    fontSize: '18px'
  },
  message: {
    marginTop:25,
    color: 'rgba(0, 0, 0, 0.64)',
    fontWeight: 'normal',
    fontSize: '18px',
  },

});

const UnlockStatusCard = ({classes, count = '', status = "", type = '', message = ''}) => {
  const {t} = useTranslation();

  return (
    <div className={classes.wrapper}>
        <div className={classes.title}>{type} {t('program')}</div>
        {/*<div className={classes.status}>{count}</div>*/}
        <div className={classes.message}>{message}</div>
    </div>
  )
};

export default withStyles(MaterialStyles)(UnlockStatusCard);
