import {connect} from 'react-redux';
import {setCurrentPath} from "reducers/NavmenuReducer"
import Mentorship from "views/Mentorship/Mentorship";

const mapStateToProps = ({
  mentorship: {
    mentorshipCards,
  },
  auth: {
    user: {
      role,
      status,
      id
    },
  }
}) => {
  return {
    mentorshipCards,
    status,
    role,
    id,
  };
};


export default connect(mapStateToProps,{
  setCurrentPath,
})(Mentorship);
