import {isNumberKey} from "common/helpers";
import "components/styles/AgreeabilityScale.scss"
import { map, padStart} from 'lodash';
import React, {Fragment} from "react";
import i18n from "i18n";

let classNames = require('classnames');


export const Circle = ({number, value, handleChange, questionKey}) => {
  const isSelected = (answer) => answer === value;
  return (
    <Fragment>
      <input id={number + "-" + questionKey}
             type="radio"
             name={questionKey}
             value={number}
             onChange={() => handleChange(number)}
             checked={isSelected(number)}
             autoFocus={true}
      />
      <label htmlFor={number + "-" + questionKey}
             className={classNames({'notSelected': true})}
      >
        <div>{padStart(number, 2, "0")}</div>
      </label>
    </Fragment>
  );
};

const AgreeabilityScale = ({onAnswered, value, maxLabel = i18n.t('completelyAgree'), minLabel = i18n.t('completelyDisagree'), question: {key}, ...rest}) => {

  const {
    minimumValue = 1,
    maximumValue = 10
  } = rest;

  const handleChange = (n) => {
    onAnswered(n);
  };

  const handleKeyPress = (event) => {
    if (isNumberKey(event)) {
      const keyNumber = parseInt(event.key);
      if (keyNumber <= maximumValue && keyNumber >= minimumValue) {
        onAnswered(keyNumber);
      }
    }
  };


  const options = [];
  for (let i = minimumValue; i <= maximumValue; i++) {
    options.push(i);
  }
  return (
    <div className="scale-toolbar">
      <div className="circleWrapper" onKeyDown={handleKeyPress}>
        {map(options, option => <Circle key={option} number={option} value={value} handleChange={handleChange}
                                        questionKey={key}/>)}
      </div>
      <div className="vertical-line"/>
      <div className="label">
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
    </div>
  );
};


export default (AgreeabilityScale);
