import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import CalendarContainer from "containers/CalendarContainer";
import Backdrop from "@material-ui/core/Backdrop/Backdrop"
import Modal from "@material-ui/core/Modal/Modal"

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const ChatCalendarModal = ({classes, open, handleClose}) => { 
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <CalendarContainer />
      </Modal>
    </div>
  )
};

export default withStyles(styles)(ChatCalendarModal);

