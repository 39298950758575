import {initSurvey} from "actions/surveyAction";
import {get} from 'lodash';
import {connect} from 'react-redux';
import {setCurrentPath} from "reducers/NavmenuReducer";
import SurveyLandingPage from "views/SurveyLandingPage";

const mapStateToProps = ({auth: {user}, survey: {gettingSurvey, survey}, auth}) => {
  const userSurveys = get(user, 'status.surveys');

  return ({
    userSurveys,
    survey,
    gettingSurvey,
  })
};

export default connect(mapStateToProps, {initSurvey, setCurrentPath})(SurveyLandingPage);
