const actions = {
  GET_INTERNSHIP_CARDS: 'GET_INTERNSHIP_CARDS',
  STORE_INTERNSHIP_CARDS: 'STORE_INTERNSHIP_CARDS',
  ERROR_FETCHING_INTERNSHIP_CARDS: 'ERROR_FETCHING_INTERNSHIP_CARDS'
};

const createInitialState = () => (
  {
    internshipCards: [],
    loadingCards: false,
    errorLoadingCards: false
  });

const internshipReducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.GET_INTERNSHIP_CARDS: {
      return {
        ...state,
        loadingCards: true,
      };
    }
    case actions.STORE_INTERNSHIP_CARDS: {
      return {
        ...state,
        internshipCards: action.payload,
        loadingCards: false
      }
    }
    case action.ERROR_FETCHING_INTERNSHIP_CARDS: {
      return {
        ...state,
        errorLoadingCards: true,
        loadingCards: false
      }
    }

    default: {
      return state;
    }
  }

};

export {actions, internshipReducer};
