import {getInternshipCards} from "actions/internshipAction";
import {connect} from 'react-redux';
import {setCurrentPath} from "reducers/NavmenuReducer";
import InternshipLearning from "views/InternshipLearning";

const mapStateToProps = ({
                           internship: {
                             internshipCards,
                           }
                         }) => {
  return {
    internshipCards,
  };
};


export default connect(mapStateToProps, {getInternshipCards, setCurrentPath})(InternshipLearning);
