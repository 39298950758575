import SessionFeedbackContainer from "containers/SessionFeedbackContainer";
import SessionIntroductionContainer from "containers/SessionIntroductionContainer";
import SessionQuizContainer from "containers/SessionQuizContainer";
import SessionStepWrapperContainer from "containers/SessionStepWrapperContainer";
import React, {Fragment} from 'react';
import {Route} from "react-router-dom";

const Session = ({}) => {
  return (
    <Fragment>
      <Route path={'/session/sessionintroduction/:sessionId'} component={SessionIntroductionContainer}>
      </Route>
      <Route path={'/session/step'}>
        <SessionStepWrapperContainer/>
      </Route>
      <Route path={'/session/step/quiz/'} component={SessionQuizContainer}/>
      <Route path={'/session/step/sessionfeedback/'} component={SessionFeedbackContainer}/>

    </Fragment>
  )
};

export default (Session);
