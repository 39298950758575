import React from 'react';
import MTMarkdown from "../MTMarkdown";


const QuestionHelpText = (props) => {
  const {helpText} = props;
  return (
    <div id="helpText" style={{paddingLeft: 16}}>
      <MTMarkdown style={{
        fontSize: 10,
        color: '#728D9A',
        marginTop:4
      }}>{helpText}</MTMarkdown>
    </div>
  );
};

export default QuestionHelpText;
