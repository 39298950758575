import withStyles from "@material-ui/core/styles/withStyles";
import MentorshipSessionCard from "components/MentorshipSessionCard";
import {filter, get, isEmpty, map} from 'lodash';
import React from 'react';
import { useTranslation } from "react-i18next";

const MaterialStyles = theme => ({
  sessionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    },
  },
  ongoingSessionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      height: 200,
      overflowY: 'hidden',
      overflowX: 'scroll',
      flexWrap: 'nowrap',
    }
  },
  allsessiionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1,
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      height: 200,
      overflowY: 'hidden',
      overflowX: 'scroll',
      flexWrap: 'nowrap',
    },
  },
  header: {
    display: 'flex',
  },
  headerText: {
    marginTop: 0,
    color: '#766430',
    fontSize: '18px',
  },
});

const MentorshipSessions = ({status, sessions, classes, toggleBookmark}) => {
  const {t} = useTranslation();

  const allSessions = get(sessions, 'allSessions');
  const ongoingSessions = get(sessions, 'ongoingSessions');
  const completedSessions = filter(ongoingSessions, (session) => session.status === 'COMPLETED');
  const incompleteSessions = filter(ongoingSessions, session => session.status !== 'COMPLETED');
  return (
    <div className={classes.sessionWrapper}>
      {!isEmpty(incompleteSessions) &&
      <div>
        <div className={classes.header}>
          <p className={classes.headerText}>{t('recentSessions')}</p>
        </div>
        <div className={classes.ongoingSessionsWrapper}>
          {map(incompleteSessions, session => <MentorshipSessionCard style={{margin: 12}} session={session}
                                                                     key={session.id}
                                                                     toggleBookmark={toggleBookmark}/>)}
        </div>
      </div>
      }
      {!isEmpty(allSessions) &&
      <div>
        <div className={classes.header} style={{marginTop: 20}}>
          <p className={classes.headerText}>{t('availableSessions')}</p>
        </div>
        <div className={classes.allsessiionsWrapper}>
          {map(allSessions, session => <MentorshipSessionCard style={{margin: 12}} session={session} key={session.id}
                                                              toggleBookmark={toggleBookmark}/>)}
        </div>
      </div>
      }
      {!isEmpty(completedSessions) &&
      <div>
        <div className={classes.header} style={{marginTop: 20}}>
          <p className={classes.headerText}>{t('completedSessions')}</p>
        </div>
        <div className={classes.ongoingSessionsWrapper}>
          {map(completedSessions, session => <MentorshipSessionCard style={{margin: 12}} session={session}
                                                                    key={session.id}
                                                                    toggleBookmark={toggleBookmark}/>)}
        </div>
      </div>
      }

    </div>
  )
};

export default withStyles(MaterialStyles)(MentorshipSessions);
