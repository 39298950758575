import withStyles from "@material-ui/core/styles/withStyles";
import MyProfileCard from "components/MyProfile/MyProfileCard";
import {get, has, map} from 'lodash';
import React from "react";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import TrackerAvatar from "components/TrackerAvatar";
import PartnerInfo from "components/PartnerInfo";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from "@material-ui/core/styles/useTheme";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  expansionPanelHead: {
    backgroundColor: '#DE6E49',
    display: 'flex',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  whiteText: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold',
  },
  expansionText: {
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  groupDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 32,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 8,
    },
  },
  avatarWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      width: '50%',
    },
  },
  expanded: {
    transform: 'rotate(0deg) !important',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  partnerDetails: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFEFE9',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  expansionPanelDetailRoot: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  }
});


const MyProfile = ({classes, profile, partnerDetails,saveProfile}) => {
  const {t} = useTranslation();

  const partners = get(partnerDetails, 'partnerDetails', []);

  const hasPartner = partners.length > 1;
  const hasSinglePartner = partners.length === 1;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));



  return (
    <div className={classes.wrapper}>
      <MyProfileCard profile={profile} hasPartner={hasPartner} saveProfile={saveProfile}/>
      {hasSinglePartner &&
      <MyProfileCard profile={get(partners, '[0]')} isSelf={false}/>
      }
      {!hasSinglePartner &&
      <div>
        <ExpansionPanel
          elevation={0}
        >
          <ExpansionPanelSummary
            className={classes.expansionPanelHead}
            expandIcon={<div className={classes.expansionText} style={{fontSize: 12}}>{t('expandGroupDetails')}</div>}
            classes={{expanded: classes.expanded}}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className={classes.avatarWrapper}>
              {map(partners, partner => <TrackerAvatar
                displayName={get(partner, 'firstName') + " " + get(partner, 'lastName')}
                styles={{
                  marginLeft: isXs ? 4 : 24,
                  height: isXs ? 48 : 56,
                  width: isXs ? 48 : 56,
                  fontSize: isXs ? 12 : 14
                }}
                size={isXs ? 48 : 56}
                displayPicture={get(partner, 'picture')}/>)}
            </div>
            <div className={classes.groupDetails}>
              <div className={classes.whiteText}>{t('yourMentorshipGroup')}</div>
              <div style={{fontSize: 24, fontWeight: 'bold'}}>{partners.length} members</div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.partnerDetails} classes={{root: classes.expansionPanelDetailRoot}}>
            {map(partners, (partner, i) => <PartnerInfo partner={partner} hideBorder={i === partners.length - 1}/>)}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      }
    </div>
  );
};

export default withStyles(styles)(MyProfile);


