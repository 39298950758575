import {isEmpty, isUndefined} from 'lodash';
import request from './request';
import Bugsnag from "@bugsnag/js";

export const emailExistsInSystem = (email, auth) => request({
  url: 'api/v2/users/account-exists/',
  method: 'POST',
  data: {
    email,
    provider: isEmpty(email) ? 'google' : 'normal'
  },
  auth
});

export const getCurrentProfileByGoogle = (auth) => request({
  url: 'api/v2/users/profile/',
  method: 'GET',
  auth,
}).catch((e) => {
  Bugsnag.notify(e.originalError, (event) => {
    event.addMetadata('responseBody', e.response);
    event.addMetadata('errorMessage', e.message);
  });
});

export const sendOtp = (email) => request({
  url: 'api/v2/users/send-otp/',
  method: 'POST',
  data: {
    email
  }
});

export const sendPasswordResetOtp = (email) => request({
  url: 'api/v2/users/reset-password/',
  method: 'POST',
  data: {
    email
  }
});

export const verifyOtp = ({email, otp}) => request({
  url: 'api/v2/users/confirm-otp/',
  method: 'POST',
  data: {
    email, otp
  }
});

export const verifyOtpForResetOtp = ({email, otp}) => request({
  url: 'api/v2/users/verify-otp/',
  method: 'POST',
  data: {
    email, otp
  }
});

export const signup = (signupDetails, auth) => {
  if (signupDetails.provider === 'google') {
    return request({
      url: 'api/v2/users/signup/',
      method: 'POST',
      data: signupDetails,
      auth
    });
  } else {
    return request({
      url: 'api/v2/users/signup/',
      method: 'POST',
      data: signupDetails,
    });
  }
};

export const getDeclaration = (role) => request({
  url: `api/v2/declarations/?type=${role}`,
  method: 'GET'
});

export const getProjects = () => request({
  url: 'api/v3/project-list/',
  method: 'GET'
});

export const resetPassword = ({email, otp, password, confirmPassword}) => request({
  url: 'api/v2/users/confirm-password/',
  method: 'POST',
  data: {
    email, otp, newPassword: password, checkNewPassword: confirmPassword
  }
});


export const updateProfile = (data, auth) => {
  const formData = new FormData();
  formData.append("full_name", data.fullName);
  formData.append("bio", data.bio);
  !isUndefined(data.picture) && formData.append("picture", data.picture);
  formData.append("phone_number", data.phoneNumber);
  request({
    url: 'api/v2/users/profile/',
    method: 'PUT',
    data: formData,
    auth,
  });
};

export const fetchSystemStatus = () => request({
  url: `api/v2/users/system_status/`,
  method: 'GET',
});
