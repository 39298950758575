import i18n from "i18n";
import config from "common/config";
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Hex from 'crypto-js/enc-hex';
import { isEmpty } from "lodash";

export const initInbox = () => {
  // console.log('in initInbox: window.$chatwoot is undefined', window.$chatwoot === undefined);
  if (window.$chatwoot) {
    resetChatwoot();
    showChatwoot();
  } else {
    initChatwoot();
  }
}

const initChatwoot = () => {
  window.chatwootSettings = {
    hideMessageBubble: false,
    position: 'right', // This can be left or right
    locale: 'en', // Language to be set
    type: 'expanded_bubble', // [standard, expanded_bubble]
    launcherTitle: i18n.t('helpCenterLauncherTitle')
  };
  window.chatwootSDK.run({
    websiteToken: config.CHATWOOT_TOKEN,
    baseUrl: config.CHATWOOT_BASEURL
  });
}

export const resetChatwoot = () => {
  window.$chatwoot && window.$chatwoot.reset();
}

const getBubbleHolderElement = () => {
  return document.getElementsByClassName("woot--bubble-holder");
}

const hideChatwoot = () => {
  const bubbleHolderElement = getBubbleHolderElement();
  if (!isEmpty(bubbleHolderElement)) bubbleHolderElement[0].style.visibility = 'hidden';
}

const showChatwoot = () => {
  const bubbleHolderElement = getBubbleHolderElement();
  if (!isEmpty(bubbleHolderElement)) bubbleHolderElement[0].style.visibility = 'visible';
}

export const removeChatwootWidget = () => {
  resetChatwoot();
  hideChatwoot();
}

export const setUserProperties = (profile) => {
  const hash = hmacSHA256(profile.id, config.CHATWOOT_HASH_KEY).toString(Hex);
  if (window.$chatwoot) {
    window.$chatwoot.setUser(profile.id, {email: profile.email, name: profile.displayName, identifier_hash: hash});
    window.$chatwoot.setCustomAttributes({role: profile.role, language: profile.newPrimaryLanguage});
  }
};
