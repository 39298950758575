import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import React, {Fragment, useState} from "react";
import {InputAdornment, IconButton} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const styles = theme => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
    "&:hover:not($focused):not($error) $notchedOutline": {}
  },
  focused: {
    border: '1px solid',
    borderColor: '#E0B334 !important'
  },
  error: {},
  notchedOutline: {
    border: 'none !important',
  },
});


const PasswordInput = (props) => {
  const {onAnswered, classes, error, ...restProps} = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Fragment>
      <TextField
        {...restProps}
        onChange={onAnswered}
        type={showPassword ? 'text' : 'password'}
        variant={'outlined'}
        fullWidth
        InputProps={{
          classes: {
            root: classes.root,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Fragment>
  );
};


export default withStyles(styles)(PasswordInput);
