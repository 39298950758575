import 'common/styles/mui-override.scss'
import EmailVerificationContainer from "containers/EmailVerificationContainer"
import InternshipLearningContainer from "containers/InternshipLearningContainer"
import LearningHubContainer from "containers/LearningHubContainer"
import MentorshipContainer from "containers/Mentorship/MentorshipContainer"
import MoreInfoContainer from "containers/MoreInfoContainer"
import ResetPasswordContainer from "containers/ResetPasswordContainer"
import SignInContainer from "containers/SignInContainer"
import SignupContainer from "containers/SignupContainer"
import StageContainer from "containers/StageContainer"
import SurveyContainer from "containers/SurveyContainer"
import SurveyListingContainer from "containers/SurveyListingContainer"
import Dev from "Dev"
import React from "react"
import {connect} from "react-redux"
import {Route, Switch} from "react-router-dom"
import Session from "views/session/Session"
import ProtectedRoute from "./components/navigation/ProtectedRoute"
import { initChatInstance } from "chat/WebSocket";
import ChatFullScreenContainer from "containers/ChatFullScreenContainer";

function App(props) {
  const {isAuthenticated, otpVerificationStatus, isEmailVerified, isVerifying} = props;
  initChatInstance();

  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/"
        component={SurveyContainer}
        isAuthenticated={isAuthenticated}
        isVerifying={isVerifying}
      />
      <Route exact path="/signin" component={SignInContainer}/>
      <Route exact path="/emailverify" component={EmailVerificationContainer}/>

      <ProtectedRoute exact path="/stage" component={StageContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute exact path="/survey" component={SurveyContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute path="/surveylisting" component={SurveyListingContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute exact path="/signup" component={SignupContainer} isAuthenticated={otpVerificationStatus || isEmailVerified}
                      isVerifying={isVerifying}/>
      <ProtectedRoute path="/session/" component={Session} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute path="/learninghub" component={LearningHubContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute path="/mentorship" component={MentorshipContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute path="/internship" component={InternshipLearningContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <ProtectedRoute path="/chat" component={ChatFullScreenContainer}
                                 isAuthenticated={isAuthenticated}
                                 isVerifying={isVerifying} />
      <Route exact path="/resetpassword" component={ResetPasswordContainer}/>


      <ProtectedRoute path="/moreinfo" component={MoreInfoContainer} isAuthenticated={isAuthenticated}
                      isVerifying={isVerifying}/>
      <Route exact path="/dev" component={Dev}/>
    </Switch>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    otpVerificationStatus: state.auth.otpVerificationStatus,
    isEmailVerified: state.auth.isEmailVerified,
    isVerifying: state.auth.isVerifying
  };
}

export default connect(mapStateToProps)(App);
