import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Modal from "@material-ui/core/Modal/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import React from "react";


const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffffff',
    width: 690,
    height: 650,
    outline: 0,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: '0px 34px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 12px'
    },
  },
  title: {
    fontSize: 14,
    color: '#000000',
    display: 'flex',
    fontWeight: 'bold',
  },
  message: {
    fontSize: 18,
    color: '#747474',
    lineHeight: '22px',
    marginTop: 7,
    overflowY: 'auto',
    padding: '20px 0px',
    height: 520,
  },
  languageText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.38)',
    marginRight: 14,
    cursor: 'pointer'
  },
  selected: {
    color: '#000000'
  }
});


const MatchingDescriptionModal = ({classes, open, handleState, message = "", title = ""}) => {

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleState}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
          <IconButton><CloseIcon onClick={handleState}/></IconButton></div>
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          <div className={classes.message}>{message}</div>
        </div>
      </div>
    </Modal>
  )
};

export default withStyles(styles)(MatchingDescriptionModal)


