import {connect} from 'react-redux';
import {
  addMessages,
  addNewMessageToChannel,
  // getChatBlackoutPeriod,
  getChatChannels,
  getUnreadCount,
  toggleMessageWindow,
  updateUnreadCount,
  storeDeviceToken,
  updateChannelMemberLastViewedTime,
  getChatBotUsers,
  updateUserChatRating,
  getChatRatingModalStatus,
  getChannelHistory,
  storeMeetingSummaryRating,
} from "actions/chatAction";
import {get} from 'lodash';

// getToken(storeDeviceToken);
const mapStateToProps = ({
  chat: {
    chatChannels,
    messageWindowActive,
    selectedChannelId,
    messages,
    channelUnreadCount,
    fetchingList,
    nextPageNumber,
    channelsWithMemberId,
    chatBlackoutPeriod,
    updateUnreadCount,
    botUsers,
    chatRatingStatus,
    chatChannelHistory,
  }, auth: {user: {id, email, status}},
}) => ({
  chatChannels,
  myId: id,
  messageWindowActive,
  selectedChannelId,
  myEmail: email,
  messages,
  channelUnreadCount,
  fetchingList,
  nextPageNumber,
  channelsWithMemberId,
  isChatActive: (get(status, 'experiences.chattype') === 'use'
              && get(status, 'experiences.chatStatus.mentorshipChat') === 'use')
              || get(status, 'experiences.chatStatus.aiChat') === 'use',
  chatBlackoutPeriod,
  updateUnreadCount,
  updateChannelMemberLastViewedTime,
  botUsers,
  updateUserChatRating,
  getChatRatingModalStatus,
  chatRatingStatus,
  chatChannelHistory,
  getChannelHistory,
  storeMeetingSummaryRating,
});

export default connect(mapStateToProps, {
  getChatChannels,
  toggleMessageWindow,
  addMessages,
  addNewMessageToChannel,
  getUnreadCount,
  // getChatBlackoutPeriod,
  updateUnreadCount,
  storeDeviceToken,
  updateChannelMemberLastViewedTime,
  getChatBotUsers,
  updateUserChatRating,
  getChatRatingModalStatus,
  getChannelHistory,
  storeMeetingSummaryRating,
});
