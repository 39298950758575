import MomentUtils from '@date-io/moment'
import { withStyles} from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary"
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {ThemeProvider} from "@material-ui/styles"
import classNames from 'classnames'
import AddEventForm from "components/AddEventForm"
import CustomCalendarEvent from "components/CustomCalendarEvent"
import ScheduledMentorshipSession from "components/ScheduledMentorshipSession"
import Streaks from "components/Streaks"
import {filter, get, includes, map} from "lodash"
import moment from "moment"
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import { createTheme } from '@material-ui/core/styles'


const styles = theme => ({
  expansionTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  panelTitle: {
    fontWeight: 'bold',
    fontSize: 14
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start'
    },
    padding: '0px 0px 16px 0px'
  },
  deadline: {
    borderBottom: '1px solid #52B2E2'
  },
  badgeColor: {
    backgroundColor: '#DE6E49'
  },
  event: {
    color: '#DE6E49',
  },
  streakHighlight:{
    backgroundColor:'#FAF2EF',
    borderRadius:'50%'
  }
});

const calendarTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#E0B334',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: 'rgba(0, 0, 0, 0.5)',
      },
      daySelected: {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.10)'
        },
      },
      current: {
        color: '#000000',
      },
    },
  },
});


const MentorshipCalendar = ({classes, loadEvents, mentorshipId, events, sessions, ongoingSessions, submitEvent, profileId, cancelEvent, progress, mentorshipGroupId}) => {
  const {t} = useTranslation();

  useEffect(() => {
    mentorshipId && loadEvents(mentorshipId);
  }, [mentorshipId]);

  const ongoingSessionsEndDate = (sessions) => map(sessions, session => moment(session.endDate).format('YYYY-MM-DD'));
  const thisMonthEventsDates = (events) => map(events, event => moment(event.scheduledTime).format('YYYY-MM-DD'));
  const thisMonthStreakDates = (streaks) => map(streaks, streak => moment(streak.timestamp).format('YYYY-MM-DD'));

  const thisMonthOngoingSessions = (ongoingSessions, selectedDate) => {
    return filter(ongoingSessions, (session) => session.endDate && moment(session.endDate).format('YYYY-MM') ===
      moment(selectedDate).format('YYYY-MM'));
  };
  const thisMonthEvents = (events, selectedDate) => {
    return filter(events, (event) => event.scheduledTime && moment(event.scheduledTime).format('YYYY-MM') ===
      moment(selectedDate).format('YYYY-MM'));
  };

  const thisDayEvents = (events, selectedDate) => {
    return filter(events, (event) => event.scheduledTime && moment(event.scheduledTime).format('YYYY-MM-DD') ===
      moment(selectedDate).format('YYYY-MM-DD'));
  };

  const thisMonthStreaks = (streaks, selectedDate) => {
    return filter(streaks, (streak) => streak.timestamp && moment(streak.timestamp).format('YYYY-MM') ===
      moment(selectedDate).format('YYYY-MM'));
  };


  const [date, changeDate] = useState(new Date());
  const [month, changeMonth] = useState(new Date());
  const [isExpanded, setisExpanded] = useState(true)

  const handleExpandPanel = (isExpanded) => setisExpanded(!isExpanded)

  const [open, setOpen] = React.useState({});

  const handleOpen = (eventType,EventId) => {
    setOpen({
      eventType,
      EventId,
      state:true
    });
  };

  const handleClose = () => {
    setOpen({
      eventType:"",
      state:false});
  };

  const handleDateChange = (date) => {
    changeDate(date);
  };
  const handleMonthChange = (date) => {
    changeMonth(date);
  };

  const endDates = ongoingSessionsEndDate(thisMonthOngoingSessions(ongoingSessions, month));
  const eventDates = thisMonthEventsDates(thisMonthEvents(events, month));
  const streakDates = thisMonthStreakDates(thisMonthStreaks(get(progress, 'quizStreaks', []), month));

  const calendar = <MuiPickersUtilsProvider utils={MomentUtils}>
    <ThemeProvider theme={calendarTheme}>
      <DatePicker
        autoOk
        orientation="portrait"
        variant="static"
        openTo="date"
        value={date}
        onChange={handleDateChange}
        disableToolbar
        onMonthChange={handleMonthChange}
        renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => {
          const isDeadline = isInCurrentMonth && includes(endDates, moment(day).format('YYYY-MM-DD'));
          const isEvent = isInCurrentMonth && includes(eventDates, moment(day).format('YYYY-MM-DD'));
          const isStreak = isInCurrentMonth && includes(streakDates, moment(day).format('YYYY-MM-DD'));

          let CustomDay = <span
            className={classNames(isDeadline && classes.deadline, isEvent && classes.event)}>
            {moment(day).format('D')}
            </span>

          let element = React.cloneElement(dayComponent, [], CustomDay)
          return <div className={isStreak && classes.streakHighlight}>{element}</div>
        }}
      />
    </ThemeProvider>
  </MuiPickersUtilsProvider>;

  return (
    <div className={classes.wrapper}>
      <ExpansionPanel
        defaultExpanded={true}
        expanded={isExpanded}
      >
        <ExpansionPanelSummary
          expandIcon={<AddCircleIcon onClick={()=>handleOpen("addEvent")} style={{color: '#52B2E2'}}/>}
          aria-controls="panel1c-content"
          id="panel1c-header"
          classes={{content: classes.expansionTitleWrapper}}
        >
          <div className={classes.panelTitle} onClick={() => handleExpandPanel(isExpanded)}> {t('calendar')}</div>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.details}>
          <Streaks progress={progress}/>
          {calendar}
          {thisDayEvents(events, date).map(event => <CustomCalendarEvent event={event} editEvent={(EventId)=>handleOpen("editEvent",EventId)}/>)}
          <ScheduledMentorshipSession sessions={thisMonthOngoingSessions(ongoingSessions, month)}/>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <AddEventForm
        open={open}
        sessions={sessions}
        events={events}
        handleClose={handleClose}
        profileId={profileId}
        submitEvent={submitEvent}
        cancelEvent={cancelEvent}
        mentorshipGroupId={mentorshipGroupId}
        mentorshipId={mentorshipId}/>
    </div>
  );
};

export default withStyles(styles)(MentorshipCalendar);


