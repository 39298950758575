import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import FullScreenMessengerContainer from "containers/FullScreenMessengerContainer";


const styles = theme => ({
  wrapper: {
    width: 970,
    height: 530,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: '80vh',
      maxHeight: 820
    }
  }
});


const ChatWindow = ({classes,}) => {

  return (
    <div className={classes.wrapper}>
      <FullScreenMessengerContainer/>
    </div>
  );
};

export default withStyles(styles)(ChatWindow);


