import React, { useEffect, useState } from "react";
import ChatBotIcon from "common/assets/chatbot-icon.svg";
import SadEmoji from "common/assets/emojis/sad.png";
import ConfusedEmoji from "common/assets/emojis/confused.png";
import NeutralEmoji from "common/assets/emojis/neutral.png";
import GrinningEmoji from "common/assets/emojis/grinning.png";
import CelebrateEmoji from "common/assets/emojis/celebrate.png";
import InactiveSadEmoji from "common/assets/emojis/inactive-sad-emoji.png";
import InactiveConfusedEmoji from "common/assets/emojis/inactive-confused-emoji.png";
import InactiveNeutralEmoji from "common/assets/emojis/inactive-neutral-emoji.png";
import InactiveGrinningEmoji from "common/assets/emojis/inactive-grinning-emoji.png";
import InactiveCelebrateEmoji from "common/assets/emojis/inactive-celebrate-emoji.png";
import SuccessIcon from "common/assets/success-icon.png";
import withStyles from "@material-ui/core/styles/withStyles";


const ratingQuestions = [
    'Accuracy of information provided?',
    'Relevance of information shared?',
    'Query resolution?'
]

const ChatRatingWrapper = ({classes, isFullScreen, updateUserChatRating, isSuccessPopup, setIsSuccessPopup, setIsRatingModal}) => {
    const [activeEmojiIndex, setActiveEmojiIndex] = useState(Array(ratingQuestions.length).fill(null));
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const handleEmojiClick = (questionIndex, emojiIndex) => {
        const newActiveEmojiIndex = [...activeEmojiIndex];
        newActiveEmojiIndex[questionIndex] = emojiIndex;
        setActiveEmojiIndex(newActiveEmojiIndex);

        const allAnswered = newActiveEmojiIndex.every(index => index !== null);
        setIsSubmitDisabled(!allAnswered);
    };

    const emojiIcons = (questionIndex) => {
        return (
            <div className={classes.emojiList}>
                <div className={classes.line}></div>
                    <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 0)}>
                        <img src={activeEmojiIndex[questionIndex] === 0 ? SadEmoji : InactiveSadEmoji} className={classes.emoji} />
                    </a>
                    <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 1)}>
                        <img src={activeEmojiIndex[questionIndex] === 1 ? ConfusedEmoji : InactiveConfusedEmoji} className={classes.emoji} />
                    </a>
                    <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 2)}>
                        <img src={activeEmojiIndex[questionIndex] === 2 ? NeutralEmoji : InactiveNeutralEmoji} className={classes.emoji} />
                    </a>
                    <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 3)}>
                        <img src={activeEmojiIndex[questionIndex] === 3 ? GrinningEmoji : InactiveGrinningEmoji} className={classes.emoji} />
                    </a>
                    <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 4)}>
                        <img src={activeEmojiIndex[questionIndex] === 4 ? CelebrateEmoji : InactiveCelebrateEmoji} className={classes.emoji} />
                    </a>
            </div>
        );
    };

    const handleSubmit = () => {
        const ratingsData = {};
        ratingQuestions.forEach((question, index) => {
            const questionKey = question;
            ratingsData[questionKey] = activeEmojiIndex[index] !== null ? activeEmojiIndex[index] + 1 : null;
        });
        updateUserChatRating(ratingsData);
        setIsSuccessPopup(true);
        return true;
    }

    useEffect(() => {
        if (isSuccessPopup) {
            const timer = setTimeout(() => {
                setIsSuccessPopup(false);
                setIsRatingModal(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isSuccessPopup]);

    const handleSkip = () => {
        const ratingsData = {};
        ratingQuestions.forEach((question ) => {
            const questionKey = question;
            ratingsData[questionKey] = null;
        });
        updateUserChatRating(ratingsData);
        setIsRatingModal(false);
        return true;
    }

    const renderRatingContent = () => {
        return (
            <>
                <div className={isFullScreen && classes.emojiWrapper}>
                    <div className={isFullScreen ? classes.chatBotSection : classes.chatBotMobileSection}>
                        <img src={ChatBotIcon} className={classes.chatBotIcon} />
                        <p className={classes.helpText}>Hope, I was able <br /> to help you!</p>
                    </div>
                    <div className={isFullScreen ? classes.emojiSection : classes.mobiledivEmojiSection}>
                        {ratingQuestions.map((question, index) => (
                            <div className={classes.feedbackRow}>
                                <p className={classes.feedbackText}>{question}</p>
                                {emojiIcons(index)}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.footer}>
                    <a className={classes.skipLink} onClick={handleSkip}>Skip</a>
                    <a 
                        className={`${classes.submitLink} ${isSubmitDisabled ? classes.disabled : ""}`}
                        onClick={handleSubmit}
                        style={{ pointerEvents: isSubmitDisabled ? 'none' : 'auto' }}
                    >
                        Submit
                    </a>
                </div>
            </>
        )
    }

    const renderSuccessMessage = () => {
        return (
            <>
                <div className={classes.successContainer}>
                    <img className={classes.successIcon} src={SuccessIcon} />
                    <span className={classes.successText}>Thank you for your feedback! Your rating has been submitted.</span>
                </div>
            </>
        )
    }

    return (
        <div className={classes.chatEmojiWrapperContainer}>
            {!isSuccessPopup ? renderRatingContent() : renderSuccessMessage()}
        </div>
    )
}


const styles = () => ({
    chatEmojiWrapperContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: 999,
        margin: 8,
        padding: 20,
        border: '1px solid #f0f0f0',
        backgroundColor: '#fff',
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
        borderRadius: 2,
        right: 0,
    },

    emojiWrapper: {
        display: 'flex',
    },

    chatBotSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRight: '1px solid #00000033',
        paddingRight: 50,
    },

    helpText: {
        textAlign: 'justify',
        fontSize: 24,
        fontWeight: 'bold',
        alignContent: 'center',
        margin: 0,
    },

    emojiSection: {
        paddingLeft: 24,
        width: 250,
    },

    mobiledivEmojiSection: {
        width: 250,
    },

    feedbackRow: {
        marginBottom: 16,
    },

    feedbackText: {
        margin: 0,
        marginBottom: 2,
        fontSize: 10,
        fontWeight: 'bold',
        color: '#00000066',
    },

    emojiList: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 32,
    },

    line: {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        width: 260,
        height: '2px',
        backgroundColor: '#E0B33433',
        transform: 'translateY(-50%)',
        zIndex: -1,
    },

    emoji: {
        width: 29,
        height: 29,
        backgroundColor: '#fff',
    },

    chatBotIcon: {
        width: 58,
        height: 58,
    },

    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 20,
    },

    skipLink: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00000033',
        textDecoration: 'none',
        cursor: 'pointer',
    },

    submitLink: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#52B2E2',
        textDecoration: 'none',
        cursor: 'pointer',
    },

    disabled: {
        color: '#CCCCCC',
        cursor: 'not-allowed',
    },

    chatBotMobileSection: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        marginBottom: 9,
        paddingBottom: 10,
        borderBottom: '1px solid #0000001A',
    },

    emojiLink: {
        width: 29,
        cursor: 'pointer',
    },

    successContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    successIcon: {
        width: 104,
        height: 104,
    },

    successText: {
        fontSize: 20,
        fontWeight: 'bold',
        marginTop: 20,
        textAlign: 'center'
    }
});

export default withStyles(styles)(ChatRatingWrapper);
