import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR, PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER} from "common/helpers";
import CodeofConduct from "components/CodeofConduct";
import FAQ from "components/FAQ";
import MoreInfoNavigation from "components/MoreinfoNavigation";
import WorkReadinessScore from "components/WorkReadinessScore";
import {find, isEmpty} from 'lodash';
import React, {useEffect} from 'react';
import {Route} from "react-router-dom";
import '../common/styles/animate.css';
import {useTranslation} from "react-i18next";

const MaterialStyles = theme => ({
  mainWrapper: {
    background: `linear-gradient(180deg, rgba(224, 179, 52, 0.2) 0%, rgba(224, 179, 52, 0) 100%)`,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    padding: '30px 0px'
  },
  paper: {
    height: '60vh',
    width: '100%',
  },
  pageWrapper: {
    height: PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    overflowY: 'auto'
  },
});

export const getItem = (community, title) => find(community,
  (item) => item.title === title) || {};

const MoreInfo = ({classes, displayName, community, faqTopics, workReadinessScore, loadCommunity}) => {
  const {t} = useTranslation();

  useEffect(() => {
    loadCommunity();
  }, []);


  return (
    <div className={classes.mainWrapper}>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={2}>
            <MoreInfoNavigation displayName={displayName} wrsAvailable={!isEmpty(workReadinessScore.scores)}/>
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            <Route exact path={"/moreinfo"}>
            </Route>
            <Route exact path={"/moreinfo/faq"}>
              <FAQ faqTopics={faqTopics}/>
            </Route>
            <Route exact path={"/moreinfo/codeofconduct"}>
              <CodeofConduct title={t('codeOfConduct')} content={getItem(community, 'CODE OF CONDUCT')}/>
            </Route>
            <Route exact path={"/moreinfo/wrs"}>
              <WorkReadinessScore workReadinessScore={workReadinessScore}/>
            </Route>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(MoreInfo);
