import Button from "@material-ui/core/Button/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import CheckIcon from '@material-ui/icons/Check';
import {convertUrl} from "common/helpers";
import {history} from "common/history";
import {get, isEmpty} from 'lodash';
import moment from "moment";
import React from "react";
import logo from 'common/assets/logo.png';
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";

const styles = theme => ({
  surveyCardWrapper: {
    background: 'white',
    borderRadius: 5,
    display: 'flex',
    width: 462,
    minHeight: 149,
    padding: '20px 24px',
    margin: 16,
    [theme.breakpoints.down('sm')]: {
      margin: 4,
    }
  },
  surveyContent: {},
  surveyAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: "flex-end",
    marginLeft: 'auto',
  },
  surveyName: {
    display: 'flex',
    alignItems: 'baseline',
    marginLeft: 11
  },
  surveyNameText: {
    fontSize: 18,
    paddingRight: 12
  },
  newTag: {
    fontSize: 14,
    color: '#E0B334',
    fontStyle: "italic",
  },
  descriptionText: {
    color: 'rgba(0, 0, 0, 0.72)',
    fontSize: 12,
  },
  completedButton: {
    backgroundColor: '#41A156',
    display: 'flex',
    boxShadow: '0px 2px 2px rgba(82, 178, 226, 0.1)',
    borderRadius: 5,
    padding: '6px 25px'
  },
  completedMessage: {
    color: '#41A156',
    fontSize: '12',
    fontWeight: 'bold',
  },
  icon: {
    width: 22,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  }
});


const SurveyCard = ({classes, survey}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const isComplete = survey.status.startsWith('COMPLETE');
  const isPartial = survey.status.startsWith('PARTIAL');
  const surveyName = st(get(survey, "name"), get(survey, "localeName"));
  const icon = isEmpty(survey.icon) ? logo : convertUrl(survey.icon);
  const shortDescription = st(get(survey, "shortDescription"), get(survey, "localeShortDescription"));
  const completeTime = moment(survey.status.replace('COMPLETE on ', '')).format('Do MMM');


  return (
    <div className={classes.surveyCardWrapper}>
      <div className={classes.surveyContent}>
        <div className={classes.nameContainer}>
          <img src={icon} className={classes.icon}/>
          <div className={classes.surveyName}>
            <p className={classes.surveyNameText}>{surveyName}</p>
          </div>
        </div>
        <div>
          <p className={classes.descriptionText}>{shortDescription}</p>
          {isComplete && <p className={classes.completedMessage}>{`${t('completedOn')} ${completeTime}`}</p>}
        </div>
      </div>
      <div className={classes.surveyAction}>
        {isComplete ?
          (
            <div className={classes.completedButton}>
              <CheckIcon style={{color: '#ffffff'}}/>
            </div>
          ) : isPartial ? (
            <Button variant="outlined" style={{border: '1px solid', borderColor: '#52B2E2', backgroundColor: '#ffffff', fontSize: 12, color: '#52B2E2'}} fullWidth onClick={() => history.push("/survey", {surveyId: survey.id})} size="large">
              {t('finishCaps')}
            </Button>
          ) : (
            <Button variant="contained" style={{backgroundColor: '#52B2E2', fontSize: 12, color: 'white'}} fullWidth onClick={() => history.push("/survey", {surveyId: survey.id})} size="large">
              {t('takeCaps')}
            </Button>
          )}
      </div>
    </div>
  );
};

export default withStyles(styles)(SurveyCard);


