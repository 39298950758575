import {useTranslation} from "react-i18next";
import classNames from "classnames";
import Video from "common/assets/videoYellow.svg";
import {formatChatTimestamp} from "common/helpers";
import React from "react";
import {connect} from "react-redux";
import {joinVideo} from "actions/videoAction";
import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment";

const styles = theme => ({
  wrapper: {
    display: 'flex',
    margin: '8px 0px',
    flexShrink: 0,
  },
  videoContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  messageTime: {
    fontSize: 10,
    color: '#989898',
    lineHeight: '12px',
    marginTop: 8,
  },
  clickToJoin: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#C9C9C9',
    lineHeight: '14px',
    marginTop: 10,
    cursor: 'pointer'
  },
  messageText: {
    fontSize: 12,
    lineHeight: '14px',
    overflowWrap: 'anywhere',
    whiteSpace: 'pre-line'
  },
  videoIcon: {
    paddingRight: 12,
  }
});

const VideoTypeMessageComponent = ({classes, messageContent, messageDate, isSelf, onJoinVideo, onClick,isBlackOutPeriodActive }) => {
  const {t} = useTranslation();
  const message = JSON.parse(messageContent);

  const onButtonClicked = () => {
    if(isBlackOutPeriodActive) return;
    onJoinVideo(message.room);
    onClick();
  };

  const isVideoRoomActive = () => {
    return moment().isBefore(moment(messageDate).add(3, 'h'));
  }

  return (
    <div className={classNames(classes.videoContentWrapper, isSelf ? "rightTriangle" : "leftTriangle")}>
      <div className={classNames(classes.videoIcon)}>
        <img src={Video} alt={""}/>
      </div>
      <div>
        <div className={classes.messageText}>{t('Invite for a video call')}</div>
        <div className={classes.messageTime}>{t('on')} {formatChatTimestamp(messageDate)}</div>
        {isVideoRoomActive() && <div className={classes.clickToJoin} onClick={onButtonClicked}>{t('CLICK TO JOIN')}</div>}
      </div>
    </div>);
};

export default connect(null, {onJoinVideo: joinVideo})(withStyles(styles)(VideoTypeMessageComponent))
