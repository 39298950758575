import _ from 'lodash';
import i18n from "i18n";

export const types = {
  text: 'Text',
  number: 'Number',
  date: 'Date',
  singleChoice: 'SingleChoice',
  multiChoice: 'multiChoice',
  singleChoiceButton: 'singleChoiceButton'
};

const isEmptyOrBlank = (value) =>
  _.overSome([_.isNil, _.isNaN])(value) ? true :
  _.overSome([_.isNumber, _.isBoolean, _.isDate])(value) ? false :
  _.isNil(value);

const mandatoryCheck = (type, value, mandatory) => isEmptyOrBlank(value) && mandatory;

const numberCheck = (type, value) =>
  (type === types.number)
  && !_.isEmpty(value)
  && (isNaN(parseFloat(value)) || !isFinite(value));

const checks = [
  [mandatoryCheck, i18n.t('fieldIsMandatory')],
  [numberCheck, i18n.t('mustBeNumeric')],
];

const runChecks = (...args) => _.reduce(checks,
  (result, check) => {
    return check[0](...args) ? _.union(result, [check[1]]) : result;
  }, []);

export const validate = (question, answer) =>
  runChecks(question.questionType, answer, question.mandatory);
