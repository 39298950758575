import {myFirebase} from "../firebase/firebase";

export const staticAction = (type) => (dispatch) => dispatch({type});

export const dataAction = (type, payload) => (dispatch) => dispatch({type, payload});

export const silentApiAction = (fn, ...args) => async () => {
  const idToken = myFirebase.auth().currentUser !== null ? await myFirebase.auth().currentUser.getIdToken() : null;

  const auth = {idToken}
  return await fn(...args, auth);
};

export const apiAction = (fn, ...args) => async (dispatch) => {
  dispatch(staticAction('LOADER_SHOW'));
  try {
    return await dispatch(silentApiAction(fn, ...args));
  }
  finally {
    dispatch(staticAction('LOADER_HIDE'));
  }
};
