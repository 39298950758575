import moment from 'moment';
import _ from 'lodash';

const actions = {
  STORE_EVENTS: 'STORE_EVENTS',
  CHANGE_EVENT: 'CHANGE_EVENT',
  CHANGE_DATE: 'CHANGE_DATE',
  CREATE_SCHEDULE: 'CREATE_SCHEDULE',
  SAVE_EVENT_START: 'SAVE_EVENT_START',
  LOCALLY_REMOVE_EVENT: 'LOCALLY_REMOVE_EVENT',
  LOAD_EVENTS_SUCCESS: 'LOAD_EVENTS_SUCCESS'
};

export const createInitialState = () => ({
  events: [],
  event: {},
  selectedDate: new Date(),
});

const mergeDate = (selectedDate, scheduledTime) => {
  const newScheduledTime = moment(scheduledTime || new Date());
  const wrappedSelectedDate = moment(selectedDate || new Date());
  newScheduledTime.set({
    year: wrappedSelectedDate.year(),
    month: wrappedSelectedDate.month(),
    date: wrappedSelectedDate.date(),
  });
  return newScheduledTime;
};

const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.STORE_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case actions.CHANGE_EVENT:
      return {
        ...state,
        event: action.payload,
      };
    case actions.CHANGE_DATE:
      return {
        ...state,
        selectedDate: action.payload,
        event: Object.assign({}, state.event, {
          scheduledTime: mergeDate(action.payload, state.event.scheduledTime),
        }),
      };
    case actions.CREATE_SCHEDULE: {
      return {
        ...state,
        selectedDate: action.payload || state.selectedDate,
        event: {
          scheduledTime: mergeDate(action.payload || state.selectedDate, state.event.scheduledTime),
        },
      };
    }
    case actions.SAVE_EVENT_START: {
      return {
        ...state,
        selectedDate: action.payload.scheduledTime || state.selectedDate,
      };
    }
    case actions.LOCALLY_REMOVE_EVENT: {
      return {
        ...state,
        events: _.without(state.events, action.payload),
      };
    }
    case actions.LOAD_EVENTS_SUCCESS:
      return {
        ...state,
        event: {},
      };
    default:
      return state;
  }
};

export {actions, reducer};