import {cancelEvent, loadEvents, submitEvent} from "actions/eventsAction"
import MentorshipCalendar from "components/MentorshipCalendar";
import {concat, get} from 'lodash';
import {connect} from 'react-redux';

const mapStateToProps = ({auth: {user}, event: {events}, mentorship: {sessions,partnerDetails,progress}}) => {
  return ({
    mentorshipId: get(partnerDetails, 'id'),
    profileId: get(partnerDetails,'userDetails[0].id'),
    mentorshipGroupId: get(user,'status.mentorGroup',null),
    events,
    sessions: concat(sessions.ongoingSessions, sessions.allSessions),
    ongoingSessions:sessions.ongoingSessions,
    progress
  })
};

export default connect(mapStateToProps, {loadEvents,submitEvent,cancelEvent})(MentorshipCalendar);
