import withStyles from "@material-ui/core/styles/withStyles";
import {noop} from "lodash";
import React, {Fragment} from "react";
import i18n from "i18n";


const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17,
    textTransform:'UPPERCASE'
  },
  answerText: {
    fontSize: 14,
  }
});

const DateSelectionAnswer = ({classes, style, quizAnswer = {}, title = i18n.t('yourAnswer')}) => {

  return (
    <Fragment>
      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.answerText}>
          {quizAnswer.answer}
        </p>
      </div>
    </Fragment>
  );
};


export default withStyles(styles)(DateSelectionAnswer);
