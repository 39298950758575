import React, {useState} from "react";
import './MentorMenteeSelector.scss';
import mentee from 'common/assets/mentorMenteeSelectionImages/mentee.png';
import inActiveMentee from 'common/assets/mentorMenteeSelectionImages/mentee_inactive.png';
import mentor from 'common/assets/mentorMenteeSelectionImages/mentor.png';
import inActiveMentor from 'common/assets/mentorMenteeSelectionImages/mentor_inactive.png';
import {isMentee, isMentor} from "business/role";
import {useTranslation} from "react-i18next";

export default function MentorMenteeSelector({onAnswered}) {
  const {t} = useTranslation();

  const [isSelected, setIsSelected] = useState(false);
  const [roleSelected, setRoleSelected] = useState("");

  const handleChange = (e) => {
    onAnswered({[e.target.name]: e.target.value});
    setRoleSelected(e.target.value);
    setIsSelected(true);
  };

  return (
    <div className={isSelected ? "selectorBox selected" : "selectorBox"}>
      <label>
        <input type="radio" name="userType" value="Mentee" onChange={handleChange}/>
        <div className="mentee box">
          <p className="text">{t('iAmA')}<br/><span className="title">{t('menteeCaps')}</span></p>
          <img src={isSelected ? isMentee(roleSelected) ? mentee : inActiveMentee : mentee} alt="" height={126}/>
        </div>
      </label>

      <label>
        <input type="radio" name="userType" value="Mentor" onChange={handleChange}/>
        <div className="mentor box spacing">
          <p className="text">{t('iAmA')}<br/><span className="title">{t('mentorCaps')}</span></p>
          <img src={isSelected ? isMentor(roleSelected) ? mentor : inActiveMentor : mentor} alt="" height={126}/>
        </div>
      </label>
    </div>
  );
};
