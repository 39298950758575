import {
  initMentorship,
  initMentorshipSessions,
  setCurrentStepId,
  toggleMentorshipBookmark
} from "actions/mentorshipAction";
import {initSession} from "actions/sessionAction";
import {find, get, isEmpty} from "lodash";
import moment from "moment";
import {connect} from 'react-redux';
import {setCurrentPath} from "reducers/NavmenuReducer";
import ActiveMentorshipLearning from "views/Mentorship/ActiveMentorshipLearning";
import i18n from "i18n";


const mapStateToProps = ({
                           mentorship: {
                             sessions, partnerDetails, progress
                           },
                           profile: {displayName},
                           session: {currentSession},
                           sessionStatus: {lastMentorshipSessionStatus},
                           auth: {user: {role, status}}
                         }) => {

  const ongoingSessions = get(sessions, 'ongoingSessions');
  const sessionOngoingForEndDate = find(ongoingSessions, session => session.id === lastMentorshipSessionStatus.sessionId);

  const completeByMessageFormat = (date) => `${i18n.t('completeBy')} ${moment(date).format('MMM Do')}.`;

  const completeByMessage = isEmpty(sessionOngoingForEndDate) ? "" : completeByMessageFormat(sessionOngoingForEndDate);

  return {
    mentorshipSessionStatus: get(status, 'experiences.mentorshipSessionStatus'),
    mentorshipId: get(status, 'mentorships[0].id'),
    mentorship: get(status, 'mentorships[0]'),
    sessions,
    partnerDetails,
    role,
    progress,
    lastMentorshipSessionStatus,
    completeByMessage,
    displayName
  };
};


export default connect(mapStateToProps, {
  initMentorship,
  initMentorshipSessions,
  setCurrentPath,
  initSession,
  toggleMentorshipBookmark,
  setCurrentStepId
})(ActiveMentorshipLearning);
