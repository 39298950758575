import {apiAction, dataAction} from "actions/actionWrappers";
import {getCommunityElements, getWorkReadinessScores} from "api/supplementaryApi";
import {actions} from "reducers/supplementaryReducer";


const fetchWorkReadinessScores = () => async (dispatch) => {
  try{
    const workReadinessScores = await dispatch(apiAction(getWorkReadinessScores));
    dispatch(dataAction(actions.STORE_WORK_READINESS_SCORE, workReadinessScores));
  }catch (e) {

  }
};


const loadCommunity = () => async (dispatch) => {
  const communityElements = await dispatch(apiAction(getCommunityElements));
  dispatch(dataAction(actions.STORE_COMMUNITY_ELEMENTS, communityElements));
};

const setCongratulationsState = (status) => dispatch => {
  dispatch(dataAction(actions.SET_GROUP_HOLD_MESSAGE_STATE, status));
};


export {
  fetchWorkReadinessScores,
  loadCommunity,
  setCongratulationsState
};


