import {humanizedTime} from "common/helpers";
import {
  capitalize,
  find,
  isBoolean,
  isDate,
  isEmpty,
  isNaN,
  isNil, isNull,
  isNumber,
  isUndefined,
  overSome,
  sortBy
} from 'lodash';
import moment from "moment";
import i18n from "i18n";


export const sessionDurationHumanReadable = (session) => {
  const sessionTimeLeft = (session) => session.sessionDuration - session.durationCompleted;
  if (sessionTimeLeft(session) < 1) return i18n.t('completed');

  const message = capitalize(humanizedTime(sessionTimeLeft(session)));
  const left = session.durationCompleted > 0 ? i18n.t('left') : '';
  return `${message} ${left}`;
};


export const sessionStatusMessage = (sessionStatus = {}, session = {}) => {
  const completeByMessage = (date) => `${i18n.t('completeBy')} ${moment(date).format('MMM Do')}.`;

  const anyPartnerHasStatus = (status, partners) => !isEmpty(find(partners, partner => partner.partnerStatus === status));

  if (sessionStatus.status === 'PARTIAL_COMPLETE' && anyPartnerHasStatus('ONGOING', sessionStatus.partnerCompletedSteps)) {
    return i18n.t('partnerYetToComplete');
  }
  if (sessionStatus.status === 'COMPLETED') {
    return i18n.t('completed');
  }
  if (sessionStatus.status === 'ONGOING' && anyPartnerHasStatus('PARTIAL_COMPLETE', sessionStatus.partnerCompletedSteps)) {
    return `${completeByMessage(sessionStatus.endDate)} (${i18n.t('partnerCompleted')})`;
  }

  if (isEmpty(sessionStatus.status)) {
    return sessionDurationHumanReadable(session);
  }

  //Cases -
  // 1. There is no partner (self learning session).
  // 2. Partner has not yet started
  return isEmpty(sessionStatus.endDate) ? sessionDurationHumanReadable(sessionStatus) : completeByMessage(sessionStatus.endDate);
};


export const orderSessionSteps = session => sortBy(session.sessionSteps, (step) => step.order);

const isEmptyOrBlank = (value) =>
  overSome([isNil, isNaN])(value) ? true :
  overSome([isNumber, isBoolean, isDate])(value) ? false :
  isNil(value); // Replaced isEmpty with isNil since isEmpty returns true for files.


export const isFormEmpty = (answers) => !find(answers, answer => !isEmptyOrBlank(answer));
