import Container from "@material-ui/core/Container/Container";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Feedback from "components/Feedback";
import React from "react";


const MaterialStyles = theme => ({
  sessionQuizWrapper: {
    position: 'absolute',
    top: 'calc(50px)',
    left: 0,
    width: '100%',
    height: '95vh',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))'
  },

});


function SessionFeedback({classes, location, submitSessionFeedback, isLearningHub}) {
  const {sessionId} = location.state;
  return (
    <div className={classNames(classes.sessionQuizWrapper, 'animated faster slideInUp')}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Feedback sessionId={sessionId} submitSessionFeedback={submitSessionFeedback} isLearningHub={isLearningHub}/>
      </Container>
    </div>
  );
};


export default withStyles(MaterialStyles)(SessionFeedback)
