import Backdrop from "@material-ui/core/Backdrop/Backdrop"
import Modal from "@material-ui/core/Modal/Modal"
import withStyles from "@material-ui/core/styles/withStyles"
import classNames from 'classnames'
import {groupBy, isEmpty, map, toUpper} from 'lodash'
import moment from "moment"
import React, {useEffect} from "react"
import {useTranslation} from "react-i18next";


const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffffff',
    width: 458,
    height: 650,
    outline: 0,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  content: {
    padding: '0px 34px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 12px'
    },
  },
  title: {
    fontSize: 14,
    color: '#000000',
    display: 'flex',
    fontWeight: 'bold',
  },
  historyItemBlock: {
    borderRadius: 5,
    backgroundColor: '#FFEFE9',
  },
  itemName: {
    color: '#000000',
    textTransform: 'uppercase',
    fontSize: 18,
  },
  dullName: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  detailText: {
    fontSize: 14,
    marginTop: 11,
  },
  historyItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 23,
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    "&:last-child": {border: 0}
  },
  match: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#41A156',
    backgroundColor: '#ffffff',
    borderRadius: 29,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 23,
    marginLeft: 'auto',
  },
  statusMessage: {
    fontSize: 12,
    color: '#E14747'
  },
  statusMessageActive: {
    color: '#41A156',
    fontWeight: 'bold',
    fontSize:12
  },
  date: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.49)',
    fontWeight: 'bold',
    marginBottom: 16,
  },
  historyBlockWrapper: {
    marginTop: 32
  },
  closeTxtBtn:{
    padding:'10px 12px',
    fontWeight:'bold',
    fontSize:12,
    cursor:'pointer'
  }
});

const MatchingHistoryModal = ({classes, open, handleState, getMatchesList, matches}) => {
  const {t} = useTranslation();

  useEffect(() => {
    open && getMatchesList()
  }, [open])
  const groupedList = groupBy(matches, match => moment(match.updatedTimestamp).format('YYYY-MM-DD'))

  const HistoryItem = ({item: {status = "", displayName = "", fullName = ""}}) => {
    const isActive = status === 'ACTIVE'
    const isSuccess = status === 'SUCCESS'

    return (
      <div className={classes.historyItem}>
        <div className={classNames(classes.itemName, !isActive && classes.dullName)}><span
          style={{width: '50%'}}>{fullName}</span>
        </div>
        <div>
          <span className={(isActive || isSuccess) ? classes.statusMessageActive : classes.statusMessage}>{t(displayName, {keySeparator: '>', nsSeparator: '|'})}</span>
        </div>
      </div>
    )
  }

  const HistoryBlock = ({date, blockItems}) =>
    <div className={classes.historyBlockWrapper}>
      <div className={classes.date}>{toUpper(moment(date).format('Do MMMM YYYY'))}</div>
      <div className={classes.historyItemBlock}>
        {map(blockItems, item => <HistoryItem item={item}/>)}
      </div>
    </div>

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleState}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
          <div onClick={handleState} className={classes.closeTxtBtn} >{t('closeCaps')}</div>
        </div>
        <div className={classes.content}>
          <div className={classes.title}>{t('matchingHistory')}</div>
          {isEmpty(matches) ? <div
            className={classes.statusMessage}>{t('noMatchingHistory')}</div> : map(groupedList, (blockItems, block) =>
            <HistoryBlock date={block}
                          blockItems={blockItems}/>)}
        </div>
      </div>
    </Modal>
  )
}

export default withStyles(styles)(MatchingHistoryModal)


