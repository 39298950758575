import React from "react";
import {useSetTranslation} from "common/useSetTranslation";

export default function StepLink({url, localeUrl}) {
  const {st} = useSetTranslation();

  return (
    <div style={{margin: '16px 0px'}}>
      <a href={url} target='_blank' style={{
        fontSize: 18,
        fontWeight: 'bold',
        width: '100%',
        overflowWrap: 'break-word',
        textDecoration: 'none',
        color: '#DE6E49',
      }}>
        {st(url, localeUrl)}
      </a>
    </div>
  );
};

