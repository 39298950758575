import withStyles from "@material-ui/core/styles/withStyles";
import React, {Fragment} from "react";
import i18n from "i18n";
import DescriptionIcon from '@material-ui/icons/Description';


const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17,
    textTransform:'UPPERCASE'
  },
  document: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

const DocumentAnswer = ({classes, style, quizAnswer = {}, title = i18n.t('yourAnswer')}) => {

  return (
    <Fragment>
      <div>
        <p className={classes.title}>{title}</p>
        <p className={classes.document}>
          <a href={quizAnswer.answer} target="_blank"><DescriptionIcon style={{ scale: "3", display: "flex", alignContent: "center", color: "#52B2E2" }}/></a>
        </p>
      </div>
    </Fragment>
  );
};


export default withStyles(styles)(DocumentAnswer);
