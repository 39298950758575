import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import {noop} from "lodash";
import React, {Fragment} from "react";


const styles = theme => ({
  root: {
    height: '350px',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 0,
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {}
  },
  disabled: {
    backgroundColor: '#d0d0d0'
  },
  focused: {
    border: '1px solid #E0B334 ',
  },
  error: {
    border: '1px solid #E01F0D',
  },
  notchedOutline: {
    border: '0px !important',
  },
  input: {
    color: '#000000'
  },
  shrink: {
    display: 'none'
  }
});

const TextBox = (props) => {
  const {onAnswered = noop, value, classes} = props;

  const handleChange = (e) => {
    onAnswered(e.target.value)
  }

  return (
    <Fragment>
      <TextField
        {...props}
        value={value}
        multiline={true}
        onChange={handleChange}
        rows={15}
        variant="outlined"
        fullWidth
        InputLabelProps={{
          classes: {
            focused: classes.shrink
          }
        }}
        InputProps={{
          classes: {
            root: classes.root, notchedOutline: classes.notchedOutline, focused: classes.focused, error: classes.error
          }
        }}
      />
    </Fragment>
  );
};


export default withStyles(styles)(TextBox);
