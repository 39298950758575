import request from "api/request";

export const getTranslation = (languageCode) => request({
  url: `api/v2/translation/getTranslation/?language_code=${languageCode}`,
  method: 'GET',
  normalizeCase: false,
  normalizeParamCase: false,
});

export const fetchSupportedLanguages = () => request({
  url: `api/v2/translation/getSupportedLanguages/`,
  method: 'GET'
});

export const updatePrimaryLanguage = (languageCode, auth) => request({
  url: `api/v2/users/primary_language/${languageCode}/`,
  method: 'PUT',
  normalizeCase: false,
  auth
})