import {withStyles} from "@material-ui/core";
import LikeIcon from '@material-ui/icons/Favorite';
import {get} from 'lodash';
import React from 'react';
import bulbImage from 'components/PostTypes/bulb.png';
import {useTranslation} from "react-i18next";


const styles = {
  postTitle: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 'bold',
    color: '#000000'
  },
  authorName: {
    marginTop: 16,
    fontSize: 12,
    color: '#000000',
    flex: 2,
  },
};


const MaterialStyles = theme => ({
  postContainer: {
    display: 'flex',
  },
  postImage: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#DD6BAC',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  postDetailContainer: {
    flex: 2,
    backgroundColor: '#F8F6EF',
    minHeight: 200,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    paddingRight: 8,
    paddingTop: 20,
    paddingLeft: 16
  },
  postType: {
    fontSize: 12,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    color: '#E0B334',
    fontWeight: 'bold',
    padding: '2px 6px',
    display: 'inline'
  },
  bulbImage: {
    height: '70%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    width: '60%'
  },
  likeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
  }
});


const QuotePost = ({post, toggleLike, classes}) => {
  const {t} = useTranslation();

  const {feedLikes, isLiked, id} = post;
  const {text = t('postTitle'), author = t('author')} = get(post, "contentObject");


  return (
    <div className={classes.postContainer}>
      <div className={classes.postImage}>
        <div className={classes.bulbImage} style={{
          backgroundImage: `url(${bulbImage})`,
        }}/>
      </div>
      <div className={classes.postDetailContainer}>
        <p className={classes.postType}>{t('quoteOfTheDay')}</p>
        <p style={styles.postTitle}>{text}</p>
        <p style={styles.authorName}>- {author}</p>
        <div className={classes.likeContainer}>
          <LikeIcon style={{color: isLiked ? '#E0B334' : '#E5E5E5', cursor: 'pointer'}}
                    onClick={() => toggleLike(id)}/>
          <span style={{
            color: isLiked ? '#E0B334' : '#E5E5E5',
            fontWeight: 'bold',
            fontSize: 10,
            marginLeft: 4,
          }}> {feedLikes === 0 ? "" : feedLikes}</span>
        </div>
      </div>
    </div>
  );
};

export default withStyles(MaterialStyles)(QuotePost);
