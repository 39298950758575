import {withStyles} from "@material-ui/core"
import {Container} from '@material-ui/core/'
import Grid from "@material-ui/core/Grid/Grid"
import {isEmpty, trim} from 'lodash'
import React, {useEffect} from "react"


import logo from 'common/assets/logo-with-name.png';
import {useTranslation} from "react-i18next";

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
    height: 600,
    flexDirection:'column'
  },
  image: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    display: 'block'
  },

};

const MaterialStyles = theme => ({
  formWrapper: {
    paddingLeft: 70,
    paddingRight: 70,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    marginTop: 40,
    backgroundColor: 'white',
  },
  messageTitle:{
    fontWeight:700,
    fontSize:22,
    color:'#404040'
  },
  messageText:{
    fontWeight:500,
    fontSize:18,
    color:'#7C7C7C'
  }
});


const UnderMaintenance = ({classes}) => {
  const {t} = useTranslation();

  useEffect(() => {
  }, [])


  return (
    <div style={{backgroundColor: '#E5E5E5', height: '100vh', display: 'flex', alignItems: 'center'}}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container style={styles.container} justify="center" >
              <img src={logo} style={{width: 145}}/>
              <div style={{textAlign:'center'}}>
                <p className={classes.messageTitle}>{t('maintenanceTitle')}</p>
                <p className={classes.messageText}>{t('maintenanceText')}</p>
              </div>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(MaterialStyles)(UnderMaintenance);
