import {getPartnerAnswers, getSelfAnswers} from "business/quiz";
import AgreeabilityScaleAnswer from "components/QuizAnswers/AgreeabilityScaleAnswer";
import DateSelectionAnswer from "components/QuizAnswers/DateSelectionAnswer";
import ImageResponseAnswer from "components/QuizAnswers/ImageResponseAnswer";
import MultiChoiceAnswer from "components/QuizAnswers/MultiChoiceAnswer";
import NumberInputAnswer from "components/QuizAnswers/NumberInputAnswer";
import QuizQuestion from "components/QuizAnswers/QuizQuestion";
import SingleChoiceAnswer from "components/QuizAnswers/SingleChoiceAnswer";
import TextBoxAnswer from "components/QuizAnswers/TexBoxAnswer";
import TextInputAnswer from "components/QuizAnswers/TexInputAnswer";
import {find, get, isEmpty, map, some, toNumber} from "lodash";
import React, {useState} from "react";
import SessionStepUserAvatar from "components/SessionStepUserAvatar";
import {getPartnerName} from "../../business/quiz";
import QuizCorrectAnswerView from "components/QuizAnswers/QuizCorrectAnswerView";
import ScaleAnswer from "components/QuizAnswers/ScaleAnswer";
import { useTranslation } from "react-i18next";
import QuizAnswerNote from "./QuizAnswerNote";
import DocumentAnswer from "./DocumentAnswer";

const GetAnswerView = (props) => {
  switch (props.question.questionType) {
    case 'Text':
      return (<TextInputAnswer {...props} />);
    case 'Number':
      return (<NumberInputAnswer {...props} />);
    case 'TextBox':
      return <TextBoxAnswer  {...props}/>;
    case 'SingleChoiceRadio':
      return <SingleChoiceAnswer {...props}/>;
    case 'SingleChoiceDropDown':
      return <SingleChoiceAnswer {...props}/>;
    case 'MultiChoiceDropDown':
      return <MultiChoiceAnswer {...props}/>;
    case 'SingleChoiceButton':
      return <SingleChoiceAnswer {...props}/>;
    case 'Date':
      return <DateSelectionAnswer {...props}/>;
    case 'AgreeabilityThreeOptions':
      return <AgreeabilityScaleAnswer {...props} maximumValue={3}/>;
    case 'AgreeabilityFiveOptions':
      return <AgreeabilityScaleAnswer {...props} maximumValue={5}/>;
    case 'AgreeabilitySevenScale':
      return <AgreeabilityScaleAnswer {...props} maximumValue={7}/>;
    case 'AgreeabilityTenScale':
      return <AgreeabilityScaleAnswer {...props} maximumValue={10}/>;
    case 'Scale':
      return <ScaleAnswer {...props} />
    case 'ImageResponse':
      return <ImageResponseAnswer {...props}/>;
    case 'Document':
      return <DocumentAnswer {...props} />;
    // case 'YoutubeVideo':
    //   return (<Video
    //     videoId={get(props.question, 'answerOptions[0].key')}
    //   />);

    default:
      return (<p>Can not render! {props.question.questionType}</p>);
  }
};


const MentorshipQuizAnswerWrapper = ({quizQuestions, quizAnswers, helpText, partnerDetails, isLearningHub}) => {
  const {t} = useTranslation();

  const selfAnswers = getSelfAnswers(isLearningHub, quizAnswers);
  const partnerAnswers = getPartnerAnswers(quizAnswers);

  let hasSelfAnswers = false;
  for (const property in selfAnswers) {
    if (selfAnswers[property].length) {
      hasSelfAnswers = true;
      break;
    }
  }

  let hasPartnerAnswers = false;
  for (const property in partnerAnswers) {
    if (partnerAnswers[property].length) {
      hasPartnerAnswers = true;
      break;
    }
  }

  const [partnerIdforAnswer, setPartnerIdforAnswer] = useState(0);

  const getAnswerByQuestionKey = (answers, key) => {
    return find(answers, (answer) => answer.questionKey === key);
  };
  const getAnswerByPartnerIdQuestionKey = (partnerAnswers, partnerId, key) => {
    let findPartnerAnswers = get(partnerAnswers, `[${partnerId}]`, {});
    return find(findPartnerAnswers, (answer) => answer.questionKey === key);
  };

  const partnerHasAnswerFor = (partner, questionKey) => some(partner, answer => answer.questionKey === questionKey);

  if (!hasSelfAnswers && !hasPartnerAnswers) {
    return <></>
  }

  return (
    <div>
      {!isEmpty(helpText) && (
        <QuizAnswerNote
          helpText={helpText}
        />
      )}
      {quizQuestions.map((question, key) => {
        return (
          <div className="quiz-answer">
            <QuizQuestion questionPrefix={t('questionPrefixQ') + (key + 1)} question={question.title || question.label}
                          localeQuestion={get(question, 'localeTitle') || get(question, 'localeLabel')}/>

            <div style={{marginBottom: 28}}>
              <QuizCorrectAnswerView question={question}/>
            </div>
            <div style={{backgroundColor: '#EEEADE', margin: '0 -32px', padding: '24px 32px'}}>
              {map(selfAnswers, (answers, key) => {
                  return answers.length ? (
                    <div>
                      <SessionStepUserAvatar userType={'SELF'}
                                             styles={{width: 40, height: 40}}
                                             userId={key}/>
                      <GetAnswerView
                        isLearningHub={isLearningHub}
                        quizAnswer={getAnswerByQuestionKey(answers, question.key)}
                        question={question}
                      />
                    </div>
                  ) : <></>
                }
              )}

              {/*Partner answer and avatar*/}
              <div style={{display: 'flex', marginLeft: -8, marginTop: 24, marginBottom: 16}}>
                {map(partnerAnswers, (partner, partneId) => {
                  if (partnerIdforAnswer === 0 && !isEmpty(partner)) {
                    setPartnerIdforAnswer(toNumber(partneId));
                  }
                  return (
                    partnerHasAnswerFor(partner, question.key) &&
                    <SessionStepUserAvatar userType={'PARTNER'} userId={partneId}
                                           styles={{
                                             marginLeft: 8,
                                             width: 40,
                                             height: 40,
                                             cursor: 'pointer'
                                           }}
                                           isSelected={toNumber(partneId) === partnerIdforAnswer}
                                           onClick={() => setPartnerIdforAnswer(toNumber(partneId))}/>
                  )
                })
                }
              </div>
              {!isEmpty(getAnswerByPartnerIdQuestionKey(partnerAnswers, partnerIdforAnswer, question.key)) &&
              <GetAnswerView
                isLearningHub={isLearningHub}
                quizAnswer={getAnswerByPartnerIdQuestionKey(partnerAnswers, partnerIdforAnswer, question.key)}
                question={question}
                title={partnerIdforAnswer !== 0 && getPartnerName(partnerDetails.partnerDetails, partnerIdforAnswer)}
              />
              }
            </div>
          </div>)
      })}
    </div>
  );
};


export default MentorshipQuizAnswerWrapper;
