import withStyles from "@material-ui/core/styles/withStyles";
import {isMentee, isMentor} from "business/role";
import {filter, get, isEmpty, isUndefined, map} from 'lodash';
import moment from "moment";
import React, {useState} from 'react';
import TrackerAvatar from "./TrackerAvatar";
import {isOneToMany, isOneToOne} from "../business/matching";
import PartnersDetailsPopUp from "components/PartnersDetailsPopUp";

import logo from 'common/assets/logo.png';
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  contactCard: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 5,
    minHeight: 101,
    backgroundColor: '#ffffff',
    padding: 20,
    marginTop: 20,
  },
  programCard: {
    marginTop: 24,
    height: 245,
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  programName: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  programDate: {
    fontSize: 12,
    color: '#878787',
  },
  programStatusCard: {
    flex: '0.8',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 'auto'
  },
  statusCard: {
    flex: 1, display: 'flex', backgroundColor: 'white', position: 'relative',
    borderRadius: 5,
  },
  statusCardSession: {
    flex: 1,
    backgroundColor: 'rgba(251, 194, 174, 0.37)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px'
  },
  logoImage: {
    width: 40,
  },
  programProject: {
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
  },
  contactTitle: {
    fontSize: 12,
    color: '#DE6E49',
  },
  contactName: {
    fontSize: 12,
    fontWeight: 'bold',
    cursor:'pointer'
  },
  contactNumber: {
    fontSize: 12,
  },
  greetingMessage: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black',
  }
});

const MentorshipProgramStatusPanel = ({classes, partnerDetails, role, projectDetail, progress, displayName, mentorshipType}) => {
  const {t} = useTranslation();

  const [detailsWindow, setDetailsWindow] = useState(false);

  const partners = get(partnerDetails, 'partnerDetails');
  const partnerMentees = filter(partners, partner => isMentee(partner.role));
  const partnerMentors = filter(partners, partner => isMentor(partner.role));
  const partnerMentor = !isEmpty(partnerMentors) && partnerMentors[0];
  const partnerMentee = !isEmpty(partnerMentees) && isOneToOne(mentorshipType) && partnerMentees[0];
  const header = isOneToOne(mentorshipType) ? isMentee(role) ? t('yourMentor') : t('yourMentee') : t('yourGroup');

  const partner = isOneToOne(mentorshipType) ? isMentor(role) ? partnerMentee : partnerMentor : partnerMentor;


  let projectName = '', projectStartDate = '';
  try {
    const matches = projectDetail.match(/^(.*) ([0-9]{4}-[0-9]{2}-[0-9]{2} .*)$/);
    projectName = matches[1];
    projectStartDate = moment(matches[2]).format('MMM YYYY');
  } catch (e) {
    //Do nothing.
  }


  const completedQuizprogress = progress.completedQuiz / progress.totalQuiz;
  const completedSessionProgress = get(progress, 'completedSessions', []).length / progress.totalSessions;

  return (
    <div className={classes.wrapper}>
      <div className={classes.greetingMessage}>{`${t('helloComma')} ${displayName}`}</div>

      <div className={classes.programCard}>
        <div className={classes.programProject}>
          <div className={classes.logo}>
            <img src={logo} className={classes.logoImage} alt={''}/>
          </div>
          <div className={classes.programName}>{projectName}</div>
          <div className={classes.programDate}>since {projectStartDate}</div>
        </div>
        <div className={classes.programStatusCard}>
          <div className={classes.statusCard}>
            <div style={{flexDirection: 'row', display: 'flex', flex: 1}}>
              <div style={{backgroundColor: '#C6E4F3', flex: completedQuizprogress || 0}}/>
              <div style={{backgroundColor: '#E4F4FC', flex: 1.0 - (completedQuizprogress || 0)}}/>
            </div>
            <div style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translateX(-50%) translateY(-50%) ',
              color: 'white',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
               <span style={{
                 color: '#52B2E2',
                 fontSize: 18
               }}>{`${progress.completedQuiz || '0'}/${progress.totalQuiz || '0'} `}</span>
              <span
                style={{fontSize: 12, color: 'black'}}>{t('quizzes')}</span>
            </div>
          </div>

          <div className={classes.statusCard}>
            <div style={{flexDirection: 'row', display: 'flex', flex: 1, borderRadius: 5}}>
              <div
                style={{
                  backgroundColor: '#F5CBBD',
                  borderRadius: '0px 0px 0px 5px',
                  flex: completedSessionProgress || 0
                }}/>
              <div style={{
                backgroundColor: 'rgba(251, 194, 174, 0.37)',
                borderRadius: '0px 0px 5px 5px',
                flex: 1.0 - (completedSessionProgress || 0)
              }}/>
            </div>
            <div style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translateX(-50%) translateY(-50%) ',
              color: 'white',
              width: '100%',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center'
            }}>
               <span style={{
                 color: '#DE6E49',
                 fontSize: 18
               }}>{`${get(progress, 'completedSessions', []).length}/${progress.totalSessions || '0'} `}</span>
              <span
                style={{fontSize: 12, color: 'black'}}>{t('sessions')}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.contactCard}>
        <div className={classes.contactTitle}>{header}</div>
        {isOneToOne(mentorshipType) &&
        <div>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 8, marginBottom: 16}}>
            <div style={{flexShrink: 1}}>
              <TrackerAvatar displayName={partner.firstName + " " + partner.lastName}
                             displayPicture={partner.picture}
                             size={40}
                             onClick={() => setDetailsWindow(true)}
                             styles={{width: 40, height: 40, fontSize: 14}}/>
            </div>
            <div style={{marginLeft: 8}}
            >{isUndefined(partner.firstName) ? '' :
              <div className={classes.contactName} onClick={() => setDetailsWindow(true)}>
                <div>{partner.firstName}</div>
                <div>{partner.lastName}</div>
              </div>
            }</div>
          </div>
        </div>
        }

        {isOneToMany(mentorshipType) &&
        <div>
          <div style={{display: 'flex', flexWrap: 'wrap', marginLeft: -4, marginRight: -8}}>
            {isMentee(role) &&
            <TrackerAvatar
              displayName={get(partner, 'firstName') + " " + get(partner, 'lastName')}
              displayPicture={get(partner, 'picture')}
              size={40}
              onClick={() => setDetailsWindow(true)}
              styles={{marginRight: 4, marginLeft: 4, width: 40, height: 40, fontSize: 12, marginTop: 4}}
            />}

            {map(partnerMentees, mentee => <TrackerAvatar
              displayName={mentee.firstName + " " + mentee.lastName}
              displayPicture={mentee.picture}
              size={40}
              onClick={() => setDetailsWindow(true)}
              styles={{marginRight: 4, marginLeft: 4, width: 40, height: 40, fontSize: 12, marginTop: 4}}
            />)}
          </div>
        </div>
        }
      </div>
      <PartnersDetailsPopUp open={detailsWindow} handleClose={() => setDetailsWindow(false)} partners={partners} />
    </div>
  )
};

export default withStyles(MaterialStyles)(MentorshipProgramStatusPanel);
