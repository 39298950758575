import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {get, isArray, map, padStart} from "lodash";
import React, {Fragment} from "react";
import i18n from "i18n";


export const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17,
    textTransform:'UPPERCASE'
  },
  circle: {
    backgroundColor: '#FFFFFF',
    width: 35,
    height: 35,
    borderRadius: '50%',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between'
  },
  circleContainerScale: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginBottom: '10px',
    paddingBottom: '10px'
  },
  labelItemScaleVertical: {
  alignItems: 'center',
  marginLeft: '30px',
  marginTop: '20px',
  justifyContent: 'center',
  textAlign: 'center'
  },
selected: {
    backgroundColor: '#A9BAC2',
    color: '#728D9A'
  },
  selectedOption: {
    backgroundColor: '#E6F9EA',
    color: '#41A156',
    fontStyle: 'bold'
  },

});


export const Circle = withStyles(styles)(({number, value, questionKey, selected, selectedOption, classes}) => {
    return (
      <Fragment>
        <div
          className={classNames(classes.circle, selected && classes.selected, selectedOption && classes.selectedOption)}>{padStart(number, 2, "0")}</div>
      </Fragment>
    );
  })
;

const AgreeabilityScaleAnswer = ({
                                   classes, style, minimumValue = 1,
                                   maximumValue = 10,
                                   question,
                                   quizAnswer = {}, title = i18n.t('yourAnswer')
                                 }) => {

  const options = [];
  for (let i = minimumValue; i <= maximumValue; i++) {
    options.push(i);
  }

  const selectedAnswer = get(quizAnswer, 'answer', "");
  const selectedOption = isArray(selectedAnswer) ? selectedAnswer[0] : selectedAnswer;

  return (
    <Fragment>
      <div>
        <p className={classes.title}>{title}</p>
        <div className={classes.circleContainer}>
          {map(options, option => <Circle key={option} number={option} selectedOption={selectedOption === option}
                                          selected={selectedOption}/>)}
        </div>
      </div>
    </Fragment>
  );
};


export default withStyles(styles)(AgreeabilityScaleAnswer);
