import {groupCompatibilityStatus} from "business/matching";
import {get, map, unset} from 'lodash';

const actions = {
  STORE_ACTIVE_MATCHES: 'STORE_ACTIVE_MATCHES',
  STORE_MATCHES: 'STORE_MATCHES',
  UPDATE_MATCH: 'UPDATE_MATCH',
  MARK_PAIR_COMPLETE: 'MARK_PAIR_COMPLETE',
  UNMARK_PAIR_COMPLETE: 'UNMARK_PAIR_COMPLETE',
  STORE_MATCH_INFO: 'STORE_MATCH_INFO',
  STORE_GROUP_INFO: 'STORE_GROUP_INFO',
  SUBMIT_MATCHES_INFO:'SUBMIT_MATCHES_INFO'
};

const createInitialState = () => (
  {
    screen: 'default',
    activeMatches: [],
    matches: [],
    screensList: []
  });

const removePairComplete = (match) =>   {
  let modifiedMatch = match;
  unset(modifiedMatch, 'groupCompatibility');
  return modifiedMatch
} ;

const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.STORE_ACTIVE_MATCHES: {
      return {
        ...state,
        activeMatches: get(action.payload, 'matchTrackers'),
        screen: get(action.payload, 'screen'),
        userMatchType: get(action.payload, 'userMatchType'),
        screensList: get(action.payload, 'screensList'),
        expiryHours: get(action.payload, 'expiryHours'),
      }
    }
    case actions.STORE_MATCHES: {
      return {
        ...state,
        matches: action.payload
      }
    }
    case actions.UPDATE_MATCH: {
      const selectedMatch = action.payload;
      return {
        ...state,
        activeMatches: map(state.activeMatches, match => match.id === selectedMatch.id ? selectedMatch : match)
      }
    }
    case actions.MARK_PAIR_COMPLETE: {
      return {
        ...state,
        activeMatches: map(state.activeMatches, match => match.status === 'ACTIVE' ? ({
          ...match,
          groupCompatibility: groupCompatibilityStatus.PAIR_COMPLETE
        }) : {...match}),
      }
    }
    case actions.UNMARK_PAIR_COMPLETE: {
      const match = map(state.activeMatches, match => match.groupCompatibility === groupCompatibilityStatus.PAIR_COMPLETE ? removePairComplete(match) : {...match});
      return {
        ...state,
        activeMatches: match
      }
    }
    case actions.STORE_MATCH_INFO: {
      return {
        ...state,
        matchInfo: action.payload
      }
    }
    case actions.SUBMIT_MATCHES_INFO: {
      return {
        ...state,
        submitMatchesResponse: action.payload
      }
    }
    case actions.STORE_GROUP_INFO: {
      return {
        ...state,
        groupInfo: action.payload
      };
    }
    default: {
      return state
    }
  }
}

export {actions, reducer}
