export const actions = {
  SAVE_SUPPORTED_LANGUAGES: 'SAVE_SUPPORTED_LANGUAGES',
  SET_ACTIVE_LANGUAGE_CODE: 'SET_ACTIVE_LANGUAGE_CODE',
  FETCHING_TRANSLATION: 'FETCHING_TRANSLATION',
  FETCHED_TRANSLATION: 'FETCHED_TRANSLATION',
};

const createInitialState = () => (
  {
    supportedLanguages: [],
    defaultLanguageName: '',
    defaultLanguageCode: '',
    activeLanguageCode: '',
    isLoadingTranslations: false,
    isActiveLanguageDefault: false
  }
);

const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.SAVE_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        supportedLanguages: action.payload,
        defaultLanguageName: action.payload.find(language => language.isDefault).language,
        defaultLanguageCode: action.payload.find(language => language.isDefault).languageCode,
      }
    }
    case actions.SET_ACTIVE_LANGUAGE_CODE: {
      return {
        ...state,
        activeLanguageCode: action.payload,
        isActiveLanguageDefault: state.defaultLanguageCode === action.payload
      }
    }
    case actions.FETCHING_TRANSLATION: {
      return {
        ...state,
        isLoadingTranslations: true
      }
    }
    case actions.FETCHED_TRANSLATION: {
      return {
        ...state,
        isLoadingTranslations: false
      }
    }
    default: {
      return state;
    }
  }
}

export default reducer;