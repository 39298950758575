import request from "api/request";
import _ from 'lodash';

export const fetchMentorshipCards = (auth) => request({
  url: 'api/v2/card/?exp_type=Mentorship&image_type=web',
  method: 'GET',
  auth
});

const parseSessions = (sessions) => sessions.results;

export const fetchAllMentorshipSessions = (auth) => request({
  url: 'api/v2/mentorships/sessions/?not_started',
  method: 'GET',
  auth,
  parse: parseSessions
});

const parseOngoingSessions = (sessions) => _.map(sessions, ({session, ...rest}) => (
  {
    ...session,
    ...rest,
  }
));

export const fetchOngoingMentorshipSessions = (auth) => request({
  url: 'api/v2/mentorships/mysessions/',
  method: 'GET',
  auth,
  parse: parseOngoingSessions,
});

export const fetchMentorship = (id, auth) => request({
  url: `api/v2/mentorships/${id}/`,
  method: 'GET',
  auth,
});

export const fetchMentorshipProgress = (id, auth) => request({
  url: `api/v2/mentorships/${id}/progress/`,
  method: 'GET',
  auth,
});
export const getSessionStatus = (id, auth) => {
  return(request({
    url: `api/v2/mentorships/mysessions/${id}/`,
    method: 'GET',
    auth,
  }))
}
