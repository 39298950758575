import Container from "@material-ui/core/Container/Container";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER, SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER} from "common/helpers";
import {map} from 'lodash';
import React from "react";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {},
  title: {
    fontSize: 18,
    color: '#766430'
  },
  contentWrapper: {
    padding: '12px 90px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 20px',
    },
    overflowY: 'auto',
    height: PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    [theme.breakpoints.down('sm')]: {
      height: SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    }
  },
  topicTitle: {
    fontSize: 18,
    color: '#766430'
  },
  topicItems: {
    marginTop: 8,
  },
  question: {
    backgroundColor: 'hsla(44, 59%, 88%, 1)',
  },
  topicQuestion: {
    fontWeight: 'bold',
    fontSize: 18,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
  },
  answer: {
    backgroundColor: 'hsla(44, 59%, 88%, 1)',
  },
  topicAnswer: {fontSize: 14}
});


const FAQ = ({classes, faqTopics}) => {
  const {t} = useTranslation();

  const faqItem = (item) =>
    <div className={classes.topicItems}>
      <ExpansionPanel
        rounded={true}
        elevation={0}
      >
        <ExpansionPanelSummary
          className={classes.question}
          expandIcon={<ArrowDropDownIcon/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.topicQuestion}>{t('questionPrefixQ')}. {item.question}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.answer}>
          <Typography className={classes.topicAnswer}>{item.answer}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>;

  const faqTopic = (topic) =>
    <div>
      <div className={classes.topicTitle}>
        <p className={classes.title}>{topic.topicTitle}</p>
      </div>
      <div className={classes.topicItems}>
        {map(topic.content, item => faqItem(item))}
      </div>
    </div>;

  return (
    <Container maxWidth={"md"} disableGutters={true} className={classes.wrapper}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.contentWrapper}>
            {map(faqTopics, topic => faqTopic(topic))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(FAQ);


