import Backdrop from "@material-ui/core/Backdrop/Backdrop"
import Button from "@material-ui/core/Button/Button"
import Modal from "@material-ui/core/Modal/Modal"
import withStyles from "@material-ui/core/styles/withStyles"
import DeleteIcon from '@material-ui/icons/Delete'
import Question from "components/Question"
import { find, get, map} from 'lodash'
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";


const styles = () => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#E7EDF0',
    width: 570,
    height: 610,
    outline: 0,
    borderRadius: '9px 9px 0px 0px',
    display: 'flex',
    flexDirection: 'column'
  },
  form: {
    padding: '33px 50px 0px 50px',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  buttonContainer: {
    marginTop: 'auto'
  },
  deleteIcon: {
    marginLeft: 6,
    color: '#000000',
    fontSize: 12,
    fontWeight: 'normal',
    padding: '5px 12px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 37,
    cursor: 'pointer'
  }
});

const AddEventForm = ({classes, events, open, handleClose, sessions, mentorshipId, submitEvent, profileId, cancelEvent, mentorshipGroupId}) => {
  const {t} = useTranslation();
  const answerOptions= [
    { key: true, label: 'Yes', displayOrder: 1 },
    { key: false, label: 'No', displayOrder: 2 },
  ]

  const [newEvent, setNewEvent] = useState({})
  const [errors, setErrors] = useState({});
  const isEditEvent = get(open, "eventType") === "editEvent"

  const question = {
    label: t('eventTitle'),
    questionType: 'Text',
  };
  const isPairGroupCallQuestion = {
    label: t('isMentoringCall'),
    questionType: 'SingleChoiceRadio',
    answerOptions: answerOptions
  };
  const question3 = {
    label: t('selectDateTime'),
    questionType: 'DateTime',
  };
  const question4 = {
    label: t('description'),
    questionType: 'TextBox',
  };

  const handleSubmit = () => {
    setErrors({});

    let validationErrors = {};
    if (!newEvent.title) {
      validationErrors.title = t('This field is required');
    }
    if (!newEvent.isPairGroupCall) {
      validationErrors.isPairGroupCall = t('This field is required');
    }
    if (!newEvent.scheduledTime) {
      validationErrors.scheduledTime = t('This field is required');
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const transformedEvent = {
      ...newEvent,
      isPairGroupCall: newEvent.isPairGroupCall ? newEvent.isPairGroupCall.key : false,
      created_by: profileId
    };
    submitEvent(mentorshipId, mentorshipGroupId, transformedEvent);

    handleClose()
  }


  const handleChange = (name) => (value) => {
    const data = {[name]: value}
    setNewEvent({
      ...newEvent,
      ...data
    })
  }

  const getEventFromEvents = eventId => find(events, event => event.id === eventId)

  const handleDelete = (eventId) => {
    cancelEvent(getEventFromEvents(eventId))
    handleClose()
  }


  const convertArrayForDropdown = () => {
    let arr = []
    map(sessions, (session) => arr.push(session.title))
    return arr
  }

  useEffect(() => {
    if (open.eventType === 'editEvent') {
      const eventInit = getEventFromEvents(open.EventId)
      const isPairGroupCallValue = answerOptions.find(option => option.key === eventInit.isPairGroupCall);

      setNewEvent({
        ...eventInit,
        isPairGroupCall: isPairGroupCallValue,
      });
    }
    return function cleanup() {
      setNewEvent({})
    }
  }, [open])

  const question2 = {
    label: t('chooseSession'),
    questionType: 'SimpleDropDown',
    answerOptions: convertArrayForDropdown()
  }

  const deleteButton = eventId => <div className={classes.deleteIcon} onClick={() => handleDelete(eventId)}>
    <DeleteIcon style={{fontSize: '18px', marginRight: 6}}/>
    {t('deleteCaps')}
  </div>

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.state}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div className={classes.form}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{
              fontSize: '24px',
              fontWeight: 'bold',
              display: 'flex'
            }}>{t(open.eventType)}{open.eventType === 'editEvent' && deleteButton(open.EventId)}</div>
            <div style={{fontSize: '12px', cursor: 'pointer'}} onClick={handleClose}>CANCEL</div>
          </div>
          <Question question={question} onAnswered={handleChange("title")} value={newEvent.title} error={errors.title}/>
          <Question question={isPairGroupCallQuestion} onAnswered={handleChange("isPairGroupCall")} value={newEvent.isPairGroupCall} error={errors.isPairGroupCall}/>
          <Question question={question2} onAnswered={handleChange("sessionName")} value={newEvent.sessionName}/>
          <Question question={question3} onAnswered={handleChange("scheduledTime")} value={newEvent.scheduledTime} error={errors.scheduledTime}/>
          <Question question={question4} onAnswered={handleChange("description")} value={newEvent.description}/>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="contained" style={{
            backgroundColor: '#52B2E2',
            boxShadow: '0px 2px 4px rgba(82, 178, 226, 0.25)',
            color: 'white',
            fontSize: 12,
            alignSelf: 'flex-end',
            borderRadius: 0,
            height: 48,
          }} fullWidth onClick={handleSubmit}>{isEditEvent ? t('confirmChanges') : t('createEvent')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(AddEventForm);


