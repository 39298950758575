import {submitSessionFeedback} from "actions/sessionAction";
import {get} from 'lodash';
import {connect} from 'react-redux';
import SessionFeedback from "views/session/SessionFeedback";


const mapStateToProps = ({
                           session: {currentSession}
                         }) => ({
  isLearningHub: get(currentSession, "isLearningHub")
});

export default connect(mapStateToProps, {submitSessionFeedback})(SessionFeedback);
