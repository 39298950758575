import React from 'react';
import ReactMarkdown from 'react-markdown/with-html'


const MTMarkdown = ({style, children}) => {
  return (<div style={style}>
    {children && <ReactMarkdown
      source={children}
      linkTarget={'_blank'}
      escapeHtml={false}>
    </ReactMarkdown>}
  </div>);
};

export default MTMarkdown;

