import React from "react";
import {useTranslation} from "react-i18next";
import Bulb from "common/assets/quizNoteIcon.svg";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';


const styles = () => ({
  box: {
    maxWidth: "65%"
  },
  titleText: {
    fontSize: 16,
    fontWeight: "bold",
  },
  answerText: {
    marginTop: 8,
    fontSize: 14,
    color: "#707070",
  },
  iconBox: {
    display: "flex",
    justifyContent: "right",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 16,
    marginTop: 16
  },
});

const QuizAnswerNote = ({ classes, helpText = "" }) => {
  const {t} = useTranslation();

  return (
    <div className={classNames(classes.wrapper)}>
      <div className={classNames(classes.box)}>
        <p className={classNames(classes.titleText)}>{t('note')}</p>
        <p className={classNames(classes.answerText)}>{helpText}</p>
      </div>
      <div className={classNames(classes.iconBox)}>
        <img src={Bulb} style={{ width: 130, marginTop: -16, paddingLeft: 4 }}/>
      </div>
    </div>
  )

};
export default withStyles(styles)(QuizAnswerNote);
