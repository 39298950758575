import {toggleMentorshipBookmark} from "actions/mentorshipAction";
import {initSession, startSession} from "actions/sessionAction";
import {connect} from 'react-redux';
import MentorshipSessionIntroduction from "views/Mentorship/MentorshipSessionIntroduction";


const mapStateToProps = ({mentorship: {sessions: {ongoingSessions, allSessions}}}) => ({
  ongoingSessions, allSessions
});

export default connect(mapStateToProps, {
  toggleMentorshipBookmark,
  initSession,
  startSession,
})(MentorshipSessionIntroduction);
