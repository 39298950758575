import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Modal from "@material-ui/core/Modal/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import {concat, find, get, map} from 'lodash';
import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import TrackerAvatar from "components/TrackerAvatar";
import PartnerInfo from "components/PartnerInfo";
import {useTranslation} from "react-i18next";

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#FFEFE9',
    width: 870,
    height: 656,
    outline: 0,
    borderRadius: 5,
  },
  title: {
    color: 'rgba(0, 0, 0, 0.49)',
    fontSize: 12,
    fontWeight: 'bold'
  },
  icon: {
    marginLeft: 'auto'
  },

});

const PartnersDetailsPopUp = ({classes, open, handleClose, partners = []}) => {
  const {t} = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div style={{
          display: 'flex',
          padding: '13px 13px 13px 52px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
          alignItems: 'center'
        }}>
          <div className={classes.title}>{t('yourMentorshipGroupCaps')}</div>
          <div className={classes.icon}>
            <CloseIcon onClick={handleClose}/>
          </div>
        </div>
        <div style={{overflowY:'scroll',height:600}}>
          {map(partners, partner => <PartnerInfo partner={partner}/>)}
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(PartnersDetailsPopUp);


