import CssBaseline from '@material-ui/core/CssBaseline';
import {history} from 'common/history';
import LoaderContainer from "containers/LoaderContainer";
import React from "react";


import {Provider} from "react-redux";
import {Router} from "react-router";

import App from "./App";
import Navbar from "./components/navbar/navbar";
import configureStore from "./stores/configureStore";
import ChatwootWidget from "components/ChatwootWidget";
import PushNotificationContainer from "containers/PushNotificationContainer";

const {store} = configureStore();

function Root() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <CssBaseline/>
        <Navbar/>
        <LoaderContainer/>
        {window.Notification && <PushNotificationContainer />}
        <App/>
        <ChatwootWidget/>
      </Router>
    </Provider>
  );
}

export default Root;
