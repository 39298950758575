import withStyles from "@material-ui/core/styles/withStyles"
import classNames from 'classnames'
import {isEmpty, noop} from "lodash"
import React, {Fragment} from "react"
import {useTranslation} from "react-i18next";
import {useSetTranslation} from "common/useSetTranslation";
import i18n from "i18n";


const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17,
    textTransform:'UPPERCASE'
  },
  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 16,
    marginTop: 8,
    fontSize: 14,
    fontWeight: 'bold'
  },
  correct: {
    backgroundColor: '#E6F9EA',
    color: '#41A156'
  },
  incorrect: {
    backgroundColor: '#F4CCCC',
    color: '#A14141'
  }
});

const SingleChoiceAnswer = ({classes, style, question, quizAnswer = {}, title = i18n.t('yourAnswer'), isLearningHub=true}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const answerOptions = question.answerOptions;
  const selectedAnswer = answerOptions.filter((answer) => answer.key === quizAnswer.answer);
  const correctAnswer = answerOptions.filter((answer) => answer.isCorrect);
  const isAnswerValidityAvailable = !isEmpty(correctAnswer) ;
  const isAnsweredCorrectly = () => {
    return isEmpty(correctAnswer) ? true : isEmpty(correctAnswer.filter((item) => item.key === quizAnswer.answer)) ? false : true;
  };

  return (
    <Fragment>
      <div>
        <p className={classes.title}>{title}</p>
        {selectedAnswer.map((item) =>
          <div key={item.key}
               className={classNames(classes.box,isAnswerValidityAvailable && ((isAnsweredCorrectly() ? classes.correct : classes.incorrect)))}>{st(item.label, item.localeLabel)}</div>
        )}

        {!isAnsweredCorrectly() && isLearningHub && correctAnswer.length > 0 && <p className={classes.title}>{t('correctAnswer')}</p>}
        {!isAnsweredCorrectly() && isLearningHub && correctAnswer.map((item) => <div key={item.key}
                                                                    className={classNames(classes.box, classes.correct)}>{st(item.label, item.localeLabel)}</div>
        )}

      </div>
    </Fragment>
  );
};


export default withStyles(styles)(SingleChoiceAnswer);
