import {toggleBookmark} from "actions/sessionAction";
import {connect} from 'react-redux';
import OnGoingSessions from "views/OnGoingSessions";


const mapStateToProps = ({session: {mySessions}}) => ({
  mySessions,
});

export default connect(mapStateToProps, {toggleBookmark})(OnGoingSessions);
