import withStyles from "@material-ui/core/styles/withStyles";
import React, {useCallback, useEffect, useState, useRef} from "react";
import ChatMemberList from "components/Chat/ChatMemberList";
import ChatSearchResult from "./ChatSearchResult";
import ChatMessageWindow from "components/Chat/ChatMessageWindow";
import { debounce, isEmpty, isNumber } from 'lodash';
import {getChatInstance} from "chat/WebSocket";
import BlankChatWindow from "components/Chat/BlankChatWindow";
import {getChannelFor} from "business/channel";
import {useTranslation} from "react-i18next";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {history} from "common/history";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton/IconButton";
import { usePrevious } from "common/hooks";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from "@material-ui/core/InputBase/InputBase";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { searchChannelsByName } from "../../../src/common/helpers"
import { searchMessages } from "../../../src/api/chatApi"
import { silentApiAction } from "../../../src/actions/actionWrappers"

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flex: 1,
  },
  titleBarText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 14,
  },
  unreadMessageCountTitleText: {
    backgroundColor: '#E0B334',
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    padding: '2px 6px',
    borderRadius: '50%',
    marginLeft: 18,
  },
  iconAdjust: {
    transform: 'rotate(180deg)',
    color: '#010101'
  },
  titleBar: {
    padding: '0px 16px',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
  },
  expandedTitleBar: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
    minHeight: '45px !important',
    alignItems: 'center'
  },
  listContainer: {
    padding: 0
  },
  closeBtnStyling: {
    padding: 0
  },
  clearIcon: {
    padding: 0,
    marginRight: 10,
    fontSize:  10,
    color: 'rgba(0, 0, 0, 0.22)',
    fontStyle: 'italic',
  },
  backIcon: {
    padding: 0,
    marginRight: 10,
    color: '#000000',
  },
  textFieldWrapper: {
    width: '100%',
    padding: 0,
    backgroundColor: '#FEFCF5',
    borderRadius: 8,
  },
  searchBlock: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  searchIconWrapper: {
    flexShrink: 0,
    padding: 0,
    marginRight: 0,
  },
  searchIcon: {
    height: 20,
    with: 20,
    color: 'rgba(28, 27, 31, 0.4)',
  },
});

const FullScreenMessenger = ({
  classes,
  getChatChannels,
  chatChannels,
  myId,
  toggleMessageWindow,
  selectedChannelId,
  myEmail,
  addNewMessageToChannel,
  addMessages,
  messages,
  channelUnreadCount,
  fetchingList,
  nextPageNumber,
  channelsWithMemberId,
  getChatBlackoutPeriod,
  chatBlackoutPeriod,
  messageWindowActive,
  updateUnreadCount,
  updateChannelMemberLastViewedTime,
  getChatBotUsers,
  botUsers,
  updateUserChatRating,
  getChatRatingModalStatus,
  chatRatingStatus,
  getChannelHistory,
  chatChannelHistory,
  storeMeetingSummaryRating,
 }) => {

  const [isChatWindowExpanded, setIsChatWindowExpanded] = useState(false);
  const chatInstance = getChatInstance();
  const {t} = useTranslation();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.between(0, 600));
  const previousChannelId = usePrevious(selectedChannelId);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false)
  const inputRef = useRef(null);
  const [searchedText, setSearchedText] = useState("");
  const [searchedChannels, setSearchedChannels] = useState([])
  const [isSearched, setIsSearched] = useState(false)
  const [searchedSelectedChannel, setSearchedSelectedChannel] = useState(null)
  const [searchMessageResult, setSearchedMessageResult] = useState([])
  const [searchResultNextPageNumber, setSearchResultNextPageNumber] = useState(2)
  const [hasMoreSearchMessageResult, setHasMoreSearchMessageResult] = useState(true)
  const [searchedMessageText, setSearchedMessageText] = useState()
  const [selectedMessageId, setSelectedMessageId] = useState(null)
  const [selectedTab, setSelectedTab] = useState('')
  const [isMessageSelected, setIsMessageSelected] = useState(false)

  const handleClick = () => {
    setIsSearchBoxOpen(true)
  }

  const clearMessage = () => {
    setSearchedText("");
    if(searchedChannels.length === 0) {
      setIsSearched(false)
    }
  }

  const handleTextInput = (value) => {
    setSearchedText(value)
  };

  const handleBack = () => {
    setIsSearchBoxOpen(false);
    setSearchedChannels([]);
    setIsSearched(false)
    setSearchedText('')
    setSearchedMessageResult([])
  }

  const searchChannels = (text) => {
    const result = searchChannelsByName(chatChannels, text, myId);
    setSearchedChannels(result);
  }

  const searchChatMessages = async (text, pageNumber, newSearch) => {
    setSearchedMessageText(text)
    try {
      const searchedMessages = await silentApiAction(searchMessages, text, pageNumber)();
      setHasMoreSearchMessageResult(searchedMessages.hasNextPage)
      if (newSearch) {
        setSearchedMessageResult(searchedMessages.messages)
      } else {
        setSearchedMessageResult(prevMessages => [...prevMessages, ...searchedMessages.messages]);
      }
    } catch (error) {
      console.error('Error fetching searched messages:', error);
    }
  }

  const onSearch = async (text) => {
    setSearchedChannels([]);
    searchChannels(text);
    setIsSearched(true);
    setSelectedMessageId(null)
    setSearchResultNextPageNumber(1);
    await searchChatMessages(text, 1, true);
  };

  const loadMoreSearchMessages = async () => {
    const nextPage = searchResultNextPageNumber + 1;
    setSearchResultNextPageNumber(nextPage);
    await searchChatMessages(searchedText, nextPage, false);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      const trimmedText = searchedText.trim();
        if (trimmedText !== '') {
          onSearch(trimmedText);
        }
      event.preventDefault()
    }
  };

  useEffect(() => {
    chatInstance.addCallbacks((messages, nextPage, type) => addMessages(messages, nextPage, type),
    (message) => addNewMessageToChannel(message), (message) => updateUnreadCount(message),
      (channelMemberData) => updateChannelMemberLastViewedTime(channelMemberData));
    chatInstance.addCallback('reload', debounce(() => {console.log('reloading channels'); getChatChannels();}, 1000));
  }, [addNewMessageToChannel, myId, addMessages, messages, chatInstance]);

  useEffect(() => {
    isEmpty(chatChannels) && getChatChannels();
    isEmpty(botUsers) && getChatBotUsers();
  }, [chatChannels]);

  const sendMessage = useCallback((message) => {
    chatInstance.newChatMessage(message);
  }, [chatInstance]);

  const viewMessage = useCallback((channelId) => {
    chatInstance.onMemberMessageSeen(channelId)
  }, [chatInstance])

  const connectWithChannel = useCallback((channelId, nextPageNumber, messageId = null) => {
    toggleMessageWindow(true, channelId, messageId);
    chatInstance.fetchMessagesForChannel(channelId, nextPageNumber, messageId);
  }, [chatInstance, toggleMessageWindow]);

  const disConnectWithChannel = (id) => {
    chatInstance.leaveChannel(id);
    toggleMessageWindow(false, id)
  };

  useEffect(() => {
    if (isNumber(previousChannelId) && previousChannelId !== selectedChannelId) chatInstance.leaveChannel(previousChannelId);
  }, [selectedChannelId]);

  return (
    <div className={classes.wrapper}>
      <div hidden={isMobileView ? messageWindowActive : false} style={{backgroundColor: isMobileView ? '#F0EFEB' : '#FFF', flex: 1, overflowY: 'scroll'}}>
        <div style={{
          backgroundColor: '#fff',
          fontSize: 14,
          display:'flex',
          fontWeight: 'bold',
          padding: isSearchBoxOpen ? '6px 16px' : '12px 16px',
          borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'sticky',
          top: 0,
          zIndex: 100,
        }}>
        {isSearchBoxOpen ?
          <div className={classes.searchBlock}>
            <IconButton classes={{root: classes.backIcon}} onClick={handleBack}>
              <ArrowBackIcon/>
            </IconButton>
            <div className={classes.textFieldWrapper}>
              <InputBase
                style={{ padding: 8, backgroundColor: '#FEFCF5', color: '#000000', borderRadius: 8}}
                inputRef={inputRef}
                placeholder={t('Type and search')}
                fullWidth
                autoFocus
                value={searchedText}
                onChange={(e) => handleTextInput(e.target.value)}
                onKeyDown={handleKeyPress}
                endAdornment={
                  <InputAdornment position="end">
                    {searchedText && (
                      <IconButton edge="end" classes={{root: classes.clearIcon}} onClick={clearMessage} tabIndex="-1">
                        clear
                      </IconButton>
                    )}
                    <SearchIcon
                      style={{ cursor: searchedText.trim() === '' ? 'not-allowed' : 'pointer', color: '#E0B334', height: 20, width:20 }}
                      onClick={() => {
                        const trimmedText = searchedText.trim();
                        if (trimmedText !== '') {
                          onSearch(trimmedText);
                        }
                      }}
                    />
                  </InputAdornment>
                }
              />
            </div>
          </div>
          : (
              <>
                <div>
                  {t("Messages")}
                  {isMobileView && (
                    <IconButton classes={{root: classes.searchIconWrapper}} style={{marginLeft: 10}} edge="end" onClick={handleClick}>
                      <SearchIcon classes={{root: classes.searchIcon}}/>
                    </IconButton>
                  )}
                </div>
                {!isMobileView && (
                  <IconButton classes={{root: classes.searchIconWrapper}} edge="end" onClick={handleClick}>
                    <SearchIcon classes={{root: classes.searchIcon}}/>
                  </IconButton>
                )}
                {isMobileView && (
                  <IconButton
                    classes={{root: classes.closeBtnStyling}}
                    onClick={() => history.goBack()}>
                    <CloseIcon style={{color: '#000'}}/>
                  </IconButton>
                )}
             </>
            )
          }
        </div>
        {isSearchBoxOpen
          ? <ChatSearchResult
            searchedChannels={searchedChannels}
            onItemClick={(channelId, nextPageNumber, messageId) => {
              connectWithChannel(channelId, nextPageNumber, messageId);
              setSearchedSelectedChannel(channelId);
              setIsMessageSelected(true)
            }}
            myId={myId}
            isSearched={isSearched}
            searchedChannelsFound={searchedChannels.length > 0}
            selectedChannelId={searchedSelectedChannel}
            searchMessageResult={searchMessageResult}
            searchedMessagesFound={searchMessageResult.length > 0}
            loadMoreSearchMessages={loadMoreSearchMessages}
            hasMoreSearchMessageResult={hasMoreSearchMessageResult}
            searchedMessageText={searchedMessageText}
            myEmail={myEmail}
            setSelectedMessageId={setSelectedMessageId}
            selectedMessageId={selectedMessageId}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
            chatChannels={chatChannels}
            nextPageNumber={nextPageNumber}
          />
          : <ChatMemberList
            chatChannels={chatChannels}
            channelUnreadCount={channelUnreadCount}
            myId={myId}
            selectedChannelId={selectedChannelId}
            isLoading={fetchingList}
            onItemClick={(id) => connectWithChannel(id, 1)}
            botUsers={botUsers}
          />
        }
      </div>
      <div hidden={isMobileView ? !messageWindowActive : false} style={{flex: 2}}>
        {selectedChannelId === "" ? <BlankChatWindow/> :
          <ChatMessageWindow
            onBackPress={() => disConnectWithChannel(selectedChannelId)}
            showBackBtn={isMobileView}
            myId={myId}
            myEmail={myEmail}
            messages={messages}
            onSendClick={sendMessage}
            hasMore={nextPageNumber !== null}
            loadMoreMessages={() => {
              chatInstance.fetchMessagesForChannel(selectedChannelId, nextPageNumber)
            }}
            onExpandBtnPress={() => setIsChatWindowExpanded(!isChatWindowExpanded)}
            channel={getChannelFor(selectedChannelId, chatChannels)}
            isFullScreen={true}
            onChatMemberClick={(id) => connectWithChannel(id, nextPageNumber)}
            channelsWithMemberId={channelsWithMemberId}
            getChatBlackoutPeriod={getChatBlackoutPeriod}
            chatBlackoutPeriod={chatBlackoutPeriod}
            showCloseBtn={isMobileView}
            closeBtnAction={() => history.goBack()}
            onViewMessage={viewMessage}
            addNewMessageToChannel={addNewMessageToChannel}
            chatChannels={chatChannels}
            selectedChannelId={selectedChannelId}
            selectedMessageId={selectedTab === 'messages' && isSearched ? selectedMessageId : undefined}
            setSelectedMessageId={setSelectedMessageId}
            setIsMessageSelected={setIsMessageSelected}
            isMessageSelected={isMessageSelected}
            botUsers={botUsers}
            updateUserChatRating={updateUserChatRating}
            getChatRatingModalStatus={getChatRatingModalStatus}
            chatRatingStatus={chatRatingStatus}
            getChannelHistory={getChannelHistory}
            chatChannelHistory={chatChannelHistory}
            storeMeetingSummaryRating={storeMeetingSummaryRating}
            isSmallScreen={false}
          />
        }
      </div>
    </div>
  );
};

export default withStyles(styles)(FullScreenMessenger);
