import withStyles from "@material-ui/core/styles/withStyles";
import {isEmpty, noop} from 'lodash';
import React from "react";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import classNames from 'classnames';

const styles = theme => ({
  wrapper: {
    backgroundColor: '#E0B334',
    borderRadius: '50%',
    width: 28,
    height: 28,
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: '#ffffff',
  },
  highlighted: {
    boxShadow:'0px 0px 2px 2px #E0B334',
  }
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1)',
    fontSize: 12,
    padding: 10,
    marginTop:2
  },
}))(Tooltip);

const getInitials = (name) => {
  var initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

const TrackerAvatar = ({classes, styles, displayName = "", displayPicture = "", size = 49, onClick = noop, isSelected=false}) => {
  const name = getInitials(displayName);

  return (
    <LightTooltip title={displayName} placement="bottom-end">
      <div className={classNames(classes.wrapper, isSelected && classes.highlighted)} style={styles} onClick={onClick}>
      {isEmpty(displayPicture) ? name : <img src={displayPicture} style={{width:size,height:size,borderRadius:'50%'}}/>}
    </div>
    </LightTooltip>
  );
};

export default withStyles(styles)(TrackerAvatar);


