import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as Sentry from '@sentry/react'

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: "https://46b8fa6f48d55c48241d98874344cd5d@o4505939224231936.ingest.sentry.io/4505939226132480",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/app\.mentortogether\.org/],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    environment: process.env.REACT_APP_SENTRY_ENV,
  });
}

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_RELEASE_STAGE,
  enabledReleaseStages: ['test', 'production'],
  appVersion: process.env.REACT_APP_VERSION,
  enabledErrorTypes: {
    unhandledRejections: false
  }
});

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);

ReactDOM.render(<ErrorBoundary><Root/></ErrorBoundary>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

