import {find, get, toNumber} from 'lodash';
import React from "react";
import {connect} from 'react-redux';
import TrackerAvatar from "./TrackerAvatar";


const SessionStepUserAvatar = ({userId, userDetails, partnerDetails, userType, onClick,styles,isSelected}) => {
  const usersList = userType === SessionStepUserAvatar.type.SELF ? userDetails : partnerDetails;
  const user = find(usersList, userDetail => userDetail.id === toNumber(userId));
  return (
    <TrackerAvatar
      styles={styles}
      onClick={onClick}
      size={40}
      isSelected={isSelected}
      displayName={get(user, 'firstName') + " " + get(user, 'lastName')}
      displayPicture={get(user, "picture")}
    />);
};

SessionStepUserAvatar.type = {
  SELF: 'SELF',
  PARTNER: 'PARTNER'
};

const mapStateToProps = ({
                           mentorship: {
                             partnerDetails: {userDetails, partnerDetails}
                           }
                         }) => {
  return {
    userDetails,
    partnerDetails
  }
};

export default connect(mapStateToProps)(SessionStepUserAvatar);


