import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import classNames from "classnames";
import {PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import {history} from "common/history";
import SessionQuizStepRenderer from "components/SessionStepRenderer/SessionQuizRenderer";
import {get, join, map, sortBy} from "lodash";
import React from "react";
import SwipeableViews from "react-swipeable-views";
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  sessionQuizWrapper: {
    position: 'absolute',
    top: 'calc(50px)',
    left: 0,
    width: '100%',
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8))'
  },
  navButton: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 57,
    padding: '2px 24px',
    display: 'flex',
  },
  sessionStepQuizWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    height: PAGE_HEIGHT_WITH_NAVBAR,
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 48px)'
    },
  },
  quizSubmitButton: {},
  stepTitle: {
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
  },
  stepTitleWrapper: {
    backgroundColor: 'white',
    padding: '5px 20px',
    fontWeight: 'bold',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  stepGridWrapper: {
    padding: '0px 16px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px',
    },
  }
});


function SessionQuiz({classes, location, quiz, session, currentPage, nextPage, previousPage, quizError, currentStepQuizAnswers, setSessionQuizAnswer, submitQuiz}) {
  const {questions, title} = quiz;
  const {stepId, quizId} = location.state;

  const currentQuizAnswers = get(currentStepQuizAnswers, `[${session.id}][${stepId}].answers`);
  const {t} = useTranslation();

  const orderedQuestions = sortBy(questions, (question) => question.order);
  let numberOfPages = orderedQuestions.length;
  const isLastPage = currentPage === (orderedQuestions ? numberOfPages - 1 : 0);
  const isFirstPage = currentPage === 0;
  const currentQuestion = get(orderedQuestions, `[${currentPage}]`);
  const questionsLeftMessage = `${numberOfPages - 1 - currentPage} ${(numberOfPages - 1 - currentPage) === 1 ? t('questionLeft') : t('questionsLeft')}`;


  const submit = async (questions, session, stepId, quizId, currentQuizAnswers, currentQuestion) => {
    await submitQuiz(questions, session, stepId, quizId, currentQuizAnswers, currentQuestion);
  };

  const getSessionQuizAnswerFor = (question, answers) => {
    return get(answers, question.key);
  }
  const getErrorFor = (question, errors) => join(get(errors, question.key), '\n');

  const handleSwipe = (currentIndex, prevIndex, meta) => {
    if (meta.reason !== 'swipe') return;
    if (prevIndex < currentIndex) {
      nextPage(orderedQuestions[currentPage], currentQuizAnswers);
    } else if (prevIndex > currentIndex) {
      previousPage();
    }
  };


  return (
    <div className={classNames(classes.sessionQuizWrapper, 'animated faster slideInUp')}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container justify="center" className={classes.stepGridWrapper}>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowBackIcon style={{color: isFirstPage ? "#D8DDE5" : "#52B2E2"}} fontSize={'large'}
                                 onClick={() => previousPage()}
                  /></IconButton>
              </div>
            </div>
          </Box>
          <Grid item xs={12} sm={6} md={6} className={classes.stepGridWrapper}>
            <div className={classes.sessionStepQuizWrapper}>
              <div className={classes.stepTitleWrapper}>
                <IconButton  
                  aria-label={'Go back'}
                  onClick={() => history.goBack()}
                >
                  <CloseIcon
                    style={{color: '#52b2e2'}}
                  />
                </IconButton>
                <div className={classes.stepTitle}>{questionsLeftMessage}</div>
              </div>
              <Box display={{xs: 'block', sm: 'none', md: 'none'}}>
                <div style={{ display: 'flex',  backgroundColor: '#E7EDF0', justifyContent: 'space-between', paddingTop: 10, paddingRight: 32, paddingLeft: 32 }}>
                  <button
                    onClick={() => previousPage()}
                    aria-label={isFirstPage ? 'Disabled: No more previous questions' : 'Go to the previous question'}
                    disabled={isFirstPage}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: isFirstPage ? 'default' : 'pointer',
                      color: isFirstPage ? '#D8DDE5' : '#52B2E2'
                    }}
                  >
                    <ArrowBackIcon 
                      fontSize={'medium'}
                    />
                  </button>
                  <button
                    onClick={() => nextPage(orderedQuestions[currentPage], currentQuizAnswers)}
                    aria-label={isLastPage ? 'Disabled: No more next questions' : 'Go to the next question'}
                    disabled={isLastPage}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: isLastPage ? 'default' : 'pointer',
                      color: isLastPage ? '#D8DDE5' : '#52B2E2'
                    }}
                  >
                    <ArrowForwardIcon
                      fontSize={'medium'}
                    />
                  </button>
                </div>
              </Box>
              <SwipeableViews
                axis={'x'}
                index={currentPage}
                enableMouseEvents
                onChangeIndex={handleSwipe}
                style={{height: PAGE_HEIGHT_WITH_NAVBAR, width: '100%', overflowY: 'hidden'}}
              >
                {map(orderedQuestions, question => <SessionQuizStepRenderer
                  questionsLeftMessage={questionsLeftMessage}
                  key={question.key}
                  title={title}
                  question={question}
                  error={getErrorFor(question, quizError)}
                  value={getSessionQuizAnswerFor(question, currentQuizAnswers)}
                  onAnswered={(answer) => setSessionQuizAnswer(stepId, question, answer)}
                />)}
              </SwipeableViews>
              {isLastPage && <div className={classes.quizSubmitButton}>
                <Button variant="contained"
                        style={{backgroundColor: '#E0B334', fontSize: 12, color: 'white', borderRadius: 0}}
                        fullWidth
                        onClick={() => submit(questions, session, stepId, quizId, currentQuizAnswers, currentQuestion)}
                        size="large">
                  {t('submitCaps')}
                </Button>
              </div>
              }
            </div>
          </Grid>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>

                  <ArrowForwardIcon style={{color: isLastPage ? "#D8DDE5" : "#52B2E2"}} fontSize={'large'}
                                    onClick={() => nextPage(orderedQuestions[currentPage], currentQuizAnswers)}
                  />
                </IconButton>
              </div>
            </div>
          </Box>
        </Grid>
      </Container>
    </div>
  );
}


export default withStyles(MaterialStyles)(SessionQuiz)
