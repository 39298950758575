import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {orderSessionSteps} from "business/session";
import classNames from 'classnames';
import {convertUrl, PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import {history} from 'common/history';
import {filter, findIndex, flatten, get, isUndefined, map, some, sortBy, toNumber, toPairs} from "lodash";
import React, { useEffect } from 'react';
import SwipeableViews from "react-swipeable-views";
import SessionStep from "views/session/SessionStep";
import { useTranslation } from "react-i18next";


const MaterialStyles = theme => ({
  sessionWrapper: {
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  navButton: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 57,
    padding: '2px 24px',
    display: 'flex',
  },
  sessionStepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    [theme.breakpoints.down('xs')]: {
      height: '94vh'
    },
  }
});


const MentorshipSessionStepWrapper = ({classes, match, firstStepId, currentStepId, lastStepId, setCurrentStepId, setCurrentSessionStepIndex, currentSessionStepIndex, session, role, initQuiz, stepCompleted, initQuizAnswers, currentMentorshipSessionStatus, quizAnswers, partnerDetails, startSession, initSession}) => {
  const {t} = useTranslation();
  const sessionId = session.id;
  useEffect(() => {
    if (sessionId) startSession(sessionId, false) && initSession(sessionId, false);
  }, [t]);

  const orderedSessionSteps = orderSessionSteps(session);
  const getIndex = (stepId) => findIndex(orderedSessionSteps, (step) => step.id === stepId);

  const stepId = toNumber(match.params.stepId);
  const stepIndex = getIndex(stepId);

  const currentStep = stepIndex;

  // const stepId = isUndefined(lastStepId) ? firstStepId : lastStepId;
  // const stepId = firstStepId;
  // const initialStepIndex = findIndex(orderedSessionSteps, (step) => step.id === stepId);

  // const [currentStep, setCurrentStep] = useState(initialStepIndex);

  // useEffect(() => {
  //   !isEmpty(currentStepId) ? setCurrentSessionStepIndex(getIndex(currentStepId)) : setCurrentSessionStepIndex(currentSessionStepIndex);
  // }, []);
  //
  // useEffect(() => {
  //   return () => {
  //     setCurrentStepId("");
  //   };
  // }, []);



  const orderStatusSteps = (steps) => sortBy(steps, (step) => step.order);

  const allPreviousStepsCompleted = (steps) => {
    const orderedSteps = orderStatusSteps(orderStatusSteps(steps));
    for (let i = 0; i < orderedSteps.length - 1; i++) {
      if (orderedSteps[i].status !== "COMPLETED") {
        return false;
      }
    }
    return true;
  };

  const isFirstRunOfSession = (session) => {
    return !(session.status === "PARTIAL_COMPLETE" || session.status === "COMPLETED");
  };

  const quizHasMandatoryQuestions = (quiz) => flatten(quiz.questions).some(q => q.mandatory === true);

  const isStepCompleted = (stepIndex, quizAnswers, sessionId) => {
    const step = orderedSessionSteps[stepIndex];
    const stepContent = get(step, 'content');
    const mandatoryQuizContents = filter(stepContent, (content) => content.contentType.toLowerCase() === 'quiz' && quizHasMandatoryQuestions(content.quiz));

    const userAnswersForMandatoryQuizContents = mandatoryQuizContents.map((quiz) => get(quizAnswers, `[${sessionId}][${step.id}][${quiz.quiz.id}].answers.userAnswer`));

    if(mandatoryQuizContents.length > 0){
      return get(toPairs(userAnswersForMandatoryQuizContents[0]),'[0][1]',[]).length > 0;
    }else {
      return true;
    }

  };
  const isLastPage = currentStep === session.sessionSteps.length - 1;

  const setSessionStep = (stepId) =>   history.push(`/mentorship/${session.id}/step/${stepId}`);

  const handleStepChange = (currentStepindex) => {
    if(currentStepindex<currentStep){
      history.goBack();
    }else if(currentStepindex>currentStep){
      setSessionStep(orderedSessionSteps[currentStepindex].id);
    }
    stepCompleted(session, orderedSessionSteps[currentStep], quizAnswers);
  };

  const previousStepChange = (stepIndex) => {
    setSessionStep(orderedSessionSteps[stepIndex].id);

  };

  const nextStepChange = (currentStepindex, quizAnswers, sessionId) => {
    if (isStepCompleted(currentStepindex, quizAnswers, sessionId)) {
      let step = currentStepindex + 1;
      const nextStep = step < 0 ? 0 : step > orderedSessionSteps.length - 1 ? orderedSessionSteps.length - 1 : step;
      setSessionStep(orderedSessionSteps[nextStep].id);
      !isLastPage && stepCompleted(session, orderedSessionSteps[currentStepindex], quizAnswers);
    }
  };

  return (
    <div className={classNames(classes.sessionWrapper, 'animated faster fadeIn')}
         style={{
           background: `linear-gradient(0deg, rgba(250, 247, 241, 0.6), rgba(250, 247, 241, 0.6)), url(${convertUrl(session.banner)}) 0% 0% / cover no-repeat`,
         }}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container justify="center" spacing={4}>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}} style={{paddingBottom: 0}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowBackIcon style={{color: "#E0B334"}} fontSize={'large'}
                                 onClick={() => currentStep === 0 ? history.replace(`/mentorship/${session.id}/summary`) : previousStepChange(currentStep - 1)}/>
                </IconButton>
              </div>
            </div>
          </Box>
          <Grid item xs={12} sm={6} md={6} style={{padding: '0px 16px'}}>
            {isUndefined(session.id) ? "Loading Session" :
             <div className={classes.sessionStepWrapper}>
               <SwipeableViews
                 axis={'x'}
                 index={currentStep}
                 enableMouseEvents
                 disabled={!isStepCompleted(currentStep, quizAnswers, session.id)}
                 onChangeIndex={(i) => handleStepChange(i)}
                 style={{height: PAGE_HEIGHT_WITH_NAVBAR, overflowY: 'hidden'}}
               >
                 {map(orderedSessionSteps, step => <SessionStep
                   progressCompleted={(currentStep + 1) * 100 / orderedSessionSteps.length}
                   sessionColor={session.color}
                   key={step.id}
                   initQuiz={initQuiz}
                   role={role}
                   step={step}
                   sessionId={session.id}
                   quizAnswers={quizAnswers}
                   isLearningHub={session.isLearningHub}
                   partnerDetails={partnerDetails}
                   initQuizAnswers={initQuizAnswers}/>)}
               </SwipeableViews>
               {isLastPage && isFirstRunOfSession(currentMentorshipSessionStatus) && allPreviousStepsCompleted(currentMentorshipSessionStatus.steps) && isStepCompleted(currentStep, quizAnswers, session.id) &&
               <div>
                 <Button variant="contained"
                         style={{backgroundColor: '#E0B334', fontSize: 12, color: 'white', borderRadius: 0}}
                         fullWidth
                         onClick={() => stepCompleted(session, orderedSessionSteps[currentStep], isStepCompleted(currentStep, quizAnswers, session.id) && quizAnswers) && history.push(`/mentorship/${session.id}/step/sessionfeedback/`, {sessionId: session.id})}
                         size="large">
                   {t('doneCaps')}
                 </Button>
               </div>
               }
             </div>}
          </Grid>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowForwardIcon
                    style={{color: isLastPage || !isStepCompleted(currentStep, quizAnswers, session.id) ? "#D8DDE5" : "#E0B334"}}
                    fontSize={'large'}
                    onClick={() => nextStepChange(currentStep, quizAnswers, session.id)}/></IconButton>
              </div>
            </div>
          </Box>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(MentorshipSessionStepWrapper);
