import {initSessions, toggleBookmark} from "actions/sessionAction";
import {connect} from 'react-redux';
import CuratedSessions from "views/CuratedSessions";


const mapStateToProps = ({session: {allSessions}}) => ({
  allSessions,
});

export default connect(mapStateToProps, {toggleBookmark, initSessions})(CuratedSessions);
