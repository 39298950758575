import {reducer as sessionStatus} from 'actions/sessionStatus'
import loader from "reducers/LoaderReducer"
import {combineReducers} from "redux"
import {persistReducer} from "redux-persist"
import storage from 'redux-persist/lib/storage'
import auth from "./authReducer"
import {reducer as event} from "./eventsReducer"
import feed from "./feedReducer"
import {internshipReducer as internship} from "./internshipReducer"
import {reducer as matching} from "./matchingReducer"
import {reducer as mentorship} from "./mentorshipReducer"
import navmenu from "./NavmenuReducer"
import profile from "./profileReducer"
import session from "./sessionReducer"
import stage from "./stageReducer"
import supplementary from "./supplementaryReducer"
import survey from "./surveyReducer"
import language from "./languageReducer"
import chat from "./chatReducer";
import video from "./videoReducer";

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  whitelist: ['sessionStatus', 'supplementary']
};

const appReducer = persistReducer(persistConfig, combineReducers({
  auth,
  profile,
  survey,
  loader,
  stage,
  feed,
  event,
  session,
  sessionStatus,
  mentorship,
  navmenu,
  internship,
  supplementary,
  matching,
  language,
  chat,
  video
}));


export default (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    try {
      storage.removeItem('persist:root');
    }catch (e) {
      console.log("Error clearing store");
    }
    state = undefined
  }

  return appReducer(state, action)
}
