import {filter, get, isEmpty, padStart} from "lodash";
import {getPartner} from "common/helpers";

const getGroupMemberTitle = (members) => {
  let numberOfMembers = padStart(!isEmpty(members) ? members.length : '', 2, "0");
  return `${numberOfMembers} members`

};
export const messageWindowTitle = (channel, myId, isAIChannel = false) => {
  return channel.isGroup || isAIChannel
      ? channel.channelName
      : get(getPartner(channel.channelMembers, myId), "userFullName")};
export const messageWindowSubTitle = (channel, myId) => channel.isGroup ? getGroupMemberTitle(channel.channelMembers) : get(getPartner(channel.channelMembers, myId), "role");

export const getChannelFor = (id, channels) => {
  return get(filter(channels, (channel) => channel.channelId === id), '[0]', []);
};
