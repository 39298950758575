import PublishIcon from '@material-ui/icons/Publish';
import "components/styles/AgreeabilityScale.scss";
import {concat, isEmpty, map, without} from 'lodash';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const styles = {
  box: {
    backgroundColor: 'white',
    borderRadius: 5,
    color: 'gray',
    width: '100%',
    fontSize: 14,
    padding: '12px 20px 12px 20px',
    alignContent: 'center',
    justifyContent:'space-between',
    display:'flex',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  imageWrapper: {
    position: 'relative',
  }
};

const ImageFileUpload = ({onAnswered, value = []}) => {
  const {t} = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");

  const handleUpload = (e) => {
    setErrorMessage("");
    const uploadedFile = e.target.files[0];
    if (uploadedFile.size > 2000000) {
      setErrorMessage(t('imageMaxSize2MB'))
    } else {
      onAnswered(URL.createObjectURL(e.target.files[0]),e.target.files[0]);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleUpload} id="upload" hidden accept="image/*"/>
      <label htmlFor="upload" style={styles.box}>{t('upload')} <PublishIcon style={{float: 'right', color: "#52B2E2"}}/></label>
      {!isEmpty(errorMessage) &&
      <div style={{
        marginLeft: 16,
        fontSize: 12,
        color: 'red',
        paddingTop: 10
      }}>
        {errorMessage === 'Not an image.' ? t('invalidImageFile') : errorMessage}
      </div>}
    </div>
  );
};


export default (ImageFileUpload);
