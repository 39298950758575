import Button from "@material-ui/core/Button/Button";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import CloseIcon from '@material-ui/icons/Close';
import {selfQuizAnswered} from "business/quiz";
import {isMentor} from "business/role";

import classNames from 'classnames';
import {history} from "common/history";
import ProgressBar from "components/ProgressBar";
import QuizAnswerWrapper from "components/QuizAnswers/QuizAnswerWrapper";
import MentorTips from "components/SessionStepRenderer/MentorTips";
import StepImage from "components/SessionStepRenderer/StepImage";
import StepLink from "components/SessionStepRenderer/StepLink";
import StepText from "components/SessionStepRenderer/StepText";
import Video from "components/Video";
import {flatten, fromPairs, get,  noop} from "lodash";
import React, {useEffect} from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MentorshipQuizAnswerWrapper from "components/QuizAnswers/MentorshipQuizAnswerWrapper";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const MaterialStyles = theme => ({
  sessionStep: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 100%',
    height: '95vh'
  },
  stepTitle: {
    color: '#BBBBBB',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    backgroundColor: 'white',
    padding: '17px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '4px 0px'
    },
    fontWeight: 'bold'
  },
  stepContent: {
    flex: 1,
    backgroundColor: '#FAF7F1',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: '16px 32px 54px 32px',
    overflow: 'auto'
  },
  closeIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
});

const quizHasMandatoryQuestions = (quiz) => flatten(quiz.questions).some(q => q.mandatory === true);


const QuizQuestionAndAnswer = ({quiz, stepId, sessionId, quizAnswers, initQuiz, initQuizAnswers = noop(), role, partnerDetails, isLearningHub, allowRetake = true, helpText}) => {
  useEffect(() => {
    initQuizAnswers(sessionId, stepId, quiz.id, isLearningHub);
  }, []);
  const {t} = useTranslation();

  const isQuizComplete = selfQuizAnswered(role, quizAnswers, isLearningHub);

  const quizTakenMessage = t('retakeQuizSameButton');
  const quizRetakeNotAllowedMessage = t('retakeQuizNotAllowed');
  const isMandatory = quizHasMandatoryQuestions(quiz);

  let buttonTitle = ''
  if (isQuizComplete) {
    buttonTitle = isMandatory ? t('quizTakenMandatory') : t('quizTakenOptional')
  } else {
    buttonTitle = isMandatory ? t('takeQuizMandatory') : t('takeQuizOptional')
  }

  return (
    <div style={{marginHorizontal: 24}}>
      {isLearningHub ? isQuizComplete &&
        <QuizAnswerWrapper quizQuestions={quiz.questions} quizAnswers={quizAnswers}
          helpText={helpText}
          role={role}
          isLearningHub={isLearningHub}
          partnerDetails={partnerDetails}
        />
      : (!isMandatory || isQuizComplete) &&
        <MentorshipQuizAnswerWrapper quizQuestions={quiz.questions} quizAnswers={quizAnswers}
          helpText={helpText}
          role={role}
          isLearningHub={isLearningHub}
          partnerDetails={partnerDetails}
        />
      }
      <Button variant={isMandatory ? "contained" : "text"}
        startIcon={ isQuizComplete ? <CheckCircleIcon />:""}
        disabled={isQuizComplete && !allowRetake}
        style={{
          backgroundColor: isMandatory ? '#EB8E6F' : '#52B2E21A',
          color: isMandatory ? 'white' : '#52B2E2',
          marginTop: 30,
          fontSize: 10,
          height: 40
        }}
        fullWidth
        onClick={() => initQuiz(quiz.id, stepId, isLearningHub)}>{buttonTitle}</Button>
      <div style={{fontSize:'10px',marginTop:'8px',display:'flex',justifyContent:'center'}}>{isQuizComplete ? allowRetake ? quizTakenMessage : quizRetakeNotAllowedMessage : ""}</div>
    </div>
  );
};

const SessionStep = ({classes, step, sessionId, role, initQuiz, initQuizAnswers, quizAnswers, progressCompleted, sessionColor, isLearningHub, partnerDetails, isInView}) => {
  const {st} = useSetTranslation();

  const getAnswersForQuiz = (quizAnswers, sessionId, stepId, quizId) => {
    return get(quizAnswers, `[${sessionId}][${stepId}][${quizId}].answers`, []);
  };

  const stepItemRender = (
    item, stepId, isSingleStepContent) => {
    const stepItems = fromPairs([
      ['TEXT', () => (<StepText text={item.text} localeText={item.localeText}/>)],
      ['INSTRUCTION', () => (<StepText text={item.text} localeText={item.localeText}/>)],
      ['MENTOR_TIPS', () => isMentor(role) ? <MentorTips text={item.text}  localeText={item.localeText}/> : null],
      ['ARTICLE', () => (<StepText text={item.text} localeText={item.localeText}/>)],
      ['IMAGE', () => <StepImage img={item.image} localeImg={get(item,'localeImage')}/>],
      ['YOUTUBE', () => (<Video videoId={st(item.url, item.localeUrl).split('v=')[1]} autoPlay={isInView && isSingleStepContent}/>)],
      ['HYPERLINK', () => <StepLink url={item.url} localeUrl={item.localeUrl}/>],
      ['QUIZ', () => <QuizQuestionAndAnswer quiz={item.quiz}
                                            stepId={stepId}
                                            sessionId={sessionId}
                                            helpText={st(item.quiz.helpText, item.quiz.localeHelpText)}
                                            initQuiz={initQuiz}
                                            role={role}
                                            isLearningHub={isLearningHub}
                                            quizAnswers={getAnswersForQuiz(quizAnswers, sessionId, stepId, item.quiz.id)}
                                            initQuizAnswers={initQuizAnswers}
                                            partnerDetails={partnerDetails}
                                            allowRetake={item.allowRetake}
      />],
    ]);

    return get(stepItems, item.contentType, noop);
  };

  return (
    <div className={classNames(classes.sessionStep)}>
      <div className={classes.stepTitle}>
        <IconButton 
          onClick={() => isLearningHub ? history.goBack():history.replace(`/mentorship/${sessionId}/summary`)} 
          className={classes.closeIcon}
          aria-label={'Go back'}
        >
          <CloseIcon
            style={{color: '#52b2e2'}}/>
        </IconButton>
        {st(step.title, step.localeTitle)}
      </div>
      <ProgressBar completed={progressCompleted} completionColor={sessionColor} progressBarColor={'#C4C4C4'}/>
      <div className={classes.stepContent}>
        {step.content.map((item) => (
          <div key={item.id}>
            {stepItemRender(item, step.id, step.content.length === 1)()}
          </div>
        ))}
      </div>
    </div>
  )
};

export default withStyles(MaterialStyles)(SessionStep);
