import Grid from "@material-ui/core/Grid/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import {getScreenIndex} from "business/matching"
import {isMentor} from "business/role"
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  navigationWrapper: {
    height: '202px',
    [theme.breakpoints.down('xs')]: {
      height:'auto',
      marginTop:16,
    },
  },
  menuItem: {
    height: '32px',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    paddingLeft: 16,
    alignItems: 'center',
    fontSize: '10px',
    justifyContent: 'space-between',
    textDecoration: 'none',
    color: 'black'

  },
  menuIcon: {
    float: 'right',
  },
  greetingMessage: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'black'
  },
  navItem: {
    fontSize: 12,
    marginBottom: 28,
    fontWeight: 'bold',
    color: '#727272'
  },
  active: {
    color: '#000000',
  },
  navNumber: {
    borderRadius: 65,
    backgroundColor: '#727272',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: 12,
    padding: '3px 8px',
    marginRight: 12,
  },
  navNumberActive: {
    backgroundColor: '#EB8E6F',
  }

})

const MatchingNavigation = ({classes, displayName = "", screen,role}) => {
  const {t} = useTranslation();

  const activeClasses = classNames(classes.navItem, classes.active)
  const inActiveClass = classNames(classes.navItem)

  const activeNumberClass = classNames(classes.navNumber, classes.navNumberActive)
  const inactiveNumberClass = classNames(classes.navNumber)
  const screenindex = getScreenIndex(screen)


  return (
    <div className={classes.navigationWrapper}>
      <Grid container style={{height: '100%'}}>
        <div className={classes.greetingMessage}>{`${displayName}, ${t('welcomeToMatching')}`}</div>
        <div>
          <ul style={{
            listStyleType: 'none',
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            padding: 0,
          }}>
            <li className={screenindex === 1 ? activeClasses : inActiveClass}><span
              className={screenindex === 1 ? activeNumberClass : inactiveNumberClass}>{t('1')}</span>{t('findingMatches')}
            </li>
            <li className={screenindex === 2 ? activeClasses : inActiveClass}><span
              className={screenindex === 2 ? activeNumberClass : inactiveNumberClass}>2</span>{t('potentialMatches')}
            </li>
            {!isMentor(role) &&
            <li className={screenindex === 3 ? activeClasses : inActiveClass}><span
              className={screenindex === 3 ? activeNumberClass : inactiveNumberClass}>3</span>{t('awaitingResponse')}
            </li>}
          </ul>
        </div>
      </Grid>
    </div>
  )
}

export default withStyles(MaterialStyles)(MatchingNavigation)
