import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import SwipeCards from "components/SwipeCards";
import UnlockMentorshipStatusHomePageCard from "components/UnlockStatusCard";
import React, {useEffect} from 'react';
import '../common/styles/animate.css';
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
  learningHubWrapper: {
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#E5E5E5',
  }
});

const InternshipLearning = ({classes, internshipCards, getInternshipCards, setCurrentPath}) => {

  const {t} = useTranslation();

  useEffect(() => {
    setCurrentPath('internship');
    getInternshipCards();
  }, []);


  return (
    <div className={classes.learningHubWrapper}>
      <Container>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={4}>
            <UnlockMentorshipStatusHomePageCard
              message={t('unlockMentorshipCardMessage')}
              status={'UNLOCK'}
              count={'01/05'}
              type={t('unlockMentorshipCardTypeInternship')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <SwipeCards cards={internshipCards}/>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(InternshipLearning);
