import Container from "@material-ui/core/Container/Container";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import {sessionStatusMessage} from "business/session"
import classNames from 'classnames';
import {OngoingIcon} from "common/customIcon";
import {convertUrl, opaque, PAGE_HEIGHT_WITH_NAVBAR, transparent} from "common/helpers";

import {history} from 'common/history';
import MTMarkdown from "components/MTMarkdown";
import {find, get, isUndefined, join, toNumber, union} from "lodash";
import CancelIcon from '@material-ui/icons/Cancel';

import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";

const MaterialStyles = theme => ({
  sessionIntroductionWrapper: {
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    overflowY: 'hidden',
    alignItems: 'center',

  },
  sessionIntroDetailWrapper: {
    backgroundColor: '#DD6BAC',
    minHeight: 258,
    borderRadius: 9,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    padding: '26px 16px 8px 16px'
  },
  sessionTitleContainer: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  mobileSessionTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  sessionTitle: {
    color: 'white',
    fontSize: '48px',
    flexGrow: 1,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    lineHeight: '56px'
  },
  mobileSessionTitle: {
    color: 'white',
    fontSize: '30px',
    fontWeight: 'bold'
  },
  sessionDescription: {
    color: 'black',
    flex: 2,
  },
  sessionActionsWrapper: {
    flex: 0.5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: '14px'
  },
  startButton: {
    backgroundColor: 'white',
    borderRadius: 26,
    padding: '4px 12px 4px 16px'
  },
  startButtonLink: {
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-end'
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: PAGE_HEIGHT_WITH_NAVBAR
  },
  mobileSessionImageWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    minHeight: 250
  },
  mobileSessionContentWrapper: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'blue',
    padding: '2px 16px'
  },
  mobileBackArrow: {
    flex: 1,
  },
  mobileBookMarkWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 8
  }


});

const MentorshipSessionIntroduction = ({classes, match, allSessions, ongoingSessions, toggleMentorshipBookmark, initSession, startSession, sessionStatus}) => {
  const sessionId = match.params.sessionId;

  const combinedSessions = union(ongoingSessions, allSessions);
  const thisSession = find(combinedSessions, (session) => session.id === toNumber(sessionId));

  const hasSessionStarted = ()=> !isUndefined(get(thisSession,"status")) ? get(thisSession,"status")==="ONGOING":false;

  const isMobileView = useMediaQuery('(max-width:600px)');
  const cardGradient = `linear-gradient(180deg,${transparent(thisSession.color)}  100px,${opaque(thisSession.color)}  300px),url(${convertUrl(thisSession.banner)})`;
  const {t} = useTranslation();
  const {st} = useSetTranslation();




  const WebView = <div className={classNames(classes.sessionIntroductionWrapper)} style={{
    background: `url(${convertUrl(thisSession.banner)})`, backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  }}>
    <Container maxWidth={"md"} disableGutters={true}
               style={{backgroundColor: thisSession.color,position:'relative'}}
               className={classNames(classes.sessionIntroDetailWrapper, 'animated faster zoomIn')}>
      <div style={{position:'absolute',top:'-16px',right:'-16px'}}>
        <IconButton>
          <CancelIcon style={{color: "#e0b334"}} fontSize={'large'} onClick={() => history.replace('/mentorship')}/>
        </IconButton>
      </div>
      <div className={classes.sessionTitleContainer}>
        <div className={classes.sessionTitle}>{st(thisSession.title, thisSession.localeTitle)}</div>
        <div style={{display: 'flex', marginBottom: 16}}>
          <div style={{color: 'rgba(255, 255, 255, 0.6)', flex: 1}}>{join(thisSession.tags, ', ')}</div>
          <div style={{flex: 2,paddingLeft:16}}>{sessionStatusMessage(thisSession, thisSession)}</div>
        </div>
      </div>
      <div className={classes.sessionDescription}>
        <MTMarkdown style={{
          fontSize: '14px',
          color: 'black',
        }}>{st(thisSession.overview, thisSession.localeOverview)}</MTMarkdown>
      </div>
      <div className={classes.sessionActionsWrapper}>
        <div style={{flex: 2}}>
          {thisSession.isBookmarked ? <BookmarkIcon style={{color: 'white', fontSize: '2rem'}}
                                                    onClick={() => toggleMentorshipBookmark(thisSession.id)}/> :
           <BookmarkBorderIcon style={{color: 'rgba(255, 255, 255, 0.8)', fontSize: '2rem'}}
                               onClick={() => toggleMentorshipBookmark(thisSession.id)}/>}
        </div>
        <div style={{flex: 1, display: 'flex', alignItems: 'flex-end'}}>
          <div className={classes.startButton}>
            <Link to={`/mentorship/${sessionId}/summary`}
                  onClick={() => startSession(sessionId, false) && initSession(sessionId, false)}
                  className={classes.startButtonLink}>
              <span style={{fontSize: 18}}>{hasSessionStarted() ? t('continue') : t('start')}</span>
              <span>
              <OngoingIcon
                viewBox="0 0 12 9"
                style={{color: '#E0B334', marginLeft: '8px'}}/>
                </span>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  </div>;

  const MobileView = <div className={classes.mobileWrapper}>
    <div className={classes.mobileSessionImageWrapper}
         style={{
           background: cardGradient,
           backgroundSize: 'cover'
         }}>

      <div className={classes.mobileBackArrow}>
        <IconButton>
          <ArrowBackIcon style={{color: "#52B2E2"}} fontSize={'large'} onClick={() => history.replace('/mentorship')}/>
        </IconButton>
      </div>
      <div className={classes.mobileBookMarkWrapper}>
        {thisSession.isBookmarked ? <BookmarkIcon style={{color: 'white', fontSize: '2rem'}}
                                                  onClick={() => toggleMentorshipBookmark(thisSession.id)}/> :
         <BookmarkBorderIcon style={{color: 'rgba(255, 255, 255, 0.8)', fontSize: '2rem'}}
                             onClick={() => toggleMentorshipBookmark(thisSession.id)}/>}
      </div>
    </div>
    <div className={classes.mobileSessionContentWrapper} style={{backgroundColor: thisSession.color}}>
      <div style={{display: 'flex', alignItems: 'flex-end', flexDirection: 'column', marginTop: 8, marginRight: 8}}>
        <div className={classes.startButton}>
          <Link to={`/mentorship/${sessionId}/summary`}
                onClick={() => startSession(sessionId, false) && initSession(sessionId, false)}
                className={classes.startButtonLink}>
            <span style={{fontSize: 18}}>{hasSessionStarted()?'Continue':'Start'}</span>
            <span>
              <OngoingIcon
                viewBox="0 0 12 9"
                style={{color: '#E0B334', marginLeft: '8px'}}/>
                </span>
          </Link>
        </div>
      </div>
      <div className={classes.mobileSessionTitleContainer}>
        <div className={classes.mobileSessionTitle}>{st(thisSession.title, thisSession.localeTitle)}</div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div style={{
            color: 'rgba(255, 255, 255, 0.6)',
            marginTop: 16,
            fontSize: 14
          }}>{join(thisSession.tags, ', ')}</div>
          <div style={{marginTop: 16, fontSize: 14}}>{sessionStatusMessage(thisSession, thisSession)}</div>
        </div>
      </div>
      <div className={classes.sessionDescription}>
        <MTMarkdown style={{
          fontSize: '14px',
          color: 'black',
        }}>{st(thisSession.overview, thisSession.localeOverview)}</MTMarkdown>
      </div>
    </div>
  </div>

  return (
    <div>

      {isMobileView ? MobileView : WebView}

    </div>
  )
};

export default withStyles(MaterialStyles)(MentorshipSessionIntroduction);
