import {setProfilePassword, validateOtp, verifyEmail} from "actions/authAction";
import {connect} from 'react-redux';
import EmailVerification from "views/signup/EmailVerification";

const mapStateToProps = ({auth: {otpVerificationStatus, authStep, emailOtpError}}) => {
  return ({
    otpVerificationStatus,
    authStep,
    emailOtpError
  })
}

export default connect(mapStateToProps, {verifyEmail, validateOtp, setProfilePassword})(EmailVerification);
