import {validate} from "common/validation";
import {assign, filter, flatten, get, reduce, values} from "lodash";

export const questionsForPage = (questions, pageNumber) =>
  filter(questions, (question) => question.pageNumber === pageNumber);

export const answerFor = (question, answers) => get(answers, question.key);

const hasError = (validationResults) => flatten(values(validationResults)).length > 0;

const validateQuestions = (questions, answers) => reduce(questions,
  (results, question) => {
    const result = {};
    result[question.key] = validate(question, answerFor(question, answers));
    return assign({}, results, result);
  }, {});

const getValidationResults = (validationResults) => (
  {
    validationResults,
    hasError: hasError(validationResults),
  }
);

export const validatePage = (questions, answers) =>
  getValidationResults(validateQuestions(questions, answers));

