import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Close from '@material-ui/icons/Close';

const styles = theme => ({
    modal: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)',
        zIndex: 999,
        padding: 20,
        paddingTop: 45,
    },
    image: {
        margin: 'auto',
        maxWidth: '100%',
        maxHeight: '90vh'
    },
    closeButton: {
        position: 'absolute',
        top: 10,
        right: 20,
        cursor: 'pointer',
        color: 'white',
        fontSize: '24px',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    }
});

const ImagePreviewModal = ({ classes, visible, imageUrl, onClose }) => {  
    const displayModal = visible ? { display: 'block' } : { display: 'none' };
    return (
      <div 
        className={classes.modal}
        style={displayModal}
      >
        <Close 
            className={classes.closeButton}
            onClick={onClose}
        />
        <div className={classes.imageContainer}>
            <img src={imageUrl} className={classes.image} alt="Preview"  onClick={(e) => e.stopPropagation()} />
        </div>
      </div>
    );
};

export default withStyles(styles)(ImagePreviewModal);
