import { Container, withStyles } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid/Grid";
import { isEmailValid } from "common/helpers";
import { isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import OTP from "../../components/OTP";
import Question from '../../components/Question/index';
import background from './signInBackgroundImage.jpg';
import logo from '../../common/assets/logo-with-name.png';
import { useTranslation } from "react-i18next";

const passwordSheriff = require('password-sheriff');
const PasswordPolicy = passwordSheriff.PasswordPolicy;
const charsets = passwordSheriff.charsets;
const lengthPolicy = new PasswordPolicy({ length: { minLength: 8 } });
const containsPolicy = new PasswordPolicy({
  contains: {
    expressions: [charsets.upperCase, charsets.numbers, charsets.lowerCase, charsets.specialCharacters]
  }
});

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex'
  },
  image: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  button: {
    marginTop: 31,
    backgroundColor: '#000000',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontSize: 12
  },
  buttonYellow: {
    marginTop: 31,
    backgroundColor: '#E0B334',
    boxShadow: '0px 2px 4px rgba(224, 179, 52, 0.25)',
    color: 'white',
    fontSize: 12
  },
  error: {
    color: 'red',
    textAlign: 'center'
  },
  passwordPolicy: {
    fontSize: 11,
    color: 'rgb(114, 141, 154)',
    listStylePosition: 'inside'
  }
};

const MaterialStyles = theme => ({
  formWrapper: {
    paddingLeft: 70,
    paddingRight: 70,
    paddingBottom: 82,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    marginTop: 40,
    position: 'relative',
    backgroundColor: 'white',
  }
});

const ResetPassword = ({
  validateEmailAddress, resetPasswordScreen = 0, emailOtpError, otpMessage, resetPasswordOtpVerify, otpVerificationStatus, setNewPassword, classes
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const prefilledEmail = queryParams.get('email');
    if (prefilledEmail) {
      setEmail(prefilledEmail);
    }
  }, [location]);

  const emailQuestion = {
    "title": t('resetPasswordEmailTitle'),
    "label": t('emailLabel'),
    "questionType": "Text",
    "helpText": t('resetPasswordEmailHelpText')
  };

  const passwordQuestion = {
    "title": t('resetPasswordPasswordTitle'),
    "label": t('resetPasswordPasswordLabel'),
    "questionType": "Password",
  };
  const confirmPasswordQuestion = {
    "label": t('resetPasswordConfirmPasswordLabel'),
    "questionType": "Password",
  };

  const isPasswordValid = () => {
    try {
      lengthPolicy.assert(password);
      containsPolicy.assert(password);
    } catch (policyException) {
      return false;
    }
    return !isEmpty(password) && !isEmpty(confirmPassword) && (password === confirmPassword);
  };

  const handleEmailSubmit = () => {
    if (isEmailValid(email.toLowerCase())) {
      setEmailError(false);
      validateEmailAddress(email);
    } else {
      setEmailError(true);
    }
  };

  return (
    <div style={{ backgroundColor: '#E5E5E5', height: '100vh', display: 'flex', alignItems: 'center' }}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container style={styles.container}>
          <Grid item xs={12} sm={5} md={6} className={"signInimageBox"}>
            <div className={"signinImage"} style={{ backgroundImage: `url(${background})` }}>
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={6} className={classes.formWrapper}>
            <Grid container alignItems="center" justify="center">
              <img src={logo} style={{ width: 145 }} alt={''} />
            </Grid>
            <Grid item sm={12} md={12} style={{ marginTop: 70 }}>
              <SwipeableViews
                axis={'x'}
                index={resetPasswordScreen}
              >
                <div>
                  <Question question={emailQuestion} onAnswered={(val) => setEmail(val.toLowerCase())} value={email}
                    error={emailError} />
                  <Button variant="contained" style={styles.button} fullWidth onClick={handleEmailSubmit}>
                    {t('sendOTP')}
                  </Button>
                  {emailOtpError && <h5 style={styles.error}>{emailOtpError}</h5>}
                </div>

                <OTP message={otpMessage} onAnswered={(otp) => resetPasswordOtpVerify(email, otp)} resendOtp={handleEmailSubmit}
                  error={!otpVerificationStatus} />

                <div>
                  <div style={styles.passwordPolicy}>
                    {t('passwordPolicyTitle')}
                    <ul>
                      <li>{t('passwordPolicyRequirementsLength')}</li>
                      <li>{t('passwordPolicyRequirementsLowerCase')}</li>
                      <li>{t('passwordPolicyRequirementsUpperCase')}</li>
                      <li>{t('passwordPolicyRequirementsNumeral')}</li>
                      <li>{t('passwordPolicyRequirementsSpecial')}</li>
                    </ul>
                  </div>
                  <Question question={passwordQuestion} onAnswered={(e) => setPassword(e.target.value)} />
                  <Question question={confirmPasswordQuestion} onAnswered={(e) => setConfirmPassword(e.target.value)} />
                  <Button variant="contained" style={styles.buttonYellow} fullWidth disabled={!isPasswordValid()}
                    onClick={() => setNewPassword(password)}>
                    {t('continueCaps')}
                  </Button>
                </div>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(MaterialStyles)(ResetPassword);
