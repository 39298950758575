import Button from "@material-ui/core/Button/Button"
import {isMatchActive, statuses} from "business/matching"
import _, {find} from 'lodash'
import React, {useState} from 'react'
import {useTranslation} from "react-i18next";

const MenteeMatchSubmitButton = ({matches, submit}) => {
  const {t} = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const allMatchesMade = !_.find(matches, match => match.status === statuses.ACTIVE && !_.get(match,'interestStatus',false));
  const atleastOneMatchMade = _.find(matches, match => _.get(match,'interestStatus',false));
  const anyActiveMatch = find(matches, isMatchActive);


  const buttonTitle = allMatchesMade ? t('confirmChoices') : t('takeActionOnAllMatches');
  const handleClick = () => {
    setIsSubmitting(true);
    submit();
  };
  return (atleastOneMatchMade && anyActiveMatch) ? (<Button variant="contained"
                                                            disabled={!allMatchesMade || isSubmitting}
                                                            style={{
                                          backgroundColor: allMatchesMade ? '#EB8E6F' : '#A9A9A9',
                                          height: 48,
                                          fontSize: 12,
                                          color: '#ffffff',
                                          borderRadius: 0,
                                        }}
                                                            fullWidth
                                                            size="large"
                                                            onClick={handleClick}
  >
    {buttonTitle}
  </Button>) : null
}

export default MenteeMatchSubmitButton
