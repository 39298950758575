import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Input from "@material-ui/core/Input/Input";
import {connect} from "react-redux";
import {changeLanguage, changeUserLanguage} from "actions/languageAction";
import { sortBy } from "lodash";

const styles = theme => ({
  icon: {
    color: '#728D9A'
  },
  languageSelectionWrapper: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  inputRoot: {
    'label + &': {
      marginTop: 0,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    fontSize: 12,
    color: '#52B2E2',
    padding: '0px',
    paddingBottom: 2,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
});


const LanguageDropdown = ({
                            classes, activeLanguageCode = 'en', changeUserLanguage, supportedLanguages = []
                          }) => {
  // useEffect(() => {
  //   getSupportedLanguages();
  // }, [getSupportedLanguages]);
  //
  // useEffect(() => {
  //   changeUserLanguage(activeLanguageCode);
  // }, []);

  return (
    <div className={classes.languageSelectionWrapper}>
      <div style={{marginLeft: 10}}>
        <Select
          labelId="language-selection"
          id="language-selection"
          variant="standard"
          onChange={(e) => changeUserLanguage(e.target.value)}
          input={<Input classes={{root: classes.inputRoot, input: classes.input}} inputProps={{'aria-label': 'naked'}}
                        disableUnderline/>}
          value={activeLanguageCode}
          classes={{
            icon: classes.icon
          }}
        >
          {
            sortBy(supportedLanguages.filter(language => language.isActive || language.languageCode === activeLanguageCode), 'languageCode')
              .map(language => <MenuItem key={language.languageCode}
                                                value={language.languageCode}>{(language.language)}</MenuItem>)
          }
        </Select>
      </div>
    </div>
  );
};


const mapStateToProps = ({language: {supportedLanguages, activeLanguageCode}}) => ({
  supportedLanguages, activeLanguageCode
});

const mapPropsToActions = {changeLanguage, changeUserLanguage};

export default connect(mapStateToProps, mapPropsToActions)(withStyles(styles)(LanguageDropdown));

