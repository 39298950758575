import {Button, InputBase, makeStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import {join, map, sortBy} from 'lodash';
import React from "react";
import {useSetTranslation} from "common/useSetTranslation";
import { useState } from "react";
import { useRef } from "react";

const useOutlinedInputStyles = makeStyles(theme => {
  return (
    {
      formControl: {
        display: 'flex',
      },
      root: {
        whiteSpace: 'unset',
      },
      selectMenu: {},
      itemRoot: {
        whiteSpace: 'normal',
        maxWidth: '350px'
      },
      visuallyHidden: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        border: '0',
      },
    })
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};


const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: 'white',
    fontSize: 16,
    padding: '14px 26px 14px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#E0B334',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);


const MultiSelectDropdown = ({DropDownItems, onAnswered, value = []}) => {
  const {st} = useSetTranslation();
  const classes = useOutlinedInputStyles();
  const orderedOptionItems = sortBy(DropDownItems, (option) => option.displayOrder);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, child) => {
    if (child.props?.data == 'close-select') {
      return 
    }
    onAnswered(event.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        ref={dropdownRef}
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value}
        onChange={handleChange}
        renderValue={selected => map(selected, selected => st(selected.label, selected.localeLabel)).join(', ')}
        MenuProps={MenuProps}
        fullWidth
        classes={{
          root: classes.root,
          selectMenu: classes.selectMenu,
        }}
        input={<BootstrapInput/>}
      >
        {/*<ListSubheader disableSticky>Category 1</ListSubheader>*/}
          {orderedOptionItems.map(option => (
            <MenuItem 
              key={option.key} 
              value={option} 
              classes={{root: classes.itemRoot}}
              aria-label={st(option.label, option.localeLabel)}
            >
              <Checkbox 
                checked={value.indexOf(option) > -1} 
                style={{color: 'rgba(82, 178, 226, 0.43)'}}
                inputProps={{ 'aria-hidden': 'true' }}
              />
              <ListItemText primary={st(option.label, option.localeLabel)} style={{flexWrap: 'wrap'}}/>
            </MenuItem>
          ))}
          <Button
            className={classes.visuallyHidden}
            data="close-select"
            aria-label="Close the select box"
            onClick={handleClose}
          >Close</Button>
      </Select>
    </FormControl>
  );
};


export default (MultiSelectDropdown);
