import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import Question from "components/Question";
import React from "react";


const MaterialStyles = theme => ({
  stepQuiz: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 100%',
    height: '95vh',
    width: '100%',
  },
  stepTitle: {
    color: '#BBBBBB',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    backgroundColor: 'white',
    padding: '17px 32px',
    fontWeight: 'bold',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  stepQuestionContent: {
    flex: 1,
    backgroundColor: '#E7EDF0',
    padding: '17px 32px',
    overflow: 'auto'
  }
});


function SessionQuizStepRenderer({question, classes, title, error, value, onAnswered, questionsLeftMessage}) {
  return (
    <div className={classNames(classes.stepQuiz)}>
      {/*<div className={classes.stepTitle}>{questionsLeftMessage}</div>*/}
      <div className={classes.stepQuestionContent}>
        <Question question={question} answer={''}
                  questionAnswered={''}
                  onAnswered={onAnswered}
                  value={value}
                  error={error}
        />
      </div>
    </div>
  );
};


export default withStyles(MaterialStyles)(SessionQuizStepRenderer);
