import {apiAction, dataAction, silentApiAction, staticAction} from "actions/actionWrappers"
import {sessionStatus} from "actions/mentorshipAction"
import {actions as sessionStatusActions} from "actions/sessionStatus"
import {ApiDataInvalidError} from "api/errors"
import {
  bookmarkSession,
  fetchBookmarkedSession,
  fetchLearningHubSessions,
  fetchMySessions,
  fetchSession,
  getQuizAnswers,
  postActivity,
  postStartSession,
  saveQuizAsForm,
  saveSessionFeedback
} from "api/session"
import {isFormEmpty} from "business/session"
import {convertToSubmitFormat} from "common/convertToSubmitFormat"
import {history} from "common/history"
import {validate} from "common/validation"
import {filter, find, flatten, get, some, values} from 'lodash'
import {actions} from "reducers/sessionReducer"
import { convertFilesToMultipartSubmitFormat } from "../common/convertFilesToMultipartSubmitFormat";


const initSessions = () => async ( dispatch ) => {
  const allSessionsPromise = dispatch(apiAction(fetchLearningHubSessions));
  const mySessionsPromise = dispatch(apiAction(fetchMySessions));
  const [allSessions, mySessions] = await Promise.all([allSessionsPromise, mySessionsPromise]);
  dispatch(dataAction(actions.STORE_ALL_SESSIONS, allSessions));
  dispatch(dataAction(actions.STORE_MY_SESSIONS, mySessions));
};


const initSession = ( sessionId, isLearningHub ) => async ( dispatch ) => {
  dispatch(staticAction(actions.RESET_CURRENT_SESSION));
  const session = await dispatch(apiAction(fetchSession, sessionId, isLearningHub));
  !isLearningHub && dispatch(sessionStatus(sessionId));
  dispatch(dataAction(actions.STORE_CURRENT_SESSION, session));
};

const initBookmarkedSessions = () => async ( dispatch ) => {
  const bookmarkedSession = await dispatch(apiAction(fetchBookmarkedSession));
  dispatch(dataAction(actions.STORE_BOOKMARKED_SESSIONS, bookmarkedSession.results))
}

const toggleBookmark = ( sessionId ) => async ( dispatch ) => {
  dispatch(silentApiAction(bookmarkSession, sessionId));
  dispatch(dataAction(actions.BOOKMARK_SESSION, sessionId));
};

const initQuiz = ( quizId, stepId, isLearningHub ) => async ( dispatch, getState ) => {
  const { session: { currentSession } } = await getState();
  const stepContent = find(get(currentSession, 'sessionSteps'), ( item ) => item.id === stepId).content;
  const currentQuiz = find(stepContent, ( content ) => content.quiz && (content.quiz.id === quizId)).quiz;
  await dispatch(dataAction(actions.STORE_QUIZ, currentQuiz));
  history.push(isLearningHub ? '/session/step/quiz/' : `/mentorship/${currentSession.id}/step/${stepId}/quiz/`, {
    stepId,
    quizId
  })
};

const hasError = ( validationResults ) => flatten(values(validationResults)).length > 0;


const getValidationResults = ( validationResults ) => (
  {
    validationResults,
    hasError: hasError(validationResults),
  }
);


const setSessionQuizAnswer = ( stepId, question, answer ) => async ( dispatch ) => {
  dispatch(dataAction(actions.STORE_SESSION_QUIZ_ANSWERS, { question, answer, stepId }));
};

const nextPage = ( question, answers ) => ( dispatch ) => {
  const answer = get(answers, question.key);
  const result = {};
  result[question.key] = validate(question, answer);
  dispatch(dataAction(actions.SESSION_NEXT_PAGE, getValidationResults(result)));
};

const previousPage = () => dispatch => {
  dispatch(staticAction(actions.SESSION_PREVIOUS_PAGE));
};

const mentorshipId = async ( getState ) => {
  const state = await getState();
  return get(state, 'auth.user.status.mentorships[0].id');
};

const submitQuiz = ( questions, session, stepId, quizId, answers, question ) =>
  async ( dispatch, getState ) => {
    const sessionId = session.id;
    const answer = get(answers, question.key);
    const result = {};
    result[question.key] = validate(question, answer);

    const quizValidationResults = getValidationResults(result);
    dispatch(dataAction(actions.SET_QUIZ_VALIDATION_RESULTS, quizValidationResults));
    if (!quizValidationResults.hasError) {
      const mentorship = await mentorshipId(getState);
      if (!isFormEmpty(answers)) {
        // await dispatch(apiAction(saveQuiz, sessionId, stepId, quizId, convertToSubmitFormat(questions, answers), session.isLearningHub, mentorship))
        await dispatch(apiAction(saveQuizAsForm, sessionId, stepId, quizId, convertToSubmitFormat(questions, answers), session.isLearningHub, mentorship,
          convertFilesToMultipartSubmitFormat(questions, answers)))
      }

      await dispatch(initQuizAnswers(sessionId, stepId, quizId, session.isLearningHub, mentorship));
      dispatch(staticAction(actions.RESET_CURRENT_QUIZ));
      history.goBack();

      const { session: { currentSession, quizAnswers } } = await getState();
      session.isLearningHub && dispatch(stepCompleted(session, currentSession.sessionSteps.find(( step ) => step.id === stepId), quizAnswers, session.isLearningHub));
    }
  };

const quizHasMandatoryQuestions = ( quiz ) => flatten(quiz.questions).some(q => q.mandatory === true);

const isStepMandatoryAndCompleted = ( step, sessionId, quizAnswers ) => {
  const mandatoryQuizContents = filter(step.content, ( content ) => content.contentType.toLowerCase() === 'quiz' && quizHasMandatoryQuestions(content.quiz));
  const answersForMandatoryQuizContents = mandatoryQuizContents.map(( quiz ) => get(quizAnswers, `[${sessionId}][${step.id}][${quiz.quiz.id}].answers`));
  return !some(answersForMandatoryQuizContents, ( answer ) => answer === undefined);
};

const stepCompleted = ( session, step, quizAnswers ) => async ( dispatch ) => {
  const sessionId = session.id;
  dispatch(dataAction(sessionStatusActions.ADD_SESSION_STATUS,
    {
      sessionId,
      stepId: step.id,
      isMentorship: !session.isLearningHub,
      sessionTitle: session.title,
      banner: session.banner,
    }));
  if (isStepMandatoryAndCompleted(step, sessionId, quizAnswers)) {
    await dispatch(silentApiAction(postActivity, sessionId, step.id, session.isLearningHub));
    !session.isLearningHub && await dispatch(sessionStatus(sessionId));
  }
};


const startSession = ( sessionId, isLearningHub ) => async dispatch => {
  try {
    dispatch(apiAction(postStartSession, sessionId, isLearningHub));
  } catch (e) {
    if (e instanceof ApiDataInvalidError) {
      console.log('Session Start Status update failed', e);
    }
  }
};

const submitSessionFeedback = ( sessionId, feedback, onComplete ) => async dispatch => {
  await dispatch(apiAction(saveSessionFeedback, sessionId, feedback));
  onComplete();
};

const initQuizAnswers = ( sessionId, stepId, quizId, isLearningHub ) => async dispatch => {
  let quizAnswers;
  try {
    quizAnswers = await dispatch(apiAction(getQuizAnswers, sessionId, stepId, quizId, isLearningHub));
  } catch (e) {
    if (e instanceof ApiDataInvalidError) {
      //Ignore. This error comes when quiz is not yet answered
    }
  }
  if (quizAnswers) {
    await dispatch(dataAction(actions.STORE_QUIZ_ANSWERS, { sessionId, stepId, quizId, quizAnswers }));
    dispatch(staticAction(actions.SET_CURRENT_QUIZ_COMPLETE));
  } else {
    dispatch(staticAction(actions.SET_CURRENT_QUIZ_INCOMPLETE));
  }
};


export {
  initQuiz,
  initSessions,
  initSession,
  toggleBookmark,
  nextPage,
  previousPage,
  setSessionQuizAnswer,
  submitQuiz,
  stepCompleted,
  startSession,
  submitSessionFeedback,
  initBookmarkedSessions,
  initQuizAnswers
};
