import {apiAction, dataAction} from "actions/actionWrappers";
import {initApp} from "actions/authAction";
import {fetchMatchInfo, getActiveMatchList, getGroupDetails, getMatchList, postMatch} from "api/matchingApi";
import {groupCompatibilityStatus, isMatchActive, trackerStatus} from "business/matching";
import _, {filter, get,  map, unset} from 'lodash';
import {actions} from "reducers/matchingReducer";

const getActiveMatches = () => async (dispatch) => {
  const activeMatches = await dispatch(apiAction(getActiveMatchList));
  dispatch(dataAction(actions.STORE_ACTIVE_MATCHES, activeMatches));
  dispatch(getGroupInfo);
};

const getMatchesList = () => async (dispatch) => {
  const activeMatches = await dispatch(apiAction(getMatchList));
  dispatch(dataAction(actions.STORE_MATCHES, get(activeMatches, 'matchTrackers')))
};

const setCompatiblilityStatus = (match, groupCompatibility, clashingCriterias) => dispatch => {
  const updatedTracker = {...match, groupCompatibility, clashingCriterias};
  dispatch(dataAction(actions.UPDATE_MATCH, updatedTracker));
};

const updateMatch = (match, interestStatus, isMentor, allowOnlyOne) => async dispatch => {
  if (interestStatus === trackerStatus.INTERESTED && allowOnlyOne === true) {
    dispatch(dataAction(actions.MARK_PAIR_COMPLETE));
  }
  let interest=interestStatus;
  if (interestStatus === trackerStatus.INTERESTED && match.interestStatus === trackerStatus.INTERESTED) {
    interest = null
  }else if(interestStatus === trackerStatus.REJECTED && match.interestStatus === trackerStatus.REJECTED){
    interest = null
  }else {
    interest = interestStatus
  }

  if (interest === null && allowOnlyOne === true) {
    dispatch(dataAction(actions.UNMARK_PAIR_COMPLETE));
  }else if(interest === trackerStatus.REJECTED && allowOnlyOne === true){
    dispatch(dataAction(actions.UNMARK_PAIR_COMPLETE));
  }


  const matchStatus = {
    ...match,
    interestStatus: interest,
    clashingCriterias: isMentor && !allowOnlyOne ? [] : null,
    groupCompatibility: isMentor && !allowOnlyOne ? groupCompatibilityStatus.COMPATIBLE : null
  };

  if(interest === null){
    unset(matchStatus,'interestStatus')
  }

  await dispatch(dataAction(actions.UPDATE_MATCH, matchStatus));

  isMentor && !allowOnlyOne && dispatch(checkCompatibility);
};

const checkCompatibility = async (dispatch, getState) => {
  const {matching} = await getState();
  const {groupInfo} = matching;
  const allActiveMatches = get(matching, 'activeMatches');
  const activeMatches = filter(allActiveMatches, isMatchActive);

  const actualSize = get(groupInfo, "actualSize");
  const groupSize = get(groupInfo, "groupSize");
  const interesetedTrackerCount = filter(filter(activeMatches, isMatchActive), match => get(match, "interestStatus", false) === trackerStatus.INTERESTED).length;

  const isGroupFull = (interesetedTrackerCount + actualSize) === groupSize;
  if (isGroupFull) {
    const noActionTracker = filter(activeMatches, match => (_.isUndefined(match.interestStatus) || match.interestStatus === trackerStatus.REJECTED) && (match.groupCompatibility === groupCompatibilityStatus.COMPATIBLE || _.isUndefined(match.groupCompatibility)));
    _.forEach(noActionTracker, tracker => {
      dispatch(setCompatiblilityStatus(tracker, groupCompatibilityStatus.GROUP_FULL, []));
    })
  } else {

    const NonSelectedMatchTrackers = filter(activeMatches,
      match => match.interestStatus === trackerStatus.REJECTED || _.isUndefined(match.interestStatus));

    const selectedTrackers = filter(activeMatches, match => match.interestStatus === trackerStatus.INTERESTED);

    _.forEach(NonSelectedMatchTrackers, (tracker) => {

      let isIncompatible = false;
      const inCompatibleReason = [];

      if (selectedTrackers.length === 0) {
        dispatch(setCompatiblilityStatus(tracker, groupCompatibilityStatus.COMPATIBLE, []));
      } else {
        //Check with each groupMember, if tracker is compatible
        for (let interestedGroupMemeber of selectedTrackers) {
          const trackerCompatibleWithGroupMember = matchTracker(tracker, interestedGroupMemeber);
          const GroupMemberCompatibleWithTracker = matchTracker(interestedGroupMemeber, tracker);
          const incompatibleCriterias = _.union(trackerCompatibleWithGroupMember, GroupMemberCompatibleWithTracker);
          if (!_.isEmpty(incompatibleCriterias)) {
            isIncompatible = true;
            inCompatibleReason.push(...incompatibleCriterias);
          }
          if (isIncompatible) {
            dispatch(setCompatiblilityStatus(tracker, groupCompatibilityStatus.INCOMPATIBLE, _.uniq(inCompatibleReason)));
          } else {
            dispatch(setCompatiblilityStatus(tracker, groupCompatibilityStatus.COMPATIBLE, []));
          }
        }
      }
    });
  }
};


const matchTracker = (trackerA, trackerB) => {

  //get tracker A mandatory criterias
  //pick 1 criteria
  //if criteria exist in other
  //check if any answer exist  **** This is being changed to => if mandatory criteria matches not any criteria
  //match
  //pick next =>else not matching
  const unMatchedCriterias = [];
  const criteriasA = getMandatoryCriteria(trackerA);
  const criteriasB = get(trackerB, 'exclusiveCriteria');

  _.forEach(criteriasA, (criteria, key) => {
    if (_.indexOf(_.keys(criteriasB), key) > -1) { //if criteria of A exist in the B
      let dif = _.difference(criteria.matchedAnswer, criteriasB[key].matchedAnswer); //find answers of A  which does not exist in B
      if (!(criteria.matchedAnswer.length > dif.length)) {  //
        //return not matched criteria
        unMatchedCriterias.push(key);
      }
    }
    else {
      unMatchedCriterias.push(key);
    }
  });
//all matched
  return unMatchedCriterias;
};

const getMandatoryCriteria = (match) => {
  const mandatoryCriterias = [];
  const exclusiveCriteria = get(match, "exclusiveCriteria");
  _.forEach(exclusiveCriteria, (criteria, key) => {
    if (criteria.mandatory)
      mandatoryCriterias[key] = criteria;
  });
  return {...mandatoryCriterias};
};

const createInterestSet = (matches) => ({
    interestSet: map(matches, (match) => ({
        email: match.email,
        interestStatus: get(match, "interestStatus", get(match, "groupCompatibility"))
      }
    ))
  }
);
const getMatchInfo = (id) => async dispatch => {
  dispatch(dataAction(actions.STORE_MATCH_INFO, {}))
  const matchInfo = await dispatch(apiAction(fetchMatchInfo, id))
  dispatch(dataAction(actions.STORE_MATCH_INFO, matchInfo))
};

const getGroupInfo = async dispatch => {
  try {
    const groupInfo = await dispatch(apiAction(getGroupDetails));
    dispatch(dataAction(actions.STORE_GROUP_INFO, groupInfo));
  } catch (e) {

  }
};

const submitMatch = (matches, isMentor = false) => async dispatch => {
  const matchesToBeSubmitted = filter(matches, match => get(match, 'interestStatus', false) || get(match, 'groupCompatibility', false));
  const submitMatchesResponse = await dispatch(apiAction(postMatch, createInterestSet(matchesToBeSubmitted)));
  dispatch(dataAction(actions.SUBMIT_MATCHES_INFO, submitMatchesResponse));
  if (!['MENTOR_UNAVAILABLE', 'MENTEE_UNAVAILABLE'].includes(submitMatchesResponse)) {
    await dispatch(getActiveMatches());
    isMentor && await dispatch(initApp());
  }
};

export {
  getActiveMatches,
  getMatchesList,
  getMatchInfo,
  updateMatch,
  submitMatch,
}
