import withStyles from "@material-ui/core/styles/withStyles";
import moment from "moment"
import React from "react";
import {get, maxBy} from 'lodash';

import streakIcon from 'common/assets/streakIcon.png';
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FEFAEE',
    borderRadius: '5px',
    width:'264px',
    padding:'12px 11px',
    marginLeft:18,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#DE6E49',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '8px',
    color: '#5A5A5A',
    fontWeight: 'normal',
    marginTop:2
  },
  streakImageIcon: {
    width:'24px',
  }
});

const pad = (num) => `00${num}`.substr(-2, 2);

const Streaks = ({classes,progress}) => {
  const {t} = useTranslation();

  const getMessage = (quizStreaks) => {
    if (quizStreaks.length === 0) return t('startStreak');

    const latestStreak = maxBy(quizStreaks, 'timestamp');
    const streakForThisWeekUnlocked = moment().startOf('day').diff(moment(latestStreak.timestamp).startOf('day'), 'days') < 7;

    if (quizStreaks.length === 1 && streakForThisWeekUnlocked) {
      return t('maintainStreakNextWeek');
    }

    if ((quizStreaks.length === 1 && !streakForThisWeekUnlocked)) {
      return t('maintainStreakThisWeek');
    }

    if (streakForThisWeekUnlocked) {
      return t('congratulationsMaintainStreakNextWeek');
    }

    return t('maintainStreakDefault');
  };

  const quizStreaks = get(progress, 'quizStreaks', []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.streakImageIcon}>
        <img src={streakIcon}/>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.title}>{`${t('streakLengthTitlePart1')} ${pad(quizStreaks.length)} ${t('streakLengthTitlePart2')}`}</div>
        <div className={classes.message}>{getMessage(quizStreaks)}</div>
      </div>
    </div>
  );
};

export default withStyles(styles)(Streaks);


