import Slider from "@material-ui/core/Slider/Slider"
import withStyles from "@material-ui/core/styles/withStyles"
import {isEmpty} from 'lodash'
import moment from "moment"
import React from "react"
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {
    backgroundColor: '#E7EDF0'

  },
  sliderWrapper: {
    backgroundColor: '#ffffff',
    padding: '21px 0px'
  },
  slideValueWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  sectionLabel: {
    fontSize: 10,
    color: '#000000',
    display: 'flex',
    padding: '0px 16px 12px 16px'
  },
  rail: {
    backgroundColor: '#E8E8E8',
  },
  track: {
    backgroundColor: '#52B2E2'
  },
  thumb: {
    backgroundColor: '#52B2E2'
  }
})


const getDates = (startDate, endDate) => {
  let dates = []

  let currDate = moment(startDate).startOf('day')
  let lastDate = moment(endDate).startOf('day')

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate())
  }
  return dates
}

const formatDate = (date) => moment(date).format('DD/MM/YY')

const TimeLineFilter = ({classes, setFeedFilter, filterValue: {date, lastDate, firstDate}}) => {
  const {t} = useTranslation();


  const fDate = isEmpty(firstDate) ? new Date(2020, 0, 1) : new Date(firstDate)
  const tDate = isEmpty(lastDate) ? new Date() : new Date(lastDate)

  const dates = getDates(fDate, tDate)
  let fVal = 0
  let tVal = dates.length

  if (!isEmpty(date)) {
    fVal = date.fromVal
    tVal = date.toVal
  }

  const [value, setValue] = React.useState([fVal, tVal])
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const setDateRange = (value) => {
    const from = value[0]
    const to = value[1]
    const fromDate = {fromDate: formatDate(dates[from]), fromVal: from}
    const toDate = {toDate: formatDate(dates[to]), toVal: to}
    setFeedFilter("date", {...fromDate, ...toDate})
  }

  const clear = () => {
    setValue([0, dates.length])
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionLabel}>
        <span style={{color: '#000000'}}>{t('timeline')}</span>
        <span style={{color: '#728D9A', marginLeft: 'auto', cursor: 'pointer'}} onClick={clear}>{t('clear')}</span>
      </div>
      <div className={classes.sliderWrapper}>
        <div
          className={classes.slideValueWrapper}>{moment(dates[value[0]]).format('MMM\' D')} - {moment(dates[value[1]]).format('MMM\' D')}</div>
        <Slider
          value={value}
          onChange={handleChange}
          onChangeCommitted={() => setDateRange(value)}
          max={dates.length}
          classes={{
            rail: classes.rail,
            track: classes.track,
            thumb: classes.thumb
          }}
        />
      </div>
    </div>
  )
}

export default withStyles(styles)(TimeLineFilter)


