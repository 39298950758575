import {makeStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar/AppBar";
import IconButton from '@material-ui/core/IconButton';
import Menu from "@material-ui/core/Menu/Menu";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from '@material-ui/icons/Menu';
import {logoutUser} from "actions/authAction";
import {isEmpty} from 'lodash';
import React from "react";
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";
import SignedInLinks from "./SignedInLinks";
import logo from 'common/assets/logo-with-name.png';
import {useTranslation} from "react-i18next";
import LanguageDropdown from "components/LanguageDropdown";


const styles = {
  logo: {
    width: 80
  },
  userName: {
    color: '#000000',
    marginRight: 4,
    marginLeft: 10
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  title: {
    flexGrow: 1,
  },
  menuHorizontal: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  appBar: {
    backgroundColor: 'white',
    paddingLeft: 100,
    paddingRight: 100,
    maxHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px'
    }
  },
  menuItemWrapper: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end'
  },
  ul: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    paddingLeft: 0,
  },
  navText: {
    textDecoration: 'none',
    color: '#000000',
    fontSize: 14,
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '12px 16px',
    cursor: 'pointer',
    '&:hover': {
      background: "#EEEEEE",
      fontWeight: 'bold'
    },
  },
  li: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '12px 16px',
    cursor: 'pointer',
    '&:hover': {
      background: "#EEEEEE",
      fontWeight: 'bold'
    }
  },
  hamburgerMenu: {
    top: 48,
  },
  list: {
    padding: 0,
  },
  subMenuUl: {
    listStyleType: 'none',
    display: 'flex',
    padding: '12px 16px',
    flexWrap: 'wrap'
  },
  subMenuLi: {
    fontSize: 12,
    padding: '2px 5px 0px 0px',
  },
  subMenuText: {
    textDecoration: 'none',
    color: '#0B8EED',
    cursor: 'pointer',
    '&:hover': {
      fontWeight: 'bold'
    },
  },
  menu: {
    width: 100,
    backgroundColor: 'red',
  },
  popOver: {
    marginTop: 32,
    maxWidth: 213,
  }
}));


const Navbar = ({auth: {isAuthenticated}, displayName, currentPath, logout, workReadinessScore}) => {
  const {t} = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hamburgerAnchor, setHamburgerAnchor] = React.useState(false);

  const open = Boolean(anchorEl);
  const openHamburger = Boolean(hamburgerAnchor);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHamburgerMenu = (event) => {
    setHamburgerAnchor(event.currentTarget);
  };

  const handleHamburgerClose = () => {
    setHamburgerAnchor(false);
  };

  const handleSignOut = () => {
    handleHamburgerClose();
    logout();
  };

  const navLink = isAuthenticated && <SignedInLinks handleClose={handleClose} currentPath={currentPath}/>;

  const Appbar =
    <AppBar position="static" className={classes.appBar}>
      <Toolbar variant="dense" disableGutters>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
          <MenuIcon style={{color: '#E0B334'}}/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {navLink}
        </Menu>


        <div>
          <img src={logo} style={styles.logo} alt={''}/>
        </div>

        <div className={classes.menuItemWrapper}>
          <div className={classes.menuHorizontal}>
            {navLink}
          </div>

          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
            <LanguageDropdown />
            <p style={styles.userName}>{displayName}</p>

            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleHamburgerMenu}
              edge="start"
            >
              <AccountCircle style={{color: '#E0B334'}}/>
            </IconButton>
            <Menu
              id="hamburger-appbar"
              anchorEl={hamburgerAnchor}
              anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openHamburger}
              onClose={handleHamburgerClose}
              PopoverClasses={{paper: classes.popOver}}
              classes={
                {
                  list: classes.list,
                }
              }
            >
              <ul className={classes.ul}>
                <NavLink to="/moreinfo/wrs" className={classes.navText}>{t('myProfile')}</NavLink>
                <NavLink to="/moreinfo/faq" className={classes.navText}>{t('faqs')}</NavLink>
                <li className={classes.li} onClick={handleSignOut}>{t('signOut')}</li>
                <li>
                  <ul className={classes.subMenuUl}>
                    <li className={classes.subMenuLi}><a href={'https://mentortogether.org/privacy/'} target={'_blank'}
                                                         className={classes.subMenuText}> {t('privacyPolicy')}</a></li>
                    <li className={classes.subMenuLi}><a href={'https://mentortogether.org/terms/'} target={'_blank'}
                                                         className={classes.subMenuText}>{t('termsOfService')}</a></li>
                    <li className={classes.subMenuLi}><NavLink className={classes.subMenuText}
                                                               to={'/moreinfo/codeofconduct'}>{t('codeOfConduct')}</NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </Menu>


            {/*<IconButton*/}
            {/*aria-label="account of current user"*/}
            {/*aria-controls="menu-appbar"*/}
            {/*aria-haspopup="true"*/}
            {/*color="blue"*/}
            {/*>*/}
            {/*<NotificationsIcon />*/}
            {/*</IconButton>*/}

            {/*<IconButton*/}
            {/*aria-label="account of current user"*/}
            {/*aria-controls="menu-appbar"*/}
            {/*aria-haspopup="true"*/}
            {/*color="blue"*/}
            {/*onClick={logout}*/}
            {/*>*/}
            {/*<ExitToAppIcon/>*/}
            {/*</IconButton>*/}

          </div>
        </div>
      </Toolbar>
    </AppBar>;
  return (
    isAuthenticated && Appbar
  );
};


const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    displayName: state.profile.displayName,
    currentPath: state.navmenu.currentPath,
    workReadinessScore: state.supplementary.workReadinessScore,
  }
};

const mapDispatchToProps = (dispatch) => {
  return ({
    logout: () => dispatch(logoutUser())
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
