import "components/styles/AgreeabilityScale.scss";
import React, {useState} from "react";
import ImageUploader from 'react-images-upload';
import {useTranslation} from "react-i18next";

const ImageResponse = ({onAnswered, value = []}) => {
  const {t} = useTranslation();

  const [pictures, setPictures] = useState([]);
  const onDrop = async picture => {
    setPictures([...pictures, picture]);
    onAnswered(picture);
  }

  return (
    <div>
      <ImageUploader
        withIcon={false}
        buttonText={t('upload')}
        onChange={onDrop}
        imgExtension={[".jpg", ".jpeg", ".png"]}
        maxFileSize={4000000}
        withPreview={true}
        withLabel={false}
        // defaultImages={value}
        fileSizeError={t('imageMaxSize4MB')}
        fileTypeError={t('invalidImageFile')}
      />
    </div>
  );
};


export default (ImageResponse);
