import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import withStyles from "@material-ui/core/styles/withStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from "classnames";
import {AllSessionIcon, CuratedSessionIcon, OngoingIcon} from "common/customIcon";
import {history} from 'common/history';
import React, {Fragment} from 'react';
import {Link, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
    navigationWrapper: {
      marginTop: 18,
      height: '172px',
    },
    menuItem: {
      height: '32px',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 5,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      paddingLeft: 16,
      alignItems: 'center',
      fontSize: '10px',
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: 'black'

    },
    menuIcon: {
      float: 'right',
    },
    mobileViewmenuItem: {
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: 'black'
    },
    greetingMessage: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'black'
    },
    ul: {
      listStyleType: 'none',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '4px 16px 4px 16px'
      }
    },
    li: {
      padding: '0 8px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 4px 8px 0px',
      },
      borderBottom: '1px solid rgba(0, 0, 0, 0.72)',
    },
    mobileView: {
      display: 'flex',
      alignItems:
        'center',
      justifyContent:
        'space-between'
    },
    currentLocation: {
      color: 'white',
      backgroundColor: '#E0B334',
    },
    mobileCurrentLocation: {
      color: 'white',
      fontWeight: 'bold',
      borderBottom: '2px solid #E0B334'
    }

  })
;

const isThisCurrent = (path) => history.location.pathname === path;

const SurveyNavigation = ({classes, displayName, mentorshipSessionStatus}) => {
  const {t} = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const MobileView = <div className={classes.mobileView}>
    <div className={classes.greetingMessage}>{`${t('helloComma')} ${displayName}`}</div>
    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
      <FilterListIcon style={{color: '#E0B334'}}/>
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <ul className={classes.ul}>
        <li className={classNames(classes.li, isThisCurrent('/surveylisting') && classes.mobileCurrentLocation)}>
          <NavLink
            className={classes.mobileViewmenuItem} to="/surveylisting">{t('allSurveys')}</NavLink>
        </li>
        <li
          className={classNames(classes.li, isThisCurrent('/surveylisting/pendingsurveys') && classes.mobileCurrentLocation)}>
          <NavLink className={classes.mobileViewmenuItem} to="/surveylisting/pendingsurveys">{t('pendingSurveys')}</NavLink>
        </li>
        <li
          className={classNames(classes.li, isThisCurrent('/surveylisting/completedsurveys') && classes.mobileCurrentLocation)}>
          <NavLink className={classes.mobileViewmenuItem} to="/surveylisting/completedsurveys">{t('completedSurveys')}</NavLink>
        </li>
      </ul>
    </Menu>
  </div>;

  const NormalView = <div className={classes.navigationWrapper}>
    <Grid container justify="center" style={{height: '100%'}}>
      <div className={classes.greetingMessage}>{`${t('helloComma')} ${displayName}`}</div>
      <Link to={'/surveylisting'}
            className={classNames(classes.menuItem, isThisCurrent('/surveylisting') && classes.currentLocation)}>
          <span><p style={{display: 'inline-block'}}>{t('allSurveys')}</p>
            <span className={classes.menuIcon}>
            <AllSessionIcon
              viewBox="1 0 17 9"
              style={{color: isThisCurrent('/surveylisting') ? 'white' : '#E0B334'}}/>
          </span></span>
      </Link>

      <Link to={'/surveylisting/pendingsurveys'}
            className={classNames(classes.menuItem, isThisCurrent('/surveylisting/pendingsurveys') && classes.currentLocation)}>
          <span><p style={{display: 'inline-block'}}>{t('pendingSurveys')}</p>
            <span className={classes.menuIcon}>
            <CuratedSessionIcon
              viewBox="0 0 16 9"
              style={{color: isThisCurrent('/surveylisting/pendingsurveys') ? 'white' : '#E0B334'}}/>
          </span></span>
      </Link>

      <Link to={'/surveylisting/completedsurveys'}
            className={classNames(classes.menuItem, isThisCurrent('/surveylisting/completedsurveys') && classes.currentLocation)}>
          <span><p style={{display: 'inline-block'}}>{t('completedSurveys')}</p>
            <span className={classes.menuIcon}>
            <OngoingIcon
              viewBox="0 0 16 9"
              style={{color: isThisCurrent('/surveylisting/completedsurveys') ? 'white' : '#E0B334'}}/>
          </span></span>
      </Link>
    </Grid>
  </div>;

  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <Fragment>
      {isMobileView ? MobileView : NormalView}
    </Fragment>
  )
};

export default withStyles(MaterialStyles)(SurveyNavigation);
