import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {find, get, isBoolean, isEmpty, noop, some} from "lodash";
import React, {Fragment} from "react";
import {useSetTranslation} from "common/useSetTranslation";
import { useTranslation } from "react-i18next";
import i18n from "i18n";


const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17,
    textTransform:'UPPERCASE'
  },
  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 16,
    marginTop: 8,
    fontSize: 14,
    fontWeight: 'bold'
  },
  correct: {
    backgroundColor: '#E6F9EA',
    color: '#41A156'
  },
  incorrect: {
    backgroundColor: '#F9E6E6',
    color: '#A14141'
  }
});

const MultiChoiceAnswer = ({classes, style, question, quizAnswer = {}, title = i18n.t('yourAnswer'), isLearningHub=true}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();
  const answerOptions = get(question, "answerOptions");
  const selectedAnswers = answerOptions.filter((answer) => find(quizAnswer.answer, (item) => item === answer.key));
  const correctAnswers = answerOptions.filter((answer) => answer.isCorrect);
  const isAnswerValidityAvailable = !isEmpty(correctAnswers) ;

  const isAnswerCorrect = (ansKey) => {
    return find(correctAnswers, (ans) => ans.key === ansKey);
  };

  const isAnyAnswerIncorrect = () => {
    return some(selectedAnswers, (selectedAnswer) => !isAnswerCorrect(selectedAnswer.key));
  };

  return (
    <Fragment>
      <div>
        <p className={classes.title}>{title}</p>
        {selectedAnswers.map((item) =>
          <div key={item.key}
               className={classNames(classes.box, (!isEmpty(correctAnswers) && (isAnswerCorrect(item.key) ? classes.correct : classes.incorrect)))}>{st(item.label, item.localeLabel)}</div>
        )}
        {/*TO Discuss: IF no answer is incorrect and also, correct answer is not selected (not attempted the question then correct answers should appear*/}
        {
          isAnyAnswerIncorrect() && isLearningHub &&
          <Fragment>
            {isAnswerValidityAvailable && <p className={classes.title}>{t('correctAnswer')}</p>}
            {
              correctAnswers.map((item) => <div key={item.key}
                                                className={classNames(classes.box, isAnswerCorrect(item.key) ? classes.correct : classes.incorrect)}>{st(item.label, item.localeLabel)}</div>)
            }

          </Fragment>

        }

      </div>
    </Fragment>
  );
};


export default withStyles(styles)(MultiChoiceAnswer);
