import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skeleton from '@material-ui/lab/Skeleton';
import {convertUrl} from "common/helpers";
import {history} from "common/history";
import ScreeningStageBar from "components/ScreeningStageBar";
import {filter, get, map} from 'lodash';
import React, {Fragment, useEffect} from "react";
import 'views/css/SurveyLandingPage.css'
import { useSetTranslation } from "common/useSetTranslation";
import { useTranslation } from "react-i18next";
import SmallScreenMessengerContainer from "containers/SmallScreenMessengerContainer";

const LodingSkeleton = () => {
  return (
    <div>
      <Container maxWidth={"md"} disableGutters={true}>
        <div className={"wrapper"}>
          <Skeleton animation="wave" height={50} width="100%"/>
          <Skeleton variant="rect" animation="wave" width={300} height={200}/>
          <Skeleton animation="wave" height={30} width="50%"/>
        </div>
      </Container>
    </div>
  );
}


export default function SurveyLandingPage({location, userSurveys, initSurvey, gettingSurvey, survey, setCurrentPath}) {
  const {surveyId} = location.state;
  const {st} = useSetTranslation();
  const {t} = useTranslation();

  const userSurvey = get(filter(userSurveys, survey => survey.surveyId === surveyId), "[0]");
  useEffect(() => {
    setCurrentPath('survey');
    initSurvey(surveyId);
  }, []);

  const stages = get(survey, 'stages');
  const stageBackground = convertUrl(get(survey, 'backgroundImage'));
  const surveyStatus = get(userSurvey, 'surveyStatus');
  const isPartialSurvey = surveyStatus.startsWith('PARTIAL');
  const currentStageId = get(userSurvey, 'currentStageId');
  const currentStageName = map(stages, stage => stage.id === currentStageId && st(stage.name, stage.localeName));
  const currentStageDescription = map(stages, stage => stage.id === currentStageId && st(stage.description, stage.localeDescription));
  const matches = useMediaQuery('(min-width:800px)');
  return (
    <div style={{
      backgroundColor: '#E0B334',
      backgroundImage: matches && `url(${stageBackground})`,
      backgroundSize: 'contain'
    }} className={"background"}>
      {gettingSurvey ? <LodingSkeleton surveyStatus={surveyStatus}/> : <Fragment>
        <ScreeningStageBar stages={stages} currentStageId={currentStageId}
                           currentStageStatus={get(userSurvey, 'stageStatus')}/>
        <Container maxWidth={"md"} disableGutters={true}>
          <div className={"wrapper"}>
            <img src={stageBackground} style={{width: '100%', display: matches ? 'none' : ''}} alt=""/>
            <h2>{currentStageName}</h2>
            <p>{currentStageDescription}</p>
            <Button variant={'contained'} color={'white'}
              onClick={() => history.push("/stage", {stageId: currentStageId, surveyId })}
              style={{color: '#52B2E2', paddingLeft: 42, paddingRight: 42, marginTop: 60, textTransform: 'uppercase'}}
              disabled={surveyStatus === 'COMPLETE'}
              disableElevation>
                {isPartialSurvey ? t('finishCaps') : t('begin')}
            </Button>
          </div>
          <SmallScreenMessengerContainer/>
        </Container></Fragment>
      }
    </div>
  );
};

