import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Button from "@material-ui/core/Button/Button";
import Modal from "@material-ui/core/Modal/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Question from "components/Question";
import {concat, find, get, isEmpty, map} from 'lodash';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#E7EDF0',
    width: 570,
    height: 610,
    outline: 0,
    borderRadius: '9px 9px 0px 0px',
    display: 'flex',
    flexDirection: 'column'
  },
  form: {
    padding: '33px 50px 0px 50px',
    flexDirection: 'column',
    overflowY: 'auto'
  },
  buttonContainer: {
    marginTop: 'auto'
  },
  deleteIcon: {
    marginLeft: 6,
    color: '#000000',
    fontSize: 12,
    fontWeight: 'normal',
    padding: '5px 12px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 37,
    cursor: 'pointer'
  },
  imagePlaceHolder: {
    width: 124,
    height: 124,
    borderRadius: '50%',
    backgroundColor: '#ffffff',
    objectFit: 'cover'
  },
  removePicture: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#000000',
    paddingLeft: 12,
    cursor:'pointer'
  }
});

const EditUserProfile = ({classes, open, handleClose, saveProfile, bio, fullName,picture,phoneNumber}) => {
  const {t} = useTranslation();

  const question = {
    label: t('fullName'),
    questionType: 'Text',
    disabled:true
  };
  const question2 = {
    label: t('aboutYou'),
    questionType: 'TextBox',
  };

  const imageQuestion = {
    label: t('profilePicture'),
    questionType: 'SingleImageUpload',
  };
  const phoneNumberQuestion = {
    label: t('phoneNumber'),
    questionType: 'Number',
  };

  const [values, setValues] = useState({});

  const handleSubmit = () => {
    saveProfile(values);
    handleClose();
  };

  useEffect(() => {
    setValues({
                fullName: fullName,
                bio: bio,
                picture:picture,
                tempPicture: picture,
                phoneNumber:phoneNumber
              });
  }, []);

  const handleChange = (name) => (value) => {
    const data = {[name]: value};
    console.log("data",data);
    setValues({
                ...values,
                ...data
              });
  };

  const handleImage = (image,file) => setValues({...values,tempPicture: image,picture: file});

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div className={classes.form}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div style={{
              fontSize: '24px',
              fontWeight: 'bold',
              display: 'flex'
            }}>{t('editProfile')}
            </div>
            <div style={{fontSize: '12px', cursor: 'pointer'}} onClick={handleClose}>{t('cancel')}</div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row',marginTop:29}}>
            <div>
              {isEmpty(values.tempPicture) ? <div className={classes.imagePlaceHolder}/> : <img
                src={isEmpty(values.tempPicture) ? values.picture : values.tempPicture}
                className={classes.imagePlaceHolder}/>}
            </div>
            <div style={{flex: 1, paddingLeft: 30}}>
              <Question question={imageQuestion} onAnswered={(image,file) => handleImage(image,file)}/>
              <div className={classes.removePicture} onClick={() => handleImage("")}>{t('removeProfilePicture')}
              </div>
            </div>
          </div>
          <Question question={question} onAnswered={handleChange("fullName")} value={values.fullName} disabled={true}/>
          <Question question={phoneNumberQuestion} onAnswered={handleChange("phoneNumber")} value={values.phoneNumber} />
          <Question question={question2} onAnswered={handleChange("bio")} value={values.bio}/>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="contained" style={{
            backgroundColor: '#52B2E2',
            boxShadow: '0px 2px 4px rgba(82, 178, 226, 0.25)',
            color: 'white',
            fontSize: 12,
            alignSelf: 'flex-end',
            borderRadius: 0,
            height: 48,
          }} fullWidth onClick={handleSubmit}>{t('confirmChanges')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(EditUserProfile);


