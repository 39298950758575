import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Modal from "@material-ui/core/Modal/Modal";
import withStyles from "@material-ui/core/styles/withStyles";

import React,{ useEffect } from "react";
import {useTranslation} from "react-i18next";

const styles = (theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffffff',
    width: 810,
    height: 208,
    outline: 0,
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 30,
    color: '#000000',
    fontWeight: 'bold',
  },
  description: {
    fontSize: 16,
    lineHeight: 1.5,
    color: '#000000',
    textAlign: 'center',
    paddingLeft: '60px',
    paddingRight: '60px',
    marginTop: '10px',
  },
  btn: {
    backgroundColor: '#E2795D',
    padding: '8px 24px',
    borderRadius: 5,
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 12,
    marginTop: 18,
    cursor:'pointer'
  },
});


const MentorUnavailableWindow = ({classes, open}) => {
  const {t} = useTranslation();

  const refreshTime = 5000; // 5 seconds

  useEffect(() => {
    if (open) {
      setTimeout(() => window.location.reload(), refreshTime);
    }
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div className={classes.title}>{t('mentorUnavailable')}</div>
        <div className={classes.description}>{t('mentorUnavailableDescription')}</div>
        <div className={classes.btn} onClick={() => window.location.reload()}>{t('refreshNow')}</div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(MentorUnavailableWindow);


