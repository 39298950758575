import {setCurrentSessionStepIndex, setCurrentStepId} from "actions/mentorshipAction";
import { initQuiz, initQuizAnswers, initSession, startSession, stepCompleted } from "actions/sessionAction";
import {find, get, map} from 'lodash';
import {connect} from 'react-redux';
import MentorshipSessionStepWrapper from "views/Mentorship/MentorshipSessionStepWrapper";


const mapStateToProps = ({
                           session: {currentSession, quizAnswers},
                           mentorship: {partnerDetails, sessions: {ongoingSessions}, currentMentorshipSessionProgress, currentSessionStepIndex, currentStepId},
                           auth: {user: {role}},

                         }) => {

  const firstStepId = get(currentSession, 'sessionSteps[0].id');

  return ({
    session: currentSession,
    quizAnswers,
    role,
    partnerDetails,
    firstStepId,
    currentSessionStepIndex,
    currentMentorshipSessionStatus : currentMentorshipSessionProgress,
    currentStepId
  });
};

export default connect(mapStateToProps, {
  initQuiz,
  stepCompleted,
  initQuizAnswers,
  setCurrentSessionStepIndex,
  setCurrentStepId,
  initSession,
  startSession
})(MentorshipSessionStepWrapper);
