import {resetPasswordOtpVerify, setNewPassword, validateEmailAddress} from "actions/authAction";
import {connect} from 'react-redux';
import ResetPassword from "views/signup/ResetPassword";

const mapStateToProps = ({auth: {emailOtpError, resetPasswordScreen, otpMessage, otpVerificationStatus}}) => {
  return ({
    emailOtpError,
    resetPasswordScreen,
    otpMessage,
    otpVerificationStatus
  })
}

export default connect(mapStateToProps, {validateEmailAddress, resetPasswordOtpVerify, setNewPassword})(ResetPassword);
