import SvgIcon from "@material-ui/core/SvgIcon/SvgIcon"
import React from "react"


export function BookMarkIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M1.18912 0H7.81088C8.19146 0 8.5 0.279552 8.5 0.624383V9.86306C8.5 9.97767 8.35371 10.0415 8.25471 9.9697L4.59405 7.3162C4.53905 7.27633 4.4604 7.27633 4.4054 7.3162L0.745291 9.9697C0.646295 10.0415 0.5 9.97767 0.5 9.86306V0.624383C0.5 0.279552 0.808538 0 1.18912 0Z"/>
    </SvgIcon>
  );
}


export function OngoingIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9 6L4.64275e-07 11.1962L9.18537e-07 0.803847L9 6Z"/>
    </SvgIcon>
  );
}

export function CuratedSessionIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M4.14866 9.83477L4.14867 9.83479L12 1.98348L10.0165 0L4.07611 5.9404L1.91095 3.77524L0 5.6862L4.14861 9.83481L4.14866 9.83477Z"/>
    </SvgIcon>
  );
}

export function AllSessionIcon(props) {
  return (
    <SvgIcon {...props}>
      <path className="cls-1" d="M15.52,6.82,14.39,10a.37.37,0,0,0,.35.48h.83a.37.37,0,0,0,.37-.41Z"/>
      <path className="cls-2" d="M15.39,8.7a.3.3,0,0,1-.31-.3V3.73a.31.31,0,0,1,.61,0V8.4A.3.3,0,0,1,15.39,8.7Z"/>
      <path className="cls-1"
            d="M16,3.49,8,0,0,3.49l2.33,1V7.56a1.85,1.85,0,0,0,.95,1.61l.88.5a7.86,7.86,0,0,0,7.68,0l.88-.5a1.85,1.85,0,0,0,.95-1.61V4.51Z"/>
      <path fill="#281009" className="cls-2"
            d="M8,7.53a.56.56,0,0,1-.22,0L2.71,5.27a.54.54,0,0,1-.28-.71.55.55,0,0,1,.73-.27L8,6.4l4.84-2.11a.55.55,0,0,1,.73.27.54.54,0,0,1-.28.71L8.22,7.48A.56.56,0,0,1,8,7.53Z"/>
    </SvgIcon>
  );
}

export function LightBulb(props) {
  return (
    <SvgIcon viewBox={'0 0 10 10'} {...props}>
      <path
        d="M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63Z"/>
    </SvgIcon>
  );
}

export function QuoteIcon(props) {
  return (
    <SvgIcon width="9" height="10" viewBox="0 0 9 10" {...props} >
      <path
        d="M4.70477 4.65065V6.74128C4.70477 7.08424 4.98565 7.36266 5.33249 7.36266H6.72489C6.72489 7.36266 6.62805 8.5638 4.98256 8.68602C4.8263 8.69747 4.70477 8.82542 4.70477 8.98048V9.7046C4.70477 9.88257 4.86296 10.0204 5.0412 9.99753C5.89385 9.88677 8.07176 9.40441 8.07176 7.36305V4.65141C8.07176 4.30845 7.79089 4.03003 7.44405 4.03003H5.3321C4.98603 4.02927 4.70477 4.30769 4.70477 4.65065Z"
       />
      <path
        d="M3.58245 5.349V3.25875C3.58245 2.9154 3.30157 2.63736 2.95473 2.63736H1.56233C1.56233 2.63736 1.65917 1.43622 3.30466 1.31401C3.46091 1.30255 3.58245 1.17461 3.58245 1.01955V0.295428C3.58245 0.117453 3.42426 -0.0204203 3.24602 0.00249489C2.39375 0.113252 0.215454 0.595999 0.215454 2.63736V5.349C0.215454 5.69234 0.496326 5.97038 0.843172 5.97038H2.95473C3.30157 5.97038 3.58245 5.69234 3.58245 5.349Z"/>
    </SvgIcon>
  )
}

export function PostIcon(props) {
  return (
    <SvgIcon width="14" height="10" viewBox="0 0 14 10" {...props}>
      <path
        d="M12.9976 0H1.00236C0.448929 0 0 0.448929 0 1.00236V8.99784C0 9.55127 0.448929 10.0002 1.00236 10.0002H12.9976C13.5511 10.0002 14 9.55127 14 8.99784V1.00236C14 0.448929 13.5511 0 12.9976 0ZM7.99548 4.53398C7.99548 4.62679 7.92055 4.70173 7.82774 4.70173H1.46916C1.37635 4.70173 1.30141 4.62679 1.30141 4.53398V1.5221C1.30141 1.42929 1.37635 1.35435 1.46916 1.35435H7.82774C7.92055 1.35435 7.99548 1.42929 7.99548 1.5221V4.53398Z"/>
    </SvgIcon>
  )
}

export function HeartIcon(props) {
  return (
    <SvgIcon width="12" height="10" viewBox="0 0 12 10"  {...props}>
      <path
        d="M10.6561 0.817424C9.62702 -0.272475 7.95838 -0.272475 6.92836 0.817424L5.71392 2.10369L4.49947 0.817424C3.47043 -0.272475 1.8018 -0.272475 0.77178 0.817424C-0.25726 1.90732 -0.25726 3.67464 0.77178 4.76558L5.71392 10L9.44161 6.05185L10.6561 4.76558C11.6861 3.67464 11.6861 1.90732 10.6561 0.817424Z"/>
    </SvgIcon>
  )
}
