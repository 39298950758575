import request from "api/request";

export const getWorkReadinessScores = (auth) => request({
  url: `api/v2/mentorships/wrt/`,
  method: 'GET',
  auth,
});


export const getCommunityElements = (auth) => request({
  url: 'api/v1/community/',
  method: 'GET',
  auth,
});
