import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import {get} from 'lodash';
import React from 'react';

const MaterialStyles = theme => ({
  pageWrapper: {
    paddingLeft: 16,
    paddingRight: 65,
    marginTop: 20,
  },
  childWrapper: {
    paddingLeft: 70,
    paddingRight: 70,
    marginTop: 10,
    overflowY: 'scroll',
    maxHeight:'65vh',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
      maxHeight: '70vh',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 70,
      paddingRight: 70,
    },
  }
});

function SignupPager(props) {
  const {pageTitle, pageDescription, children, classes} = props;
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={6}>
        <div className={classes.pageWrapper}>
          <h1>{pageTitle}</h1>
          <p>{pageDescription}</p>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.childWrapper}>
        {children}
      </Grid>

    </Grid>
  );
}

export default withStyles(MaterialStyles)(SignupPager);


