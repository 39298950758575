import {initSession, startSession, toggleBookmark} from "actions/sessionAction";
import {connect} from 'react-redux';
import SessionIntroduction from "views/session/SessionIntroduction";


const mapStateToProps = ({session: {allSessions, mySessions}}) => ({
  allSessions, mySessions
});

export default connect(mapStateToProps, {toggleBookmark, initSession, startSession})(SessionIntroduction);
