import withStyles from "@material-ui/core/styles/withStyles";
import MatchingDescriptionModal from "components/MatchingDescriptionModal";
import {truncate} from "lodash";
import React, {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  readmore: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
    cursor: 'pointer',
  },
  message: {
    fontSize: 12,
    lineHeight: '21px',
    color: '#747474',
    textAlign: 'center',
    marginTop: 14,
  },
})
const formatDescription = (text) => truncate(text, {length: 160})


const ScreenDescription = ({classes, description, title}) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(!Boolean(open))
  };

  return (
    <Fragment>
      <div className={classes.message}>{formatDescription(description)}<span
        className={classes.readmore} onClick={() => handleClose()}>{t('readMoreCaps')}</span></div>

      <MatchingDescriptionModal open={open} handleState={handleClose} title={title}
                                message={description} />
    </Fragment>
  )
};

export default withStyles(styles)(ScreenDescription);


