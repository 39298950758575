import {withStyles} from "@material-ui/core";
import "components/styles/AgreeabilityScale.scss";
import React from "react";
import ReactPlayer from 'react-player';
import {
  SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER
} from "common/helpers";


const styles = theme => {
  return (
    {
      playerWrapper: {
        position: 'relative',
        paddingTop: '60%'
      },
      reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
      }
    }
  );
};

const Video = ({onAnswered, value, videoId = "", classes, autoPlay}) => {

  const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;
  return (
    <div id="Youtube-video" className={classes.playerWrapper}>
      <ReactPlayer url={videoUrl}
                   controls
                   width='100%'
                   height={autoPlay ? SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER : '100%'}
                   className={classes.reactPlayer}
                   playing={autoPlay}
      />
    </div>
  );
};


export default withStyles(styles)(Video);
