import FeedFilterPopover from "components/FeedFilterPopover"
import React, {useState} from 'react'


const question = {
  questionType: 'AgreeabilityFiveOptions',
  quizAnswers: {
    answer: '25-12-2019'
  },
}

const Dev = () => {
  const [state, setState] = useState(null)



  return (
    <div
      id={"feed"}
      style={{
      margin: '10px 200px',
      width: 464,
      height: '90vh',
      display: 'flex',
      flexDirection: 'column',
      padding: '17px 32px',
      backgroundColor: '#FAF7F1',
      border: '1px solid black'
    }}>
      <FeedFilterPopover open={true} handleClose={console.log("close")} id={"feed"} anchorEl={state}/>
    </div>
  )
};

export default Dev;
