import {assign} from "lodash"

const actions = {
  GET_FEEDS: 'GET_FEEDS',
  STORE_FEEDS: 'STORE_FEEDS',
  INIT_FEEDS: 'INIT_FEEDS',
  ERROR_FETCHING_FEEDS: 'ERROR_FETCHING_FEEDS',
  STORE_FEED_DATE_RANGE: 'STORE_FEED_DATE_RANGE',
  TOGGLE_LIKE: 'TOGGLE_LIKE',
  TOGGLE_LIKE_FOR_FEED_POST: 'TOGGLE_LIKE_FOR_FEED_POST',
  APPLY_FILTER: 'APPLY_FILTER',
  REMOVE_FILTER:'REMOVE_FILTER',
  SET_FILTER:'SET_FILTER',
};


const initFeedState = {
  feedPosts: [],
  page: 1,
  pageSize: 10,
  errorLoadingFeeds: false,
  loadingFeeds: false,
  endReached: false,
  filter: {date: "", event: "", tags: "", firstDate: "", lastDate: ""},
  filterApplied: false,
  filterParams: ""
};

const updateLikeForPost = (feedPosts, postId, like) => {
  return feedPosts.map(post => post.id === postId ? {
    ...post,
    isLiked: like,
    feedLikes: post.feedLikes
  } : post);
};

const toggleLikeForPost = (feedPosts, postId) => {
  return feedPosts.map(post => post.id === postId ? {
    ...post,
    isLiked: !post.isLiked,
    feedLikes: post.isLiked ? post.feedLikes - 1 : post.feedLikes + 1
  } : post);
};

export default (state = initFeedState, action) => {
  switch (action.type) {
    case actions.GET_FEEDS: {
      return {
        ...state,
        loadingFeeds: true,
      };
    }
    case actions.STORE_FEEDS: {
      const feeds = action.payload.filtering ? action.payload.feeds.results : state.feedPosts.concat(action.payload.results);
      const endReached = action.payload.next === null;
      return {
        ...state,
        loadingFeeds: false,
        page: state.page + 1,
        feedPosts: feeds,
        errorLoadingFeeds: false,
        endReached
      }
    }
    case actions.ERROR_FETCHING_FEEDS: {
      return {
        ...state,
        errorLoadingFeeds: true,
        loadingFeeds: false
      }
    }
    case actions.TOGGLE_LIKE_FOR_FEED_POST: {
      const newPost = updateLikeForPost(state.feedPosts, action.payload.data.id, action.payload.like);
      return {
        ...state,
        feedPosts: newPost
      }
    }
    case actions.TOGGLE_LIKE: {
      const newPost = toggleLikeForPost(state.feedPosts, action.payload.feedPostId);
      return {
        ...state,
        feedPosts: newPost
      }
    }
    case actions.INIT_FEEDS: {
      return {
        ...state,
        page: 1,
        feedPosts: []
      }
    }
    case actions.SET_FILTER: {
      const newFilter = {}
      newFilter[action.payload.filterType] = action.payload.value
      return {
        ...state,
        filter: assign({}, state.filter, newFilter),
      }
    }
    case actions.APPLY_FILTER: {
      return {
        ...state,
        filterApplied: true,
        filterParams: action.payload,
      }
    }
    case actions.REMOVE_FILTER: {
      return {
        ...state,
        filterApplied: false
      }
    }
    case actions.STORE_FEED_DATE_RANGE: {
      const dateRange = action.payload
      return {
        ...state,
        filter: {...state.filter, ...dateRange},
      }
    }
    default:
      return state

  }
}


export {
  actions
};
