import IconButton from "@material-ui/core/IconButton/IconButton"
import withStyles from "@material-ui/core/styles/withStyles"
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import {convertUrl} from "common/helpers"
import {get, map} from 'lodash'
import React, {useState} from 'react'
import SwipeableViews from "react-swipeable-views"
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const MaterialStyles = theme => ({
  wrapper: {
    height: '434px',
    borderRadius: 10,
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      height: '230px',
    },
  },
  message: {
    color: 'rgba(255, 255, 255, 0.64)',
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontSize: '10px'
  },
  leftArrow: {},
  rightArrow: {},
  image: {
    flex: 2,
    maxWidth: 693,
    width: '100%',
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
  },
  imageMessage: {
    background: '#EB8E6F',
    padding: '8px 8px',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
  leftButton: {
    backgroundColor: 'white',
    borderRadius: '30px 0px 0px 30px',
    display: 'flex',
    height: 58,
  },
  rightButton: {
    backgroundColor: 'white',
    borderRadius: '0px 30px 30px 0px',
    display: 'flex',
    height: 58,
    alignContent: 'center'
  },
  navButton: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: 10
  },
  cardWrapper: {
    minWidth: '450px',
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      minWidth: '200px',
    },
  },
  cardImage: {
    flex: 1,

  }
});

const SwipeCards = ({classes, cards}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const [cardIndex, setCardIndex] = useState(0);
  const cardsLength = cards.length - 1;

  const handleClick = (number) => {
    let newIndex = cardIndex + number;

    if (newIndex > cardsLength) {
      setCardIndex(0)
    } else if (newIndex < 0) {
      setCardIndex(cardsLength)
    } else {
      setCardIndex(newIndex);
    }
  };

  const renderCardText = (card) => {
    const cardText = st(get(card,'text'), get(card, 'localeText'));
    return cardText ? cardText : t('readMore');
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.navButton}>
        <div className={classes.leftButton}>
          <IconButton>
            <ArrowBackIcon style={{color: "#E0B334"}} fontSize={'large'} onClick={() => handleClick(-1)}/>
          </IconButton>
        </div>
      </div>

      <SwipeableViews
        axis={'x'}
        index={cardIndex}
        enableMouseEvents
      >
        {map(cards, card =>
          <div className={classes.cardWrapper}>
            <div className={classes.cardImage}>
              <img src={convertUrl(card.image)} className={classes.image} alt={''}/>
              {get(card, 'link') &&
              <a href={card.link} target='_blank'
                 rel="noopener noreferrer"
                 className={classes.link}>
                <div className={classes.imageMessage}>{renderCardText(card)}</div>
              </a>}
            </div>
          </div>
        )}

      </SwipeableViews>

      <div className={classes.navButton}>
        <div className={classes.rightButton}>
          <IconButton>
            <ArrowForwardIcon style={{color: "#E0B334"}} fontSize={'large'} onClick={() => handleClick(1)}/>
          </IconButton>
        </div>
      </div>
    </div>
  )
};

export default withStyles(MaterialStyles)(SwipeCards);
