import {initQuiz, initQuizAnswers, initSession, startSession, stepCompleted} from "actions/sessionAction";
import {connect} from 'react-redux';
import SessionStepWrapper from "views/session/SessionStepWrapper";


const mapStateToProps = ({session: {currentSession, quizAnswers}, auth: {user: {role}}}) => ({
  session: currentSession,
  quizAnswers,
  role
});

export default connect(mapStateToProps, {initSession, startSession, initQuiz, stepCompleted, initQuizAnswers})(SessionStepWrapper);
