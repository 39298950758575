export const actions = {
  STORE_ROOM_DETAILS: 'STORE_ROOM_DETAILS',
  CALL_ENDED: 'CALL_ENDED',
  START_VIDEO: 'START_VIDEO'
};


const initVideo = {
  callInProgress: false,
  room: {},
  channel: {}
};

export default (state = initVideo, action) => {
  switch (action.type) {
    case actions.STORE_ROOM_DETAILS :
      return {
        ...state,
        ...action.payload,
        callInProgress: true
      };
    case actions.CALL_ENDED:
      return {
        ...state,
        callInProgress: false,
        room: {}
      };
    case actions.START_VIDEO:
      return {
        ...state,
        callInProgress: true,
        channel: action.payload
      };
    default:
      return state
  }
}


