import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
// import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import React from "react";


export default function Loader({visible}) {

  return (
    <div style={{position: 'absolute', left: '50%', top: '50%',zIndex:999}}>
      {visible && <CircularProgress/>}
    </div>
  );


}
