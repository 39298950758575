import {findIndex} from 'lodash';
import React from "react";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";

var classNames = require('classnames');
require('components/styles/screeningStageBar.css');

const StageList = ({currentStageId, stages, totalPages, currentPageNumber, currentStageStatus, fetchingStage}) => {
  const {st} = useSetTranslation();
  let stageItems = [], status = "";
  for (let [index, stage] of stages.entries()) {
    if (currentStageId === stage.id) {
      if (currentStageStatus === 'COMPLETED') {
        status = "complete"
      } else {
        status = "current";
      }
    } else if (findIndex(stages, (stg) => stg.id === currentStageId) > index) {
      status = "complete";
    } else {
      status = ''
    }
    stageItems.push({
      ...stage,
      status: status,
    });
  }

  return stageItems.map((item => <StageItem name={st(item.name, item.localeName)} status={item.status} key={item.id} totalPages={totalPages}
                                            fetchingStage={fetchingStage}
                                            currentPageNumber={currentPageNumber}/>))
};


const StageItem = ({name, status, totalPages, currentPageNumber, fetchingStage}) => {
  const {t} = useTranslation();

  return (<div className={classNames('bar', {'current': status === 'current'}, {'complete': status === 'complete'})}>
    <p style={{display: 'inline'}}> {name.toUpperCase()}</p>
    {status === 'current' && totalPages && !fetchingStage &&
    <p className="pageStatus">{totalPages - currentPageNumber} <span className="steps">{t('stepsLeft')}</span></p>}
  </div>);
};


const ScreeningStageBar = ({stages, currentStageId, totalPages, currentPageNumber = 0, currentStageStatus, fetchingStage}) => {

  return (
    <div>
      <div style={{display: 'flex', backgroundColor: '#E7EDF0', justifyContent: 'center', minHeight: 60}}>
        {stages && <StageList stages={stages} currentStageId={currentStageId} currentPageNumber={currentPageNumber}
                              fetchingStage={fetchingStage}
                              currentStageStatus={currentStageStatus}
                              totalPages={totalPages}/>}
      </div>
    </div>
  )
}

export default ScreeningStageBar;
