import config from "common/config";
import {myFirebase} from "../firebase/firebase";
import {isEmpty} from "lodash";

const {API_BASE_URL} = config;
const baseUrl = API_BASE_URL.replace(/http/, 'ws')
  .replace(/https/, 'wss');

const API_PATH = baseUrl + '/ws/chat/';

export class WebSocketService {
  static instance = null;
  callbacks = {};

  constructor() {
    this.socketRef = null;
    this.previousChannelCount = 0;
  }

  static getInstance() {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  async getToken() {
    return await myFirebase.auth().currentUser.getIdToken();
  }

  connect(nickName, chatChannels) {
    this.nickName = nickName;
    if (this.socketRef && this.socketRef.readyState === 1 && this.previousChannelCount === chatChannels.length) {
      return;
    }
    this.previousChannelCount = chatChannels.length;
    this.socketRef = new WebSocket(API_PATH);
    this.socketRef.onopen = () => {
      // console.debug('Socket: WebSocket open');
      chatChannels.map(channel => this.joinChannel(channel.channelId));
      this.getUnreadMessagesCount();
    };
    this.socketRef.onmessage = e => {
      this.socketNewMessage(e.data);
    };

    this.socketRef.onerror = e => {
      console.debug('Socket: error in socket connection', e.message);
    };
    this.socketRef.onclose = () => {
      // console.debug("Socket: WebSocket closed let's reopen if user is still logged in");
      myFirebase.auth().currentUser && this.connect(this.nickName, chatChannels);
    };
    return this;
  }

  close() {
    this.socketRef && this.socketRef.close();
  }

  socketNewMessage(data) {
    // console.debug('Socket: received message ', data);
    const parsedData = JSON.parse(data);
    const command = parsedData.command;
    if (Object.keys(this.callbacks).length === 0) {
      return;
    }
    if (command === 'messages_payload') {
      this.callbacks[command](parsedData.messages, parsedData.new_page_number, parsedData.type);
    }
    if (command === 'update_last_viewed_timestamp') {
      this.callbacks[command](parsedData.data);
    }
    if (command === 'new_message' || command === 'unread_message' || command === 'reload') {
      this.callbacks[command](parsedData.messages);
    }
    return this;
  }

  async getUnreadMessagesCount() {
    await this.sendMessage({command: 'unread_message'});
    return this;
  }

  async fetchMessages() {
    await this.sendMessage({command: 'fetch_messages'});
    return this;
  }

  async joinAndFetchMessagesForChannel(channelId, pageNumber, messageId) {
    await this.joinChannel(channelId);
    await this.fetchMessagesForChannel(channelId, pageNumber, messageId);
    this.channelId = channelId;
  }

  async fetchMessagesForChannel(channelId, pageNumber, messageId) {
    await this.sendMessage({command: 'get_room_chat_messages', channel: channelId, page_number: pageNumber, message_id: messageId});
    this.channelId = channelId;
    return this;
  }

  async newChatMessage(message) {
    await this.sendMessage({command: 'send', message: message.text, channel: message.channelId, attachments: message.attachments, tempId: message.tempId});
    return this;
  }

  async joinChannel(channelId) {
    // console.debug('Socket: joining channel ', channelId);
    await this.sendMessage({command: 'join', channel: channelId});
    return this;
  }

  async leaveChannel(channelId) {
    // console.debug('Socket: leaving channel ', channelId);
    await this.sendMessage({command: 'leave', channel: channelId});
    return this;
  }


  addCallbacks(messagesCallback, newMessageCallback, unreadMessagesCallback, updateLastViewedTime) {
    this.callbacks['messages_payload'] = messagesCallback;
    this.callbacks['new_message'] = newMessageCallback;
    this.callbacks['unread_message'] = unreadMessagesCallback;
    this.callbacks['update_last_viewed_timestamp'] = updateLastViewedTime;
    return this;
  }

  addCallback(command, callback) {
    this.callbacks[command] = callback;
  }

  async handleAttachmentData(data, messageWithAttachment) {
    const attachmentsData = []
    // Map attachments to an array of Promises for file reading
    const fileReadPromises = data.attachments.map((attachment) => {
      if (attachment?.url) {
        return attachmentsData.push({
          name: attachment.name,
          type: attachment.type,
          url: attachment.url,
        });
      }
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          attachmentsData.push({
            name: attachment.name,
            type: attachment.type,
            data: reader.result.split(',')[1], // Extracting base64 data from the result
          });
          resolve();
        };

        // Handle errors during file reading
        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(attachment);
      });
    });

    // Wait for all file reading promises to resolve before sending the message
    await Promise.all(fileReadPromises);

    messageWithAttachment.attachments = attachmentsData;
  }

  async sendMessage(data) {
    try {
      await this.ensureSocketConnection();
      const idToken = await this.getToken();
      const messageWithAttachment = {
        ...data,
        authToken: idToken,
        nickName: this.nickName,
        attachments: [], // Replace attachment with its metadata
      };
      if (isEmpty(data.attachments)) {
        this.socketRef.send(JSON.stringify(messageWithAttachment));
        return
      }

      await this.handleAttachmentData(data, messageWithAttachment)

      this.socketRef.send(JSON.stringify(messageWithAttachment));
    } catch (err) {
      console.debug('Socket: error sending message', data, err.message);
      throw err;
    }
  }

  async ensureSocketConnection() {
    const socket = this.socketRef;

    async function wait(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms);
      });
    }

    const timeToWait = 5000;
    const pollInterval = 10;
    let waitedTime = 0;

    while (socket.readyState !== 1 && waitedTime < timeToWait) {
      await wait(pollInterval);
      waitedTime += pollInterval;
    }

    if (socket.readyState === 1) return;
    throw new Error('Cannot connect to socket');
  }

  async onMemberMessageSeen(channelId) {
    await this.sendMessage({ command: "message_seen", channel: channelId });
    return this;
  }
}

let WebSocketInstance;
export const initChatInstance = () => {
  WebSocketInstance = WebSocketService.getInstance();
};


export const getChatInstance = () => WebSocketInstance;
