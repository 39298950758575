import {isEmpty} from 'lodash';
import React from 'react';

const styles = ({
  errorMessage: {
    marginTop: 2,
    marginLeft: 16,
    fontSize: 12,
    color: 'red'
  },
});


const QuestionErrorMessage = ({error}) => {
  const errorMessage = <p style={styles.errorMessage}>{error}</p>;

  return (
    <div>
      {!isEmpty(error) && errorMessage}
    </div>
  );
};

export default QuestionErrorMessage;

