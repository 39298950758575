import request from "api/request";

export const fetchFeed = (params, auth) => {
  const url = `api/v2/learninghub/feed/?${params}`;
  return request({
    url: url,
    method: 'GET',
    auth
  });

};


export const feedDateRange = (auth) => {
  const url = 'api/v2/learninghub/feed/daterange/';
  return request({
    url: url,
    method: 'GET',
    auth
  })
};

export const updateLike = async (feedPostId, auth) => request({
  url: 'api/v2/learninghub/feed/toggle-like/',
  method: 'POST',
  data: {
    id: feedPostId
  },
  auth
});


export const feedLinkClickCount = async (feedPostId, auth) => request({
  url: 'api/v2/learninghub/feed/click/',
  method: 'POST',
  data: {
    id: feedPostId
  },
  auth
});






