import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import {useSetTranslation} from "common/useSetTranslation";


const styles = theme => ({
  questionPrefix: {
    fontSize: 16,
    color: '#BBBBBB',
    fontWeight: 'bold',
  },
  question: {
    fontSize: 16,
    color: '#000000',
    fontWeight: 'bold'
  },
  localeQuestion: {
    fontSize: 16,
    color: '#000000',
  }
});

const QuizQuestion = ({classes, question = "", localeQuestion = "", questionPrefix = "Q1", style}) => {
  const {st} = useSetTranslation();

  return (
    <div style={style}>
      <p className={classes.questionPrefix}>{questionPrefix}</p>
      <p className={classes.question}>{st(question, localeQuestion)}</p>
    </div>
  );
};


export default withStyles(styles)(QuizQuestion);
