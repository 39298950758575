import {withStyles} from "@material-ui/core"
import {Container} from '@material-ui/core/'
import Button from "@material-ui/core/Button/Button"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid/Grid"
import Question from "components/Question"
import {isEmpty, trim} from 'lodash'
import React, {useEffect, useState} from "react"
import EmailIcon from "./emailIcon.svg"
import GoogleLogo from "./googleLogo.svg"
import UnderMaintenance from "views/UnderMaintenance";
import background from './signInBackgroundImage.jpg';
import logo from '../../common/assets/logo-with-name.png';
import {useTranslation} from "react-i18next";
import ChatwootWidget from "components/ChatwootWidget";

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    height: 600
  },
  image: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    display: 'block'
  },
  button: {
    backgroundColor: '#E0B334',
    boxShadow: '0px 2px 4px rgba(224, 179, 52, 0.25)',
    color: 'white',
    fontSize: 12
  },
  formWrapper: {
    position: 'relative',
    paddingLeft: 70,
    paddingRight: 70,
    marginTop: 40,
    zIndex: 10,
  },
  error: {
    color: 'red',
    textAlign: 'center'
  },
  resetPassword: {
    fontSize: 12,
    fontStyle: 'italic',
    color: '#728D9A',
    marginTop: 4,
    marginBottom: 4,
  }
};

const MaterialStyles = theme => ({
  formWrapper: {
    paddingLeft: 70,
    paddingRight: 70,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    marginTop: 40,
    backgroundColor: 'white',
  }
});



const SignIn = ({emailSignIn, googleSignIn, loginError, loginErrorMessage, history, classes, resetErrorMessage, inMaintenance = false}) => {
  const {t} = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const emailQuestion = {
    "label": t('emailLabel'),
    "questionType": "Text",
  }

  const passwordQuestion = {
    "label": t('passwordLabelCaps'),
    "questionType": "Password",
  }

  useEffect(() => {
    resetErrorMessage()
  }, [])

  const isEmailValid = (email) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      setEmailError(false);
      return (true)
    }
    setEmailError(true);
    return (false)
  };

  const isPasswordValid = (password) => {
    if (!isEmpty(password)) {
      setPasswordError(false);
      return true;
    } else {
      setPasswordError(true);
      return false;
    }
  }

  const handleSubmit = () => {
    if (isEmailValid(email) && isPasswordValid(password)) {
      emailSignIn(email, password);
    }
  };

  const _SignIn = <div style={{backgroundColor: '#E5E5E5', height: '100vh', display: 'flex', alignItems: 'center'}}>
    <Container maxWidth={"md"} disableGutters={true}>
      <Grid container style={styles.container}>
        <Grid item xs={12} sm={5} md={6} className={"signInimageBox"}>
          <div className={"signinImage"} style={{backgroundImage: `url(${background})`}}>
          </div>
        </Grid>
        <Grid item xs={12} sm={7} md={6} className={classes.formWrapper}>
          <Grid container alignItems="center" justify="center">
            <img src={logo} style={{width: 145}}/>
          </Grid>
          <Grid item sm={12} md={12} style={{marginTop: 70}}>
            <div>
              <div>
                <Question question={emailQuestion} onAnswered={(value) => setEmail(trim(value.toLowerCase()))}
                          value={email}
                          error={emailError}/>
                <Question question={passwordQuestion} onAnswered={(p) => setPassword(p.target.value)}
                          value={password}
                          error={passwordError}/>
                <Button onClick={() => history.push('/resetpassword')} style={styles.resetPassword}>{t('signInPasswordHelpTextForgotPassword')} {t('signInPasswordHelpTextResetNow')}</Button>
                <Button variant="contained" style={styles.button} onClick={handleSubmit} fullWidth>
                  {t('submit')}
                </Button>
                {(loginError && <h5 style={styles.error}>{loginErrorMessage}</h5>) || <h5><br/></h5>}
                <Divider style={{marginTop: 40}}/>
                <div className={'mobile-signin-label'}>{t('signInUsing')}</div>
                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                  <Button variant="contained" style={{backgroundColor: 'white', marginTop: 20, fontSize: 10}}
                          onClick={() => googleSignIn(email, history)}>
                    <img src={GoogleLogo} style={{marginRight: 5}}/> <span className={'gBtn'}/>
                  </Button>
                  <Button variant="contained" style={{backgroundColor: 'white', marginTop: 20, fontSize: 10}}
                          onClick={() => history.push('/emailverify')}
                  >
                    <img src={EmailIcon} style={{marginRight: 5}}/> <span className={'eBtn'}/>
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </div>;

  return inMaintenance ? <UnderMaintenance/> : _SignIn;
};

export default withStyles(MaterialStyles)(SignIn);
