import {apiAction, dataAction, silentApiAction, staticAction} from "actions/actionWrappers"
import {feedDateRange, feedLinkClickCount, fetchFeed, updateLike} from "api/feeds"
import _, {join} from "lodash"
import {actions} from "reducers/feedReducer"

const initFeeds = () => async (dispatch) => {
  await dispatch(staticAction(actions.INIT_FEEDS));
  await dispatch(getNextFeeds());
  await dispatch(getDateFilterRange())
};

const getNextFeeds = () => async (dispatch, getState) => {
  const {feed} = await getState();
  const {page, pageSize, loadingFeeds, filterApplied, filterParams} = feed;

  if (loadingFeeds) return;

  dispatch(staticAction(actions.GET_FEEDS));
  let params = filterApplied ? [...filterParams] : [];
  params.push(`page=${page}`);
  params.push(`size=${pageSize}`);
  const filterParameters = join(params, '&');
  try {
    const feeds = await dispatch(silentApiAction(fetchFeed, filterParameters));
    dispatch(dataAction(actions.STORE_FEEDS, feeds));
  } catch (e) {
    dispatch(staticAction(actions.ERROR_FETCHING_FEEDS));
  }
};

const toggleLike = (feedPostId) => async dispatch => {
  try {
    dispatch(dataAction(actions.TOGGLE_LIKE, {feedPostId}));
    const result = await dispatch(silentApiAction(updateLike, feedPostId));
    dispatch(dataAction(actions.TOGGLE_LIKE_FOR_FEED_POST, result));
  } catch (e) {
    console.log("Error in Like", e)
  }
};


const postFeedLinkClick = (feedPostId) => async dispatch => {
  try {
    dispatch(silentApiAction(feedLinkClickCount, feedPostId))
  } catch (e) {
    console.log("Could not update click count", e);
  }
};


const setFeedFilter = (filterType, value) => dispatch => {
  dispatch(dataAction(actions.SET_FILTER, {filterType, value}))
}


const applyFeedFilter = () => async (dispatch, getState) => {
  const {feed: {filter, page, pageSize}} = await getState()
  const {event, date, tags} = filter

  let params = []
  event.map(e => {
    if (e === "Quotes") {
      params.push("event=quotefeed")
    } else if (e === "Post") {
      params.push("event=postfeed")
    }
  })

  if (_.includes(params, "event=quotefeed") && _.includes(params, "event=postfeed")) {
    params = []
  }

  if (!_.isEmpty(date.fromDate)) {
    console.log("date",date)
    params.push(`from=${date.fromDate}`)
    params.push(`to=${date.toDate}`)
  }

  dispatch(staticAction(actions.INIT_FEEDS))
  await dispatch(dataAction(actions.APPLY_FILTER, params))
  dispatch(getNextFeeds())
}


const getDateFilterRange = () => async dispatch => {
  const range = await dispatch(apiAction(feedDateRange))
  dispatch(dataAction(actions.STORE_FEED_DATE_RANGE, range))
}


export {
  initFeeds,
  getNextFeeds,
  toggleLike,
  postFeedLinkClick,
  setFeedFilter,
  applyFeedFilter,
  getDateFilterRange
}
