import IconButton from "@material-ui/core/IconButton/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PageContent from "components/PageContent";
import PageTitle from "components/PageTitle";
import {get} from 'lodash';
import React from "react";
import { useSetTranslation } from "common/useSetTranslation";

export default function Page({pageErrors, submitSurveyStage, pageItems, nextPage, previousPage, setAnswer, answers, isLastPage, fetchingStage, existingAnswers, isSurveySubmitting}) {
  const {st} = useSetTranslation();
  const questions = get(pageItems, "questions");
  const pageTitle = st(get(pageItems, "title"), get(pageItems, "localeTitle"));
  const pageDescription = st(get(pageItems, "description"), get(pageItems, "localeDescription"));

  return (
    <div className={"page"}>
      <div style={{position: 'absolute'}}>
        <IconButton>
          <ArrowBackIcon style={{color: "#52B2E2"}} fontSize={'large'} onClick={previousPage}/>
        </IconButton>
      </div>
      <PageTitle title={pageTitle} description={pageDescription}/>
      <PageContent isLastPage={isLastPage} questions={questions} nextPage={nextPage} setAnswer={setAnswer}
                   fetchingStage={fetchingStage}
                   answers={answers} pageErrors={pageErrors} submitSurveyStage={submitSurveyStage} existingAnswers={existingAnswers} isSurveySubmitting={isSurveySubmitting} />
    </div>
  );
};
