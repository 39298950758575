import AgreeabilityScale from "components/AgreeabilityScale";
import DateSelection from "components/DateSelection";
import DateTimeSelection from "components/DateTimeSelection";
import ImageResponse from "components/ImageResponse";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import NumberInput from "components/NumberInput";
import SimpleDropdown from "components/SimpleDropdown"
import SingleChoiceButton from "components/SingleSelectButton";
import SingleSelectDropDown from "components/SingleSelectDropdown";
import TextBox from "components/TextBox";
import Video from "components/Video";
import {get} from 'lodash';
import React from 'react';
import PasswordInput from "../PasswordInput";
import TextInput from "../TextInput";
import QuestionWrapper from "./QuestionWrapper";
import ImageFileUpload from "components/ImageFileUpload";
import Scale from "components/Scale";
import DocumentUpload from "../DocumentUpload";


const QuestionWidget = (props) => {
  switch (props.question.questionType) {
    case 'Text':
      return (<TextInput {...props} autoComplete={props.question.autocomplete}/>);
    case 'Password':
      return (<PasswordInput {...props}/>);
    case 'Number':
      return (<NumberInput {...props} autoComplete={props.question.autocomplete}/>);
    case 'TextBox':
      return <TextBox  {...props}/>;
    case 'SingleChoiceRadio':
      return <SingleChoiceButton {...props}/>;
    case 'SingleChoiceDropDown':
      return <SingleSelectDropDown {...props} DropDownItems={props.question.answerOptions}/>;
      case 'SimpleDropDown':
      return <SimpleDropdown {...props} DropDownItems={props.question.answerOptions}/>;
    case 'MultiChoiceDropDown':
      return <MultiSelectDropdown {...props} DropDownItems={props.question.answerOptions}/>;
    case 'SingleChoiceButton':
      return <SingleChoiceButton {...props}/>;
    case 'Date':
      return <DateSelection {...props}/>;
    case 'DateTime':
      return <DateTimeSelection {...props}/>;
    case 'AgreeabilityThreeOptions':
      return <AgreeabilityScale {...props} maximumValue={3}/>;
    case 'AgreeabilityFiveOptions':
      return <AgreeabilityScale {...props} maximumValue={5}/>;
    case 'AgreeabilitySevenScale':
      return <AgreeabilityScale {...props} maximumValue={7}/>;
    case 'AgreeabilityTenScale':
      return <AgreeabilityScale {...props} maximumValue={10}/>;
    case 'Scale':
      return <Scale {...props} />
    case 'ImageResponse':
      return <ImageResponse {...props}/>;
    case 'SingleImageUpload':
      return <ImageFileUpload {...props}/>;
    case 'YoutubeVideo':
      return (<Video
        videoId={get(props.question, 'answerOptions[0].key')}
      />);
    case 'Document':
      return <DocumentUpload {...props} />;

    default:
      return (<p>Can not render! {props.question.questionType}</p>);
  }
};


function Question(props) {
  const {question, onAnswered, value, labelStyling = {},  error} = props;
  return (
    <QuestionWrapper question={question} labelStyling={labelStyling} error={error}>
      <QuestionWidget question={question} value={value} onAnswered={onAnswered} error={error}/>
    </QuestionWrapper>
  );
}

export default Question;


