import {initSessions, toggleBookmark} from "actions/sessionAction";
import Sessions from "components/Sessions";
import {connect} from 'react-redux';


const mapStateToProps = ({session: {allSessions, mySessions}}) => ({
  allSessions,
  mySessions
});

export default connect(mapStateToProps, {initSessions, toggleBookmark})(Sessions);
