import {initApp} from "actions/authAction";
import {applyMiddleware, compose, createStore} from "redux";
import {persistStore} from "redux-persist";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default function configureStore(persistedState) {
  const store = createStore(
    rootReducer,
    persistedState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
  store.dispatch(initApp());

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}

