import {withStyles} from "@material-ui/core";
import {Container} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid/Grid";
import {isEmailValid} from "common/helpers";
import {isEmpty} from 'lodash';
import React, {useState} from "react";
import SwipeableViews from 'react-swipeable-views';
import OTP from "../../components/OTP";
import Question from '../../components/Question/index';
import background from './signInBackgroundImage.jpg';
import logo from '../../common/assets/logo-with-name.png';
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

const passwordSheriff = require('password-sheriff');
const PasswordPolicy = passwordSheriff.PasswordPolicy;
const charsets = passwordSheriff.charsets;
const lengthPolicy = new PasswordPolicy({length: {minLength: 8}});
const containsPolicy = new PasswordPolicy({contains: {
    expressions: [charsets.upperCase, charsets.numbers, charsets.lowerCase, charsets.specialCharacters]
  }});

const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex'
  },
  image: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  button: {
    marginTop: 31,
    backgroundColor: '#000000',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontSize: 12
  },
  buttonYellow: {
    marginTop: 31,
    backgroundColor: '#E0B334',
    boxShadow: '0px 2px 4px rgba(224, 179, 52, 0.25)',
    color: 'white',
    fontSize: 12
  },
  error: {
    color: 'red',
    textAlign: 'center'
  },
  passwordPolicy: {
    fontSize: 11,
    color: 'rgb(114, 141, 154)',
    listStylePosition: 'inside'
  }
};

const MaterialStyles = theme => ({
  formWrapper: {
    paddingLeft: 70,
    paddingRight: 70,
    paddingBottom: 82,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    marginTop: 40,
    position: 'relative',
    backgroundColor: 'white',
  }
});

const EmailVerification = ({authStep, verifyEmail, emailOtpError, otpVerificationStatus, validateOtp, setProfilePassword, classes}) => {
  const {t} = useTranslation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const emailQuestion = {
    "title": t('emailTitle'),
    "label": t('emailLabel'),
    "questionType": "Text",
    "helpText": t('emailHelpText')
  }

  const passwordQuestion = {
    "title": t('passwordTitle'),
    "label": t('passwordLabel'),
    "questionType": "Password",
  }
  const confirmPasswordQuestion = {
    "label": t('confirmPasswordLabel'),
    "questionType": "Password",
  }

  const handleEmailSubmit = () => {
    if (isEmailValid(email)) {
      setEmailError(false);
      verifyEmail(email);
    } else {
      setEmailError(true);
    }
  };

  const resendOtp = () => {
    verifyEmail(email);
  };

  const isPasswordValid = () => {
    try {
      lengthPolicy.assert(password);
      containsPolicy.assert(password);
    } catch (policyException) {
      return false;
    }
    return !isEmpty(password) && !isEmpty(confirmPassword) && (password === confirmPassword);
  };


  return (
    <div style={{backgroundColor: '#E5E5E5', height: '100vh', display: 'flex', alignItems: 'center'}}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container style={styles.container}>
          <Grid item xs={12} sm={5} md={6} className={"signInimageBox"}>
            <div className={"signinImage"} style={{backgroundImage: `url(${background})`}}>
            </div>
          </Grid>
          <Grid item xs={12} sm={7} md={6} className={classes.formWrapper}>
            <Grid container alignItems="center" justify="center">
              <img src={logo} style={{width: 145}} alt={''}/>
            </Grid>
            <Grid item sm={12} md={12} style={{marginTop: 70}}>
              <SwipeableViews
                axis={'x'}
                index={authStep}
                enableMouseEvents
              >
                <div>
                  <Question question={emailQuestion} onAnswered={(value) => setEmail(value.toLowerCase())} value={email}
                            error={emailError}/>
                  <Button variant="contained" style={styles.button} fullWidth onClick={handleEmailSubmit}>
                    SEND OTP
                  </Button>
                  {emailOtpError && <h5 style={styles.error}>{t('emailAlreadySignedUp')} {t('please')} <Link
                    to={'./signin'}>{t('login')}</Link> {t('or')} <Link to={'./resetpassword'}>{t('resetPassword')}</Link> {t('ifForgotten')}</h5>}
                </div>

                <OTP onAnswered={(otp) => validateOtp(email, otp)} error={!otpVerificationStatus} resendOtp={resendOtp}
                     currentStep={authStep === 1}/>

                <div>
                  <div style={styles.passwordPolicy}>
                    {t('passwordPolicyTitle')}
                    <ul>
                      <li>{t('passwordPolicyRequirementsLength')}</li>
                      <li>{t('passwordPolicyRequirementsLowerCase')}</li>
                      <li>{t('passwordPolicyRequirementsUpperCase')}</li>
                      <li>{t('passwordPolicyRequirementsNumeral')}</li>
                      <li>{t('passwordPolicyRequirementsSpecial')}</li>
                    </ul>
                  </div>
                  <Question question={passwordQuestion} onAnswered={(e) => setPassword(e.target.value)}/>
                  <Question question={confirmPasswordQuestion} onAnswered={(e) => setConfirmPassword(e.target.value)}/>
                  <Button variant="contained" style={styles.buttonYellow} fullWidth disabled={!isPasswordValid()}
                          onClick={() => setProfilePassword(password)}>
                    Submit
                  </Button>
                </div>
              </SwipeableViews>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default withStyles(MaterialStyles)(EmailVerification);

