import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { get, map } from 'lodash';
import Avatar from "@material-ui/core/Avatar/Avatar";
import { useTranslation } from "react-i18next";
import ChatIcon from "common/assets/Chat.svg";
import DownloadIcon from "common/assets/download-icon.svg";
import classNames from "classnames";
import SadEmoji from "common/assets/emojis/sad.png";
import ConfusedEmoji from "common/assets/emojis/confused.png";
import NeutralEmoji from "common/assets/emojis/neutral.png";
import GrinningEmoji from "common/assets/emojis/grinning.png";
import CelebrateEmoji from "common/assets/emojis/celebrate.png";

const emojiList = [
  SadEmoji, ConfusedEmoji, NeutralEmoji, GrinningEmoji, CelebrateEmoji];

const styles = () => ({
  ListMemberWrapper: {
    display: 'flex',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
    padding: '12px 16px',
    alignItems: 'center',
  },
  wrapper: {
    background: '#E0B334',
    borderTop: '0.5px solid rgba(0, 0, 0, 0.15)',
    height: '80vh',
    flex: 0.8,
  },
  mediumAvatar: {
    width: 48,
    height: 48
  },
  name: {
    marginLeft: 12,
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  },
  closeBtn: {
    fontWeight: 'bold',
    color: '#fff',
    fontSize: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 102,
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
  },
  historyWrapper: {
    background: '#ffffff',
    borderTop: '0.5px solid rgba(0, 0, 0, 0.15)',
    height: '80vh',
    flex: 1.2,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '10px',
    fontWeight: 'bold',
    fontSize: 14,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    borderBottom: '1px solid #ddd',
    position: 'relative',
  },
  cellText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#000000',
  },
  cellFirstText: {
  },
  cellOtherText: {
    flex: 1,
  },
  cellLastText: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  fadedEmoji: {
    opacity: 0.5,
  },
  headerText: {
    textAlign: 'right',
    fontSize: 10,
    fontWeight: 400,
    color: '#00000082',
  },
  headerFirstText: {
    flex: 2,
    textAlign: 'left',
  },
  headerOtherText: {
    flex: 1,
  },
  tableContainer: {
    padding: 20,
    maxWidth: 600,
    margin: '0 auto',
  },
  button: {
    cursor: 'pointer',
    color: '#007BFF',
    border: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
  },
  emojiPopup: {
    position: 'absolute',
    top: -50,
    right: 0,
    background: '#ffffff',
    borderRadius: 30,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '0px 2px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'fit-content',
    maxWidth: 300,
    overflow: 'hidden',
    zIndex: 10,
  },
  emoji: {
    fontSize: '24px',
    cursor: 'pointer',
    padding: '0 5px',
    whiteSpace: 'nowrap',
  },
  noDataMessage: {
    textAlign: 'center',
    marginTop: 15,
    fontSize: 14,
    fontWeight: 'bold',
  },
  accordianTitle : {
    fontSize: 14,
    fontWeight: '400',
    color: 'rgba(0, 0, 0, 0.51)',
    margin: 0,
  },
  accordianContainer: {
    marginTop: 0,
    marginBottom: 0,
    minHeight: 50,
  },
  accordion: {
    minHeight: 50,
    margin: 0,
  },
  accordionCollapsed: {
    backgroundColor: '#E0B334',
  },
  accordionExpanded: {
    backgroundColor: '#e0e0e0',
  },
  '@global': {
    '.MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '.MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 50,
    }
  },
  accordionContent: {
    margin: 0,
    padding: 0,
  },
  smallScreenContainer: {
    padding: 20,
    margin: 0,
    width: '100%',
  },
  emojiImage: {
    width: 16,
    height: 16,
  },
});

function ChannelHistory({ handleRateClick, handleEmojiSelect, showPopup, classes, chatChannelHistory, hasChannelHistoryRating, isSmallScreen }) {
  const channelHistory = chatChannelHistory?.chatMeetingHistory;
  return (
    <div className={classNames(classes.tableContainer, isSmallScreen && classes.smallScreenContainer)}>
      {channelHistory.length !== 0 &&
        <div className={classes.header}>
          <div className={classNames(classes.headerText, classes.headerFirstText)}>Call Date & Time</div>
          <div className={classNames(classes.headerText, classes.headerOtherText)}>Summary</div>
          <div className={classNames(classes.headerText, classes.headerOtherText)}>Transcript</div>
          <div className={classNames(classes.headerText, classes.headerOtherText)}>Rate</div>
        </div>
      }

      {channelHistory.length === 0 ? (
        <div className={classes.noDataMessage}>No Meeting History available</div>
      ) : (
        channelHistory.map((chatChannel, index) => (
          <div key={index}>
            <div className={classes.row}>
              <div className={classNames(classes.cellText, classes.cellFirstText)}>{chatChannel.meetingDatetime}</div>
              <a href={chatChannel.summaryTextFileUrl} target="_blank" download rel="noreferrer">
                <button className={classNames(classes.button, classes.cellOtherText)}>
                  <img src={DownloadIcon} alt={''} />
                </button>
              </a>
              <a href={chatChannel.transcriptionTextFileUrl} target="_blank" download rel="noreferrer">
                <button className={classNames(classes.button, classes.cellOtherText)}>
                  <img src={DownloadIcon} alt={''} />
                </button>
              </a>
              <div className={classNames(classes.cellText, classes.cellLastText, !hasChannelHistoryRating(chatChannel) && classes.fadedEmoji)} onClick={() => handleRateClick(index)}>
                <img className={classes.emojiImage} src={hasChannelHistoryRating(chatChannel) ? emojiList[chatChannel.rating - 1] : emojiList[0]} alt="rating"/>
              </div>

              {showPopup === index && (
                <div className={classes.emojiPopup}>
                  {emojiList.map((emoji, emojiIndex) => (
                    <span key={emojiIndex} onClick={() => handleEmojiSelect(emojiIndex, chatChannel?.meetingRecordingId)} className={classes.emoji}>
                      <img src={emoji} alt={emoji} className={classes.emojiImage} />
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

function MemberItem({ member, isSelf, classes  }) {
  return (
    <div className={classes.ListMemberWrapper}>
      <div>
        <Avatar alt={get(member, "userFullName")} src={get(member, 'displayPicture')}
          className={classes.mediumAvatar} />
      </div>

      <div className={classes.name}>
        {get(member, "userFullName")}
      </div>
      {(!isSelf) &&
        <div style={{ marginLeft: 'auto', marginRight: 16, cursor: 'pointer' }}>
          <img src={ChatIcon} alt={''} />
        </div>
      }
    </div>
  )
};

function GroupMemberList({
  classes, members, onClose, myId, onChatClick, chatChannelHistory, storeMeetingSummaryRating, isSmallScreen
}) {
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState(null);
  const [expandedPanel, setExpandedPanel] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const handleRateClick = (callId) => {
    setShowPopup((prevPopup) => (prevPopup === callId ? null : callId));
  };

  const handleEmojiSelect = (callId, meetingRecordingId) => {
    const newCallRates = chatChannelHistory?.chatMeetingHistory;
    const meetingRecording = newCallRates.find(r => r.meetingRecordingId === meetingRecordingId);

    if (meetingRecording) {
      meetingRecording['rating'] = callId + 1;

      const ratingsPayloadData = {
        meeting_recording_id: meetingRecordingId,
        user_id: myId,
        meeting_rating: callId + 1,
      };

      storeMeetingSummaryRating(ratingsPayloadData);
    }
    setShowPopup(null);
  };

  const hasChannelHistoryRating = (chatChannel) => {
    return chatChannel?.rating && chatChannel?.rating >= 0;
  };

  return (
    isSmallScreen ? (
      <div>
        <Accordion
          className={classNames(
            classes.accordion,
            expandedPanel === 'members' ? classes.accordionExpanded : classes.accordionCollapsed
          )}
          onChange={handleAccordionChange('members')}
        >
          <AccordionSummary className={classes.accordianContainer} expandIcon={<ExpandMoreIcon />}>
            <h2 className={classes.accordianTitle}>View Members</h2>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <div>
              {map(members, member => (
                <MemberItem
                  member={member}
                  isSelf={member.userId === myId}
                  classes={classes}
                  onChatClick={onChatClick}
                />
              ))}
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classNames(
            classes.accordion,
            expandedPanel === 'summary' ? classes.accordionExpanded : classes.accordionCollapsed
          )}
          onChange={handleAccordionChange('summary')}
        >
          <AccordionSummary className={classes.accordianContainer} expandIcon={<ExpandMoreIcon />}>
            <h2 className={classes.accordianTitle}>View Summary & Transcript</h2>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            <ChannelHistory
              handleRateClick={handleRateClick}
              handleEmojiSelect={handleEmojiSelect}
              showPopup={showPopup}
              classes={classes}
              chatChannelHistory={chatChannelHistory}
              hasChannelHistoryRating={hasChannelHistoryRating}
              isSmallScreen={isSmallScreen}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {map(members, member => (
            <MemberItem
              member={member}
              isSelf={member.userId === myId}
              classes={classes}
              onChatClick={onChatClick}
            />
          ))}
          <div className={classes.closeBtn} onClick={onClose}>{t("CLOSE")}</div>
        </div>
        <div className={classes.historyWrapper}>
          <ChannelHistory
            handleRateClick={handleRateClick}
            handleEmojiSelect={handleEmojiSelect}
            showPopup={showPopup}
            classes={classes}
            chatChannelHistory={chatChannelHistory}
            hasChannelHistoryRating={hasChannelHistoryRating}
          />
          <div className={classes.closeBtn} onClick={onClose}>{t("CLOSE")}</div>
        </div>
      </div>
    )
  );
};

export default withStyles(styles)(GroupMemberList);

GroupMemberList.propTypes = {};

GroupMemberList.defaultProps = {};
