import {withStyles} from "@material-ui/core";
import LikeIcon from '@material-ui/icons/Favorite';
import {convertUrl, parseUrl} from "common/helpers";
import {get} from 'lodash';
import React from 'react';
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const styles = {
  mtp: {
    marginTop: 8,
    color: '#E0B334',
    fontWeight: 'bold',
    fontSize: 12,
    textDecoration: 'none',
  }
};


const MaterialStyles = theme => ({
  postContainer: {
    display: 'flex',
  },
  postImage: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  postDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    minHeight: 200,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    paddingRight: 8,
    paddingTop: 4,
    paddingLeft: 16
  },
  postType: {
    fontSize: 12,
    backgroundColor: "#ffffff",
    borderRadius: 10,
    color: '#E0B334',
    fontWeight: 'bold',
    padding: '2px 6px',
    display: 'inline'
  },
  likeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 12,
  },
  sourceText: {
    textDecoration: 'none',
    fontSize: 12,
    color: '#AFAFAF',
  },
  postTitle: {
    textDecoration: 'none',
    marginTop: 8,
    fontSize: 14,
    fontWeight: '400',
    color: '#000000'
  },
  imageWrapper: {
    display: 'flex',
    maxHeight: '150px',
    alignSelf: 'flex-start',
    paddingTop: '8px'
  },
  image: {
    objectFit: 'contain',
    display: 'block',
    width: '100%',
  },
  postDetails: {
    marginTop: 8,
    fontSize: 10,
    color: '#747474'
  },

});

const DefaultPost = ({post, toggleLike, onClickCount, classes}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const {feedLikes, isLiked, id} = post;
  const {thumbnail, description, localeDescription, link, title, localeTitle, mtCuratedNote} = get(post, "contentObject");

  const linkHost = get(parseUrl(link), 'hostname', '');

  return (
    <div className={classes.postContainer}>
      <div className={classes.postImage}>
        <div className={classes.imageWrapper}>
          {thumbnail && <img src={convertUrl(thumbnail)} className={classes.image}/>}
        </div>
      </div>
      <div className={classes.postDetailContainer}>
        <a href={link} onClick={() => onClickCount(id)} target="_blank" className={classes.sourceText}>{linkHost}</a>
        <a href={link} onClick={() => onClickCount(id)} target="_blank" className={classes.postTitle}>{st(title, localeTitle)}</a>
        {mtCuratedNote && <a href={link} style={styles.mtp}>{t('fromMentorTogetherTeam')}</a>}
        <p className={classes.postDetails}>{st(description, localeDescription)}</p>
        <div className={classes.likeContainer}>
          <LikeIcon
            style={{fontSize: 14, color: isLiked ? '#E0B334' : '#E5E5E5', cursor: 'pointer'}}
            onClick={() => toggleLike(id)}/>
          <span style={{
            color: isLiked ? '#E0B334' : '#E5E5E5',
            fontWeight: 'bold',
            fontSize: 10,
            marginLeft: 4,
          }}> {feedLikes === 0 ? "" : feedLikes}</span>
        </div>
      </div>
    </div>
  );
};

export default withStyles(MaterialStyles)(DefaultPost);

