import {initSessions, toggleBookmark} from "actions/sessionAction";
import {connect} from 'react-redux';
import AllSessions from "views/AllSessions";


const mapStateToProps = ({session: {allSessions, mySessions}}) => ({
  allSessions,
  mySessions
});

export default connect(mapStateToProps, {toggleBookmark, initSessions})(AllSessions);
