import Paper from "@material-ui/core/Paper/Paper"
import withStyles from "@material-ui/core/styles/withStyles"
import FilterListIcon from '@material-ui/icons/FilterList'
import {PAGE_HEIGHT_WITH_NAVIGATION_CONTENT_CONTAINER} from "common/helpers"
import FeedFilterPopover from "components/FeedFilterPopover"
import DefaultPost from "components/PostTypes/DefaultPost"
import QuotePost from "components/PostTypes/QuotePost"
import {map} from 'lodash'
import React, {useEffect} from "react"
import InfiniteScroll from 'react-infinite-scroll-component'
import {useTranslation} from "react-i18next";

const styles = theme => ({
  sessionContainer: {
    width: '600px',
  },
  feeds: {
    height: PAGE_HEIGHT_WITH_NAVIGATION_CONTENT_CONTAINER,
    overflowY: 'auto',
  },
  feedWrapper: {
    padding: '0px 15px',
  },
  header: {
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 24,
  },
  headerText: {
    color: '#766430',
    fontSize: '18px',
    padding: '0px 15px',
  },
  filterBtn: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 38,
    padding: '6px 16px',
    fontSize: 12,
    cursor: 'pointer',
  }
});

const FeedPostItem = (props) => {
  switch (props.type) {
    case "post":
      return <DefaultPost {...props}/>;

    case "quote":
      return <QuotePost {...props}/>;

    default:
      return <p>We do not support this typeof of post currently</p>
  }
};

const Feeds = ({classes, feedPosts = [], getNextFeeds, initFeeds, endReached, postFeedLinkClick, toggleLike,applyFeedFilter,setFeedFilter,filter}) => {
  const {t} = useTranslation();

  useEffect(() => {
    initFeeds();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }


  return (
    <div className={classes.sessionContainer}>
      <Paper>
        <FeedFilterPopover open={open} handleClose={handleClose} id={id} anchorEl={anchorEl} applyFeedFilter={applyFeedFilter} setFeedFilter={setFeedFilter} filterValue={filter}/>
        <div className={classes.header} id={"feed"}>
          <p className={classes.headerText}>{t('feed')}</p>
          <div className={classes.filterBtn} onClick={handleClick}>
            <FilterListIcon style={{fontSize: 12}}/>
            <span style={{marginLeft: 4}}>{t('filter')}</span>
          </div>
        </div>
        <div id="scrollableDiv" className={classes.feeds}>
          <InfiniteScroll
            dataLength={feedPosts.length}
            next={getNextFeeds}
            hasMore={!endReached}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
            endMessage={
              <p style={{textAlign: 'center'}}>
                <b>{t('feedSeenAll')}</b>
              </p>
            }
            refreshFunction={() => console.log("Refreshing")}
            pullDownToRefresh
            pullDownToRefreshContent={
              <h3 style={{textAlign: 'center'}}>&#8595; {t('pullDownToRefresh')}</h3>
            }
            releaseToRefreshContent={
              <h3 style={{textAlign: 'center'}}>&#8593; {t('releaseToRefresh')}</h3>
            }>

            {map(feedPosts, item =>
              <div key={item.id} className={classes.feedWrapper} style={{backgroundColor: false && '#F8F6EF'}}>
                <FeedPostItem type={item.event} post={item} toggleLike={toggleLike} onClickCount={postFeedLinkClick}/>
              </div>)}

          </InfiniteScroll>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Feeds);
