import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER, SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER} from "common/helpers";
import MyProfileContainer from "containers/MyProfileContainer";
import {isEmpty, map, round} from 'lodash';
import React from "react";
import * as PropTypes from "prop-types";

const styles = theme => ({
  wrapper: {},
  title: {
    fontSize: 18,
    color: '#766430',

  },
  content: {
    fontSize: 14,
    color: '#404040'
  },
  contentWrapper: {
    padding: '16px 10px',
    overflowY: 'auto',
    height: PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    [theme.breakpoints.down('sm')]: {
      height: SM_PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    }
  },
  scoreCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 256,
    [theme.breakpoints.down('sm')]: {
      marginTop: 64
    }
  },
  scoreCards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems:'center'
    }
  },
  scoreCardImage: {
    width: 192,
    height: 227,
    backgroundColor: '#C4C4C4',
    borderRadius: 5,
    marginTop: 20
  },
  factorName: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 22,
    height: 40
  },
  scoreText: {
    color: '#E0B334',
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 20,
  },
  scoreDescription: {
    fontSize: 12,
    marginTop: 18
  },
  paper: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'center',
    flexGrow: 1,
  },
  partnerName:{
    fontSize:18,
    fontWeight:'bold',
    marginBottom:8,
  }
});


function ScoreCards(props) {
  return <div className={props.className}>
    {!isEmpty(props.scores) &&
    map(props.scores, props.prop2)
    }
  </div>;
}

ScoreCards.propTypes = {
  className: PropTypes.any,
  scores: PropTypes.any,
  prop2: PropTypes.func
};
const WorkReadinessScore = ({workReadinessScore, classes}) => {
  const scoreCard = (factor, i) => {
    return (
      <div className={classes.scoreCardWrapper}>
        <div className={classes.scoreCardImage}>
          <img src={factor.icon} width={192}/>
        </div>
        <div>
          <div className={classes.factorName}>{factor.factor}</div>
          <div className={classes.scoreText}>{round(factor.score, 2)}</div>
          <div className={classes.scoreDescription}>{factor.description}</div>
        </div>
      </div>
    )
  };


  return (
    <Container maxWidth={"md"} disableGutters={true} className={classes.wrapper}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <div className={classes.contentWrapper}>
            <MyProfileContainer/>
            <br />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(WorkReadinessScore);
