import {loginType} from "common/loginType";

const actions = {
  GOOGLE_LOGIN_REQUEST: "[auth] GOOGLE_LOGIN_REQUEST",
  GOOGLE_LOGIN_SIGNUP_SUCCESS: "[auth] GOOGLE_LOGIN_SIGNUP_SUCCESS",
  GOOGLE_LOGIN_SUCCESS: "[auth] GOOGLE_LOGIN_SUCCESS",
  GOOGLE_LOGIN_FAILURE: "[auth] GOOGLE_LOGIN_FAILURE",
  EMAIL_LOGIN_REQUEST: "[auth] EMAIL_LOGIN_REQUEST",
  GET_PROFILE_VIA_GOOGLE: "[auth] GET_PROFILE_VIA_GOOGLE",
  EMAIL_LOGIN_SUCCESS: "[auth] EMAIL_LOGIN_SUCCESS",
  EMAIL_LOGIN_FAILURE: "[auth] EMAIL_LOGIN_FAILURE",
  LOGOUT_REQUEST: "[auth] LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "[auth] LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "[auth] LOGOUT_FAILURE",
  VERIFY_REQUEST: "[auth] VERIFY_REQUEST",
  VERIFY_SUCCESS: "[auth] VERIFY_SUCCESS",
  OTP_VERIFIED: "[auth] OTP_VERIFIED",
  OTP_VERIFY_FAILED: "[auth] OTP_VERIFY_FAILED",
  UPDATE_STEP: "[auth] UPDATE_STEP",
  SET_PASSWORD: "[auth] SET_PASSWORD",
  ADD_ID_TOKEN: "[auth] ADD_ID_TOKEN",
  SET_EMAIL_OTP_ERROR: "[auth] SET_EMAIL_OTP_ERROR",
  RESET_PASSWORD_ERROR: "[auth - Pass Reset] RESET_PASSWORD_ERROR",
  RESET_PASSWORD_SCREEN: "[auth - Pass Reset] RESET_PASSWORD_SCREEN",
  RESET_OTP_SCREEN_MESSAGE: "[auth - Pass Reset] RESET_OTP_SCREEN_MESSAGE",
  RESET_ERROR_MESSAGE: '[auth] RESET_ERROR_MESSAGE',
  UPDATE_USER_PROFILE_LOCALLY: '[auth] UPDATE_USER_PROFILE_LOCALLY',
  SET_SYSTEM_STATUS: '[auth] SET_SYSTEM_STATUS',
};

export default (
  state = {
    isLoggingIn: false,
    isLoggingOut: false,
    isVerifying: false,
    loginError: false,
    logoutError: false,
    isAuthenticated: false,
    inMaintenance: false,
    user: {},
    otpVerificationStatus: false,
    isEmailVerified: false,
    authStep: 0,
    emailOtpError: false,
    resetPasswordScreen: 0,
    newPasswordForReset: "",
    email: "",
    otp: "",
    loginErrorMessage:""
  },
  action
) => {
  switch (action.type) {
    case actions.RESET_ERROR_MESSAGE:
      return {
        ...state,
        loginErrorMessage: null,
        loginError: false
      };
    case actions.GOOGLE_LOGIN_REQUEST:
      return {
        ...state,
        provider: loginType.GOOGLE,
        isLoggingIn: true,
        loginError: false
      };
    case actions.GOOGLE_LOGIN_SIGNUP_SUCCESS:
      return {
        ...state,
        provider: loginType.GOOGLE,
        isLoggingIn: false,
        isEmailVerified: true
      }
    case actions.GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        otpVerificationStatus: true,
        isEmailVerified: true,
        user: action.payload,
      };
    case actions.GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        isEmailVerified: false,
        loginError: true
      };
    case actions.GET_PROFILE_VIA_GOOGLE:
      return {
        ...state,
        user: action.payload.profile,
        idToken: action.payload.token
      };
    case actions.ADD_ID_TOKEN:
      return {
        ...state,
        idToken: action.payload
      };

    case actions.EMAIL_LOGIN_REQUEST:
      return {
        ...state,
        provider: loginType.EMAIL,
        isLoggingIn: true,
        loginError: false
      };
    case actions.EMAIL_LOGIN_SUCCESS:
      return {
        ...state,
        provider: loginType.EMAIL,
        isLoggingIn: false,
        isAuthenticated: true,
        loginError: false,
        user: action.payload
      };
    case actions.EMAIL_LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
        loginErrorMessage: action.payload
      };
    case actions.LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        provider: null,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {}
      };
    case actions.LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true
      };
    case actions.VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false
      };
    case actions.VERIFY_SUCCESS:
      return {
        ...state,
        isVerifying: false
      };
    case actions.OTP_VERIFIED:
      return {
        ...state,
        otpVerificationStatus: true,
        email: action.payload.email,
        provider: loginType.EMAIL,
        otp: action.payload.otp
      };
    case actions.OTP_VERIFY_FAILED:
      return {
        ...state,
        otpVerificationStatus: false,
        isEmailVerified: false
      };
    case actions.UPDATE_STEP:
      return {
        ...state,
        authStep: action.payload
      };
    case actions.SET_PASSWORD:
      return {
        ...state,
        password: action.payload
      };
    case actions.SET_EMAIL_OTP_ERROR:
      return {
        ...state,
        emailOtpError: action.payload,
      };
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        emailOtpError: action.payload,
      };
    case actions.RESET_PASSWORD_SCREEN:
      return {
        ...state,
        resetPasswordScreen: action.payload,
      };
    case actions.RESET_OTP_SCREEN_MESSAGE:
      return {
        ...state,
        otpMessage: action.payload
      };
    case actions.UPDATE_USER_PROFILE_LOCALLY:
      return {
        ...state,
        user: {...state.user, ...action.payload,picture:action.payload.tempPicture}
      };
    case actions.SET_SYSTEM_STATUS:
      return {
        ...state,
        inMaintenance: action.payload
      };
    default:
      return state;
  }
};


export {
  actions
};
