import {getActiveMatches, getMatchesList, submitMatch, updateMatch} from "actions/matchingAction";
import {connect} from 'react-redux';
import ActiveMatching from "views/Matching/ActiveMatching";
import {setCongratulationsState} from "../actions/supplementaryAction";


const mapStateToProps = ({
                           auth: {user: {role,primaryLanguage}},
                           profile: {displayName},
                           matching: {activeMatches, screen, screensList, matches, expiryHours, submitMatchesResponse, groupInfo,userMatchType},
                           supplementary:{showGroupHoldCongratulationDialog}
                         }) => ({
  activeMatches,
  screen,
  screensList,
  matches,
  displayName,
  role,
  expiryHours,
  groupInfo,
  submitMatchesResponse,
  userMatchType,
  primaryLanguage,
  showGroupHoldCongratulationDialog
});

export default connect(mapStateToProps, {
  getMatchesList,
  getActiveMatches,
  submitMatch,
  updateMatch,
  setCongratulationsState
})(ActiveMatching)
