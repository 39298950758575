import withStyles from "@material-ui/core/styles/withStyles";
import PickupWhereYouLeft from "components/PickupWhereYouLeft";
import CalendarContainer from "containers/CalendarContainer";
import MentorshipRewardsContainer from "containers/Mentorship/MentorshipRewardsContainer";
import {isEmpty} from "lodash";
import React from 'react';


const MaterialStyles = theme => ({
  wrapper: {
    minWidth: 320
  },
  rewardsWrapper: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center'
  },
  rewardMessage: {
    display: 'flex',
    fontSize: 12,
    flexWrap: 'wrap',
  },
  rewards: {
    flex: 1,
  },
  calenderWrapper: {
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  programName: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  programDate: {
    fontSize: 12,
    color: '#878787',
  },
  logoImage: {
    width: 84,
  },
  contactTitle: {
    fontSize: 12,
    color: '#DE6E49',
  },
  contactName: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  contactNumber: {
    fontSize: 12,
  },
  pickupWhereYouLeft: {
    display: 'block',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  }
});

const MentorshipRightPanel = ({classes, lastMentorshipSessionStatus, completeByMessage, initSession, setCurrentStepId}) => {


  return (
    <div className={classes.wrapper}>
      {!isEmpty(lastMentorshipSessionStatus) &&
      <div className={classes.pickupWhereYouLeft}>
        <PickupWhereYouLeft setCurrentStepId={setCurrentStepId} initSession={initSession}
                            lastMentorshipSessionStatus={lastMentorshipSessionStatus}
                            completeByMessage={completeByMessage}/>
      </div>}
      <div className={classes.calenderWrapper}>
        <CalendarContainer/>
      </div>
      <div className={classes.rewardsWrapper}>
        <MentorshipRewardsContainer/>
      </div>
    </div>
  )
};

export default withStyles(MaterialStyles)(MentorshipRightPanel);
