import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from "@material-ui/icons/Close";
import {orderSessionSteps} from "business/session";
import classNames from 'classnames';
import {convertUrl, PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import {history} from "common/history";
import ProgressBar from "components/ProgressBar";
import {find, get, isEmpty, map, some} from "lodash";
import React, { useEffect } from 'react';
import TrackerAvatar from "../../components/TrackerAvatar";
import {isMentee, isMentor} from "../../business/role";
import {isOneToMany, isOneToOne} from "../../business/matching";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";

const MaterialStyles = theme => ({
  sessionWrapper: {
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  navButton: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 57,
    padding: '2px 24px',
    display: 'flex',
  },
  sessionStepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    [theme.breakpoints.down('xs')]: {
      height: '94vh'
    },
  },
  sessionStep: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 100%',
    height: '95vh'
  },
  stepTitle: {
    color: '#BBBBBB',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    backgroundColor: 'white',
    padding: '17px 32px',
    [theme.breakpoints.down('xs')]: {
      padding: '4px 0px'
    },
    fontWeight: 'bold'
  },
  stepContent: {
    flex: 1,
    backgroundColor: '#FAF7F1',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    padding: '16px 0px 54px opx',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  closeIcon: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    },
  },
  sessionImage: {
    overflow: 'hidden',
  },
  sessionSummaryWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    padding: 24,
  },
  summaryStep: {
    margin:'12px 0px'
  },
  summaryStepTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    cursor: 'pointer'
  },

});


function SessionStepAvatar({complete, displayPicture, displayName, styles}) {
  return <div style={{position: "relative", marginLeft: 12}}>
    <TrackerAvatar displayName={displayName} displayPicture={displayPicture} styles={styles} size={29}/>
    {complete && <CheckIcon
      style={{
        color: '#ffffff',
        borderRadius: '50%',
        position: 'absolute',
        bottom: 2,
        left: 5,
        fontSize: 18,
        background: 'linear-gradient(180deg, rgba(224, 179, 52, 0) 0%, #E0B334 69.79%)'
      }}/>}
  </div>;
}

const MentorshipSessionSummary = ({classes, session, role, completedSteps, partnerCompletedSteps, currentMentorshipSessionProgress, sessionProgress, userDetails, mentorDetails, partnerMenteesDetails, mentorshipType, startSession, initSession}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const sessionId = session.id;
  useEffect(() => {
    if (sessionId) startSession(sessionId, false) && initSession(sessionId, false);
  }, [t]);

  const isComplete = (step, StepsStatus) => some(StepsStatus, doneStep => step.id === doneStep.id && doneStep.status==="COMPLETED");
  const selfComplete = (step) => isComplete(step, currentMentorshipSessionProgress.steps);
  const partnerComplete = (step, partnerId) => {
    let findPartner = find(currentMentorshipSessionProgress.partnerSteps, partner => partner.userId === partnerId);
    return isComplete(step, get(findPartner, 'stepDetails'))
  };


  const partnerStepDetails = (partners, partnerId) => {
    return get(find(partners, partner => partner.userId === partnerId), 'stepDetails')
  };
  const isStepStarted = (steps, stepId) => {
    const matchingStep = find(steps, step => step.id === stepId);
    return matchingStep && !(matchingStep.status === 'NOT_STARTED');
  };

  // const isStepBothComplete = (step) => partnerComplete(step) && selfComplete(step);
  const sessionSteps = orderSessionSteps(session);

  const lastStepId = (sessionId) => get(sessionProgress, `${[sessionId]}`);
  return (
    <div className={classNames(classes.sessionWrapper, 'animated faster slideInUp')}
         style={{
           background: `linear-gradient(0deg, rgba(250, 247, 241, 0.6), rgba(250, 247, 241, 0.6)), url(${convertUrl(session.banner)}) 0% 0% / cover no-repeat`,
         }}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container justify="center" spacing={4}>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}} style={{paddingBottom: 0}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowBackIcon style={{color: "#E0B334"}} fontSize={'large'}
                                 onClick={() => history.push(`/mentorship/${session.id}/sessionintro`)}/>
                </IconButton>
              </div>
            </div>
          </Box>
          <Grid item xs={12} sm={6} md={6} style={{padding: '0px 16px'}}>
            <div className={classes.sessionStepWrapper}>
              <div className={classNames(classes.sessionStep)}>
                <div className={classes.stepTitle}>
                  <IconButton 
                    className={classes.closeIcon} 
                    aria-label={'Go back'}
                    onClick={() => history.push(`/mentorship/${session.id}/sessionintro`)}
                  >
                    <CloseIcon style={{color: '#52b2e2'}}/>
                  </IconButton>
                  {st(session.title, session.localeTitle)}
                </div>
                <ProgressBar completionColor={session.color} progressBarColor={'#C4C4C4'}/>
                <div className={classes.stepContent}>

                  <div className={classes.sessionImage}>
                    <img
                      src={convertUrl(session.banner)}
                      style={{width: 464}}
                      alt={''}
                    />
                  </div>
                  {/* ------------Session Steps ----------------*/}
                  {!isEmpty(currentMentorshipSessionProgress) &&
                  <div className={classes.sessionSummaryWrapper}>
                    {sessionSteps.map((step, index) => (
                      <div className={classes.summaryStep}>
                        <div style={{display: 'flex'}}>
                          {/*Step Name and Index with duration*/}
                          <div style={{color: '#DE6E49', fontSize: 14}}>{`${t('step')} ${index + 1} `}</div>
                          <div
                            style={{
                              color: '#DE6E49',
                              fontStyle: 'italic',
                              fontSize: 14,
                              marginLeft: 2
                            }}>{`(${step.duration})`}</div>
                        </div>
                        <div
                          className={classNames(classes.summaryStepTitle)}
                          onClick={() => history.push(`/mentorship/${session.id}/step/${step.id}`)}>{st(step.title, step.localeTitle)}</div>
                        <div style={{flexDirection: 'row', display: 'flex', marginLeft: -12, marginTop: 5}}>
                          {/*Mentor*/}
                          {isMentee(role) && isStepStarted(partnerStepDetails(currentMentorshipSessionProgress.partnerSteps, mentorDetails.id), step.id) &&
                          <SessionStepAvatar complete={partnerComplete(step, mentorDetails.id)}
                                             displayPicture={get(mentorDetails, 'picture')}
                                             displayName={mentorDetails.firstName + " " + mentorDetails.lastName}/>
                          }


                          {/*Self*/}
                          {isStepStarted(currentMentorshipSessionProgress.steps, step.id) &&
                          <SessionStepAvatar complete={selfComplete(step)}
                                             displayPicture={get(userDetails, 'picture')}
                                             displayName={get(userDetails, 'firstName') + " " + get(userDetails, 'lastName')}/>
                          }

                          {/* Other Mentees */}
                          {(isOneToMany(mentorshipType) || (isOneToOne(mentorshipType) && isMentor(role))) &&
                          map(partnerMenteesDetails, mentee => isStepStarted(partnerStepDetails(currentMentorshipSessionProgress.partnerSteps, mentee.id), step.id) &&
                            <SessionStepAvatar
                              complete={partnerComplete(step, mentee.id)}
                              displayPicture={get(mentee, 'picture')}
                              displayName={mentee.firstName + " " + mentee.lastName}/>)
                          }
                        </div>
                      </div>
                    ))}
                  </div>}
                </div>
              </div>

            </div>
          </Grid>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowForwardIcon style={{color: "#E0B334"}} fontSize={'large'}
                                    onClick={() => lastStepId(session.id) ? history.push(`/mentorship/${session.id}/step/${lastStepId(session.id)}`) : history.push(`/mentorship/${session.id}/step/${sessionSteps[0].id}`)}
                  /></IconButton>
              </div>
            </div>
          </Box>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(MentorshipSessionSummary);
