const LOADER_SHOW = 'LOADER_SHOW';
const LOADER_HIDE = 'LOADER_HIDE';

const createInitialState = () => ({
  loader: false,
});


const loader = (state = createInitialState(), action) => {
  switch (action.type) {
    case LOADER_SHOW:
      return {
        ...state,
        loader: true,
      };

    case LOADER_HIDE: {
      return {
        ...state,
        loader: false,
      };
    }
    default:
      return state;
  }
};


export {createInitialState};
export default loader;
