import {getAllSurveys} from "actions/surveyAction";
import {connect} from 'react-redux';
import {setCurrentPath} from "reducers/NavmenuReducer";
import SurveysListing from "views/SurveysListing";

const mapStateToProps = ({profile: {displayName}, survey: { surveys} }) => {

  return ({
    surveys,
    displayName,
  })
};

export default connect(mapStateToProps, {setCurrentPath, getAllSurveys})(SurveysListing);
