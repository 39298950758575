import withStyles from "@material-ui/core/styles/withStyles";
import {get, isArray, map} from "lodash";
import React from "react";
import {Circle, styles} from "components/QuizAnswers/AgreeabilityScaleAnswer";
import Grid from "@material-ui/core/Grid/Grid";
import {renderOptionTextLabelsForHorizontalDisplay, renderVerticalLinesForHorizontalDisplay} from "components/Scale";
import { useSetTranslation } from "common/useSetTranslation";
import i18n from "i18n";


const ScaleAnswer = ({
                                   classes,
                                   question: {scaleQuestionnaire: {options}},
                                   quizAnswer = {}, title = i18n.t('yourAnswer')
                                 }) => {
  const {st} = useSetTranslation();
  const scaleOptionsCount = options.length;

  const selectedAnswer = get(quizAnswer, 'answer', "");
  const selectedOption = isArray(selectedAnswer) ? selectedAnswer[0] : selectedAnswer;

  return scaleOptionsCount < 6 ? (
      <div>
        <p className={classes.title}>{title}</p>
        <div className={classes.circleContainer}>
          {map(options, scaleOption => <Circle key={scaleOption.option}
                                               number={scaleOption.option}
                                               selectedOption={selectedOption === scaleOption.option}
                                               selected={selectedOption}
                                               />)}
        </div>
        <div className="vertical-line-scale">
          {renderVerticalLinesForHorizontalDisplay(scaleOptionsCount)}
        </div>
        <div className="label-scale">
          {renderOptionTextLabelsForHorizontalDisplay(options, st)}
        </div>
      </div>
    ) :
    (
      <div>
        <p className={classes.title}>{title}</p>
        <div className={classes.circleContainerScale}>
          {map(options, scaleOption =>
            <Grid container style={{paddingTop: '20px'}}>
              <Grid item>
                <Circle key={scaleOption.option}
                        number={scaleOption.option}
                        selectedOption={selectedOption === scaleOption.option}
                        selected={selectedOption}
                />
              </Grid>
              <Grid item>
                <div style={{paddingLeft: '20px', paddingTop: '10px'}}>{st(scaleOption.label, scaleOption.localeLabel)}</div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
};

export default withStyles(styles)(ScaleAnswer);
