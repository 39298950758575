import {apiAction, dataAction,  staticAction} from "actions/actionWrappers";
import {initApp} from "actions/authAction";
import {getStage, getUserStageExistingAnswers as getUserStageExistingAnswersApi, saveStageAsForm} from "api/stage";
import {convertToSubmitFormat} from "common/convertToSubmitFormat";
import {validatePage} from "common/surveySubmitHelper";
import {flatten, get, map, join} from 'lodash';
import {actions} from "reducers/stageReducer";
import { convertFilesToMultipartSubmitFormat } from "../common/convertFilesToMultipartSubmitFormat";

const initStage = (stageId) => async (dispatch) => {
  dispatch(staticAction(actions.FETCHING_STAGE_DETAILS));
  try {
    const stage = await dispatch(apiAction(getStage, stageId))
    await dispatch(dataAction(actions.STORE_STAGE, {...stage, stageId}));
  } catch (e) {
    dispatch(staticAction(actions.ERROR_FETCHING_STAGE_DETAILS));
  }
};

const nextPage = (pageItems, answers, existingAnswers = []) => dispatch => {
  const questions = get(pageItems, "questions");

  let questionsToValidate = []
  let existingAnswersQuestionKeys = []

  if (existingAnswers.length && existingAnswers[0].answers.length) {
    existingAnswersQuestionKeys = existingAnswers[0].answers.map(answer => answer.questionKey)
  }
  questionsToValidate = (questions || []).filter(question => !existingAnswersQuestionKeys.includes(question.key))

  dispatch(dataAction(actions.NEXT_PAGE, validatePage(questionsToValidate, answers)));
};
const previousPage = () => dispatch => {
  dispatch(staticAction(actions.PREVIOUS_PAGE));
};


const setAnswer = (answer, question) => dispatch => {
  dispatch(dataAction(actions.SET_ANSWER, {question, answer}));
};


const submitSurveyStage = (pageItems, answers, existingAnswers = []) =>
  async (dispatch, getState) => {
    const {stage: {stage}, survey: {survey}} = await getState();
    const surveyId = get(survey, "id");
    const stageId = get(stage, "stageId");
    const questions = get(pageItems, "questions");

    let questionsToValidate = []
    let existingAnswersQuestionKeys = []

    if (existingAnswers.length && existingAnswers[0].answers.length) {
      existingAnswersQuestionKeys = existingAnswers[0].answers.map(answer => answer.questionKey)
    }
    questionsToValidate = (questions || []).filter(question => !existingAnswersQuestionKeys.includes(question.key))

    const pageValidationResults = validatePage(questionsToValidate, answers);

    if (!pageValidationResults.hasError) {
      await dispatch(dataAction(actions.SET_SURVEY_SUBMITTING, true));
      await dispatch(apiAction(saveStageAsForm, surveyId, stageId,
        convertToSubmitFormat(flatten(map(stage.pages, 'questions')), answers),
        convertFilesToMultipartSubmitFormat(flatten(map(stage.pages, 'questions')), answers)
      ));
      dispatch(initApp())
    } else {
      await dispatch(dataAction(actions.SET_VALIDATION_ERROR, pageValidationResults));
    }
  };

const getUserStageExistingAnswers = (stageId) =>
  async (dispatch, getState) => {
    const {survey: {survey}} = await getState();
    const surveyId = get(survey, "id");
    let params = [];
    params.push(`survey=${surveyId}`);
    params.push(`stage=${stageId}`);
    const filterParameters = join(params, '&');
    const existingAnswers = await dispatch(apiAction(getUserStageExistingAnswersApi, filterParameters));
    await dispatch(dataAction(actions.STORE_USER_STAGE_EXISTING_ANSWERS, existingAnswers));
  };

export {
  initStage,
  nextPage,
  previousPage,
  setAnswer,
  submitSurveyStage,
  getUserStageExistingAnswers
}
