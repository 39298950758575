import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {convertUrl} from "common/helpers";
import React from "react";
import {isEmpty} from 'lodash';


const styles = theme => ({
  criteria: {
    borderRadius: 40,
    color: '#ffffff',
    fontSize: '10px',
    fontWeight: 'bold',
    backgroundColor: '#E2795D',
    display: 'flex',
    justifyContent: 'center',
    width: 'fit-content',
    padding: '8px 12px',
    marginLeft: 8,
    marginTop:8,
    alignItems:'center',
  },
  inactive: {
    backgroundColor: '#A3A3A3',
  },
  clashing: {
    backgroundColor: '#E14747',
  }
});


const MatchCriteria = ({classes, criteria, status, icon}) => {

  const isInactive = status === MatchCriteria.criteriaStatus.INACTIVE;
  const isClashing = status === MatchCriteria.criteriaStatus.CLASHING;

  return (
    <div className={classNames(classes.criteria, isInactive && classes.inactive, isClashing && classes.clashing)}>
      {!isEmpty(icon) && <img src={convertUrl(icon)} style={{marginRight:8,height:14}} />}
      {criteria}
    </div>
  );
};

MatchCriteria.criteriaStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  CLASHING: "clashing"
};

export default withStyles(styles)(MatchCriteria);


