import withStyles from "@material-ui/core/styles/withStyles";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import {sessionStatusMessage} from "business/session";
import classNames from 'classnames';
import {convertUrl, opaque, transparent} from "common/helpers";
import ProgressBar from "components/ProgressBar";
import {get, join, truncate} from 'lodash';
import React from "react";
import {Link} from "react-router-dom";
import {useSetTranslation} from "common/useSetTranslation";


const width = '130';

const styles = theme => ({
  sessionCardWrapper: {
    width: width * 1,
    height: (width * 4) / 3,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    position: 'relative'
  },
  cardImage: {
    flex: 1,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    padding: '2px 2px 0px 0px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  cardDetails: {
    flex: 1,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    marginTop: '-4px',
    padding: '0px 8px',
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: '13px',
    height: '45px',
  },
  category: {
    paddingTop: 3,
    color: 'rgba(255, 255, 255, 0.6)',
    fontSize: '9px'
  },
  duration: {
    paddingTop: 4,
    fontSize: '9px',
    color: 'black'
  },
  bookMark: {
    position: 'absolute',
    top: 15,
    right: 12,
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  }
});

export const sessionCompletionPercentage =
  ({durationCompleted = 0, sessionDuration = 100}) => (durationCompleted / sessionDuration) * 100;

const MentorshipSessionCard = ({session, classes, style, toggleBookmark}) => {
  const {st} = useSetTranslation();

  const {title, localeTitle, tags, isBookmarked} = session;
  const bannerUrl = get(session, 'banner');
  const cardGradient = `linear-gradient(180deg,${transparent(session.color)}  55px,${opaque(session.color)}  80px),url(${convertUrl(bannerUrl)})`;

  const sessionProgress = sessionCompletionPercentage(session);


  return (
    <div className={classes.wrapper}>
      <Link to={`/mentorship/${session.id}/sessionintro`}
            className={classes.link}>
        <div className={classNames(classes.sessionCardWrapper, 'animate fadeInRight')} style={{...style}}>
          <div className={classes.cardImage} style={{
            backgroundImage: cardGradient
          }}>
          </div>
          <div className={classes.cardDetails} style={{backgroundColor: opaque(session.color)}}>
            {sessionProgress > 0 &&
            <ProgressBar height={2} completed={sessionProgress} completionColor={'#ffffff'}
                         progressBarColor={'rgba(0, 0, 0, 0.23)'}/>}
            <div className={classes.cardTitle}>
              <Link to={`/mentorship/${session.id}/sessionintro`}
                    className={classes.link}> {truncate(st(title, localeTitle), {length: 26})}</Link>
            </div>
            <div className={classes.category}>
              <span>{join(tags, ', ')}</span>
            </div>
            <div className={classes.duration}>
              <span>{sessionStatusMessage(session, session)}</span>
            </div>
          </div>
        </div>
      </Link>
      <div className={classes.bookMark}>
        {isBookmarked ? <BookmarkIcon style={{color: 'white'}} onClick={() => toggleBookmark(session.id)}/> :
         <BookmarkBorderIcon style={{color: 'rgba(255, 255, 255, 0.8)'}} onClick={() => toggleBookmark(session.id)}/>}
      </div>
    </div>
  );
};

export default withStyles(styles)(MentorshipSessionCard);


