import ActiveMatchContainer from "containers/ActiveMatchContainer";
import InactiveMentorshipLearningContainer from "containers/Mentorship/InactiveMentorshipLearningContainer";
import {get} from 'lodash';
import React, {useEffect} from 'react';
import UnlockedMentorship from "views/Mentorship/UnlockedMentorship";
import {isMatchingOpen} from "business/matching";


const Mentorship = ({status, setCurrentPath}) => {

  useEffect(() => {
    setCurrentPath('mentorship')
  }, []);

  let mentorshipStatus = get(status, 'experiences.curriculum');
  const isMentorshipActive = get(status, 'activeMentorship');
  const matchingIsActive = isMatchingOpen(status);

  return(
    <>
      {matchingIsActive ? (
        <ActiveMatchContainer/>
      ) : (
        mentorshipStatus === 'use' && isMentorshipActive ? (
          <UnlockedMentorship/>
        ) : (
          <InactiveMentorshipLearningContainer/>
        )
      )}
    </>
  )

};


export default Mentorship;
