import React, {useEffect} from "react";
import "views/css/stage.scss"
import StageWrapper from "wrapper/StageWrapper";
import { get} from 'lodash';

export default function Stage({location, initStage, stage, survey, nextPage, previousPage, setAnswer, submitSurveyStage, getUserStageExistingAnswers }) {
  const { stageId} = location.state;
  useEffect(() => {
    initStage(stageId);
    getUserStageExistingAnswers(stageId);
  }, []);

  const stageList = get(survey, 'stages');

  return (
    <div className={"stage"}>
      <StageWrapper
        stage={stage}
        stageList={stageList}
        currentStageId={stageId}
        nextPage={nextPage}
        previousPage={previousPage}
        setAnswer={setAnswer}
        submitSurveyStage={submitSurveyStage}
        existingAnswers={stage.userExistingAnswers}
      />
    </div>
  );
};
