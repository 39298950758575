import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {find, get, isBoolean, isEmpty, noop, some} from "lodash";
import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {useSetTranslation} from "common/useSetTranslation";


const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17
  },
  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 16,
    marginTop: 8,
    fontSize: 14,
    fontWeight: 'bold'
  },
  correct: {
    backgroundColor: '#E6F9EA',
    color: '#41A156'
  },
  incorrect: {
    backgroundColor: '#F9E6E6',
    color: '#A14141'
  }
});

const CorrectMultiChoiceAnswer = ({classes, style, question}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();
  const answerOptions = get(question, "answerOptions");
  const correctAnswers = answerOptions.filter((answer) => answer.isCorrect);

  return (
    <Fragment>
      <div>
        {!isEmpty(correctAnswers) &&
        <p className={classes.title}>{t('correctAnswer')}</p>}
        {
          correctAnswers.map((item) => <div key={item.key}
                                            className={classNames(classes.box, classes.correct)}>{st(item.label, item.localeLabel)}</div>)
        }
      </div>
    </Fragment>
  );
};


export default withStyles(styles)(CorrectMultiChoiceAnswer);
