import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import MTMarkdown from "components/MTMarkdown";
import React from "react";
import logo from 'common/assets/logo-with-name.png';


const styles = theme => ({
  wrapper: {},
  title: {
    fontWeight: 'bold',
    fontSize: 24,
  },
  content: {
    fontSize: 14,
    color: '#404040'
  },
  contentTextWrapper: {
    columnCount: 2,
    [theme.breakpoints.down('sm')]: {
      columnCount: 1,
    }
  },
  contentWrapper: {
    backgroundColor: '#ffffff',
    padding: '54px 90px',
    [theme.breakpoints.down('md')]: {
      padding: '12px 20px',
    },
    marginTop: 44,
    overflowY: 'auto',
    height: `calc(100vh - 48px - 92px - 100px)`,
    [theme.breakpoints.down('sm')]: {
      height: `calc(100vh - 48px - 92px - 100px - 64px)`,
    }

  },
  logoImage: {
    width: 150,
  }
});


const SurveyCard = ({title, content, classes}) => {

  return (
    <Container maxWidth={"md"} disableGutters={true} className={classes.wrapper}>
      <Grid container justify="center" spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <div>
            <img src={logo} className={classes.logoImage}/>
          </div>
          <div className={classes.contentWrapper}>
            <div>
              <p className={classes.title}>{title}</p>
            </div>
            <div className={classes.contentTextWrapper}>
              <MTMarkdown className={classes.content}>{content.content}</MTMarkdown>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(SurveyCard);


