import withStyles from "@material-ui/core/styles/withStyles";
import React, {useState} from "react";
import Modal from "@material-ui/core/Modal/Modal";
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import {map} from 'lodash';
import {useTranslation} from "react-i18next";
import i18n from "i18n";

const styles = theme => ({
  title: {
    fontSize: 10,
    color: '#728D9A',
    paddingLeft: 17,
    textTransform:'UPPERCASE'
  },
  answerText: {
    fontSize: 14,
    color: '#0F853E'
  },
  image: {
    borderRadius: 10,
    height: 100,
    width: 100,
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  imagePreview: {
    backgroundColor: '#fff'
  },
  paper: {
    backgroundColor: '#fff',
    width: '80%',
    height: '80%',
    outline: 0,
    borderRadius: 5,
    display: 'flex',
    padding:4,
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '80%',
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImage: {
    objectFit: 'contain'
  },
  closeTxtBtn: {
    padding: '10px 12px',
    fontWeight: 'bold',
    fontSize: 12,
    cursor: 'pointer'
  },
  imagePreviewWrapper:{
    display:'flex',
    overflow:'auto',
    justifyContent:'center',
  }
});

const ImageResponseAnswer = ({classes, style, quizAnswer = {}, title = i18n.t('yourAnswer')}) => {
  const {t} = useTranslation();

  const value = quizAnswer.answer;
  const [selectedImage, setSelectedImage] = useState("");
  const [imagePreview, setImagePreview] = useState(false);

  const handlePreviewClick = (image) => {
    setSelectedImage(image);
    setImagePreview(true);
  };


  return (
    <div>
      <p className={classes.title}>{title}</p>
      <div className={classes.imageWrapper}>
        {value && map(value,(img, idx) => <div onClick={() => handlePreviewClick(img)} style={{cursor: 'pointer'}}>
          <img src={img} className={classes.image} alt={""}/>
        </div>)
        }
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={imagePreview}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        onClose={() => setImagePreview(false)}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <div style={{display: 'flex',justifyContent:'flex-end'}}>
            <div onClick={() => setImagePreview(false)} className={classes.closeTxtBtn}>{t('closeCaps')}</div>
          </div>
          <div className={classes.imagePreviewWrapper}>
            <div>
              <img src={selectedImage} className={classes.previewImage} alt={""}/>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};


export default withStyles(styles)(ImageResponseAnswer);
