import withStyles from "@material-ui/core/styles/withStyles";
import MatchCriteria from "components/Matching/MatchCriteria";
import TrackerAvatar from "components/TrackerAvatar";
import {get, map} from 'lodash';
import React, {useState} from "react";
import MatchingInfoModal from "components/MatchingInfoModal";
import {useTranslation} from "react-i18next";

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    padding: '24px 40px',
  },
  groupStatus: {
    color: 'rgba(0, 0, 0, 0.49)',
    fontSize: 12,
    display: 'flex',
  },
  groupDetailsWrapper: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  groupCriterias: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.13)',
    paddingLeft: 12,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft:-8
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf:'flex-end',
    paddingRight:8
  }
});

const ClubMemberRuleDisplay = ({classes, groupInfo, interesetedTrackerCount}) => {
  const {t} = useTranslation();

  const groupSize = get(groupInfo, "groupSize");
  const actualSize = get(groupInfo, "actualSize");
  const slotsAvailable = groupSize - actualSize - interesetedTrackerCount;

  const existingMembers = get(groupInfo, 'existingMemberInfo');
  const clubRules = get(groupInfo, 'clubRule');

  const [open, setOpen] = useState(false);
  const [partnerName, setpartnerName] = useState("");
  const [partnerId, setpartnerId] = useState("");

  const handleClick = (id, name) => {
    setpartnerId(id);
    setpartnerName(name);
    setOpen(true);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.groupStatus}><span
        style={{
          fontWeight: 'bold',
          marginRight: 2
        }}>{t('yourGroup')}</span> {slotsAvailable}/{groupSize} {t('spotsAvailable')}
      </div>
      <div className={classes.groupDetailsWrapper}>
        <div className={classes.avatarWrapper}>
          {map(existingMembers, member => member.id !== "" && <TrackerAvatar displayName={member.fullName}
                                                                             onClick={() => handleClick(member.id, member.fullName)}
                                                                             displayPicture={member.displayPicture}
                                                                             styles={{marginRight: 8}}/>)}
        </div>
        <div className={classes.groupCriterias}>
          {map(clubRules, (rule) =>
            map(rule.answer, answer => <MatchCriteria criteria={answer} icon={rule.icon}
                                                      status={MatchCriteria.criteriaStatus.ACTIVE}/>)
          )}
        </div>
      </div>
      <MatchingInfoModal handleClose={()=>setOpen(false)} open={open} fullName={partnerName} matchId={partnerId}/>
    </div>
  );
};

export default withStyles(styles)(ClubMemberRuleDisplay);


