import {fromPairs, merge, omit} from 'lodash';

const prefix = "[SESSION STATUS]";
const actions = {
  ADD_SESSION_STATUS: `${prefix} ADD_SESSION_STATUS`,
  CLEAR_SESSION_STATUS: `${prefix} CLEAR_SESSION_STATUS`,
};

const createInitialState = () => ({
  sessions: {},
  lastMentorshipSessionStatus: {}
});

const reducer = (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.ADD_SESSION_STATUS: {
      return {
        ...state,
        sessions: merge({}, state.sessions, fromPairs([[action.payload.sessionId, action.payload.stepId]])),
        lastMentorshipSessionStatus: action.payload.isMentorship ? action.payload : state.lastMentorshipSessionStatus
      };
    }
    case actions.CLEAR_SESSION_STATUS: {
      const actionSession = action.payload;
      const lastMentorshipSessionStatus = (actionSession.id === state.lastMentorshipSessionStatus.sessionId) ? {} : state.lastMentorshipSessionStatus;
      return {
        ...state,
        sessions: omit(state.sessions, actionSession.id),
        lastMentorshipSessionStatus
      };
    }
    default:
      return state;
  }
};

export {actions, reducer};
