import {isNumberKey} from "common/helpers";
import "components/styles/AgreeabilityScale.scss"
import {map} from 'lodash';
import React from "react";
import {Circle} from "components/AgreeabilityScale";
import Grid from "@material-ui/core/Grid/Grid";
import { useSetTranslation } from "common/useSetTranslation";

export const renderVerticalLinesForHorizontalDisplay = (scaleOptionsCount) => {
  if (scaleOptionsCount > 2) {
    let returnVal = [];
    for (let i = 2; i < scaleOptionsCount; i++) {
      returnVal.push(<div key={i} className="hr"/>);
    }
    return returnVal;
  }
}

export const renderOptionTextLabelsForHorizontalDisplay = (scaleOptions, st) => {
  return scaleOptions.map(scaleOption => {
    return <div className={"label-item-scale"}>{st(scaleOption.label, scaleOption.localeLabel)}</div>
  })
}

const Scale = ({onAnswered, value, question: {key, scaleQuestionnaire: {sorting, options}}}) => {
  const {st} = useSetTranslation();

  const minimumValue = sorting === 'Ascending' ? options[0].option : options[options.length - 1].option;
  const maximumValue = sorting === 'Ascending' ? options[options.length - 1].option : options[0].option;

  const handleChange = (n) => {
    onAnswered(n);
  };

  const handleKeyPress = (event) => {
    if (isNumberKey(event)) {
      const keyNumber = parseInt(event.key);
      if (keyNumber <= maximumValue && keyNumber >= minimumValue) {
        onAnswered(keyNumber);
      }
    }
  };

  const scaleOptionsCount = options.length;

  return scaleOptionsCount < 6 ? (
      <div className="scale-toolbar">
        <div className="circleWrapper" onKeyDown={handleKeyPress}>
          {map(options, scaleOption => <Circle key={scaleOption.option} number={scaleOption.option} value={value}
                                               handleChange={handleChange}
                                               questionKey={key}/>)}
        </div>
        <div className="vertical-line-scale">
          {renderVerticalLinesForHorizontalDisplay(scaleOptionsCount)}
        </div>
        <div className="label-scale">
          {renderOptionTextLabelsForHorizontalDisplay(options, st)}
        </div>
      </div>
    ) :
    (
      <div className="scale-toolbar">
        <div className="circleWrapper-scale" onKeyDown={handleKeyPress}>
          {map(options, scaleOption =>
            <Grid container>
              <Grid item>
                <Circle key={scaleOption.option} number={scaleOption.option} value={value} handleChange={handleChange}
                        questionKey={key}/>
              </Grid>
              <Grid item>
                <div className={"label-item-scale-vertical"}>{st(scaleOption.label, scaleOption.localeLabel)}</div>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
};


export default (Scale);
