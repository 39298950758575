import React, {Fragment} from 'react'
import CorrectMultiChoiceAnswer from "components/QuizAnswers/CorrectMultiChoiceAnswer";
import CorrectSingleChoiceAnswer from "components/QuizAnswers/CorrectSingleChoiceAnswer";

const QuizCorrectAnswerView = (props) => {
  switch (props.question.questionType) {
    case 'SingleChoiceRadio':
      return <CorrectSingleChoiceAnswer {...props}/>;
    case 'SingleChoiceDropDown':
      return <CorrectSingleChoiceAnswer {...props}/>;
    case 'MultiChoiceDropDown':
      return <CorrectMultiChoiceAnswer {...props}/>;
    case 'SingleChoiceButton':
      return <CorrectSingleChoiceAnswer {...props}/>;
    default:
      return (<Fragment/>);
  }
};

export default QuizCorrectAnswerView


