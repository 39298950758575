import 'components/styles/singleSelectButton.scss';
import {findIndex, isEmpty, map, noop, sortBy} from 'lodash';
import React, {Fragment} from "react";
import { useSetTranslation } from "common/useSetTranslation";

const styles = {
  box: {
    width: 200,
  }
};


const OptionItem = ({option, handleChange, value, questionKey}) => {
  const {st} = useSetTranslation();
  const optionLabel = st(option.label, option.localeLabel);
  const answered = !isEmpty(value);
  const isSelected = (answer) => answered && answer.key === value.key;

  return (
    <Fragment>
      <input type="radio" id={optionLabel+"-"+questionKey} name={questionKey} value={option.key} checked={isSelected(option)}
             onChange={() => handleChange(option)}
             className={answered && "notSelected"}/>
      <label htmlFor={optionLabel+"-"+questionKey}>{optionLabel}</label>
    </Fragment>);
};


const SingleChoiceButton = ({onAnswered = noop(), value, options, question: {answerOptions = [], key}}) =>
{

  const orderedAnswerOptions = sortBy(answerOptions, (option) => option.displayOrder);

  const handleChange = (option) => {
    onAnswered(option);
  };

  return (
    <div className="radio-toolbar">
      {map(orderedAnswerOptions, option => <OptionItem key={option.key} option={option} handleChange={handleChange}
                                                       questionKey={key}
                                                       value={value}/>)}
    </div>
  );
};

export default SingleChoiceButton;
