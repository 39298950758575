import {apiAction, dataAction} from "actions/actionWrappers";
import {deleteEvent, fetchEvents, saveEvent} from "api/eventsApi"
import {actions} from "reducers/eventsReducer";


//
// export const changeEvent = event => dispatch => dispatch(dataAction(actions.CHANGE_EVENT, event));
//
export const submit = (mentorship,mentorshipGroupId, event) =>
  async dispatch =>
    dispatch(apiAction(saveEvent, mentorship,mentorshipGroupId, event));

export const changeDate = (newDate) =>
  dispatch =>
    dispatch(dataAction(actions.CHANGE_DATE, newDate));

export const loadEvents = (mentorshipId) => async(dispatch) => {
  const events = await dispatch(apiAction(fetchEvents, mentorshipId));
  dispatch(dataAction(actions.STORE_EVENTS, events));
};
//
// export const goToSchedule = (mentorshipId, selectedDate) => async dispatch => {
//   await dispatch(loadEvents(mentorshipId));
//   dispatch(dataAction(actions.CREATE_SCHEDULE, selectedDate || new Date()));
//   dispatch(initSessions());
//   Navigator.navigate('schedule');
// };
//
export const submitEvent = (mentorshipId,mentorshipGroupId, event) => async dispatch => {
  await dispatch(submit(mentorshipId,mentorshipGroupId, event));
  dispatch(loadEvents(mentorshipId));
};

// {"created_by": 8273, "description": "Some Description is here about the event.for event 3", "scheduledTime": 2020-04-14T08:30:00.000Z, "sessionName": "Roadmap to My Vision (mandatory)", "title": "Test Event 3"}



export const locallyRemoveEvent = event => dispatch => dispatch(dataAction(actions.LOCALLY_REMOVE_EVENT, event));

export const cancelEvent = event => async dispatch => {
  await dispatch(apiAction(deleteEvent, event));
  dispatch(locallyRemoveEvent(event));
};
