import {getMentorshipCards} from "actions/mentorshipAction";
import {get} from "lodash";
import {connect} from 'react-redux';
import InactiveMentorshipLearning from "views/Mentorship/InactiveMentorshipLearning";

const mapStateToProps = ({
                           mentorship: {
                             mentorshipCards,

                           },
                           auth
                         }) => {
  return {
    mentorshipCards,
    status: get(auth, 'user.status'),
    ruleMessage: get(auth, 'user.status.experiences.ruleMessage')
  };
};


export default connect(mapStateToProps, {getMentorshipCards})(InactiveMentorshipLearning);
