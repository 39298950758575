import withStyles from "@material-ui/core/styles/withStyles"
import {convertUrl} from "common/helpers"
import ScreenDescription from "components/ScreenDescription"
import {get} from "lodash"
import React from "react"
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  message: {
    fontSize: 18,
    lineHeight: '21px',
    color: '#747474',
    textAlign: 'center',
    marginTop: 14,
  },
  imageWrapper: {
    marginTop: 130,
    marginBottom: 40,
  }
})


const FindingMatches = ({classes, listDetail}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();
  const title = st(get(listDetail, "title"), get(listDetail, "localeTitle")) || t("findingMatches");
  const description = st(get(listDetail, "description"), get(listDetail, "localeDescription"));
  const image = get(listDetail, "image", "")

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <ScreenDescription description={description}
                         title={title}
      />
      <div className={classes.imageWrapper}>
        <img src={convertUrl(image)}/>
      </div>
    </div>
  )
}

export default withStyles(styles)(FindingMatches)


