import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import ChatBotIcon from "common/assets/chatbot-icon.svg";
import SadEmoji from "common/assets/emojis/sad.png";
import ConfusedEmoji from "common/assets/emojis/confused.png";
import NeutralEmoji from "common/assets/emojis/neutral.png";
import GrinningEmoji from "common/assets/emojis/grinning.png";
import CelebrateEmoji from "common/assets/emojis/celebrate.png";
import InactiveSadEmoji from "common/assets/emojis/inactive-sad-emoji.png";
import InactiveConfusedEmoji from "common/assets/emojis/inactive-confused-emoji.png";
import InactiveNeutralEmoji from "common/assets/emojis/inactive-neutral-emoji.png";
import InactiveGrinningEmoji from "common/assets/emojis/inactive-grinning-emoji.png";
import InactiveCelebrateEmoji from "common/assets/emojis/inactive-celebrate-emoji.png";
import SuccessIcon from "common/assets/success-icon.png";

const ratingQuestions = [
    'Accuracy of information provided?',
];

function MeetingSummaryRating({ classes, meetingSummaryRating, storeMeetingSummaryRating, userId, getMeetingRatingStatus }) {
    const [activeEmojiIndex, setActiveEmojiIndex] = useState(Array(ratingQuestions.length).fill(null));
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isSuccessPopup, setIsSuccessPopup] = useState(false);
    const [isVisible, setIsVisible] = useState(true);

    const handleEmojiClick = (questionIndex, emojiIndex) => {
        const newActiveEmojiIndex = [...activeEmojiIndex];
        newActiveEmojiIndex[questionIndex] = emojiIndex;
        setActiveEmojiIndex(newActiveEmojiIndex);

        const allAnswered = newActiveEmojiIndex.every(index => index !== null);
        setIsSubmitDisabled(!allAnswered);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsSuccessPopup(false);
        }, 3000);

        return () => clearTimeout(timeout);
    }, [isSuccessPopup]);

    const handleSubmit = () => {
        const ratingsData = {};
        ratingQuestions.forEach((question, index) => {
            const questionKey = question;
            ratingsData[questionKey] = activeEmojiIndex[index] !== null ? activeEmojiIndex[index] + 1 : null;
        });
        const ratingsPayloadData = {
            meeting_recording_id: meetingSummaryRating?.meetingRecordingId,
            user_id: userId,
            meeting_rating: ratingsData,
        };
        storeMeetingSummaryRating(ratingsPayloadData);
        setIsSuccessPopup(true);
        getMeetingRatingStatus();
        setActiveEmojiIndex([]);
        return true;
    };

    const handleSkip = () => {
        const ratingsData = {};
        ratingQuestions.forEach((question) => {
            const questionKey = question;
            ratingsData[questionKey] = null;
        });
        const ratingsPayloadData = {
            meeting_recording_id: meetingSummaryRating?.meetingRecordingId,
            user_id: userId,
            meeting_rating: {},
        };
        storeMeetingSummaryRating(ratingsPayloadData);
        setIsVisible(false);
    };

    const emojiIcons = (questionIndex) => {
        return (
            <div className={classes.emojiList}>
                <div className={classes.line}></div>
                <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 0)}>
                    <img src={activeEmojiIndex[questionIndex] === 0 ? SadEmoji : InactiveSadEmoji} className={classes.emoji} />
                </a>
                <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 1)}>
                    <img src={activeEmojiIndex[questionIndex] === 1 ? ConfusedEmoji : InactiveConfusedEmoji} className={classes.emoji} />
                </a>
                <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 2)}>
                    <img src={activeEmojiIndex[questionIndex] === 2 ? NeutralEmoji : InactiveNeutralEmoji} className={classes.emoji} />
                </a>
                <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 3)}>
                    <img src={activeEmojiIndex[questionIndex] === 3 ? GrinningEmoji : InactiveGrinningEmoji} className={classes.emoji} />
                </a>
                <a className={classes.emojiLink} onClick={() => handleEmojiClick(questionIndex, 4)}>
                    <img src={activeEmojiIndex[questionIndex] === 4 ? CelebrateEmoji : InactiveCelebrateEmoji} className={classes.emoji} />
                </a>
            </div>
        );
    };

    const renderRatingContent = () => {
        return (
            <div className={classes.ratingCardContainer}>
                <div className={classes.summaryRatingDateText}>
                    {meetingSummaryRating?.meetingDateTime}
                </div>
                <div className={classes.cardDescriptionContainer}>
                    <div className={classes.summaryRatingDescription}>
                        {meetingSummaryRating?.meetingRatingTitle}
                    </div>
                    <img
                        src={ChatBotIcon}
                        className={classes.chatBotIcon}
                        alt="Chat Bot Icon"
                    />
                </div>

                {ratingQuestions.map((question, index) => (
                    <div className={classes.feedbackRow} key={index}>
                        <p className={classes.feedbackText}>{question}</p>
                        {emojiIcons(index)}
                    </div>
                ))}

                <div className={classes.footer}>
                    <a className={classes.skipLink} onClick={handleSkip}>
                        Skip
                    </a>
                    <a
                        className={`${classes.submitLink} ${isSubmitDisabled ? classes.disabled : ""}`}
                        onClick={isSubmitDisabled ? null : handleSubmit}
                        style={{ pointerEvents: isSubmitDisabled ? 'none' : 'auto' }}
                    >
                        Submit
                    </a>
                </div>
            </div>
        );
    };

    const renderSuccessMessage = () => {
        return (
            <div className={classes.successContainer}>
                <img className={classes.successIcon} src={SuccessIcon} alt="Success" />
                <span className={classes.successText}>Thank you for your feedback! Your rating has been submitted.</span>
            </div>
        );
    };
    if (!isVisible) return null;

    return (
        <div className={classes.root}>
            <div className={classes.card}>
                {isSuccessPopup ? renderSuccessMessage() : renderRatingContent()}
            </div>
        </div>
    );
}

const styles = () => ({
    root: {
        position: 'absolute',
        bottom: 0,
        left: 16,
        width: '100%',
        paddingBottom: '20px',
        zIndex: 999
    },
    card: {
        backgroundColor: 'white',
        borderRadius: '15px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        padding: '20px',
        textAlign: 'justify',
        width: 310,
    },
    cardDescriptionContainer: {
        display: 'flex',
        gap: 30,
    },
    summaryRatingDateText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    summaryRatingDescription: {
        fontSize: 16,
        fontWeight: '400',
        textAlign: 'left',
    },
    feedbackRow: {
        marginBottom: 16,
        paddingTop: 16,
    },

    feedbackText: {
        margin: 0,
        marginBottom: 2,
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00000066',
    },

    emojiList: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 32,
    },

    line: {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: '#E0B33433',
        transform: 'translateY(-50%)',
    },

    emoji: {
        width: 29,
        height: 29,
        backgroundColor: '#fff',
    },

    chatBotIcon: {
        width: 60,
        height: 60,
    },

    footer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 20,
    },

    skipLink: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#00000033',
        textDecoration: 'uppercase',
        cursor: 'pointer',
    },

    submitLink: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#52B2E2',
        textDecoration: 'uppercase',
        cursor: 'pointer',
    },

    disabled: {
        color: '#CCCCCC',
        cursor: 'not-allowed',
    },
    emojiLink: {
        width: 29,
        cursor: 'pointer',
        zIndex: 1,
    },
    ratingCardContainer: {
        borderBottomColor: '#0000001A',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        paddingBottom: 16
    },
    successContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    successIcon: {
        width: 50,
        height: 50,
    },

    successText: {
        fontSize: 14,
        fontWeight: 'bold',
        marginTop: 20,
        textAlign: 'center'
    }
});

export default withStyles(styles)(MeetingSummaryRating);
