import {makeStyles} from "@material-ui/core";
import classNames from 'classnames';
import {get} from 'lodash';
import React from "react";
import {connect} from 'react-redux'
import {NavLink} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    ul: {
      listStyleType: 'none',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        paddingLeft: 16
      }
    },
    navText: {
      textDecoration: 'none',
      color:
        'rgba(0, 0, 0, 0.5)',
    }
    ,
    current: {
      color: '#000000',
      fontWeight:
        'bold',
      borderBottom:
        '2px solid #E0B334',
      paddingBottom:
        4
    }
    ,
    li: {
      padding: '0 8px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 4px 8px 0px',
      }
    }
    ,
  }))
;


const SignedInLinks = ({ currentPath, experiences, handleClose}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const internship = get(experiences, 'internship');
  const learninghub = get(experiences, 'session');
  const mentorship = get(experiences, 'curriculum');
  const survey = get(experiences, 'survey');
  const chat = get(experiences, 'chattype');
  const chatStatus = get(experiences, 'chatStatus');
  const mentorshipChatStatus = get(chatStatus, 'mentorshipChat')
  const aiChatStatus = get(chatStatus, 'aiChat')

  return (
    <ul className={classes.ul} onClick={handleClose}>

      {(learninghub === 'use' || learninghub === 'show') && <li className={classes.li}><NavLink
        className={classNames(classes.navText, currentPath === 'learninghub' && classes.current)} to="/learninghub">
        {t('learningHub')}</NavLink></li>}

      {(mentorship === 'use' || mentorship === 'show') &&
      <li className={classes.li}><NavLink
        className={classNames(classes.navText, currentPath === 'mentorship' && classes.current)}
        to="/mentorship">{t('mentorshipCaps')}</NavLink>
      </li>}

      {(survey === 'use' || survey === 'show') && <li className={classes.li}><NavLink
        className={classNames(classes.navText, currentPath === 'survey' && classes.current)}
        to="/surveylisting">{t('survey')}</NavLink></li>}

      {((chat === 'use' || chat === 'show') && (mentorshipChatStatus === 'use' || aiChatStatus === 'use')) && <li className={classes.li}><NavLink
        className={classNames(classes.navText, currentPath === 'chat' && classes.current)}
        to="/chat">{t('chat')}</NavLink></li>}

      {(internship === 'use' || internship === 'show') && < li className={classes.li}><NavLink
        className={classNames(classes.navText, currentPath === 'internship' && classes.current)}
        to="/internship">{t('internships')}</NavLink></li>}

    </ul>
  );
};


const mapStateToProps = (state) => {
  return {
    experiences: get(state, "auth.user.status.experiences"),
  }
};

export default connect(mapStateToProps)(SignedInLinks);
