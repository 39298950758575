import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {orderSessionSteps} from "business/session";
import classNames from 'classnames';
import {convertUrl, PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import {history} from 'common/history';
import {filter, flatten, get, map, some} from "lodash";
import React, { useEffect, useState } from 'react';
import SwipeableViews from "react-swipeable-views";
import SessionStep from "views/session/SessionStep";
import { useTranslation } from "react-i18next";


const MaterialStyles = theme => ({
  sessionWrapper: {
    height: PAGE_HEIGHT_WITH_NAVBAR,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  navButton: {
    display: 'flex',
    alignContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 57,
    padding: '2px 24px',
    display: 'flex',
  },
  sessionStepWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    [theme.breakpoints.down('xs')]: {
      height: '94vh'
    },
  }
});


const SessionStepWrapper = ({classes, session, role, initQuiz, stepCompleted, initQuizAnswers, quizAnswers, initSession, startSession}) => {
  const {t} = useTranslation();
  const sessionId = session.id;
  useEffect(() => {
    if (sessionId) startSession(sessionId, true) && initSession(sessionId, true);
  }, [t]);
  const orderedSessionSteps = orderSessionSteps(session);
  const firstStepId = 0;
  // const initialStepIndex = findIndex(orderedSessionSteps, (step) => step.id === firstStepId);

  // const [currentStep, setCurrentStep] = useState(initialStepIndex);
  const [currentStep, setCurrentStep] = useState(firstStepId);

  const quizHasMandatoryQuestions = (quiz) => flatten(quiz.questions).some(q => q.mandatory === true);

  const isStepCompleted = (stepIndex, quizAnswers, sessionId) => {
    const step = orderedSessionSteps[stepIndex];
    const stepContent = get(step, 'content');
    const mandatoryQuizContents = filter(stepContent, (content) => content.contentType.toLowerCase() === 'quiz' && quizHasMandatoryQuestions(content.quiz));

    const answersForMandatoryQuizContents = mandatoryQuizContents.map((quiz) => get(quizAnswers, `[${sessionId}][${step.id}][${quiz.quiz.id}].answers`));

    return !some(answersForMandatoryQuizContents, (answer) => answer === undefined);
  };

  const handleStepChange = (currentStep) => {
    const step = currentStep < 0 ? 0 : currentStep > orderedSessionSteps.length - 1 ? orderedSessionSteps.length - 1 : currentStep;
    setCurrentStep(step);
    stepCompleted(session, orderedSessionSteps[step], quizAnswers);
  };

  const nextStepChange = (currentStep, quizAnswers, sessionId) => {
    if (isStepCompleted(currentStep, quizAnswers, sessionId)) {
      let step = currentStep + 1;
      const nextStep = step < 0 ? 0 : step > orderedSessionSteps.length - 1 ? orderedSessionSteps.length - 1 : step;
      setCurrentStep(nextStep);
      stepCompleted(session, orderedSessionSteps[currentStep], quizAnswers);
    }
  };

  const isLastPage = currentStep === session.sessionSteps.length - 1;

  return (
    <div className={classNames(classes.sessionWrapper, 'animated faster slideInUp')}
         style={{
           background: `linear-gradient(0deg, rgba(250, 247, 241, 0.6), rgba(250, 247, 241, 0.6)), url(${convertUrl(session.banner)}) 0% 0% / cover no-repeat`,
         }}>
      <Container maxWidth={"md"} disableGutters={true}>
        <Grid container justify="center" spacing={4}>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}} style={{paddingBottom: 0}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowBackIcon style={{color: "#E0B334"}} fontSize={'large'}
                                 onClick={() => currentStep === 0 ? history.goBack() : handleStepChange(currentStep - 1)}/>
                </IconButton>
              </div>
            </div>
          </Box>
          <Grid item xs={12} sm={6} md={6} style={{padding: '0px 16px'}}>
            <div className={classes.sessionStepWrapper}>
              <SwipeableViews
                axis={'x'}
                index={currentStep}
                enableMouseEvents
                disabled={!isStepCompleted(currentStep, quizAnswers, session.id)}
                onChangeIndex={(i) => handleStepChange(i)}
                style={{height: PAGE_HEIGHT_WITH_NAVBAR, overflowY: 'hidden'}}
              >
                {orderedSessionSteps.map((step, index) => <SessionStep
                  progressCompleted={(currentStep + 1) * 100 / orderedSessionSteps.length}
                  sessionColor={session.color}
                  key={step.id}
                  initQuiz={initQuiz}
                  role={role}
                  step={step}
                  sessionId={session.id}
                  quizAnswers={quizAnswers}
                  isLearningHub={session.isLearningHub}
                  initQuizAnswers={initQuizAnswers}
                  isInView={index === currentStep}
                />)}
              </SwipeableViews>
              {isLastPage && isStepCompleted(currentStep, quizAnswers, session.id) && <div>
                <Button variant="contained"
                        style={{backgroundColor: '#E0B334', fontSize: 12, color: 'white', borderRadius: 0}}
                        fullWidth
                        onClick={() => stepCompleted(session, orderedSessionSteps[currentStep], isStepCompleted(currentStep, quizAnswers, session.id) && quizAnswers) && history.push('/session/step/sessionfeedback/', {sessionId: session.id})}
                        size="large">
                  {t('doneCaps')}
                </Button>
              </div>
              }
            </div>
          </Grid>
          <Box display={{xs: 'none', sm: 'block', md: 'block'}}>
            <div className={classes.navButton}>
              <div className={classes.button}>
                <IconButton>
                  <ArrowForwardIcon
                    style={{color: isLastPage || !isStepCompleted(currentStep, quizAnswers, session.id) ? "#D8DDE5" : "#E0B334"}}
                    fontSize={'large'}
                    onClick={() => nextStepChange(currentStep, quizAnswers, session.id)}/></IconButton>
              </div>
            </div>
          </Box>
        </Grid>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(SessionStepWrapper);
