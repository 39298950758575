import request from "api/request";

export const fetchChatChannels = (auth) => request({
  url: `api/v2/chat/channels/`,
  method: 'GET',
  auth,
});

export const fetchUnreadCount = (auth) => request({
  url: `api/v2/chat/unread-message/`,
  method: 'GET',
  auth,
});

export const searchMessages = (text, pageNumber, auth) => request({
  url: `api/v2/chat/search-chat-message/`,
  method: 'POST',
  auth,
  data: { search_text: text, page_number: pageNumber },
})

export const fetchChatBotUsers = () => request({
  url: `api/v2/chat/bot-users/`,
  method: 'GET',
})

export const storeUserChatRating = (channelId, ratingsData, auth) =>
  request({
    url: `api/v2/chat/${channelId}/rating/`,
    method: 'POST',
    auth,
    data: {
      rating: ratingsData,
    },
  });

export const getChatRatingStatus = (selectedChannelId, auth) =>
  request({
    url: `api/v2/chat/${selectedChannelId}/rating-status/`,
    method: 'GET',
    auth,
  });

export const getChatChannelHistory = (selectedChannelId, auth) =>
  request({
    url: `api/v2/chat/${selectedChannelId}/channel-history/`,
    method: 'GET',
    auth,
  })
  
export const storeUserMeetingSummaryRating = (ratingsPayloadData, auth) =>
  request({
    url: `api/v2/chat/meeting-summary-rating/`,
    method: 'POST',
    auth,
    data: ratingsPayloadData,
  });
