import withStyles from "@material-ui/core/styles/withStyles";
import SurveyCard from "components/SurveyCard";
import {map} from 'lodash';
import React, {Fragment} from "react";

const styles = theme => ({
  cardWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    color: '#766430',
    fontSize: 18,
    fontFamily: 'Roboto',
    marginLeft: 16,
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 4,
      marginBottom: 16
    }
  }
});


const SurveyCardListing = ({classes, surveys, title = ""}) => {

  return (
    <Fragment>
      <div className={classes.title}>{title}</div>
      <div className={classes.cardWrapper}>
        {map(surveys, survey => <SurveyCard survey={survey}/>)}
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(SurveyCardListing);


