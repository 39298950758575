import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import {get, noop} from "lodash";
import React, {Fragment} from "react";


const styles = theme => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {}
  },
  disabled: {
    backgroundColor: '#d0d0d0'
  },
  focused: {
    border: '1px solid #E0B334 ',
  },
  error: {
    border: '1px solid #E01F0D',
  },
  notchedOutline: {
    border: '0px !important',
  },
  input: {
    color: '#000000'
  }
});

const TextInput = (props) => {
  const {onAnswered = noop, classes, value, error, ...restProps} = props;
  const isDisabled = get(props,"question.disabled",false);
  const handleChange = (e) => {
    onAnswered(e.target.value);
  };

  return (
    <Fragment>
      <TextField
        {...restProps}
        onChange={handleChange}
        type={'text'}
        value={value}
        variant={'outlined'}
        fullWidth
        disabled={isDisabled}
        InputProps={{
          classes: {
            root: classes.root,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
            error: classes.error
          },
        }}
      />
    </Fragment>
  );
};


export default withStyles(styles)(TextInput);
