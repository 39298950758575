import withStyles from "@material-ui/core/styles/withStyles";
import {getTimeRemaining, timeRemainingMessage} from "business/matching";

import CongratulationsWindow from "components/CongratulationsWindow";
import EmptyMatchingCard from "components/EmptyMatchingCard";
import ScreenDescription from "components/ScreenDescription";
import {get, isEmpty, isNumber, map} from "lodash";
import React from "react";
import {convertUrl} from "../../common/helpers";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px'
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  alternateTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  message: {
    fontSize: 12,
    lineHeight: '21px',
    color: '#747474',
    textAlign: 'center',
    marginTop: 14,
  },
  alternateMessage: {
    fontSize: 10,
  },
  imageWrapper: {
    marginTop: 24,
    marginBottom: 8,
  },
  contentWrapper: {
    marginBottom: 40,
    backgroundColor: '#FFEFE9',
    borderRadius: 5,
    width: '390px',
    [theme.breakpoints.down('xs')]: {
      width: '320px'
    },
  },
  groupTitle: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    padding: '18px 23px',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.49)',
  },
  expiryMessage: {
    fontSize: 12,
    marginTop: 8,
  },
});


const GroupHold = ({classes, listDetail, groupInfo,  setCongratulationsState, showGroupHoldCongratulationDialog}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const title = st(get(listDetail, "title"), get(listDetail, "localeTitle")) || t("groupHold")
  const description = st(get(listDetail, "description"), get(listDetail, "localeDescription"));

  const groupMembers = get(groupInfo, "existingMemberInfo");
  const groupSize = get(groupInfo, "groupSize");
  const actualSize = get(groupInfo, "actualSize");
  const image = get(listDetail, "image", "");


  const emptyCards = [];
  for (let i = 1; i <= groupSize - actualSize; i++) {
    emptyCards.push(i);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>

      <div className={classes.expiryMessage}
           style={{color: '#41A156'}}>
        {t('beginsIn')} <span style={{fontWeight: 'bold'}}>
              {timeRemainingMessage(getTimeRemaining(get(groupInfo, "createdOn"), get(groupInfo, "groupStartsIn")))}</span>
      </div>

      <ScreenDescription description={description}
                         title={title}
      />

      {!isEmpty(image) &&
      <div className={classes.imageWrapper}>
        <img src={convertUrl(image)} alt={t('Matching')}/>
      </div>}
      <div className={classes.contentWrapper}>
        <div className={classes.groupTitle}>{t("yourMentorshipGroupCaps")}</div>

        {map(groupMembers, member => isNumber(get(member, "id")) && <EmptyMatchingCard
          member={member}
        />)}

        {map(emptyCards, card => <EmptyMatchingCard key={card} isEmptySpot={true}/>)}
      </div>
      <CongratulationsWindow open={showGroupHoldCongratulationDialog}
                             handleClose={() => setCongratulationsState(false)}/>
    </div>
  );
};

export default withStyles(styles)(GroupHold);


