import Container from "@material-ui/core/Container/Container";
import Grid from "@material-ui/core/Grid/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import {PAGE_HEIGHT_WITH_NAVBAR} from "common/helpers";
import MentorshipProgramStatusPanel from "components/MentorshipProgramStatusPanel";
import MentorshipRightPanel from "components/MentorshipRightPanel";
import PickupWhereYouLeft from "components/PickupWhereYouLeft";
import {get, isEmpty} from "lodash";
import React, {useEffect} from 'react';
import MentorshipSessions from "views/Mentorship/MentorshipSessions";
import '../../common/styles/animate.css';
import { useSetTranslation } from "common/useSetTranslation";
import SmallScreenMessengerContainer from "containers/SmallScreenMessengerContainer";


const MaterialStyles = theme => ({
  mainWrapper: {
    background: `linear-gradient(180deg, #FFEFE9  0%, rgba(255, 239, 233, 0) 100%)`,
    height: PAGE_HEIGHT_WITH_NAVBAR,
    padding: '30px 0px',
    overflowY:'auto',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 12px',
      overflowX: 'hidden',
    },
  },
  pickupWhereYouLeft: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: 16
    },
  }
});

const ActiveMentorshipLearning = ({classes, displayName, setCurrentPath, mentorship, sessions, partnerDetails, initMentorship, mentorshipId, initMentorshipSessions, role, toggleMentorshipBookmark, progress, setCurrentStepId, lastMentorshipSessionStatus, completeByMessage, initSession}) => {

  useEffect(() => {
    setCurrentPath('mentorship');
    initMentorship(mentorshipId);
    initMentorshipSessions();
  }, []);

  const {st} = useSetTranslation();
  return (
    <div className={classes.mainWrapper}>
      <Container disableGutters>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={12} sm={4} md={2}>
            <MentorshipProgramStatusPanel
              partnerDetails={partnerDetails}
              role={role}
              projectDetail={st(get(mentorship, 'projectDetail'), get(mentorship, 'localeProjectDetail'))}
              mentorshipType={get(mentorship, 'mentorshipType')}
              progress={progress}
              displayName={displayName}
            />
            {!isEmpty(lastMentorshipSessionStatus) && <div className={classes.pickupWhereYouLeft}>
              <PickupWhereYouLeft initSession={initSession} lastMentorshipSessionStatus={lastMentorshipSessionStatus}
                                  completeByMessage={completeByMessage}/>
            </div>}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MentorshipSessions sessions={sessions} toggleBookmark={toggleMentorshipBookmark}/>
          </Grid>
          <Grid item md={1}>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <MentorshipRightPanel initSession={initSession} completeByMessage={completeByMessage}
                                  lastMentorshipSessionStatus={lastMentorshipSessionStatus}
                                  setCurrentStepId={setCurrentStepId}/>
          </Grid>
        </Grid>
        <SmallScreenMessengerContainer/>
      </Container>
    </div>
  )
};

export default withStyles(MaterialStyles)(ActiveMentorshipLearning);
