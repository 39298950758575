import firebase from "firebase/app";
import "firebase/auth";
import config from "../common/config";

const firebaseConfig = {
  apiKey: config.FIREBASE_CONFIG.APIKEY,
  authDomain: config.FIREBASE_CONFIG.AUTHDOMAIN,
  databaseURL: config.FIREBASE_CONFIG.DATABASEURL,
  projectId: config.FIREBASE_CONFIG.PROJECTID,
  storageBucket: config.FIREBASE_CONFIG.STORAGEBUCKET,
  messagingSenderId: config.FIREBASE_CONFIG.MESSAGINGSENDERID,
  appId: config.FIREBASE_CONFIG.APPID,
  measurementId: config.FIREBASE_CONFIG.MEASUREMENTID
};

export const myFirebase = firebase.initializeApp(firebaseConfig);

//
// const firebaseAppAuth = myFirebase.auth();
//
// const providers = {
//   googleProvider: new firebase.auth.GoogleAuthProvider(),
// };
