import {addFormDetailsToProfile, getProjectList, gettingDeclaration, submitProfileData} from "actions/profileAction";
import { initLanguages } from "actions/languageAction";
import {connect} from 'react-redux';
import Signup from "views/signup/Signup";

const mapStateToProps = ({profile: {signupPageIndex, ...rest}, language: {supportedLanguages}, auth}) => {
  return ({
    signupPageIndex,
    profile: rest,
    supportedLanguages,
    auth
  })
}

export default connect(mapStateToProps, {
  addFormDetailsToProfile,
  gettingDeclaration,
  getProjectList,
  submitProfileData,
  initLanguages
})(Signup);
