import {nextPage, previousPage, setSessionQuizAnswer, submitQuiz} from "actions/sessionAction";
import {connect} from 'react-redux';
import SessionQuiz from "views/session/SessionQuiz";


const mapStateToProps = ({session: {currentQuiz, currentPage, stepId, currentStepQuizAnswers, quizError, currentSession}, auth: {user: {role}}}) => ({
  quiz: currentQuiz,
  session: currentSession,
  stepId,
  currentStepQuizAnswers,
  currentPage,
  quizError,
  role
});


export default connect(mapStateToProps, {nextPage, previousPage, setSessionQuizAnswer, submitQuiz})(SessionQuiz);
