const actions = {
  MOVE_TO_NEXT_PAGE: '[Signup] MOVE_TO_NEXT_PAGE',
  MOVE_TO_PREVIOUS_PAGE: '[Signup] MOVE_TO_PREVIOUS_PAGE',
  ADD_FORM_DETAILS_TO_PROFILE: '[profile] ADD_FORM_DETAILS_TO_PROFILE',
  ADD_BASIC_DETAILS_TO_PROFILE: '[profile] ADD_BASIC_DETAILS_TO_PROFILE',
  SAVE_PROJECT_LIST: '[profile] SAVE_PROJECT_LIST',
  SET_DECLARATION: '[profile] SET_DECLARATION',
  SIGNUP_ERROR: '[profile] SIGNUP_ERROR',
  SIGNUP_SUCCESS: '[profile] SIGNUP_ERROR',
  ADD_DISPLAY_NAME: '[profile] ADD_DISPLAY_NAME',
  RESET_USER_PROFILE: '[profile] RESET_USER_PROFILE',
};

const initProfileState = {
  signupPageIndex: 0,
  firstName: "",
  lastName: "",
  email: "",
  userType: "",
  declaration: "",
  primaryLanguage: {},
  signupError: false,
  signupComplete: false,
  displayName: "",
};

export default (state = initProfileState, action) => {
  switch (action.type) {
    case actions.ADD_FORM_DETAILS_TO_PROFILE :
      return {
        ...state,
        ...action.payload
      };
    case actions.ADD_BASIC_DETAILS_TO_PROFILE:
      return {
        ...state,
        firstName: action.payload.additionalUserInfo.profile.given_name,
        lastName: action.payload.additionalUserInfo.profile.family_name,
        email: action.payload.additionalUserInfo.profile.email,
        displayName: action.payload.displayName,
      };
    case actions.SAVE_PROJECT_LIST:
      return {
        ...state,
        projectList: action.payload
      };
    case actions.SET_DECLARATION:
      return {
        ...state,
        declaration: action.payload
      };
    case actions.SIGNUP_ERROR:
      return {
        ...state,
        signupError: true,
        signupErrorMessage: action.payload
      };
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        signupError: false,
        signupComplete: true,
      };
    case actions.ADD_DISPLAY_NAME:
      return {
        ...state,
        displayName: action.payload,
      };

    case actions.RESET_USER_PROFILE:
      return {
        ...state,
        displayName: "",
      };



    default:
      return state

  }
}


export {
  actions
};
