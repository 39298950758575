import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import withStyles from "@material-ui/core/styles/withStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import FilterListIcon from '@material-ui/icons/FilterList';
import classNames from "classnames";
import {CuratedSessionIcon, OngoingIcon} from "common/customIcon";
import React, {Fragment} from 'react';
import ChatFullScreen from "views/ChatFullScreen";
import {useTranslation} from "react-i18next";


const MaterialStyles = theme => ({
    navigationWrapper: {
      marginTop: 18,
      height: '172px',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'row',
        display: 'flex',
        padding: '4px 16px 4px 16px',
        height:'100%'
      }
    },
    menuItem: {
      height: '32px',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: 5,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      paddingLeft: 16,
      alignItems: 'center',
      fontSize: '10px',
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: 'black',
      [theme.breakpoints.down('md')]: {
        width: 170,
        margin:'0 6px'
      }

    },
    menuIcon: {
      float: 'right',
    },
    mobileViewmenuItem: {
      justifyContent: 'space-between',
      textDecoration: 'none',
      color: 'black'
    },
    greetingMessage: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'black',
      [theme.breakpoints.down('md')]: {
        display:'none'
      }

    },
    ul: {
      listStyleType: 'none',
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        padding: '4px 16px 4px 16px'
      }
    },
    li: {
      padding: '0 8px',
      [theme.breakpoints.down('sm')]: {
        padding: '8px 4px 8px 0px',
      },
      borderBottom: '1px solid rgba(0, 0, 0, 0.72)',
    },
    mobileView: {
      display: 'flex',
      alignItems:
        'center',
      justifyContent:
        'space-between'
    },
    currentLocation: {
      color: 'white',
      backgroundColor: '#E0B334',
    },
    mobileCurrentLocation: {
      color: 'white',
      fontWeight: 'bold',
      borderBottom: '2px solid #E0B334'
    }

  })
;

const ChatWindowNavigation = ({classes, displayName, onChange, activeSection}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {t} = useTranslation();
  const chatIsActive = activeSection === ChatFullScreen.sections.CHAT;
  const videoIsActive = activeSection === ChatFullScreen.sections.VIDEO;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const MobileView = <div className={classes.mobileView}>
    <div className={classes.greetingMessage}>{`Hello, ${displayName}`}</div>
    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleMenu}>
      <FilterListIcon style={{color: '#E0B334'}}/>
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleClose}
    >
      <ul className={classes.ul}>
        <li
          className={classNames(classes.li, chatIsActive && classes.mobileCurrentLocation)}>
          <div className={classes.mobileViewmenuItem}
               onClick={() => onChange(ChatFullScreen.sections.CHAT)}>{t("Chat")}</div>
        </li>
        <li className={classNames(classes.li, videoIsActive && classes.mobileCurrentLocation)}>
          <div className={classes.mobileViewmenuItem}
               onClick={() => onChange(ChatFullScreen.sections.VIDEO)}>{t("Video")}</div>
        </li>
      </ul>
    </Menu>
  </div>;

  const NormalView = <div className={classes.navigationWrapper}>
    <Grid container justify="center" style={{height: '100%'}}>
      <div className={classes.greetingMessage}>{`Hello, ${displayName}`}</div>
      <div onClick={() => onChange(ChatFullScreen.sections.CHAT)}
            className={classNames(classes.menuItem, chatIsActive && classes.currentLocation)}>
          <span><p style={{display: 'inline-block'}}>{t("Chat")}</p>
            <span className={classes.menuIcon}>
            <CuratedSessionIcon
              viewBox="0 0 16 9"
              style={{color: chatIsActive ? 'white' : '#E0B334'}}/>
          </span></span>
      </div>

      <div onClick={() => onChange(ChatFullScreen.sections.VIDEO)}
            className={classNames(classes.menuItem, videoIsActive && classes.currentLocation)}>
          <span><p style={{display: 'inline-block'}}>{t("Video")}</p>
            <span className={classes.menuIcon}>
            <OngoingIcon
              viewBox="0 0 16 9"
              style={{color: videoIsActive ? 'white' : '#E0B334'}}/>
          </span></span>
      </div>

    </Grid>
  </div>;

  const isMobileView = useMediaQuery('(max-width:600px)');

  return (
    <Fragment>
      {isMobileView ? MobileView : NormalView}
    </Fragment>
  )
};

export default withStyles(MaterialStyles)(ChatWindowNavigation);
