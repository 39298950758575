import {makeStyles} from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl/FormControl"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput"
import Select from "@material-ui/core/Select/Select"
import {find, map, sortBy} from 'lodash'
import React from "react"

const useOutlinedInputStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ffffff',
    "& $notchedOutline": {
      border: 'none',
    },
    "&:hover $notchedOutline": {},
    "&$focused $notchedOutline": {},
  },
  focused: {},
  error: {
    border: '1px solid red',
  },
  notchedOutline: {}
}));


const SimpleDropdown = ({DropDownItems, onAnswered, value}) => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const handleChoice = (e) => {
    onAnswered(e.target.value);
  }

  return (
    <FormControl
      style={{display: 'flex'}}>
      <Select
        variant="outlined"
        onChange={handleChoice}
        value={value}
        input={
          <OutlinedInput
            labelWidth={100}
            id="outlined-age-simple"
            classes={outlinedInputClasses}
          />
        }
      >
        {map(DropDownItems, (item) =>
          <MenuItem  value={item}>{item}</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};


export default (SimpleDropdown);
