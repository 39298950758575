import {makeStyles} from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

const useStyles = (barColor, progressBarColor, height) => makeStyles({
  root: {
    height: height,
    backgroundColor: progressBarColor,
  },
  progressWrapper: {
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  barColorPrimary: {
    backgroundColor: barColor
  }
});

const ProgressBar = (
  {
    completed,
    style,
    completionColor,
    progressBarColor,
    height = 6,
  }) => {
  const classes = useStyles(completionColor, progressBarColor, height)();
  return (
    <div>
      <LinearProgress
        classes={classes}
        variant="determinate"
        value={completed}
      />
    </div>

  )
}


export default (ProgressBar);
