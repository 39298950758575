import React from "react";
import ListMember from "components/Chat/ListMember";
import { find, get } from 'lodash';
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
  ChatMemberListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'white'
  },
  isLoadingListMember: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    borderBottom: '0.5px solid rgba(0, 0, 0, 0.15)',
  }
});

const getUnreadCountFor = (channelList, channelId) => find(channelList, channel => channel.channelId === channelId);

const ChatMemberList = ({ classes, chatChannels, myId, isLoading, onItemClick, channelUnreadCount, selectedChannelId, botUsers }) => {

  const isLoadingListMember =
    <>
      <div className={classes.isLoadingListMember}>
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton animation="wave" height={60} width="68%" style={{ marginLeft: 12 }} />
      </div>
      <div className={classes.isLoadingListMember}>
        <Skeleton variant="circle" width={48} height={48} />
        <Skeleton animation="wave" height={60} width="68%" style={{ marginLeft: 12 }} />
      </div>
    </>;

  const filteredChannels = (Array.isArray(chatChannels) ? chatChannels : [])
  .filter(channel => {
    const channelMembers = channel.channelMembers || [];
    const myMember = channelMembers.find(member => member.userId === myId);

    if (myMember && myMember.isPairManager) {
      const otherMembersInactive = channelMembers
        .filter(member => member.userId !== myId)
        .every(member => !member.isActive);
      return !otherMembersInactive;
    }
    return true;
  });
  return (
    <div className={classes.ChatMemberListWrapper}>
      {isLoading ? isLoadingListMember :
        filteredChannels.map(channel =>
          <ListMember
            key={channel.channel_id}
            unreadMessageCount={get(getUnreadCountFor(channelUnreadCount, get(channel, 'channelId')), 'count')}
            unreadMessageData={get(getUnreadCountFor(channelUnreadCount, get(channel, 'channelId')), 'data')}
            isGroup={get(channel, 'isGroup')}
            members={get(channel, 'channelMembers')}
            channelName={get(channel, 'channelName')}
            onItemClick={onItemClick}
            channelId={get(channel, 'channelId', '')}
            isSelected={selectedChannelId === get(channel, 'channelId')}
            myId={myId}
            botUsers={botUsers}
          />
        )}
    </div>
  );
};

export default withStyles(styles)(ChatMemberList);
