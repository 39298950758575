import { apiAction, dataAction, staticAction } from "actions/actionWrappers";
import { get, isEmpty } from 'lodash';
import { actions } from "reducers/languageReducer"
import { fetchSupportedLanguages, getTranslation, updatePrimaryLanguage } from "api/languageApi";
import i18n from "i18n";
import { initApp } from "actions/authAction";

export const initLanguages = () => async (dispatch, getState) => {
  let state = await getState();
  if (isEmpty(state.language.defaultLanguageCode)) {
    await dispatch(getSupportedLanguages());
    state = await getState();
    await dispatch(changeLanguage(state.language.defaultLanguageCode, false));
  }
}
export const getSupportedLanguages = () => async dispatch => {
  const supportedLanguages = await dispatch(apiAction(fetchSupportedLanguages));
  dispatch(dataAction(actions.SAVE_SUPPORTED_LANGUAGES, supportedLanguages));
};

const saveTranslationFileToLocal = async (langCode, path) => {
  localStorage.setItem(`${langCode}_translation`, JSON.stringify(path));
};

export const getTranslationResource = (languageCode) => async dispatch => {
  dispatch(staticAction(actions.FETCHING_TRANSLATION));
  try {
    const details = await dispatch(apiAction(getTranslation, languageCode));
    await saveTranslationFileToLocal(languageCode, details);
    dispatch(staticAction(actions.FETCHED_TRANSLATION));
  } catch (e) {
    console.log(e)
  }
};

const addResourceToTranslation = async (languageCode) => {
  try {
    await i18n.addResources(languageCode, 'translation', JSON.parse(localStorage.getItem(`${languageCode}_translation`)))
    await i18n.loadLanguages(languageCode);
  } catch (e) {
    console.log(e)
  }
};

export const changeUserLanguage = (languageCode) => async (dispatch) => {
  await dispatch(apiAction(updatePrimaryLanguage, languageCode));
  await dispatch(changeLanguage(languageCode));
};

export const changeLanguage = (languageCode, initRequired = true) => async (dispatch, getState) => {
  const state = await getState();
  if (get(state, "language.activeLanguageCode") === languageCode) return;
  try {
    await dispatch(getTranslationResource(languageCode));
    await addResourceToTranslation(languageCode);
    await i18n.changeLanguage(languageCode);
    await dispatch(dataAction(actions.SET_ACTIVE_LANGUAGE_CODE, languageCode));
    initRequired && dispatch(initApp(true));
  } catch (e) {
    console.log(e)
  }
};
