import withStyles from "@material-ui/core/styles/withStyles";
import {map} from 'lodash'
import moment from "moment";
import React from "react";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const styles = theme => ({
  wrapper: {
    padding: '16px 18px 0px 18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  sessionDate: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#DE6E49'
  },
  sessionTitle: {

  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '16px',
    color:'#000000'
  }
});


const ScheduledMentorshipSession = ({classes, sessions}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const _render_session = (session) => <div className={classes.wrapper}>
    <div className={classes.sessionDate}>{t('completeBy')} {moment(session.endDate).format('D MMM')}</div>
    <div className={classes.sessionTitle}>
      <Link to={`/mentorship/${session.id}/sessionintro`} className={classes.link}>
        {st(session.title, session.localeTitle)}
      </Link>
    </div>
  </div>;
  return (
    <div>
      {map(sessions, session => _render_session(session))}
    </div>
  );
};

export default withStyles(styles)(ScheduledMentorshipSession);


