import withStyles from "@material-ui/core/styles/withStyles"
import classNames from 'classnames'
import {PostIcon, QuoteIcon} from "common/customIcon"
import _ from 'lodash'
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";


const styles = theme => ({
  wrapper: {
    backgroundColor: '#E7EDF0',
    padding: '26px 16px'
  },
  sectionLabel: {
    fontSize: 10,
    display: 'flex'
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: '12px 0px'
  },
  btn: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    color: '#C7C7C7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: ' center',
    padding: '2px 8px',
    cursor: 'pointer',
  },
  btnSelected: {
    backgroundColor: '#52B2E2',
    color: '#ffffff'
  }
})


const TypeOfPostFilter = ({classes, setFeedFilter, filterValue}) => {
  const {t} = useTranslation();


  const [postTypeFilters, setPostTypeFilters] = useState([...filterValue])

  useEffect(() => {
    setFeedFilter("event", postTypeFilters)
  }, [postTypeFilters])

  const addType = (type) => {
    setPostTypeFilters(_.xor(postTypeFilters, [type]))
  }

  const isSelected = (type) => {
    return _.find(postTypeFilters, (i) => i === type)
  }

  const clear = () => {
    setPostTypeFilters([])
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.sectionLabel}>
        <span style={{color: '#000000'}}>{t('typeOfPost')}</span>
        <span style={{color: '#728D9A', marginLeft: 'auto', cursor: 'pointer'}} onClick={clear}>{t('clear')}</span>
      </div>
      <div className={classes.btns}>
        <div className={classNames(classes.btn, isSelected("Post") && classes.btnSelected)}
             onClick={() => addType("Post")}>
          <PostIcon style={{fontSize: 14, color: isSelected("Post") && '#ffffff'}}/>
          <span style={{marginLeft: 8}}>Posts</span>
        </div>

        <div className={classNames(classes.btn, isSelected("Quotes") && classes.btnSelected)}
             onClick={() => addType("Quotes")}>
          <QuoteIcon style={{fontSize: 14, color: isSelected("Quotes") && '#ffffff'}}/>
          <span style={{marginLeft: 8}}>Quotes</span>
        </div>

        {/*<div className={classNames(classes.btn, isSelected("Liked") && classes.btnSelected)}*/}
        {/*onClick={() => addType("Liked")}><HeartIcon*/}
        {/*style={{fontSize: 14, color: isSelected("Liked") && '#ffffff'}}/><span*/}
        {/*style={{marginLeft: 8}}>Liked</span>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default withStyles(styles)(TypeOfPostFilter)


