import withStyles from "@material-ui/core/styles/withStyles";
import {getScreenDetail, getScreenIndex} from "business/matching";
import {isMentee, isMentor} from "business/role";
import {PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER} from "common/helpers";
import React from "react";
import AwaitingResponse from "views/Matching/AwaitingResponse";
import FindingMatches from "views/Matching/FindingMatches";
import GroupHold from "views/Matching/GroupHold";
import MenteeMatchSubmitButton from "views/Matching/MenteeMatchSubmitButton";
import MentorMatchSubmitButton from "views/Matching/MentorMatchSubmitButton";
import PotentialMatches from "views/Matching/PotentialMatches";
import {isEmpty} from "lodash";
import Container from "@material-ui/core/Container/Container";
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";


const styles = theme => ({
  wrapper: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 390,
    height: '100%',
    minHeight:PAGE_HEIGHT_WITH_NAVIGATION_CONTAINER,
    overflowY: 'auto',
    position:'relative',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  }
});

const LoadingSkeleton = () => {
  return (
    <div>
      <Container maxWidth={"md"} disableGutters={true}
                 style={{
                   justifyContent: 'center',
                   alignItems: 'center',
                   display: 'flex',
                   marginTop: 50,
                   flexDirection: 'column'
                 }}>
        <div style={{marginTop: 12, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <Skeleton animation="wave" height={50} width="100px"/>
          <Skeleton animation="wave" height={100} width="300px"/>
          <Skeleton animation="wave" height={150} width="400px" style={{marginTop: 50}}/>
          <Skeleton animation="wave" height={150} width="400px" style={{marginTop: -50}}/>
        </div>
      </Container>
    </div>
  );
}



const MatchingContentWrapper = ({classes, userMatchType, groupInfo, expiryHours, activeMatches, submitMatchesResponse, screen, screensList, submitMatch, role, updateMatch, setCongratulationsState, showGroupHoldCongratulationDialog}) => {

  const screenindex = getScreenIndex(screen);
  let _renderScreen;

  switch (screenindex) {
    case 1:
      _renderScreen = <FindingMatches
          listDetail={getScreenDetail(screensList, "finding_matches")}/>
      break
    case 2:
      _renderScreen =
        <PotentialMatches
          matches={activeMatches}
          onUpdateMatch={updateMatch}
          role={role}
          expiryHours={expiryHours}
          groupInfo={groupInfo}
          userMatchType={userMatchType}
          submitMatchesResponse={submitMatchesResponse}
          listDetail={getScreenDetail(screensList, "potential_match")}/>;
      break;
    case 3:
      _renderScreen = <AwaitingResponse
        matches={activeMatches}
        listDetail={getScreenDetail(screensList, "awaiting_mentor_response")}/>;
      break;
    case 4:
      _renderScreen = <GroupHold
        matches={activeMatches}
        groupInfo={groupInfo}
        setCongratulationsState={setCongratulationsState}
        showGroupHoldCongratulationDialog={showGroupHoldCongratulationDialog}
        listDetail={getScreenDetail(screensList, "group_hold")}/>;
      break;
    default:
      _renderScreen = <FindingMatches
        listDetail={getScreenDetail(screensList, "finding_matches")}
      />
  }

  return (
    <div className={classes.wrapper}>
      {isEmpty(screensList) ? <LoadingSkeleton/> : _renderScreen}
      <div style={{position: 'sticky',width:'100%', bottom: 0,marginTop:'auto'}}>
        {screenindex === 2 && isMentee(role) &&
        <MenteeMatchSubmitButton matches={activeMatches} submit={() => submitMatch(activeMatches)}/>}
        {screenindex === 2 && isMentor(role) &&
        <MentorMatchSubmitButton matches={activeMatches} submit={() => submitMatch(activeMatches,true)}/>}
      </div>
    </div>
  )
};

export default withStyles(styles)(MatchingContentWrapper)


