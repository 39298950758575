export const actions = {
  STORE_WORK_READINESS_SCORE: 'STORE_WORK_READINESS_SCORES',
  STORE_COMMUNITY_ELEMENTS: 'STORE_COMMUNITY_ELEMENTS',
  SET_GROUP_HOLD_MESSAGE_STATE: 'SET_GROUP_HOLD_MESSAGE_STATE',
};


const initSuplimentary = {
  workReadinessScore: {},
  faqTopics: {},
  community: {},
  showGroupHoldCongratulationDialog: true,
};

export default (state = initSuplimentary, action) => {
  switch (action.type) {
    case actions.STORE_WORK_READINESS_SCORE:
      return {
        ...state,
        workReadinessScore: action.payload
      };
    case actions.STORE_COMMUNITY_ELEMENTS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_GROUP_HOLD_MESSAGE_STATE:
      return {
        ...state,
        showGroupHoldCongratulationDialog: action.payload
      };
    default:
      return state
  }
}

