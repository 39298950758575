import request, { toSnakeCase } from 'api/request';
import _, { isArray, isUndefined } from 'lodash';


const replaceIfNecessary = (url, isLearningHub) => isLearningHub ? url : url.replace('learninghub', 'mentorships');

const parseSessions = (allSessions) => allSessions.results;

export const fetchLearningHubSessions = (auth) => request({
  url: 'api/v2/learninghub/sessions/?not_started&image_type=web',
  method: 'GET',
  auth,
  parse: parseSessions
});

const parseSession = (session) => {
  const mapQuestion = (question) => ({
    order: question.order,
    mandatory: question.mandatory,
    ...question.question
  });

  const mapContent = (content) => {
    if (content.contentType.toLowerCase() !== 'quiz') return content;

    return {
      ...content,
      quiz: {
        ...content.quiz,
        questions: _.map(content.quiz.questions, mapQuestion)
      }
    };
  };

  const mapSessionStep = (sessionStep) => (
    {
      ...sessionStep,
      content: _.map(sessionStep.content, mapContent)
    }
  );

  return {
    ...session,
    sessionSteps: _.map(session.sessionSteps, mapSessionStep)
  };
};

const parseMySessions = (sessions) => _.map(sessions, ({session, completedSteps}) => (
  {
    ...session,
    completedSteps,
  }
));

export const fetchMySessions = (auth) => request({
  url: 'api/v2/learninghub/mysessions/?image_type=web&ongoing_session=true',
  method: 'GET',
  parse: parseMySessions,
  auth
});


export const fetchSession = (sessionId, isLearningHub, auth) => request({
  url: replaceIfNecessary(`api/v2/learninghub/sessions/${sessionId}/`, isLearningHub),
  method: 'GET',
  auth,
  parse: parseSession
});


export const fetchBookmarkedSession = (auth) => request({
  url: 'api/v2/learninghub/sessions/?bookmark&image_type=web',
  method: 'GET',
  auth,
});

export const postActivity = (sessionId, stepId, isLearningHub, auth) => request({
  url   : replaceIfNecessary(`api/v2/learninghub/step-complete/`, isLearningHub),
  method: 'POST',
  data  : {
    session: sessionId,
    step   : stepId,
  },
  auth
});

export const bookmarkSession = (sessionId, auth) => request({
  url: `api/v2/learninghub/session/bookmark/`,
  method: 'POST',
  data: {
    session: sessionId,
  },
  auth
});

export const saveQuiz = (sessionId, stepId, quizId, answers, isLearningHub, mentorship, auth) => request({
  url: replaceIfNecessary('api/v2/learninghub/quiz/save-answer/', isLearningHub),
  method: 'POST',
  data: {
    session: sessionId,
    step: stepId,
    quiz: quizId,
    answer_json: answers,
    mentorship
  },
  auth,
});

export const saveQuizAsForm = async (sessionId, stepId, quizId, answers, isLearningHub, mentorship, files, auth) => {
  const formData = new FormData();

  formData.append("data", JSON.stringify({
    session: sessionId,
    step: stepId,
    quiz: quizId,
    answer_json: toSnakeCase(answers),
    mentorship
  }));

  _.forEach(files, file => isArray(file.answer) ? file.answer.forEach((item) => formData.append(file.questionKey, item))
    : formData.append(file.questionKey, file.answer));

  await request({
    url: replaceIfNecessary('api/v3/learninghub/quiz/save-answer/', isLearningHub),
    method: 'POST',
    data: formData,
    auth,
  });
}

export const getQuizAnswers = (sessionId, stepId, quizId, isLearningHub, auth) => request({
  url: replaceIfNecessary(`api/v2/learninghub/quiz/${sessionId}/${stepId}/${quizId}/`, isLearningHub),
  method: 'GET',
  auth,
});

export const saveSessionFeedback = (sessionId, feedback, auth) => request({
  url: 'api/v2/learninghub/session/feedback/',
  method: 'POST',
  data: {
    session: sessionId,
    ...feedback,
  },
  auth,
});


export const postStartSession = (sessionId, isLearningHub, auth) => request({
  url: replaceIfNecessary('api/v2/learninghub/session-start/', isLearningHub),
  method: 'POST',
  data: {
    session: sessionId,
  },
  auth,
});
