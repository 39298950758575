import {apiAction, dataAction, staticAction} from "actions/actionWrappers";
import {fetchSurveys, getSurvey} from "api/survey";
import {get} from 'lodash';
import {actions} from "reducers/surveyReducer";

const initSurvey = (surveyId) => async dispatch => {
  try {
    const survey = await dispatch(apiAction(getSurvey, surveyId));
    dispatch(staticAction(actions.GET_SURVEY))
    dispatch(dataAction(actions.STORE_SURVEY, survey))

  } catch (e) {
    console.log("Error in SurveyAction", e);
  }
};


const getAllSurveys = () => async dispatch => {
  let surveys = await dispatch(apiAction(fetchSurveys));
  await dispatch(dataAction(actions.STORE_SURVEYS, surveys));
};


export {
  initSurvey,
  getAllSurveys
};
