import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import MatchingInfoModal from "components/MatchingInfoModal";
import TrackerAvatar from "components/TrackerAvatar";
import {get, isEmpty, noop} from 'lodash';
import React, {useState} from "react";
import {getSubTitle} from "../business/matching";
import {useTranslation} from "react-i18next";


const styles = theme => ({
  matchCardWrapper: {
    display: 'flex',
    padding: 23,
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    "&:last-child": {border: 0, borderBottomRightRadius: 5, borderBottomLeftRadius: 5}
  },
  incompatible: {
    backgroundColor: '#DFDFDF',
  },
  icon: {
    backgroundColor: '#ffffff',
    color: '#808080',
    borderRadius: 2,
  },
  iconContainer: {
    backgroundColor: '#ffffff',
    width: 37,
    height: 37,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    marginRight: 16
  },
  infoButton: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#808080',
    backgroundColor: '#ffffff',
  },
  infoButtonWrapper: {
    paddingTop: 4,
  },
  role: {
    color: '#EB8E6F',
    fontSize: 12,
    marginTop: 8,
    fontWeight: 'bold'
  },
  userInfo: {
    color: '#EB8E6F',
    fontSize: 12,
  },
  name: {
    fontSize: 18,
    color: '#000000'
  },
});

const _groupMember = (member, classes, handleClose, open) =>
  <>
    {get(member, "id", false) &&
    <div className={classes.infoButtonWrapper}>
      <div className={classes.iconContainer} style={{borderRadius: 43}}
           onClick={handleClose}>
        <span className={classes.infoButton}>i</span>
        <MatchingInfoModal handleClose={handleClose} open={open} fullName={get(member, "fullName")}
                           matchId={member.id}/>
      </div>
    </div>
    }
    <div style={{display: 'flex', flex: 1, paddingLeft: get(member, "id", false) ? 0 : 34}}>
      <div style={{display: "flex", flexDirection: 'column',maxWidth:'75%'}}>
        <div className={classes.name}>{get(member, "fullName")}</div>
        <div className={classes.role}>{get(member, "role")}</div>
        <div className={classes.userInfo}>{getSubTitle(member)}</div>
      </div>
      <TrackerAvatar displayName={get(member, "fullName")} displayPicture={get(member, "displayPicture")}
                     styles={{marginLeft: 'auto', width: 49, height: 49, fontSize: 18}}/>
    </div>
  </>;

const EmptyMatchingCard = ({classes, isEmptySpot = false, member}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!Boolean(open));

  const _EmptyCard =
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', padding:'23px 54px'}}>
      {t('availableSpotForMentee')}
    </div>;


  return (
    <div
      className={classNames(classes.matchCardWrapper, isEmptySpot && classes.incompatible)}>
      {isEmptySpot ? _EmptyCard : _groupMember(member, classes, handleClose, open)}
    </div>
  );
};

export default withStyles(styles)(EmptyMatchingCard);


