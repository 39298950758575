import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Modal from "@material-ui/core/Modal/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import congratulationBackground from 'common/assets/congratulationBackground.png';
import {useTranslation} from "react-i18next";

const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#ffffff',
    width: 810,
    height: 508,
    outline: 0,
    borderRadius: 9,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage:`url(${congratulationBackground})`,
    backgroundRepeat:'no-repeat',
    backgroundPosition:'bottom',
    backgroundSize:'cover'
  },
  btn: {
    backgroundColor: '#E2795D',
    padding: '8px 24px',
    borderRadius: 5,
    color: '#ffffff',
    textAlign: 'center',
    fontSize: 12,
    marginTop: 18,
    cursor:'pointer'
  },
  description: {
    fontSize: 14,
    color: 'rgba(107, 107, 107, 0.82)',
    marginTop:13,
    textAlign: 'center',
    maxWidth:'44%',
  },
  title: {
    fontSize: 30,
    color: '#000000',
    fontWeight: 'bold',
  }
});

/*
background-image: url(/static/media/congratulationBackground.80fb0170.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
*/


const CongratulationsWindow = ({classes, open, handleClose}) => {
  const {t} = useTranslation();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div className={classes.title}>{t('congratulations')}</div>
        <div className={classes.description}>
          {t('congratulationsDescription')}
        </div>
        <div className={classes.btn} onClick={handleClose}>{t('proceed')}</div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(CongratulationsWindow);


