import {withStyles} from "@material-ui/core"
import {isEmpty} from 'lodash'
import moment from "moment"
import React from "react"
import EditIcon from 'common/assets/edit-icon.png';


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: '1px solid rgba(0, 0, 0, 0.04)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.04)',
    padding: '16px 18px',
    width: '100%',
  },
  EventActionBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  },
  eventSchedule: {
    fontWeight: 'bold',
    fontSize: 10,
    color: '#52B2E2',
  },
  EventTitle: {
    marginTop: 6,
    fontSize: 12,
    fontWeight: 'bold'
  },
  EventDescription: {
    marginTop: 6,
    fontSize: 12,
    lineHeight: '12px'
  },
  EventSessionName: {
    marginTop: 10,
    fontWeight: 'bold',
    fontSize: 10,
    padding: '5px 11px',
    borderRadius: 27,
    backgroundColor: '#FAF2EF',
  }
});

const getDate = (date) => moment(date).format('ddd, D MMM, YYYY, hh:mm a');

const CustomCalendarEvent = ({classes, event,editEvent}) => {
  return (
    <> {!isEmpty(event) &&
    <div className={classes.wrapper}>
      <div className={classes.EventActionBar}>
        <span className={classes.eventSchedule}>{getDate(event.scheduledTime)}</span>
        <span onClick={() => editEvent(event.id)}><img src={EditIcon} style={{width: 20}}/></span>
      </div>
      <div className={classes.EventTitle}>{event.title}</div>
      <div className={classes.EventDescription}>{event.description}</div>
      <div className={classes.EventSessionName}>{event.sessionName}</div>
    </div>}
    </>
  );
};

export default withStyles(styles)(CustomCalendarEvent);


