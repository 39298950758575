import MTMarkdown from "components/MTMarkdown";
import React from "react";
import {useSetTranslation} from "common/useSetTranslation";

export default function StepText({text = '', localeText = ''}) {
  const {st} = useSetTranslation();

  return (
    <div style={{margin: '8px 0px'}}>
      <MTMarkdown>{st(text, localeText)}</MTMarkdown>
    </div>
  );
};

