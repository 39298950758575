import {Jutsu} from "react-jutsu";
import React, {useEffect, useState, useRef} from "react";
import config from "common/config";
import MessageWindowHeader from "components/Chat/MessageWindowHeader";
import {messageWindowSubTitle, messageWindowTitle} from "business/channel";
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import CallIllustration from 'common/assets/callIllustration.svg';
import {useHistory, useLocation} from "react-router-dom";
import Consent from "components/Chat/Consent";

const styles = {
  wrapperRestored: {
    width: '100%'
  },
  wrapperFullScreen: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 40,
  },
  videoContainerRestored: {
    flex: '1 1 auto',
    width: '100%',
    height: '60vh',
  },
  videoContainerFullScreen: {
    flex: '1 1 auto',
    width: '100vw',
    height: '100%',
  },
  contentTitle: {
    fontFamily: 'Roboto',
    fontSize: '24px', fontWeight: 'bold', lineHeight: '120%',
    textAlign: 'center'
  },
  contentSubtitle: {
    fontFamily: 'Roboto',
    color: '#7E7E7E', fontSize: '24px', lineHeight: '120%', fontWeight: 'bold',
    textAlign: 'center',
    width: '50%'
  },
  videoContainerBackground: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: '#DFDFDF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
};

const interfaceConfigOverwrite = {
  APP_NAME: 'MentorToGo',
  DEFAULT_REMOTE_DISPLAY_NAME: 'You',
  HIDE_INVITE_MORE_HEADER: true,
  JITSI_WATERMARK_LINK: '',
  TOOLBAR_BUTTONS: [
    'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
    'fodeviceselection', 'hangup', 'profile',
    'settings', 'raisehand',
    'videoquality', 'filmstrip', 'stats', 'shortcuts',
    'tileview', 'download', 'help', 'recording'
  ],
  MEETING_NAME_ENABLED: false,
};

const configOverwrite = {
  prejoinPageEnabled: false,
};

const VideoFrameComponent = ({ classes, displayName, video = {}, callEnded, myId, isActive, joinVideo, logVideoEvent, submitConsent }) => {
  const {room = {}, channel = {}, callInProgress} = video;
  const [myJitsiParticipantId, _setMyJitsiParticipantId] = useState();
  const myJitsiParticipantIdRef = useRef(myJitsiParticipantId);
  const [recording, setRecording] = useState(false);
  const [showConsentScreen, setShowConsentScreen] = useState(true);
  const setMyJitsiParticipantId = data => {
    myJitsiParticipantIdRef.current = data;
    _setMyJitsiParticipantId(data);
  };

  const title = messageWindowTitle(channel, myId);
  const subTitle = messageWindowSubTitle(channel, myId);
  const [fullScreenVideo, setFullScreenVideo] = useState(false);
  const {t} = useTranslation();
  const [callJustEnded, setCallJustEnded] = useState(false);

  useEffect(() => {
    setShowConsentScreen(true);
  }, [isActive])

  const onMeetingEnd = () => {
    setCallJustEnded(true);
    setMyJitsiParticipantId(null);
    callEnded();
  };

  const onJitsi = (jitsiAPI) => {
    const videoConferenceJoinedHandler = (props) => {
      const numParticipants = jitsiAPI.getNumberOfParticipants();

      if (!recording) {
        // if current user is not the first participant
        jitsiAPI.stopRecording('file');
      }

      if (numParticipants === 1 && recording) {
        // if current user is the first participant
        jitsiAPI.startRecording({ mode: 'file' });
      }

      setMyJitsiParticipantId(props.id);
      logVideoEvent(room.room, "join", props.id);
    };

    const videoConferenceLeftHandler = () => {
      logVideoEvent(room.room, "leave", myJitsiParticipantIdRef.current);
    };

    jitsiAPI.addEventListener('videoConferenceJoined', videoConferenceJoinedHandler);
    jitsiAPI.addEventListener('videoConferenceLeft', videoConferenceLeftHandler);
  };

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('room')) {
      const room = queryParams.get('room');
      queryParams.delete('room');
      history.replace({
        search: queryParams.toString(),
      });
      joinVideo(room);
    }
  }, [location, history, joinVideo]);

  useEffect(() => {
    if (!isActive) {
      setCallJustEnded(false);
    }
  }, [isActive, setCallJustEnded]);

  const videoContainerStyle = fullScreenVideo ? styles.videoContainerFullScreen : styles.videoContainerRestored;
  const wrapperStyle = fullScreenVideo ? styles.wrapperFullScreen : styles.wrapperRestored;

  const VideoFrameHeader = ({ titlePrefix }) => (
    <MessageWindowHeader
      titlePrefix={titlePrefix}
      windowTitle={title}
      subTitle={subTitle}
      isFullScreen={true}
      showExpandBtn={false}
      showFullScreenButton={true}
      showVideoBtn={false}
      isLoading={false}
      channel={channel}
      onFullScreenRequested={() => setFullScreenVideo(!fullScreenVideo)}
      fullScreenVideo={fullScreenVideo}
    />
  );

  if (showConsentScreen) {
    return <Consent
      submitConsent
      onConsentSubmit={(consentValue) => {
        console.log('debug consent submitted.....', consentValue, consentValue?.key);
        setShowConsentScreen(false);
        if (consentValue?.key === true) {
          setRecording(true);
        }
        submitConsent(room.room, consentValue?.key === true);
      }}
    />
  }

  if (callJustEnded) {
    return (
      <div style={wrapperStyle}>
        <VideoFrameHeader titlePrefix={t('Call ended with')} />
        <div style={{ ...videoContainerStyle, display: 'flex', position: 'relative' }}>
          <div style={{ ...videoContainerStyle, ...styles.videoContainerBackground }}>
            <p className={classes.contentTitle}>{t('callEnded')}</p>
            <p className={classes.contentSubtitle}>{t('toStartAnotherCallHeadOverToChatSection')}</p>
          </div>
        </div>
      </div>
    );
  }

  if (callInProgress) {
    return (
      <div style={wrapperStyle}>
        <VideoFrameHeader titlePrefix={t('Chat with')} />
        <div style={{ ...videoContainerStyle, display: 'flex', position: 'relative' }}>
          <div style={{ ...videoContainerStyle, ...styles.videoContainerBackground }}>
            <img src={CallIllustration} alt='' style={{ height: '25%' }} />
            <p className={classes.contentTitle}>{t('yourCallWillBeginSoon')}</p>
          </div>
          {room.room && room.token &&
            <Jutsu
              domain={config.VIDEO_URL}
              containerStyles={{ ...videoContainerStyle, zIndex: 2, position: 'absolute', top: 0, left: 0 }}
              subject=' '
              roomName={room.room}
              jwt={room.token}
              requireDisplayName={true}
              displayName={displayName}
              onMeetingEnd={onMeetingEnd}
              onJitsi={onJitsi}
              userInfo={{ displayName: displayName }}
              interfaceConfigOverwrite={interfaceConfigOverwrite}
              configOverwrite={configOverwrite}
            />}
        </div>
      </div>
    );
  }

  return (
    <div style={wrapperStyle}>
      <div style={{ ...videoContainerStyle, display: 'flex', position: 'relative' }}>
        <div style={{ ...videoContainerStyle, ...styles.videoContainerBackground }}>
          <p className={classes.contentTitle}>{t('noVideoAvailableTitle')}</p>
          <p className={classes.contentSubtitle}>{t('noVideoAvailableSubtitle')}</p>
        </div>
      </div>
    </div>
  );
};

export const VideoFrame = withStyles(styles)(VideoFrameComponent);
