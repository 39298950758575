import {initBookmarkedSessions, toggleBookmark} from "actions/sessionAction";
import {connect} from 'react-redux';
import BookmarkedSessions from "views/BookmarkedSessions";


const mapStateToProps = ({session: {bookmarkedSessions}}) => ({
  bookmarkedSessions,
});

export default connect(mapStateToProps, {toggleBookmark, initBookmarkedSessions})(BookmarkedSessions);
