import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import Fab from "@material-ui/core/Fab/Fab";
import * as PropTypes from "prop-types";


const styles = theme => ({
  fabRoot: {
    backgroundColor: '#fff',
    border: '1px solid #DFDFDF'
  }
});


const ChatFloatingButton = ({classes, onClick, isActive}) => {

  return (
    <Fab color={'#fff'}
         onClick={onClick}
         classes={{
           root: classes.fabRoot
         }}>
      <ChatBubbleIcon style={{color: isActive ? '#E0B334' : '#DFDFDF'}}/>
    </Fab>
  );
};

export default withStyles(styles)(ChatFloatingButton);


ChatFloatingButton.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

ChatFloatingButton.defaultProps = {
  isActive: true
};
