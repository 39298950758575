import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import React, {Fragment} from "react";

const styles = theme => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '5px',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {}
  },
  focused: {
    border: '1px solid',
    borderColor: '#E0B334 !important'
  },
  error: {},
  notchedOutline: {
    border: 'none !important',
  },
});

const NumberInput = (props) => {
  const {onAnswered, classes} = props;

  const handleChange = (e) => {
    onAnswered(e.target.value);
  };

  return (
    <Fragment>
      <TextField
        {...props}
        onChange={handleChange}
        type={'number'}
        variant={'outlined'}
        fullWidth
        InputProps={{
          classes: {
            root: classes.root,
            focused: classes.focused,
            notchedOutline: classes.notchedOutline,
          },
        }}
      />
    </Fragment>
  );
};

export default withStyles(styles)(NumberInput);
