import {dataAction} from "actions/actionWrappers";

const SET_CURRENT_PATH = 'SET_CURRENT_PATH';
const RESET_CURRENT_PATH = 'RESET_CURRENT_PATH';

const createInitialState = () => ({
  currentPath: "",
});


const setCurrentPath = (pathName) => dispatch => {
  dispatch(dataAction(SET_CURRENT_PATH, pathName));
}


const NavMenu = (state = createInitialState(), action) => {
  switch (action.type) {
    case SET_CURRENT_PATH:
      return {
        ...state,
        currentPath: action.payload,
      };

    case RESET_CURRENT_PATH: {
      return {
        ...state,
        currentPath: "",
      };
    }
    default:
      return state;
  }
};


export {createInitialState, setCurrentPath};
export default NavMenu;
