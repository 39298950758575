import {connect} from 'react-redux';
import ChatFullScreen from "views/ChatFullScreen";
import {setCurrentPath} from "reducers/NavmenuReducer";
import {storeMeetingSummaryRating} from 'actions/chatAction';
import {getMeetingRatingStatus} from 'actions/videoAction';

const mapStateToProps = ({
  auth: {
    user: {
      id,
      status: {
        experiences: { video },
      },
    },
  },
  mentorship: {
    meetingSummaryRating,
  },
}) => ({
  videoEnabled: video,
  id,
  getMeetingRatingStatus,
  meetingSummaryRating,
});

export default connect(mapStateToProps, {
  setCurrentPath,
  getMeetingRatingStatus,
  storeMeetingSummaryRating,
})(ChatFullScreen);
