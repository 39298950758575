import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import Modal from "@material-ui/core/Modal/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import {getMatchInfo} from "actions/matchingAction";
import {convertUrl} from "common/helpers";
import {get, indexOf, isEmpty, isNull, map, sortBy} from 'lodash';
import React, {useEffect} from "react";
import {connect} from "react-redux";
import MTMarkdown from "./MTMarkdown";
import defaultMatchingIcon from 'common/assets/defaultMatchingIcon.png';
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";


const styles = theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#FFEFE9',
    width: 391,
    height: 650,
    outline: 0,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column'
  },
  nameTitle: {
    fontSize: 24,
    color: '#000000',
    borderBottom: '1px solid rgba(0, 0, 0, 0.13)',
    display: 'flex',
    padding: '3px 27px 27px 27px',
  },
  detailsWrapper: {
    padding: '42px 31px',
    overflowY: 'auto'
  },
  detailTitle: {
    color: 'rgba(0, 0, 0, 0.39)',
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'bold',
  },
  detailText: {
    fontSize: 14,
  },
  detailItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 33,
  },
  match: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#41A156',
    backgroundColor: '#ffffff',
    borderRadius: 29,
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 23,
    marginLeft: 'auto',
  },
  closeTxtBtn:{
    padding:'10px 12px',
    fontWeight:'bold',
    fontSize:12,
    cursor:'pointer'
  },
  iconWrapper: {
    backgroundColor: '#E2795D',
    borderRadius: '50%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    width:28,
    height:28,
  }

})


const MatchingInfoModal = ({classes, open, matchId, handleClose, getMatchInfo, matchInfo, fullName}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  useEffect(() => {
    getMatchInfo(matchId)
  }, [open]);

  const matchingParameters = sortBy(get(matchInfo, "criteriaJson"), criteria => criteria.order);
  const about = get(matchInfo, "about");

  const MatchingItem = ({isMatch = true, detailTitle = "", answer = [], matchedAnswer, icon, helpText,matchKey}) => {
    const isMatchedAnswer = (answer) => indexOf(matchedAnswer, answer) >= 0;
    const isGroupInfo = matchKey === 'group-info';
    return (
      <div className={classes.detailItem} style={{borderBottom: isGroupInfo && '1px solid rgba(0, 0, 0, 0.13)'}}>
        <div>
          <div className={classes.iconWrapper}>
            <img src={icon} style={{width: 12}}/>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', padding: '0px 8px',maxWidth:'100%'}}>
          <div style={{flexDirection:'column',display:'flex'}}>
            <div className={classes.detailTitle}>{detailTitle}</div>
            <div style={{display: 'flex', flexDirection: 'row',flexWrap:'wrap'}}>
              {map(answer, (answerText, i) => <span
                style={{fontWeight: isMatchedAnswer(answerText) ? 'bold' : 'normal',marginRight:!isGroupInfo && 2}}
                className={classes.detailText}>
                {isGroupInfo ? <MTMarkdown>{answerText}</MTMarkdown> : answerText}
                {(answer.length > 1 && i < answer.length - 1 && !isGroupInfo) && ","}</span>)}
            </div>
          </div>
          {!isEmpty(helpText) && <p className={classes.detailText} style={{marginTop:11}}>{helpText}</p>}
        </div>
        {isMatch && <div className={classes.match}>{t('match')}</div>}
      </div>
    );
  };


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <div className={classes.paper}>
        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
            <div onClick={handleClose} className={classes.closeTxtBtn} >{t('closeCaps')}</div>
        </div>
        <div className={classes.nameTitle}>
          <span style={{width: '50%'}}>{fullName}</span>
        </div>
        <div className={classes.detailsWrapper}>
          {map(matchingParameters, (match) => <MatchingItem isMatch={match.match} detailTitle={st(match.matchingCriteria, match.localeMatchingCriteria)}
                                                          answer={match.answer}
                                                          matchedAnswer={match.matchedAnswer}
                                                          helpText={st(match.helpText, match.localeHelpText)}
                                                          matchKey={match.key}
                                                          icon={isNull(match.icon) ? defaultMatchingIcon : convertUrl(match.icon)}/>)}
          {!isEmpty(about) && <MatchingItem
            detailTitle={t('about')}
            answer={[about]}
            icon={defaultMatchingIcon}
            isMatch={false}/>}
        </div>
      </div>
    </Modal>
  )
}


const mapStateToProps = ({
                           matching: {matchInfo}
                         }) => {
  return {
    matchInfo
  }
};

export default connect(mapStateToProps, {getMatchInfo})(withStyles(styles)(MatchingInfoModal))


