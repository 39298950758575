import {initStage, nextPage, previousPage, setAnswer, submitSurveyStage, getUserStageExistingAnswers} from "actions/stageAction";
import {connect} from 'react-redux';
import Stage from "views/survey/Stage";


const mapStateToProps = ({stage, survey: {survey}}) => {
  return ({
    stage,
    stageMetaData: stage.stage,
    survey
  })
};

export default connect(mapStateToProps, {initStage, nextPage, previousPage, setAnswer, submitSurveyStage, getUserStageExistingAnswers})(Stage);
