import ActiveMentorshipLearningContainer from "containers/Mentorship/ActiveMentorshipLearningContainer"
import MentorshipSessionIntroductionContainer from "containers/Mentorship/MentorshipSessionIntroductionContainer"
import MentorshipSessionStepWrapperContainer from "containers/Mentorship/MentorshipSessionStepWrapperContainer"
import MentorshipSessionSummaryContainer from "containers/Mentorship/MentorshipSessionSummaryContainer"
import SessionFeedbackContainer from "containers/SessionFeedbackContainer"
import SessionQuizContainer from "containers/SessionQuizContainer"
import React, {Fragment} from 'react'
import {Route} from "react-router-dom"


const UnlockedMentorship = () => {

  return (
    <Fragment>
      <Route path={'/mentorship/:sessionId/sessionintro/'} component={MentorshipSessionIntroductionContainer}/>

      <Route exact path={"/mentorship"} component={ActiveMentorshipLearningContainer}/>

      <Route path={'/mentorship/:sessionId/summary'}>
        <MentorshipSessionSummaryContainer/>
      </Route>
      <Route path={'/mentorship/:sessionId/step/:stepId'} component={MentorshipSessionStepWrapperContainer}/>

      <Route path={'/mentorship/:sessionId/step/:stepId/quiz/'} component={SessionQuizContainer}/>
      <Route path={'/mentorship/:sessionId/step/sessionfeedback/'} component={SessionFeedbackContainer}/>
    </Fragment>
  )

};

export default UnlockedMentorship;
