import React, { useEffect, useState } from 'react';
import { getToken, onMessageListener } from "../firebase/firebase-messaging";
import WebNotification from 'react-web-notification';
import { isNewNotificationSupported } from "common/helpers";

const PushNotification = ({storeDeviceToken, isAuthenticated}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState();

  useEffect(() => {
    if (isNewNotificationSupported()) {
      // console.log('New Notification supported. Fetching and storing token if auth');
      if (isAuthenticated) getToken(storeDeviceToken);
    }
  }, [isAuthenticated]);

  onMessageListener().then(payload => {
    // console.log('Foreground Push notification payload', payload);
    setNotification(payload);
    setShowNotification(true);
  }).catch(err => console.log('failed: ', err));

  return (
    showNotification && <WebNotification
      options={notification}
      // onShow={() => console.log('Notification shown')}
      onClick={() => setShowNotification(false)}
      onClose={() => setShowNotification(false)}
      onError={(e) => console.log('Notification onError', e)}
      title={notification.title}
    />
  );
}

export default PushNotification;
