import React from "react";


const styles = {
  titleWrapper: {
    flex: 1,
    height: '100%',
    paddingTop: 100,
    paddingLeft: 10,
    paddingRight: 50
  },
  title: {
    fontWeight: 'bold',
    fontSize: 32,
    display: 'inline',
    marginBottom: 0
  },
  description: {
    fontWeight: 'light',
    fontSize: 14,
    marginTop: 30,
  }
};


export default function PageTitle({title, description}) {
  return (
    <div style={styles.titleWrapper} className={"pageTitle"}>
      <p style={styles.title}>{title}</p>
      {description && <p style={styles.description}>{description}</p>}
    </div>
  );
};
