import IconButton from "@material-ui/core/IconButton/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import EditUserProfile from "components/MyProfile/EditUserProfile";
import {get, isEmpty} from 'lodash';
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    padding: '55px 52px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 18px',
      flexDirection:'column'
    },
      flexWrap:'wrap'
  },
  bottomRadius: {
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  topRadius:{
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  },
  profileImageContainer: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      display:'flex',
      justifyContent: 'center',
    }
    },
  profileImage: {
    width: '180px',
    height: '180px',
    borderRadius: '50%',
    objectFit:'cover'
  },
  imagePlaceHolder: {
    width: 180,
    height: 180,
    backgroundColor: '#ffffff',
    borderRadius: '50%'
  },
  profileDetail: {
    flex: 2,
    paddingLeft: 56,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      display:'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  viewMentorDetails: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  editButton: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    padding: '10px 16px',
    fontSize: 12,
    borderRadius: 3,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 36,
    color: '#000000',
  },
  bio: {
    fontWeight: 'normal',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.78)'
  },
  partnerTitle:{
    fontSize:18,
    color:'#ffffff',
    fontWeight:'bold'
  },
  phoneNumber:{
    fontSize:14,
    color:'rgba(0, 0, 0, 0.78)',
    marginTop:8,
    marginBottom:8
  }
});


const MyProfileCard = ({classes, profile, hasPartner, isSelf = true, saveProfile}) => {
  const {t} = useTranslation();

  const [openEditProfile, setOpenEditProfile] = useState(false);

  const firstName = get(profile, 'firstName', "");
  const lastName = get(profile, 'lastName', "");
  const bio = get(profile, 'bio', "");
  const picture = get(profile, 'picture', "");
  const phoneNumber = get(profile, 'phoneNumber', "");
  const role = get(profile, 'role', "");

  return (
    <div className={
      classNames(classes.wrapper, !hasPartner && classes.bottomRadius, !isSelf && classes.topRadius)}
         style={{backgroundColor: isSelf ? '#E0B334' : '#DE6E49'}}>
      <div className={classes.profileImageContainer}>
        {isEmpty(picture) ? <div className={classes.imagePlaceHolder}/>
                          : <img src={picture} className={classes.profileImage}/>}
      </div>
      <div className={classes.profileDetail}>
        {!isSelf && <div className={classes.partnerTitle}>{t('your')} {role}</div>}
        <div className={classes.title}>{firstName} {lastName}</div>
        <div className={classes.phoneNumber}>{phoneNumber}</div>
        <div className={classes.bio}>{bio}</div>
      </div>
      <div className={classes.viewMentorDetails}>
        <IconButton sizeSmall={true}>
          {isSelf && <div className={classes.editButton} onClick={() => setOpenEditProfile(true)}>{t('editProfile')}</div>}
        </IconButton>
      </div>
      <EditUserProfile open={openEditProfile} handleClose={() => setOpenEditProfile(false)} saveProfile={saveProfile}
                       fullName={firstName + " " + lastName} bio={bio} picture={picture} phoneNumber={phoneNumber}/>
    </div>
  );
};

export default withStyles(styles)(MyProfileCard);


