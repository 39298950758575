import Container from "@material-ui/core/Container";
import ScreeningStageBar from "components/ScreeningStageBar";
import {get} from 'lodash';
import React from "react";
import Page from "views/survey/Page";

export default function StageWrapper({submitSurveyStage, stage, stageList, currentStageId, nextPage, previousPage, setAnswer, existingAnswers }) {

  const {currentPage, totalPages,  errors, answers, fetchingStage, ...rest} = stage;
  const currentPageItem = get(rest.stage, `pages[${currentPage}]`);
  const isLastPage = () => totalPages === (currentPage + 1);

  return (
    <Container maxWidth={"md"} disableGutters={true} className={"StageWrapper"}>
      <ScreeningStageBar stages={stageList} currentStageId={currentStageId} totalPages={totalPages}
                         fetchingStage={fetchingStage}
                         currentPageNumber={currentPage + 1}/>
      <Page submitSurveyStage={() => submitSurveyStage(currentPageItem, answers, existingAnswers)} pageItems={currentPageItem}
            nextPage={() => currentPageItem && nextPage(currentPageItem, answers, existingAnswers)} previousPage={previousPage} setAnswer={setAnswer}
            answers={answers} pageErrors={errors} isLastPage={isLastPage()} fetchingStage={fetchingStage} existingAnswers={existingAnswers} isSurveySubmitting={stage.isSurveySubmitting} />
    </Container>
  );
};
