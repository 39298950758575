import {saveProfile} from "actions/authAction";
import MyProfile from "components/MyProfile/MyProfile";
import {connect} from 'react-redux';

const mapStateToProps = ({auth: {user}, mentorship: {partnerDetails}}) => {
  return ({
    profile: user,
    partnerDetails
  });
};

export default connect(mapStateToProps, {saveProfile})(MyProfile);
