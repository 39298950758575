import {get} from "lodash";

const actions = {
  STORE_ALL_SESSIONS: '[SESSIONS] STORE_ALL_SESSIONS',
  STORE_MY_SESSIONS: '[SESSIONS] STORE_MY_SESSIONS',
  STORE_CURRENT_SESSION: 'STORE_CURRENT_SESSION',
  RESET_CURRENT_SESSION: '[SESSIONS] RESET_CURRENT_SESSION',
  STORE_SESSION_QUIZ_ANSWERS: '[SESSIONS] STORE_SESSION_QUIZ_ANSWERS',
  STORE_QUIZ: '[SESSIONS] STORE_QUIZ',
  SESSION_NEXT_PAGE: '[SESSIONS] SESSION_NEXT_PAGE',
  SESSION_PREVIOUS_PAGE: '[SESSIONS] SESSION_PREVIOUS_PAGE',
  SET_QUIZ_VALIDATION_RESULTS: '[SESSIONS] SET_QUIZ_VALIDATION_RESULTS',
  RESET_CURRENT_QUIZ: '[SESSIONS] RESET_CURRENT_QUIZ',
  SET_CURRENT_QUIZ_COMPLETE: '[SESSIONS] SET_CURRENT_QUIZ_COMPLETE',
  SET_CURRENT_QUIZ_INCOMPLETE: '[SESSIONS] SET_CURRENT_QUIZ_INCOMPLETE',
  BOOKMARK_SESSION: '[SESSIONS] BOOKMARK_SESSION',
  STORE_QUIZ_ANSWERS: '[SESSIONS] STORE_QUIZ_ANSWERS',
  STORE_BOOKMARKED_SESSIONS: '[SESSIONS] STORE_BOOKMARKED_SESSIONS'
};

const createInitialState = () => ({
  allSessions: [],
  mySessions: [],
  bookmarkedSessions: [],
  currentSession: {sessionSteps: []},
  currentQuiz: {},
  quizError: {},
  currentStepQuizAnswers: [],
  currentPage: "",
  firstPage: "",
  lastPage: "",
  quizComplete: false,
  quizAnswers: []
});

export default (state = createInitialState(), action) => {
  switch (action.type) {
    case actions.STORE_ALL_SESSIONS: {
      return {
        ...state,
        allSessions: action.payload,
      };
    }
    case actions.STORE_BOOKMARKED_SESSIONS: {
      return {
        ...state,
        bookmarkedSessions: action.payload,
      };
    }
    case actions.STORE_MY_SESSIONS: {
      return {
        ...state,
        mySessions: action.payload,
      };
    }
    case actions.STORE_CURRENT_SESSION: {
      return {
        ...state,
        currentSession: action.payload,
      };
    }
    case actions.RESET_CURRENT_SESSION: {
      return {
        ...state,
        currentSession: {sessionSteps: []},
      };
    }
    case actions.STORE_SESSION_QUIZ_ANSWERS: {
      const sessionId = state.currentSession.id;
      const quizId = state.currentQuiz.id;
      const stepId = action.payload.stepId;

      return {
        ...state,
        currentStepQuizAnswers: {
          ...state.currentStepQuizAnswers,
          [sessionId]: {
            ...(state.currentStepQuizAnswers[sessionId] || {}),
            [stepId]: {
              quizId,
              answers: {
                ...(state.currentStepQuizAnswers[sessionId] && state.currentStepQuizAnswers[sessionId][stepId] ? state.currentStepQuizAnswers[sessionId][stepId].answers : {}),
                [action.payload.question.key]: action.payload.answer,
              }
            }
          }
        },
      };
    }
    case actions.STORE_QUIZ: {
      const firstPage = 0;
      const lastPage = action.payload.questions.length;
      return {
        ...state,
        currentQuiz: action.payload,
        currentPage: firstPage,
        firstPage,
        lastPage,
      };
    }
    case actions.SESSION_NEXT_PAGE: {
      const newPageNumber = action.payload.hasError ?
                            state.currentPage :
                            Math.min(state.currentPage + 1, state.lastPage - 1);
      return {
        ...state,
        currentPage: newPageNumber,
        quizError: action.payload.validationResults
      };
    }
    case actions.SET_QUIZ_VALIDATION_RESULTS: {
      return {
        ...state,
        quizError: action.payload.validationResults
      };
    }
    case actions.SESSION_PREVIOUS_PAGE: {
      return {
        ...state,
        currentPage: Math.max(state.currentPage - 1, 0),
      };
    }
    case actions.RESET_CURRENT_QUIZ: {
      return {
        ...state,
        currentQuiz: {},
        quizError: {},
        quizComplete: false,
        currentStepQuizAnswers: []
      };
    }
    case actions.SET_CURRENT_QUIZ_COMPLETE: {
      return {
        ...state,
        quizComplete: true,
      };
    }
    case actions.BOOKMARK_SESSION: {
      const sessionId = action.payload;
      const allSessions = state.allSessions.map((session) => ({
        ...session,
        isBookmarked: sessionId === session.id ? !session.isBookmarked : session.isBookmarked,
      }));

      const bookmarkedSessions = state.bookmarkedSessions.map((session) => ({
        ...session,
        isBookmarked: sessionId === session.id ? !session.isBookmarked : session.isBookmarked,
      }));

      const mySessions = state.mySessions.map((session) => ({
        ...session,
        isBookmarked: sessionId === session.id ? !session.isBookmarked : session.isBookmarked,
      }));

      const currentSession = get(state.currentSession, 'id') === sessionId ? {
        ...state.currentSession,
        isBookmarked: !state.currentSession.isBookmarked
      } : state.currentSession;

      return {
        ...state,
        allSessions,
        mySessions,
        currentSession,
        bookmarkedSessions
      };
    }
    case actions.STORE_QUIZ_ANSWERS: {
      const sessionId = action.payload.sessionId;
      const stepId = action.payload.stepId;
      const quizId = action.payload.quizId;
      const newQuizAnswer = {
        answers: action.payload.quizAnswers,
        sessionId: sessionId,
        stepId: stepId,
        quizId: quizId
      };

      return {
        ...state,
        quizAnswers: {
          ...state.quizAnswers,
          [sessionId]: {
            ...(state.quizAnswers[sessionId] || {}),
            [stepId]: {
              ...(state.quizAnswers[sessionId] && state.quizAnswers[sessionId][stepId] ? state.quizAnswers[sessionId][stepId] : {}),
              [quizId]: {
                ...newQuizAnswer,
              }
            }
          }
        }
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
};

export {actions};
