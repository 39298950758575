import {
  applyFeedFilter,
  getNextFeeds,
  initFeeds,
  postFeedLinkClick,
  setFeedFilter,
  toggleLike
} from "actions/feedAction"
import Feeds from "components/Feeds"
import {connect} from 'react-redux'


const mapStateToProps = ({feed: {feedPosts, loadingFeeds, endReached,filter}}) => ({
  feedPosts,
  loadingFeeds,
  endReached,
  filter,
});

export default connect(mapStateToProps, {
  getNextFeeds,
  initFeeds,
  toggleLike,
  postFeedLinkClick,
  setFeedFilter,
  applyFeedFilter,
})(Feeds)
