export default {
  API_BASE_URL: process.env.REACT_APP_HOST_URL,
  VIDEO_URL: process.env.REACT_APP_VIDEO_URL,
  API_PATH: '/',
  // API_BASE_URL: 'https://m2g.mentortogo.org',
  // API_BASE_URL: 'http://127.0.0.1:8000',
  FIREBASE_CONFIG: {
    APIKEY: process.env.REACT_APP_FIREBASE_APIKEY,
    AUTHDOMAIN: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    PROJECTID: process.env.REACT_APP_FIREBASE_PROJECTID,
    STORAGEBUCKET: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    MESSAGINGSENDERID: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    APPID: process.env.REACT_APP_FIREBASE_APPID,
    MEASUREMENTID: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
    DATABASEURL: process.env.REACT_APP_FIREBASE_DATABASEURL
  },
  CHATWOOT_BASEURL: process.env.REACT_APP_CHATWOOT_BASEURL,
  CHATWOOT_TOKEN: process.env.REACT_APP_CHATWOOT_TOKEN,
  CHATWOOT_HASH_KEY: process.env.REACT_APP_CHATWOOT_HASH_KEY,
  VAPID_KEY: process.env.REACT_APP_VAPID_KEY,
  GIPHY_SDK_KEY: process.env.REACT_APP_GIPHY_SDK_KEY,
  AI_ROLE_TYPE: process.env.REACT_APP_AI_CHANNEL_ROLE_TYPE,
};
