import {LightBulb} from "common/customIcon";
import MTMarkdown from "components/MTMarkdown";
import React from 'react';
import {useTranslation} from "react-i18next";
import {useSetTranslation} from "common/useSetTranslation";

const MentorTips = ({text, localeText}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  return (
    <div style={{
      backgroundColor: '#fcf5e8',
      borderRadius: 5,
      margin: 16,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    }}>
      <div style={{
        backgroundColor: '#e3cda4',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        paddingLeft: 8,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        display: 'flex',
      }}>
        <LightBulb/>
        <p style={{marginLeft: 8, fontWeight: 'bold', fontSize: 16, display: 'inline-block'}}>{t('mentorTip')}</p>
      </div>
      <MTMarkdown style={{marginLeft: 16}}>{st(text, localeText)}</MTMarkdown>
    </div>
  )
};

export default MentorTips;
