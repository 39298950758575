import MentorshipRewards from "components/MentorshipRewards";
import {connect} from 'react-redux';

const mapStateToProps = ({
                           mentorship: {
                             progress
                           }
                         }) => {
  return {
    badges: progress.badges
  };
};


export default connect(mapStateToProps)(MentorshipRewards);
