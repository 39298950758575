import { dataAction, silentApiAction, staticAction} from "actions/actionWrappers";
import {actions} from "reducers/chatReducer";
import {actions as mentorshipActions} from "reducers/mentorshipReducer";
import {fetchChatChannels, fetchUnreadCount, fetchChatBotUsers, storeUserChatRating, getChatRatingStatus, getChatChannelHistory, storeUserMeetingSummaryRating} from "api/chatApi";
// import {fetchBlackoutPeriod} from "api/eventsApi";

import { filter, get, isNumber } from "lodash";
import { getChatInstance } from "chat/WebSocket";
import { registerDevice } from "api/messaging";

const getChatChannels = () => async (dispatch, getState) => {
  try {
    dispatch(dataAction(actions.CHANNEL_API_ACTION, true));
    const channels = await dispatch(silentApiAction(fetchChatChannels));
    const {auth: {user: {id}}} = getState();
    dispatch(dataAction(actions.STORE_CHANNEL_LIST, {channels, myId: id}));
    dispatch(dataAction(actions.SET_ERROR, false))
    dispatch(dataAction(actions.CHANNEL_API_ACTION, false));

  } catch (e) {
    dispatch(dataAction(actions.SET_ERROR, true))
    dispatch(dataAction(actions.CHANNEL_API_ACTION, false));
  }
};

const getUnreadCount = () => async (dispatch) => {
  try {
    const unread = await dispatch(silentApiAction(fetchUnreadCount));
    dispatch(dataAction(actions.UPDATE_UNREAD_COUNT, unread));
    dispatch(dataAction(actions.SET_ERROR, false))
  } catch (e) {
    dispatch(dataAction(actions.SET_ERROR, true))
  }
};

const addMessages = (messages, nextPage, type) => async (dispatch) => {
  dispatch(dataAction(actions.ADD_MESSAGES, {messages: messages, nextPageNumber: nextPage, type}))
};

const addNewMessageToChannel = (message) => async (dispatch, getState) => {
  const {chat: {selectedChannelId}} = await getState();
  // alertSound.play();
  if (message.channel_id === selectedChannelId) {
    dispatch(dataAction(actions.ADD_NEW_MESSAGE_TO_CHANNEL, message))
  }
};

const updateChannelMemberLastViewedTime = (data) => async (dispatch, getState) => {
  const {chat: {chatChannels, channelsWithMemberId}} = await getState();
  dispatch(dataAction(actions.UPDATE_LAST_VIEWED_TIME, {chatChannels, channelsWithMemberId, channelId: data.channel_id, userId: data.user_id, lastViewedTime: data.last_viewed_timestamp}));
};

const updateUnreadCount = (message) => async (dispatch, getState) => {
  const {auth: {user: {id}}} = getState();
  const myUnreadMessages = filter(get(message
    , 'unread_messages'), unreadMessage => unreadMessage.user_id === id).map(channel => ({channelId: channel.channel, count: channel.count, data: channel.unread_messages}));
  dispatch(dataAction(actions.UPDATE_UNREAD_COUNT, myUnreadMessages));
}

const toggleMessageWindow = (state, channelId, messageId=null) => async (dispatch) => {
  if (messageId === null) {
    dispatch(staticAction(actions.CLEAR_MESSAGES));
  }
  dispatch(dataAction(actions.SET_MESSAGE_WINDOW, {
    state,
    channelId
  }));
};

const cleanupChat = () => async (dispatch, getState) => {
  const {chat: {selectedChannelId}} = await getState();
  if (isNumber(selectedChannelId)) await getChatInstance().leaveChannel(selectedChannelId);
  getChatInstance().close();
}

const storeDeviceToken = (token) => async (dispatch, getState) => {
  const {chat: {deviceToken}} = await getState();

  if (deviceToken !== token) {
    dispatch(dataAction(actions.STORE_DEVICE_TOKEN, token));
    dispatch(silentApiAction(registerDevice, {
      registrationId: token,
      type: 'web'
    }))
  }
}

const messageSeen = () => async (dispatch) => {
  dispatch(dataAction(actions.MESSAGE_SEEN))
};

const getChatBotUsers = () => async (dispatch) => {
  try {
    const chatBotUsers = await dispatch(silentApiAction(fetchChatBotUsers));
    dispatch(dataAction(actions.STORE_BOT_USERS, {chatBotUsers}));
    dispatch(dataAction(actions.SET_ERROR, false))
  } catch (e) {
    dispatch(dataAction(actions.SET_ERROR, true))
  }
};

// const getChatBlackoutPeriod = () => async dispatch => {
//   const today = moment().format("YYYY-MM-DD")
//   const chatBlackoutPeriod = await dispatch(apiAction(fetchBlackoutPeriod, today, today))
//   await dispatch(dataAction(actions.ADD_CHAT_BLACKOUT_PERIOD, chatBlackoutPeriod))
// };

const updateUserChatRating = (channelId, ratingsData) => async (dispatch, getState) => {
  try {
    const response = await dispatch(silentApiAction(storeUserChatRating, channelId, ratingsData));
    dispatch(dataAction(actions.STORE_USER_CHAT_RATING, {response}))
    dispatch(dataAction(actions.SET_ERROR, false))
  } catch (e) {
    dispatch(dataAction(actions.SET_ERROR, true))
  }
}

const getChatRatingModalStatus = (selectedChannelId) => async (dispatch) => {
  try {
    const chatRatingStatus = await dispatch(silentApiAction(getChatRatingStatus, selectedChannelId));
    dispatch(dataAction(actions.STORE_CHAT_RATING_STATUS, {chatRatingStatus}));
    dispatch(dataAction(actions.SET_ERROR, false))
  } catch (e) {
    dispatch(dataAction(actions.SET_ERROR, true))
  }
}

const getChannelHistory = (selectedChannelId) => async (dispatch) => {
  try {
    const chatChannelHistory = await dispatch(silentApiAction(getChatChannelHistory, selectedChannelId));
    dispatch(dataAction(actions.STORE_CHAT_CHANNEL_HISTORY, {chatChannelHistory}))
    dispatch(dataAction(actions.SET_ERROR, false))
  } catch (e) {
    dispatch(dataAction(actions.SET_ERROR, true))
  }
}

const storeMeetingSummaryRating = (ratingsPayloadData) => async (dispatch) => {
  try {
    const userMeetingSummaryRating = await dispatch(silentApiAction(storeUserMeetingSummaryRating, ratingsPayloadData));
    dispatch(dataAction(mentorshipActions.STORE_USER_MEETING_SUMMARY_RATING, {userMeetingSummaryRating}))
    dispatch(dataAction(actions.STORE_USER_MEETING_SUMMARY_RATING, {userMeetingSummaryRating}))
  } catch (e) {
    console.log(e);
  }
}

export {
  getChatChannels,
  toggleMessageWindow,
  addMessages,
  addNewMessageToChannel,
  getUnreadCount,
  // getChatBlackoutPeriod,
  updateUnreadCount,
  cleanupChat,
  storeDeviceToken,
  messageSeen,
  updateChannelMemberLastViewedTime,
  getChatBotUsers,
  updateUserChatRating,
  getChatRatingModalStatus,
  getChannelHistory,
  storeMeetingSummaryRating,
};
