import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { getChannelFor } from "business/channel";
import classNames from "classnames";

const styles = (theme) => ({
  searchMessage: {
    marginTop: 6,
    marginBottom: 6,
    fontSize: 12,
    color: "#808080",
    fontWeight: 400,
    cursor: 'pointer',
  },
  highlight: {
    color: "#3E3E3E",
    fontWeight: 600,
    fontSize: 12,
  },
  selectedMessage: {
    color: '#E0B334'
  }
});

const SearchedChatMessage = ({
  classes,
  message,
  chatChannels,
  searchedMessageText,
  myEmail,
  onItemClick,
  setSelectedMessageId,
  setSelectedTab,
  selectedTab,
  selectedMessageId,
  nextPageNumber,
}) => {
  const channel= getChannelFor(message.channelId, chatChannels);
  const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const highlightMatchingText = (text, match) => {
    const escapedMatch = escapeRegExp(match);
    return text.replace(
      new RegExp(escapedMatch, "gi"),
      (match) => `<span class="${classes.highlight} ${selectedTab === 'messages' && selectedMessageId === message.messageId && classes.selectedMessage}">${match}</span>`
    );
  };

  const extractTextAroundSearch = (text, searchText, maxChars = 65) => {
    const index = text.toLowerCase().indexOf(searchText.toLowerCase());
  
    if (index !== -1) {
      const start = Math.max(0, index - Math.floor(maxChars / 2));
      const end = Math.min(text.length, index + searchText.length + Math.ceil(maxChars / 2));
  
      const extractedText = text.substring(start, end);
      const ellipsis = end < text.length ? "..." : "";
      return start > 0 ? `...${extractedText}${ellipsis}` : `${extractedText}${ellipsis}`;
    }
  
    return text.substring(0, maxChars);
  }

  const displayPartialText = (text, search, maxChars) => {
    const message = renderParsedMessage()
    return extractTextAroundSearch(message, search, maxChars);
  };

  const displayName = channel.isGroup
    ? myEmail === message.user.userEmailId
      ? `You in [${channel.channelName}]`
      : `${message.user.firstName} ${message.user.lastName} in [${channel.channelName}]`
    : myEmail === message.user.userEmailId
    ? `You in [${channel?.channelName}]`
    : `${message.user.firstName} ${message.user.lastName}`;

  const renderParsedMessage = () => {
    const mentionRegex = /@\[([^]+?)\]\((\d+)\)/g;
    let parsedMessage = message.text;
    let parsedData;
    while ((parsedData = mentionRegex.exec(message.text)) !== null) {
      const mentionText = `@${parsedData[1]}`;
      parsedMessage = parsedMessage.replace(parsedData[0], mentionText);
    }
    parsedMessage = parsedMessage.replace(/<[^>]*>/g, ' ');
  
    return parsedMessage;
  };

  return (
    <div>
      <h1
        className={classNames(
          classes.searchMessage,
          selectedTab === "messages" &&
            selectedMessageId === message.messageId &&
            classes.selectedMessage
        )}
        onClick={() => {
          onItemClick(message.channelId, nextPageNumber, message.messageId);
          setSelectedMessageId(message.messageId);
          setSelectedTab("messages");
        }}
        dangerouslySetInnerHTML={{
          __html: `${displayName}: ${highlightMatchingText(
            displayPartialText(message.text, searchedMessageText, 65),
            searchedMessageText
          )}`,
        }}
      />
    </div>
  );
};

export default withStyles(styles)(SearchedChatMessage);
