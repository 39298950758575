import MomentUtils from '@date-io/moment';
import {makeStyles} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {map, noop} from 'lodash';
import React from "react";
import {useTranslation} from "react-i18next";


const useOutlinedInputStyles = makeStyles(theme => {
  return (
    {
      root: {
        backgroundColor: 'white',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1) inset',
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {}
      },
      notchedOutline: {
        border: '0px !important',
      },
    })
});

const DateSelection = ({onAnswered = noop(), value = null}) => {
  const {t} = useTranslation();
  const classes = useOutlinedInputStyles();
  const handleDateChange = date => {
    onAnswered(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        fullWidth
        id="date-picker-dialog"
        format="DD/MM/YYYY"
        value={value}
        disableFuture={'true'}
        onChange={handleDateChange}
        emptyLabel={`${t('selectDate')} (DD/MM/YYYY)`}
        openTo="year"
        KeyboardButtonProps={{
          'aria-label': 'Select Date',
        }}
        inputVariant="outlined"
        inputStyle={{backgroundColor: 'red'}}
        InputProps={{
          classes: {
            root: classes.root, notchedOutline: classes.notchedOutline
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
};


export default (DateSelection);
