import {loadCommunity} from "actions/supplementaryAction";
import {connect} from 'react-redux';
import MoreInfo from "views/MoreInfo";


const mapStateToProps = ({supplementary: {community, workReadinessScore, faqTopics}, profile: {displayName}}) => ({
  workReadinessScore,
  community,
  displayName,
  faqTopics
});

export default connect(mapStateToProps, {loadCommunity})(MoreInfo);
