import Button from "@material-ui/core/Button/Button";
import {groupCompatibilityStatus, isMatchActive, statuses} from "business/matching";
import _, {find} from 'lodash';
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";


const MentorMatchSubmitButton = ({matches, submit}) => {
  const {t} = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const allMatchesMade = !_.find(matches, match => match.status === statuses.ACTIVE && !(_.get(match, 'interestStatus', false) || _.get(match, 'groupCompatibility') === groupCompatibilityStatus.INCOMPATIBLE
      || _.get(match, 'groupCompatibility') === groupCompatibilityStatus.GROUP_FULL
      || _.get(match, 'groupCompatibility') === groupCompatibilityStatus.PAIR_COMPLETE
    )
    )
  ;
  const anyActiveMatch = find(matches, isMatchActive);

  const buttonTitle = t('confirmChoices');
  const handleClick = () => {
    setIsSubmitting(true);
    submit();
  };
  return (anyActiveMatch ? <Button variant="contained"
                                   disabled={!allMatchesMade || isSubmitting}
                                   style={{
                    backgroundColor: allMatchesMade ? '#EB8E6F' : '#A9A9A9',
                    height: 48,
                    fontSize: 12,
                    color: '#ffffff',
                    borderRadius: 0,
                  }}
                                   fullWidth
                                   size="large"
                                   onClick={handleClick}
  >
    {buttonTitle}
  </Button> : null)
};

export default MentorMatchSubmitButton
