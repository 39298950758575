import request from "api/request"

export const getMatchList = (auth) => request({
  url: 'api/v2/matching/get-match-list/',
  method: 'GET',
  auth,
})

export const getGroupDetails = (auth) => request({
  url: 'api/v2/matching/group-hold-info/',
  method: 'GET',
  auth,
});

export const getActiveMatchList = (auth) => request({
  url: 'api/v2/matching/get-match-list/?active=true&image_type=web',
  method: 'GET',
  auth,
})

export const fetchMatchInfo = (id, auth) => request({
  url: `api/v2/matching/get-match-info/${id}/`,
  method: 'GET',
  auth,
})

export const postMatch = (data, auth) => request({
  url: 'api/v2/matching/match-status/',
  method: 'POST',
  data,
  auth,
});
