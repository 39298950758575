import {emailSignIn, googleSignIn, resetErrorMessage} from "actions/authAction"
import {connect} from 'react-redux';
import SignIn from 'views/signup/SignIn'

const mapStateToProps = ({auth: {loginError,loginErrorMessage,inMaintenance}}) => {
  return ({
    loginError,loginErrorMessage,inMaintenance
  })
}

export default connect(mapStateToProps, {emailSignIn, googleSignIn,resetErrorMessage})(SignIn);
