import {apiAction, dataAction, staticAction} from "actions/actionWrappers";
import {fetchInternshipCards} from "api/internship";
import {actions} from "reducers/internshipReducer";

const getInternshipCards = () => async (dispatch) => {
  try {
    const cards = await dispatch(apiAction(fetchInternshipCards));
    dispatch(dataAction(actions.STORE_INTERNSHIP_CARDS, cards));
  } catch (e) {
    dispatch(staticAction(actions.ERROR_FETCHING_CARDS));
  }
};

export {
  getInternshipCards
};
