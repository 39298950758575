import request from "api/request";
import {sortBy} from 'lodash';
import moment from "moment";


const parseEvents = events => sortBy(events, event => event.scheduledTime);

export const fetchEvents = (mentorshipId, auth) => request({
  url: `api/v1/mentorships/${mentorshipId}/calendar-events/`,
  method: 'GET',
  auth,
  parse: parseEvents,
});

export const saveEvent = (mentorship, mentorshipGroupId, event, auth) => {
  return request({
    url: `api/v1/mentorships/${mentorship}/calendar-events/${event.id || ''}`,
    method: event.id ? 'PUT' : 'POST',
    data: {
      ...event,
      scheduledTime: moment(event.scheduledTime).toISOString(true),
      mentorship,
      mentor_group: mentorshipGroupId
    },
    auth,
  });
};

export const deleteEvent = (event, auth) => request({
  url: `api/v1/mentorships/${event.mentorship}/calendar-events/${event.id}/`,
  method: 'DELETE',
  auth,
});
