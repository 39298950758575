import {assign} from 'lodash';


export const actions = {
  FETCHING_STAGE_DETAILS: "[Stage] FETCHING_STAGE_DETAILS",
  ERROR_FETCHING_STAGE_DETAILS: "[Stage] ERROR_FETCHING_STAGE_DETAILS",
  STORE_STAGE: "[Stage] STORE_STAGE",
  NEXT_PAGE: "[Stage] NEXT_PAGE",
  PREVIOUS_PAGE: "[Stage] PREVIOUS_PAGE",
  SET_ANSWER: "[Stage] SET_ANSWER",
  SET_VALIDATION_ERROR: "[Stage] SET_VALIDATION_ERROR",
  STORE_USER_STAGE_EXISTING_ANSWERS: "[Stage] STORE_USER_STAGE_EXISTING_ANSWERS",
  SET_SURVEY_SUBMITTING: "[Stage] SET_SURVEY_SUBMITTING",
};

const initStageState = {
  stage: {},
  fetchingStage: false,
  errorFetchingStage: false,
  currentStageId: "",
  currentPage: 0,
  totalPages: "",
  userExistingAnswers: [],
  isSurveySubmitting: false,
};

export default (state = initStageState, action) => {
  switch (action.type) {
    case actions.FETCHING_STAGE_DETAILS :
      return {
        ...state,
        errorFetchingStage: false,
        fetchingStage: true,
        currentPage: 0,
        totalPages: 0,
      };
    case actions.ERROR_FETCHING_STAGE_DETAILS :
      return {
        ...state,
        errorFetchingStage: true,
        fetchingStage: false
      };
    case actions.STORE_STAGE :
      const totalPages = action.payload.pages.length;
      return {
        ...state,
        totalPages,
        stage: action.payload,
        fetchingStage: false,
        errorFetchingStage: false,
      };
    case actions.NEXT_PAGE:
      const newPageNumber = action.payload.hasError ?
                            state.currentPage :
                            Math.min(state.currentPage + 1, state.stage.pages.length - 1);
      return {
        ...state,
        currentPage: newPageNumber,
        errors: action.payload.validationResults
      };
    case actions.PREVIOUS_PAGE:
      return {
        ...state,
        currentPage: Math.max(state.currentPage - 1, 0),
      };
    case actions.SET_ANSWER:
      const newAnswer = {};
      newAnswer[action.payload.question.key] = action.payload.answer;
      return {
        ...state,
        answers: assign({}, state.answers, newAnswer),
      };
    case actions.SET_VALIDATION_ERROR:
      return {
        ...state,
        errors: action.payload.validationResults
      };
    case actions.STORE_USER_STAGE_EXISTING_ANSWERS:
      return {
        ...state,
        userExistingAnswers: action.payload
      };
    case actions.SET_SURVEY_SUBMITTING:
      return {
        ...state,
        isSurveySubmitting: action.payload
      };

    default:
      return state
  }
}
