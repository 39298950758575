import withStyles from "@material-ui/core/styles/withStyles";
import {getTimeRemaining, isMatchActive, isOneToMany, isOneToOne, trackerStatus} from "business/matching";
import {isMentee, isMentor} from "business/role";
import ScreenDescription from "components/ScreenDescription";
import {filter, find, get, isEmpty, map} from 'lodash';
import React, { useState } from "react";
import MatchingCard from "components/MatchingCard";
import ClubMemberRuleDisplay from "components/Matching/ClubMemberRuleDisplay";
import {useTranslation} from "react-i18next";
import { useSetTranslation } from "common/useSetTranslation";
import MenteeUnavailableWindow from "components/MenteeUnavailableWindow";
import MentorUnavailableWindow from "components/MentorUnavailableWindow";


const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '60px',
    [theme.breakpoints.down('xs')]: {
      padding: '24px 12px',
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  alternateTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  contentWrapper: {
    marginTop: 24,
    marginBottom: 40,
    backgroundColor: '#FFEFE9',
    borderRadius: 5,
    width: '390px',
    [theme.breakpoints.down('xs')]: {
      width: '320px'
    },
  },
});



const PotentialMatches = ({classes, matches, role, expiryHours, listDetail, onUpdateMatch, groupInfo, userMatchType, submitMatchesResponse}) => {
  const {t} = useTranslation();
  const {st} = useSetTranslation();

  const title = st(get(listDetail, "title"), get(listDetail, "localeTitle")) || t("potentialMatches");
  const description = st(get(listDetail, "description"), get(listDetail, "localeDescription"));
  const anyActiveMatch = find(matches, isMatchActive);
  const activeMatchLeft = !isEmpty(anyActiveMatch);
  const interestedTrackerCount = filter(filter(matches, isMatchActive), match => get(match, "interestStatus", false) === trackerStatus.INTERESTED).length;

  const actualSize = get(groupInfo, "actualSize");
  const groupSize = get(groupInfo, "groupSize");
  const isGroupFull = (interestedTrackerCount + actualSize) === groupSize;

  let timeRemaining = "";
  if (isMentee(role) && activeMatchLeft) {
    let timeStamp = get(anyActiveMatch, "updatedTimestamp");
    timeRemaining = getTimeRemaining(timeStamp, expiryHours);
  }

  const [openMenteeUnavailableWindow, setOpenMenteeUnavailableWindow] = useState(false);
  const [openMentorUnavailableWindow, setOpenMentorUnavailableWindow] = useState(false);

  React.useEffect(() => {
    if (submitMatchesResponse === 'MENTEE_UNAVAILABLE') {
      setOpenMentorUnavailableWindow(false);
      setOpenMenteeUnavailableWindow(true);
    }
    if (submitMatchesResponse === 'MENTOR_UNAVAILABLE') {
      setOpenMenteeUnavailableWindow(false);
      setOpenMentorUnavailableWindow(true);
    }
  }, [submitMatchesResponse]);


  return (
    <div className={classes.wrapper}>

      <MenteeUnavailableWindow open={openMenteeUnavailableWindow} handleClose={() => {}} />
      <MentorUnavailableWindow open={openMentorUnavailableWindow} handleClose={() => {}} />

      <div className={classes.title}>{title}</div>
      <ScreenDescription description={description}
                         title={title}
      />


      <div className={classes.contentWrapper}>
        {!isOneToOne(userMatchType) && isMentor(role) &&
        <ClubMemberRuleDisplay groupInfo={groupInfo} interesetedTrackerCount={interestedTrackerCount}/>}

        {map(matches, match => <MatchingCard
          key={match.id}
          role={role}
          onUpdateMatch={(interestStatus) => onUpdateMatch(match, interestStatus, isMentor(role), isOneToOne(userMatchType))}
          expiryHours={expiryHours}
          showCriteria={isMentor(role) && isOneToMany(userMatchType)}
          showExpiry={true}
          match={match}/>)}
      </div>

    </div>
  )
};

export default withStyles(styles)(PotentialMatches)


