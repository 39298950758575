import {convertUrl} from "common/helpers";
import React from "react";
import {useSetTranslation} from "common/useSetTranslation";
import { isEmpty } from "lodash";


export default function StepImage({img, localeImg}) {
  const {st} = useSetTranslation();

  const inputWidth = `${img.sizePercentage}%` || '100%';
  const imagePath = convertUrl(st(img.path, !isEmpty(localeImg) ? localeImg.path : null));

  return (
    <div style={{margin: '16px 0px', display: 'flex', justifyContent: 'center'}}>
      <img
        src={imagePath}
        style={{maxWidth: inputWidth, width: "expression(this.width > 360 ? 360: true)",alignSelf:'flex-start'}}
      />
    </div>
  );
};
